import React, { useEffect, useState } from "react";
import location from "../../../assets/images/icon_location.svg";
import profilepicture from "../../../assets/images/professional_profile.png";
import dummy from "../../../assets/images/dummy.jpeg";
import picture from "../../../assets/images/profilepicture.svg";
import pictureblue from "../../../assets/images/profilepicture-blue.svg";
import share from "../../../assets/images/share_profile.svg";
import styles from "../styles/style.module.css";
import Slider from "react-slick";
import { MyFriendsResponse } from "../interface";
import { GetMyFriends } from "../api";
import { useNavigate } from "react-router-dom";

// interface User {
//   id: number;
//   // Define other properties as needed, such as name, profilePicture, etc.
// }

const Myfriends = () => {
  const [myFriends, setMyFriends] = useState<MyFriendsResponse[]>([]);
  const navigate = useNavigate();
  const getConnectedFriends = async () => {
    const response = await GetMyFriends();
    setMyFriends(response?.data);
  };

  useEffect(() => {
    getConnectedFriends();
  }, []);

  console.log("friends", myFriends);

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3.1,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3.1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleClick = (userId: string) => {
    // Navigate to the chat screen route and pass receiverId as state
    navigate(`/chat-page/${userId}`);
  };

  return (
    <>
      <div className="recommendedjobs">
        <div className="row align-items-center justify-content-between">
          <Slider {...settings}>
            {myFriends?.length > 0 ? (
              myFriends?.map((data, idx) => {
                return (
                  <div
                    key={idx}
                    className="col border rounded-3 overflow-hidden bg-lightblue"
                  >
                    <div className="row flex-column align-items-center justify-content-between pt-3 mx-0">
                      <div className="row align-items-center justify-content-center g-0 position-relative">
                        <div className="col-3 profile_icon text-center mx-auto">
                          <figure className={styles.profileCircle}>
                            <img
                              src={
                                data?.user?.profilePicture
                                  ? data?.user?.profilePicture
                                  : dummy
                              }
                              alt=""
                              className="text-center mx-auto"
                            />
                          </figure>
                        </div>
                        <div className="col-9 ps-2">
                          <div className="d-flex align-items-start justify-content-between">
                            <div className="col-7">
                              <div className="newheadingstyle">
                                <h3 className="mb-0 text-16 clamp clamp1">
                                  {data?.user?.name.toLocaleUpperCase()}
                                </h3>
                                <p className="text-12 my-1">
                                  {data?.user?.occupation?.name}
                                </p>
                              </div>
                            </div>
                            <div className="col-4">
                              <span className={styles.status}>
                                {data?.user?.isAvailable
                                  ? "available"
                                  : "Unavailable"}
                              </span>
                            </div>
                          </div>
                          <div className="">
                            <p className="text-12 d-flex align-items-center mb-2">
                              <i className="me-1">
                                <img
                                  src={location}
                                  alt=""
                                  width={15}
                                  height={14}
                                />
                              </i>
                              {data?.user?.address}
                            </p>
                          </div>
                          <div className={styles.myfriendsshare}>
                            <figure className="bg-white rounded-circle p-2 mb-0">
                              <img src={share} alt="" />
                            </figure>
                          </div>
                        </div>
                      </div>

                      <div className="skills border-top pt-2">
                        <div className="newheadingstyle">
                          <h3 className="text-14">Programming Skills</h3>
                        </div>
                        <ul className="ps-0 d-flex flex-wrap align-items-center justify-content-start gap-2 mx-0">
                          {data?.user.skills?.map((skill, idx) => {
                            return (
                              <li
                                key={idx}
                                className="col badge bg-lightblue text-black rounded-pill fw-normal text-12"
                              >
                                {skill?.name}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="p-3 pt-0 border-top">
                      <div className={styles.payrates}>
                        <h3 className="fw-semibold pt-2 text-14 opacity-75">
                          Pay Rates / Hour
                        </h3>
                        <ul className="row g-3 ps-0 mb-0 mt-2">
                          <li className="col mt-0">
                            <div className="">
                              <h3 className="text-13 fw-noraml mb-2">Base</h3>
                              <p className="text-primary text-16 mb-2 fw-semibold">
                                ${data?.user?.rates?.baseRate}
                              </p>
                            </div>
                          </li>
                          <li className="col mt-0">
                            <div className="">
                              <h3 className="text-13 fw-noraml mb-2">
                                Overtime
                              </h3>
                              <p className="text-primary text-16 mb-2 fw-semibold">
                                ${data?.user?.rates?.overTimeRate}
                              </p>
                            </div>
                          </li>
                          <li className="col mt-0">
                            <div className="">
                              <h3 className="text-13 fw-noraml mb-2">
                                Sun/Holidays
                              </h3>
                              <p className="text-primary text-16 mb-2 fw-semibold">
                                ${data?.user?.rates?.doubleTimeRate}
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="border-top">
                        <h3 className="fw-semibold pt-2 text-14 opacity-75">
                          Working Experience
                        </h3>
                        <ul className="row g-3 ps-0 mb-0 mt-2">
                          {data?.user?.workExperiences?.map((data, idx) => {
                            return (
                              <li key={idx} className="col-12 mt-0">
                                <div className="d-flex align-items-center">
                                  <div className="">
                                    <i>
                                      <img src={picture} alt="" />
                                    </i>
                                  </div>
                                  <div className="ps-3">
                                    <h3 className="text-13 fw-noraml mb-0">
                                      {data?.occupation?.name}
                                    </h3>
                                    <p className="text-black text-12 mb-0 fw-normal">
                                      {data?.company?.name}
                                    </p>
                                    <p className="text-black text-12 mb-0 fw-normal">
                                      Jun 2022 — Present{" "}
                                      <span className="text-blue">
                                        1 yrs 02 mos
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="mt-3 border-top pt-3">
                        <div className="row align-items-center g-2">
                          <div className="col">
                            <button
                              type="button"
                              className="btn btn-transparent w-100 text-12 border-2 border-blue text-blue"
                            >
                              Remove
                            </button>
                          </div>

                          <div className="col">
                            <button
                              onClick={() => handleClick(data?.user?._id)}
                              type="button"
                              className="btn btn-primary w-100 text-12 border-2 border-blue"
                            >
                              Message
                            </button>
                          </div>

                          <div className="col">
                            <button
                              type="button"
                              className="btn btn-primary w-100 text-center border-2 border-blue text-12"
                            >
                              Endorse
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No Friends Added Yet</p>
            )}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Myfriends;
