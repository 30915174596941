import React, { useCallback, useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import tip from "../../../../assets/images/tip.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import edit from '../../../../assets/images/penbl.png'
import EditBusinessModal from "../../../NewBusiness/modals/EditBusinessModal";
import { userProfileResponse } from "../../../home/interface";
import Toltip from "../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";

const NewBusiness = ({ business, ProfileDetails }: any) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [editData,setEditData]=useState<any>()
    const businessNameRedux = useSelector((state: RootState) => state.business.businessName);
    const [profileDetails,setProfileDetails]=useState<any>()
    const navigate = useNavigate();
    const closeModal = () => { setShowModal(false) };
     const [tooltipVisibility, setTooltipVisibility] = useState<{
        [key: string]: boolean;
      }>({
        business: false
      })
    
      const handleIconClick = (tooltipKey: string) => {
        setTooltipVisibility(prev => ({
            business: false,
          [tooltipKey]: !prev[tooltipKey]
        }));
      };
      const handleClickOutside = useCallback((event: MouseEvent) => {
        const target = event.target as HTMLElement;
        const isTooltipIcon = target.closest(".tooltip-icon");
        const isTooltipContent = target.closest(".tooltip-content");
        const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line
    
        if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
          setTooltipVisibility({
            business: false
          });
        }
      }, []);
      useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
      }, [handleClickOutside])


    const openBusinessModal = (business:any) => {
        setEditData(business)
        setProfileDetails(ProfileDetails)
        setShowModal(true)
    }
    const createBusiness = () => navigate('/addBusiness');
    const createBusinessGroup = () => {
        navigate('/newBusiness')
    }

    return (
        <div className={styles.AboutEdit}>
            <div className="row align-items-center justify-content-between mb-3">
                <div className="col-6">
                    <div className="topTipHover">
                        <div className="d-flex align-items-center">
                            <h4 className="text-20 fw-bold font-inter mb-0">Business</h4>
                            <i onClick={() => handleIconClick("business")} className="tooltip-icon">
                  <img
                    className="customeTolImg"
                    src={tip}
                    alt="Tooltip"
                  />
                </i>
                <Toltip tooltipClass={tooltipVisibility.business ? "activetooltip" : ""}
                  slug={CONSTANT_MANAGER.SLUG.EDIT_PROFILE}
                  labelId={
                    CONSTANT_MANAGER.EDIT_PROFILE.BUSINESS
                  }
                />
                        </div>
                    </div>
                </div>
                <div className="col-6 text-end">
                    <span className="float-end">
                        {businessNameRedux ? (
                            <div></div>
                            // <img src={edit} onClick={()=>openBusinessModal(business)} alt="" />
                        ) : (
                            <button className="border-blue bg-blue text-white py-2 text-14 px-2 rounded-2"
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick={createBusiness}
                            >
                                + Create New Business
                            </button>
                        )}

                    </span>
                </div>
            </div>

            <div className={styles.personalinfo}>
                {businessNameRedux ? (
                    <ul className="row align-items-center border rounded-2 bg-light p-2 g-0">
                        <li className="col-12 mb-2">
                            <span className="">Name of Facility</span>
                            <label className="clamp clamp1">{business?.businessName}</label>
                        </li>
                        <li className="col-xl-4 col-md-6 col-12">
                            <span className="">Country</span>
                            <label className="clamp clamp1">{business?.state}</label>
                        </li>
                        {business?.street && (
                            <li className="col-xl-4 col-md-6 col-12">
                                <span className="">Street</span>
                                <label className="clamp clamp1">{business.street}</label>
                            </li>
                        )}
                        <li className="col-xl-4 col-md-6 col-12">
                            <span className="">City</span>
                            <label className="clamp clamp1">{business?.city}</label>
                        </li>
                        <li className="col-xl-4 col-md-6 col-12">
                            <span className="">Contact Name</span>
                            <label className="clamp clamp1">{ProfileDetails?.data?.name}</label>
                        </li>
                        <li className="col-xl-4 col-md-6 col-12">
                            <span className="">Contact Phone</span>
                            <label className="clamp clamp1">{ProfileDetails?.data?.phoneNumber}</label>
                        </li>
                        <li className="col-xl-4 col-md-6 col-12">
                            <span className="">Contact Email</span>
                            <label className="clamp clamp1">{ProfileDetails?.data?.email}</label>
                        </li>
                    </ul>
                ) : (
                    <p className="text-muted">You currently have no business added.</p>
                )}
            </div>

            {businessNameRedux && (
                <div className="text-start mt-3">
                    <button
                        className="border-blue bg-blue text-white py-2 text-14 px-2 rounded-2"
                        onClick={createBusinessGroup}
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        + Create Business Group
                    </button>
                </div>
            )}
            <EditBusinessModal profileDetails={profileDetails}  editData={editData} closeModal={closeModal} showModal={showModal} />
        </div>

    );
};

export default NewBusiness;
