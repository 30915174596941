import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { CongratulationComp } from "./CongratulationComp";
import confirmrole from "../../../assets/images/confirmrole.png";
import { addEmployee } from "../api"; // Ensure this is correctly imported
import { toast } from "react-toastify";

interface ConfirmationCompProps {
  memberData: any; // Get the selected user
  showModal: boolean;
  closeModal: () => void;
  refresh: () => void;
}

export const ConfirmationComp: React.FC<ConfirmationCompProps> = ({
  showModal,
  closeModal,
  memberData,
  refresh
}) => {
  console.log(memberData, "the member ");
  console.log(memberData?.businessId, "the member dataid");
  console.log(memberData?.occupationDetails[0]?.name, "the member occupation");
  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAssignRole = async (roleType: "manager" | "client") => {
    if (!memberData) return;

    const payload = {
      email: memberData.email,
      businessId: memberData?.businessId,
      businessGroupId: memberData?.businessMemberInfo[0]?.businessGroupId,
      type: roleType,
      primaryOccupation: memberData?.occupationDetails.map((occupation:any) => ({
        label: occupation.label,
        value: occupation.value,
      }))[0], 
      firstName: memberData.firstName || "",
      lastName: memberData.lastName || "",
    };

    try {
      setLoading(true);
      await addEmployee(payload);
      toast.success(`Successfully assigned as ${roleType}`);
      if (roleType === "manager") {
        setShowCongratsModal(true);
      }
      closeModal();
    } catch (error) {
      console.error("Error assigning role:", error);
      toast.error("Failed to assign role. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={closeModal} centered backdrop="static">
        <Modal.Header closeButton style={{ background: "#DBE3EC" }}>
          <Modal.Title className="d-none">Confirm Role</Modal.Title>
        </Modal.Header>
        <ModalBody style={{ background: "#DBE3EC" }}>
          <div className="modal_catch d-flex flex-column justify-content-center text-center rounded-0">
            <div className="text-center">
              <figure>
                <img src={confirmrole} alt="" className="img-fluid mx-auto my-4" />
              </figure>
            </div>
            <div className="col-lg-8 col-12 mx-auto">
              <h1 className="text-24 fw-bold">Confirm Role</h1>
              <p className="text-12 mb-0">
                Do you want to assign this professional as the <strong>Team Manager</strong> for
                this business group? Assigning them will grant them manager-level responsibilities
                and permissions.
              </p>
            </div>
          </div>
        </ModalBody>

        <ModalFooter className="btn-hover-effect justify-content-center border-top-0" style={{ background: "#DBE3EC" }}>
          <Button
            type="button"
            variant="secondary"
            onClick={() => handleAssignRole("client")}
            disabled={loading}
            className="btn bg-white arrowyellow border border-1 rounded-3 position-relative py-2 backbutton w-auto mb-3"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              {loading ? "Processing..." : "No"}
            </span>
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={() => handleAssignRole("manager")}
            disabled={loading}
            className="btn bg-blue arrowblue border border-1 rounded-3 position-relative py-2 w-auto mb-3"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-white">
              {loading ? "Processing..." : "Yes"}
            </span>
          </Button>
        </ModalFooter>
      </Modal>

      {showCongratsModal && <CongratulationComp refresh={refresh} closeModal={() => setShowCongratsModal(false)} showCongratsModal={showCongratsModal} />}
    </>
  );
};
