import React, { useCallback, useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../../assets/images/penbl.png";
import ProfExpModal from "../modals/ProfExpModal";
import { ProfExperience } from "../../interface";
import Toltip from "../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";
import tip from "../../../../assets/images/tip.png";

interface ModalProps {
  profData: ProfExperience[];
  refresh: () => void;
}

const ProfessionalExperience: React.FC<ModalProps> = ({
  profData = [],
  refresh,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<ProfExperience | null>(null);
  const [indexNumber, setIndexNumber] = useState<number | null>(null);
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: string]: boolean;
  }>({
    professionalexperience: false
  })
  const handleIconClick = (tooltipKey: string) => {
    setTooltipVisibility(prev => ({
      ...prev,
      [tooltipKey]: !prev[tooltipKey], // Toggle only the clicked tooltip
    }));
  };
  // const handleMouseLeave = (tooltipKey: string) => {
  //   setTooltipVisibility(prev => ({
  //     ...prev,
  //     [tooltipKey]: false, // Hide tooltip when not hovering
  //   }));
  // };
  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!target.closest(".tooltip-icon") && !target.closest(".tooltip-content")) {
      setTooltipVisibility({ professionalexperience: false });
    }
  }, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  const openModal = (data?: ProfExperience, index?: number) => {
    setEditData(data || null); // Set data to edit or null for new entry
    setIsModalOpen(true);
    console.log("index---", index);
    if (index !== null && index !== undefined && index >= 0) {
      console.log("index-if", index);
      setIndexNumber(index);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditData(null); // Clear data when closing modal
  };

  const handleEdit = (updatedData: ProfExperience) => {
    // Logic to update the specific education record
    console.log("Updated Education Data:", updatedData);
    // Implement the update logic here (e.g., make an API call)
    refresh(); // Refresh the education list
  };

  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-10 d-flex">
          <h5 className="text-20 fw-semibold mb-0 ">
            Experience{" "}
          </h5>
          <i
            className="tooltip-icon"
            onClick={() => handleIconClick("professionalexperience")}
            // onMouseEnter={() => handleMouseEnter("professionalexperience")}
          >
            <img className="customeTolImg" src={tip} alt="Tooltip" />
          </i>
          <Toltip
            tooltipClass={tooltipVisibility.professionalexperience ? "activetooltip" : ""}
            slug={CONSTANT_MANAGER.SLUG.EDIT_PROFILE}
            labelId={CONSTANT_MANAGER.EDIT_PROFILE.PROFESSIONAL_EXPERIENCE}
          />
        </div>
        <div className="col-12 mt-3">
          <h4 className="text-16 fw-medium mb-0">Work Experience </h4>
        </div>
      </div>
      <div>
        {profData?.length > 0
          ? profData?.map((data, ind) => (

            <div
              className="bg-light border rounded-2"
              key={ind}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
                padding: "5px",
              }}
            >
              <ul className={styles.experienceSet}>
                <li>
                  <span className={styles.expUi}></span>
                  <div>
                    <h4>{data?.occupationDetail?.name}</h4>
                    <h5>{data?.companyDetail?.name}</h5>
                    <h6>
                      <span>
                        {data?.from
                          ? new Date(data?.from).toLocaleDateString("default", {
                            month: "short",
                            year: "numeric",
                          })
                          : "No date added"}{" "}
                        -{" "}
                        {data?.isCurrent === "true"
                          ? "Present"
                          : data?.to
                            ? new Date(data?.to).toLocaleDateString("default", {
                              month: "short",
                              year: "numeric",
                            })
                            : "No date added"}{" "}
                        <span>
                          {data?.from && data?.to && data?.from !== "Invalid Date" && data?.to !== "Invalid Date"
                            ? `${new Date(
                              data?.isCurrent === "true" ? Date.now() : data?.to
                            ).getFullYear() -
                            new Date(data?.from).getFullYear()} yrs ${(new Date(
                              data?.isCurrent === "true" ? Date.now() : data?.to
                            ).getMonth() -
                              new Date(data?.from).getMonth() +
                              12) %
                            12
                            } mos`
                            : "No date range"}
                        </span>
                      </span>
                    </h6>

                    <h6>Responsibilites</h6>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data?.description ? data?.description : "",
                      }}
                    />
                  </div>
                </li>
              </ul>
              <i>
                <img
                  src={penbl}
                  alt=""
                  className="cursor-pointer ms-auto"
                  style={{ width: "15px", height: "15px" }}
                  onClick={
                    // () => handleEdit(data)
                    () => openModal(data, ind)
                  }
                />
              </i>
            </div>
          ))
          : "You haven't added any Professional Experience yet!"}
        <li>
          <h4
            className="text-14 fw-semibold text-blue cursor-pointer"
            onClick={() => openModal()}
          >
            + Add more
          </h4>
        </li>
      </div>
      <ProfExpModal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Edit Professional Experience"
        profExpData={profData}
        refresh={refresh}
        handleEdit={handleEdit}
        initialData={editData}
        indexNumber={indexNumber}
      />
    </div>
  );
};

export default ProfessionalExperience;
