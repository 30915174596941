import {axios} from '../../../lib/axios'
import { API_ROUTES } from '../../../utils/routes/apiRoutes'
import { GroupPayload,Employeepayload,BusinessPayloadd} from '../interface'
import { AxiosResponse } from '../../../shared/models'
import { teamListingRes } from '../interface'

export const addBusinessGroup=async (payload:any):Promise<GroupPayload>=>{
return axios.post(API_ROUTES.BUSINESS.BUSINESS_GROUP,payload)
}
export const addEmployee=async(payload:Employeepayload):Promise<Employeepayload>=>{
    return axios.post(API_ROUTES.BUSINESS.ADD_EMPLOYEE, payload)
}
export const addBusiness=async(payload:BusinessPayloadd):Promise<BusinessPayloadd>=>{
    return axios.post(API_ROUTES.BUSINESS.ADD_BUSINESS, payload)
}
export const getBusinessMembers=async()=>{
    return axios.get(API_ROUTES.BUSINESS.GET_BUSINESS_MEMBER)
}

export const listingTeam = async (businessId: any,
    businessGroupId: any): Promise<teamListingRes> => {
    return axios.post(API_ROUTES.BUSINESS.LISTINGTEAM, 
        {businessId: businessId,
        businessGroupId: businessGroupId}
    );
};

export const addconfirm=async(payload:BusinessPayloadd):Promise<BusinessPayloadd>=>{
    return axios.post(API_ROUTES.BUSINESS.ADD_BUSINESS, payload)
}
