import styles from "./styles.module.css";
import React from 'react';

const SkeletonLoader: React.FC<{ type: 'list' | 'details' }> = ({ type }) => {
    return (
      <div className={styles.skeletonloader}>
        {type === 'list' ? (
          <div className={styles.skeletonlist}>
            {[...Array(5)].map((_, idx) => (
              <div key={idx} className={styles.skeletonlistitem}></div>
            ))}
          </div>
        ) : (
          <div className={styles.skeletondetails}>
            <div className={styles.skeletonusername}></div>
            <div className={styles.skeletonjobtitle}></div>
            <div className={styles.skeletondescription}></div>
          </div>
        )}
      </div>
    );
};

export default SkeletonLoader;  // Export the component


  