import React, { useCallback, useEffect, useState } from "react";
import styles from "../../../styles/style.module.css";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "../../../../../redux/hooks";
import { RootState } from "../../../../../redux/store";
import { setWorkLocation } from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { Dropdown, FormControl } from "react-bootstrap";
import { setActiveStep } from "../../../../../redux/reducers/addContract";
import tip from "../../../../../assets/images/tip.png";
import Toltip from "../../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import { toast } from "react-toastify";

const WorkLocation: React.FC = () => {
  const dispatch = useDispatch();
  const { workLocation } = useAppSelector(
    (state: RootState) => state.addContract
  );
  const [selectedOption, setSelectedOption] = useState<any>(workLocation.workLocation);
  const options = ["Remote", "Hybrid", "Onsite"];
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: string]: boolean;
  }>({
    workLoc: false,
  });

  const formIk = useFormik({
    initialValues: { workLocation: workLocation.workLocation },
    onSubmit: () => {
      // dispatch(setActiveStep(ADD_CONTRACT_STEPS.PROCEDUCERS_POLICIES));
    },
  });

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.BACKGROUND_CHECK));
  };

  const handleGoToNextStep = () => {
    if (!formIk.values.workLocation) {
      toast.error('Work Location Is Required');
    }
    else {
      dispatch(setActiveStep(ADD_CONTRACT_STEPS.PROCEDUCERS_POLICIES));
    }
  };

  const onValueChange = (value: string, key: string): void => {
    formIk.setFieldValue(key, value);
    // const workLocation = {
    //   key: value,
    // };

    dispatch(setWorkLocation({ ...workLocation, [key]: value }));
    setSelectedOption(value);
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isTooltipIcon = target.closest(".tooltip-icon");
    const isTooltipContent = target.closest(".tooltip-content");
    const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line

    if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
      setTooltipVisibility({
        workLoc: false,
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  const handleIconClick = (tooltipKey: string) => {
    setTooltipVisibility(prev => ({
      workLoc: false,
      [tooltipKey]: !prev[tooltipKey]
    }));
  };

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formIk.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div className={styles.registerLeft}>
                  <div className="col-lg-11 col-12 text-center mx-auto">
                    <figure>
                      <img
                        src={choosetype}
                        alt=""
                        className="img-fluid w-100"
                      />
                    </figure>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <h4 className="text-white text-32 fw-semibold">
                  Choose the work location that suits you!
                </h4>
                <p className="text-white">
                  Add an additional information of the work that you
                  need
                </p>
                <div className={styles.signUPContainers}>
                  <div className={styles.registerRight}>
                    <>
                      <div className={styles.fromGroup}>
                        <div className="topTipHover">
                          <label className={styles.starlabel}>Select Work Location</label>
                          <i onClick={() => handleIconClick("workLoc")} className="tooltip-icon">
                            <img
                              className="customeTolImg"
                              src={tip}
                              alt="Tooltip"
                            />
                          </i>
                          <Toltip tooltipClass={tooltipVisibility.workLoc ? "activetooltip" : ""}
                            slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                            labelId={
                              CONSTANT_MANAGER.ADD_CONTRACT.WORK_LOCATION
                            }
                          />
                        </div>
                        <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            name="travelTip"
                          >
                            {selectedOption ||
                              "Select your work location"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div
                              style={{
                                maxHeight: "300px",
                                overflowY: "scroll",
                              }}
                            >
                              {options.map((item) => (
                                <Dropdown.Item
                                  key={item}
                                  onClick={() =>
                                    onValueChange(
                                      item,
                                      "workLocation"
                                    )
                                  }
                                >
                                  {item}
                                </Dropdown.Item>
                              ))}
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <div className="col-xl-4 col-12 text-start my-3 btn-hover-effect">
                    <h6
                      onClick={() => handleGoBack()}
                      className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold">
                        Back
                      </span>
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="" />
                      </i>
                    </h6>
                  </div>
                  {/* <div className="col-xl-8 col-12 d-flex justify-content-between"> */}
                    {/* <div className="col-xl-4 col-6 text-end my-3 btn-hover-effect">
                      <button
                        onClick={handleGoToNextStep}
                        className="btn arrowyellow border-grey border-1 position-relative"
                      >
                        <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                          Skip
                        </span>
                      </button>
                    </div> */}
                    <div className="col-xl-4 col-6 text-end my-3 btn-hover-effect">
                      <h6
                        onClick={handleGoToNextStep}
                        className="btn arrowblue border-grey border-1 position-relative pe-5"
                      >
                        <span className="px-lg-4 px-2 text-12 fw-semibold">
                          Next
                        </span>
                        <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                          <img src={whitearrow} alt="" />
                        </i>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/* </div> */}
        </form>
      </div>
    </>
  );
};

export default WorkLocation;
