import React, { useCallback, useEffect, useState } from "react";
import styles from "../styles/style.module.css"
import penbl from "../../../../assets/images/penbl.png"
import tip from "../../../../assets/images/tip.png";
import AccountRecievModal from "../modals/AccountRecievModal";
import { AccountReceival } from "../../interface";
import Toltip from "../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";

interface AccReceivalProps {
  AccountReceival: AccountReceival[];
  refresh: () => void;
}

const AccReceivableCont: React.FC<AccReceivalProps> = ({ refresh, AccountReceival = [] }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [editData, setEditData] = useState<AccountReceival | null>(null);
  const [indexNumber, setIndexNumber] = useState<number | null>(null);

  const [tooltipVisibility, setTooltipVisibility] = useState<{
      [key: string]: boolean;
    }>({
      accounts: false
    })
  
    const handleIconClick = (tooltipKey: string) => {
      setTooltipVisibility(prev => ({
        accounts: false,
        [tooltipKey]: !prev[tooltipKey]
      }));
    };
    const handleClickOutside = useCallback((event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const isTooltipIcon = target.closest(".tooltip-icon");
      const isTooltipContent = target.closest(".tooltip-content");
      const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line
  
      if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
        setTooltipVisibility({
          accounts: false
        });
      }
    }, []);
    useEffect(() => {
      document.addEventListener('click', handleClickOutside);
      return () => document.removeEventListener('click', handleClickOutside);
    }, [handleClickOutside])
  const openModal = (data?: AccountReceival, index?: number) => {
    setEditData(data || null);

    if (index !== null && index !== undefined && index >= 0) {
      setIndexNumber(index);
    }
    setShowModal(true);
  }
  const closeModal = () => { setShowModal(false) };
  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-12">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">
                Payables Contact
              </h4>
              <i onClick={() => handleIconClick("accounts")} className="tooltip-icon">
                  <img
                    className="customeTolImg"
                    src={tip}
                    alt="Tooltip"
                  />
                </i>
                <Toltip tooltipClass={tooltipVisibility.accounts ? "activetooltip" : ""}
                  slug={CONSTANT_MANAGER.SLUG.EDIT_PROFILE}
                  labelId={
                    CONSTANT_MANAGER.EDIT_PROFILE.ACCOUNT_RECEIVAL_CONTACT
                  }
                />
            </div>
          </div>
        </div>
        {/* <div className="col-2 text-end">
          <span>
            <img src={penbl} alt="" className="ms-auto cursor-pointer" onClick={() => openModal(admin, index)}/>
          </span>
        </div> */}
      </div>
      <div className={styles.personalinfo}>
        {AccountReceival.length === 0 ? (
          <p>No Accounts found.</p>
        ) : (
          AccountReceival.map((admin, index) => (
            <ul className="row align-items-center rounded-2 border bg-light p-2 mx-0 mb-2" key={index}>
              <li className="col-xl-3 col-6">
                <span>Name</span>
                <label className="clamp clamp1">{admin.name}</label>
              </li>
              <li className="col-xl-4 col-6">
                <span>Email</span>
                <label className="clamp clamp1">{admin.email}</label>
              </li>
              <li className="col-xl-2 col-6">
                <span>Phone</span>
                <label className="clamp clamp1">{admin.phoneNumber}</label>
              </li>
              <li className="col-xl-3 col-6">
                <img
                  src={penbl}
                  alt="Edit"
                  className="cursor-pointer ms-auto"
                  onClick={() => openModal(admin, index)}
                />
              </li>
              <li className="col-xl-3 col-6">
                <span>City</span>
                <label className="clamp clamp1">{admin.city}</label>
              </li>

              {/* Add Edit Icon */}
              
            </ul>
          ))
        )}

        {/* Add more button */}
        <div className="mt-2">
          <h4 className="text-14 fw-semibold text-blue cursor-pointer" onClick={() => openModal()}>
            + Add more
          </h4>
        </div>
      </div>
      <AccountRecievModal
       showModal={showModal} 
       closeModal={closeModal}
        title="Edit Accounts Receivable Contact"
         refresh={refresh}
         editData={editData}        // Pass the selected data for editing
         index={indexNumber} 
         accounReceivalData={AccountReceival}
          />

    </div>
  );
};

export default AccReceivableCont;
