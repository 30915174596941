import React, { useCallback, useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../../assets/images/penbl.png";
import bin from "../../../../assets/images/dlt.png";
import EducationModal from "../../../common/modals/education/page";
import { Link } from "react-router-dom";
import { EducationDetails } from "../../interface";
import Toltip from "../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";
import tip from "../../../../assets/images/tip.png";


interface EducationCertiProps {
  education: EducationDetails[];
  refresh: () => void;
}

const EducationCerti: React.FC<EducationCertiProps> = ({
  education = [],
  refresh,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<EducationDetails | null>(null);
  const [indexNumber, setIndexNumber] = useState<number | null>(null);
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: string]: boolean;
  }>({
    educationcerti: false
  })

  const handleIconClick = (tooltipKey: string) => {
    setTooltipVisibility(prev => ({
      ...prev,
      [tooltipKey]: !prev[tooltipKey], // Toggle only the clicked tooltip
    }));
  };
  const handleMouseEnter = (tooltipKey: string) => {
    setTooltipVisibility(prev => ({
      ...prev,
      [tooltipKey]: true, // Show tooltip on hover
    }));
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!target.closest(".tooltip-icon") && !target.closest(".tooltip-content")) {
      setTooltipVisibility({ educationcerti: false });
    }
  }, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  const openModal = (data?: EducationDetails, index?: number) => {
    setEditData(data || null); // Set data to edit or null for new entry
    setIsModalOpen(true);
    console.log("index---", index);
    if (index !== null && index !== undefined && index >= 0) {
      console.log("index-if", index);
      setIndexNumber(index);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditData(null); // Clear data when closing modal
  };

  const label = [
    "School name",
    "Degree/Certificate Name",
    "Year of Completion",
    "Specialization",
  ];

  const handleEdit = (updatedEducation: EducationDetails) => {
    // Logic to update the specific education record
    console.log("Updated Education Data:", updatedEducation);
    // Implement the update logic here (e.g., make an API call)
    refresh(); // Refresh the education list
  };

  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-10 d-flex">
          <h5 className="text-20 fw-semibold mb-0">
            {" "}
            Education{" "}
          </h5>
          <i
            className="tooltip-icon"
            onClick={() => handleIconClick("educationcerti")}
            // onMouseEnter={() => handleMouseEnter("educationcerti")}
          >
            <img className="customeTolImg" src={tip} alt="Tooltip" />
          </i>
          <Toltip
            tooltipClass={tooltipVisibility.educationcerti ? "activetooltip" : ""}
            slug={CONSTANT_MANAGER.SLUG.EDIT_PROFILE}
            labelId={CONSTANT_MANAGER.EDIT_PROFILE.EDUCATION_CERTIFICATION}
          />
        </div>
      </div>
      <div className={styles.addPop}>
        {education?.length > 0 ? (
          education?.map((data, index) => (
            <div
              className="bg-light p-2 border rounded-2"
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
                padding: "5px",
              }}
            >
              <div className="d-flex align-items-start justify-content-start">
                <ul className={styles.experienceSet}>
                  <li>
                    <span className={styles.expUi}></span>
                    <div>
                      <h5>
                        <strong>School:</strong> {data?.schoolDetails?.name}
                      </h5>
                      <h5>
                        <strong>Degree/Certificate:</strong>{" "}
                        {data?.degreeDetails?.name}
                      </h5>
                      <h5>
                        <strong>Year of Completion:</strong>{" "}
                        {data?.completionYear}
                      </h5>
                      <h5>
                        <strong>Specialization:</strong>{" "}
                        {data?.specializationDetails?.name}
                      </h5>
                      <h5>
                        <strong>Description:</strong> {data?.comment}
                      </h5>
                    </div>
                  </li>
                </ul>
              </div>
              <span style={{ alignItems: "center", justifyContent: "center", flexShrink: 0 }}>
                <div>
                  <img
                    src={penbl}
                    alt="Edit"
                    className={`cursor-pointer ${styles.penIcon}`} // Apply fixed size class
                    onClick={() => openModal(data, index)}
                  />
                </div>
              </span>

            </div>
          ))
        ) : (
          <p>You don’t have any certifications yet.</p>
        )}
        <h4
          className="text-14 fw-semibold text-blue cursor-pointer"
          onClick={() => openModal()}
        >
          + Add more
        </h4>
        {/* <button type="button" onClick={openModal}>
          {" "}
        </button> */}

        <EducationModal
          isOpen={isModalOpen}
          onClose={closeModal}
          title=" Edit Education"
          educationDetails={education}
          refresh={refresh}
          handleEdit={handleEdit}
          initialData={editData}
          indexNumber={indexNumber}
        />
      </div>
    </div>
  );
};


export default EducationCerti;
