import { sk } from "date-fns/locale";
import * as Yup from "yup";

const SkillValidationSchema = Yup.object().shape({
  skills: Yup.array()
    .of(
      Yup.object().shape({
        category: Yup.object()
          .shape({
            label: Yup.string().required("Manufacturer is required"),
            value: Yup.string().required("Manufacturer is required"),
          })
          .required("Manufacturer is required"),
        subCategory: Yup.array()
          .min(1, "At least one model/version/controller is required")
          .required("Model/Version/Controller is required"),
        isRequired: Yup.boolean().required("Skill requirement is required"),
      })
    )
    .min(1, "At least one skill is required"),
    skillNames: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required('Value is required').min(2, 'Value must be at least 2 characters'),
        label: Yup.string().required('Label is required').min(2, 'Value must be at least 2 characters'),
      })
    )
});

export {SkillValidationSchema}