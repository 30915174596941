import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { IOption } from "../../../Interfaces";
import blsearch from "../../../assets/images/blsearch.png";
import gprs from "../../../assets/images/gprs.png";

import loc from "../../../assets/images/icon_location_filled.svg";
import view from "../../../assets/images/view.png";
import { getMasterTypes } from "../../../feature/auth/signup/api";
import { MASTER_TYPE } from "../../../utils/enums/masterTypes.enum";
import styles from "../../common/css/styles/style.module.css";
import Header from "../../header/routes";
import { getRecommendedWork, getWorkDetailById } from "../api";
import { GET_RECOMMENDED_TYPE, JobResponse, Pagination } from "../interface";
import styless from "../styles/style.module.css";
import GoogleMaps from "./GoogleMaps";
import UserDetails from "./UserDetails";
import UserList from "./UserList";
import SkeletonLoader from "./SkeletonLoader";
import {
  faMicrophone,
  faMicrophoneLines,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import DateRangePicker from "./DateRange";
import { DateRange } from "react-date-range";
import { Button, Placeholder } from "react-bootstrap";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { id } from "date-fns/locale";
import Feeds from "../../Feeds/routes/feed";
import FullContract from "./FullContract";
const workType = [
  // Sample options for the Select component
  { label: "Hourly contract", value: "hourly_contract" },
  { label: "Direct job", value: "direct_job" },
  // Add more options as needed
];
const FindOpportunities: React.FC = () => {
  const [jobs, setJobs] = useState<JobResponse[]>([]); // Array of job data
  const [selectedUser, setSelectedUser] = useState<JobResponse | null>(null);
  const [workDetails, setWorkDetails] = useState<any>();
  const [workId, setWorkId] = useState<any>('');
  const [search, setSearch] = useState("");
  const [locationSearch, setLocationSearch] = useState("");
  const [loading, setLoading] = useState(true); // Loader state
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [isMapView, setIsMapView] = useState(false);
  const [skip, setSkip] = useState(0);
  const [noResults, setNoResults] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pagination, setPagination] = useState<Pagination | undefined>(
    undefined
  );
  const [occupationId, setOccupationId] = useState<string | undefined>(
    undefined
  );
  const [primaryData, setPrimaryData] = useState<IOption[]>([]);
  const [value, setValue] = useState<IOption[] | null>([]);
  const [showCalender, setShowCalender] = useState(false);
  const [range, setRange] = useState<
    { start_date: string; end_date: string } | undefined
  >();
  const [planTypeValue, setPlanTypeValue] = useState<string | undefined>();
  const [byStatusValue, setByStatusValue] = useState<string | undefined>();
  const [selectedWorkType, setSelectedWorkType] = useState(workType[0]);
  let initDate = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };
  const [initialDate, setInitialDate] = useState(initDate);
  const [isListening, setIsListening] = useState(false);
  const [showContract, setShowContract] = useState(false);
  const byStatus = [
    // Sample options for the Select component
    { label: "Open", value: "open" },
    { label: "Filled", value: "filled" },
    { label: "Completed", value: "completed" },
    // Add more options as needed
  ];


  interface QueryParams {
    workType: string | undefined;
    workTypeState: string | undefined;
    occupationId: string | undefined;
    search: string;
    locationSearch: string;
    startDate: string | undefined;
    endDate: string | undefined;
    limit: number;
    page: number;
    viewType: string;
  }
  const initalParams = {
    workType: undefined,
    workTypeState: undefined,
    occupationId: undefined,
    search: "",
    locationSearch: "",
    startDate: undefined,
    endDate: undefined,
    limit: 10,
    page: pageNumber,
    viewType: GET_RECOMMENDED_TYPE.LIST_VIEW,
  };
  const [queryParams, setQueryParams] = useState<QueryParams>(initalParams);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  useEffect(() => {
    if (search.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        setQueryParams((prev) => ({ ...prev, search }));
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
    if (locationSearch.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        setQueryParams((prev) => ({ ...prev, locationSearch }));
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [search, locationSearch]);

  useEffect(() => {
    fetchData();
  }, [queryParams]);

  useEffect(() => {
    if (transcript) {
      console.log("Transcript updated:", transcript);
      setSearch(transcript);
    }
  }, [transcript]);

  const fetchData = () => {
    setLoading(true);

    getRecommendedWork({ ...queryParams })
      .then((response) => {
        const jobsData = response?.data || [];
        const newPagination = response?.pagination; // T
        setJobs((prevJobs) => {
          // Use pageNumber to decide whether to replace or append jobs
          const updatedJobs = [...prevJobs, ...jobsData];

          // Update noResults state based on the updatedJobs length
          setNoResults(jobsData.length === 0);

          return updatedJobs; // Return the updated jobs array
        });

        if (newPagination) {
          setPagination(response.pagination);
        }
        if (jobsData.length > 0) {
          const selectedUser = jobsData[0]; // Select the first user
          setSelectedUser(selectedUser); // Set the selected user from jobsData

          // Fetch the detailed work information using the selected user's ID
          getWorkDetials(selectedUser._id);
        } else {
          setSelectedUser(null);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const getWorkDetials = async (id: string) => {
    const response: any = await getWorkDetailById(id);
    console.log(response?.data, "get work");
    setWorkDetails(response?.data);
  };
  const handleUserSelect = (user: JobResponse) => {
    setSelectedUser(user);
    getWorkDetials(user._id);
  };
  const handleMapViewClick = () => {
    const newViewType = isMapView
      ? GET_RECOMMENDED_TYPE.LIST_VIEW
      : GET_RECOMMENDED_TYPE.MAP_VIEW;
    setIsMapView(!isMapView); // Toggle between list and map view
    setJobs([]);
    setQueryParams((prev) => ({ ...prev, viewType: newViewType }));
  };
  const fetchMoreData = useCallback(() => {
    if (pagination && pagination?.nextPage) {
      setPageNumber((prev) => prev + 1);
      setQueryParams((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  }, [pagination]);
  const loadDropDowns = async () => {
    try {
      const occupationDataRes = await getMasterTypes(MASTER_TYPE.OCCUPATIONS);
      if (occupationDataRes?.data) {
        setPrimaryData(
          occupationDataRes?.data?.map((x: any) => ({
            label: x.name,
            value: x._id,
          }))
        );
      }
    } catch (error) {
      console.error("Failed to load school data", error);
    }
  };

  useEffect(() => {
    loadDropDowns();
  }, []);

  const handleSelectChange = (selectedOption: any) => {
    if (selectedOption) {
      const selectedOccupationId = selectedOption.value; // Get the selected value
      setOccupationId(selectedOccupationId); // Update state with the selected occupationId
      setValue(selectedOption);
      setJobs([]);
      setPageNumber(1);
      setQueryParams((prev) => ({
        ...prev,
        occupationId: selectedOccupationId,
        page: 1,
      }));
      console.log("Selected Occupation:", selectedOption); // Debug: check selected value
      // Call fetchData to use the new occupationId
    }
  };

  const handleSelectChangePlanType = (selectedOption: any) => {
    if (selectedOption) {
      const selectedPlanType = selectedOption.value;
      setPlanTypeValue(selectedPlanType);
      setPageNumber(1);
      setQueryParams((prev) => ({
        ...prev,
        workType: selectedPlanType,
        page: 1,
      }));
      setJobs([]);

      // setPlanTypeValue(selectedPlanType);
      console.log("Selected Occupation:", selectedOption);
    }
  };

  const handleSelectChangeByStatus = (selectedOption: any) => {
    if (selectedOption) {
      const selectedByStatus = selectedOption.value;
      setByStatusValue(selectedByStatus);
      setPageNumber(1);
      setQueryParams((prev) => ({
        ...prev,
        workTypeState: selectedByStatus,
        page: 1,
      }));
      setJobs([]);

      // setPlanTypeValue(selectedPlanType);
      console.log("Selected Occupation:", selectedOption);
    }
  };
  const resetFilters = () => {
    // Reset all filter states
    setPageNumber(1);
    setQueryParams({
      ...initalParams,
      page: 1, // Set the page back to 1 in queryParams
    });
    setJobs([]);
    setOccupationId(undefined);
    setPlanTypeValue(undefined);
    setByStatusValue(undefined);
    setValue(null);
    setSearch("");
    setLocationSearch("");
  };

  const startListening = () => {
    resetTranscript(); // Clear previous transcript
    SpeechRecognition.startListening({ continuous: true, language: "en-IN" }); // Start listening for speech
  };

  const stopListening = () => {
    SpeechRecognition.stopListening(); // Stop listening
    setSearch("");
  };

  const toggleMic = () => {
    if (isListening) {
      stopListening(); // Stop the microphone if it was listening
    } else {
      startListening(); // Start the microphone if it wasn't listening
    }
    setIsListening(!isListening); // Toggle the state
  };
  const handleShowContract = () => {
    setShowContract(true);
  };

  // Function to return to user list/details view
  const handleBackToList = () => {
    setShowContract(false);
  };
  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }



  const selectStyles = {
    control: (base: any) => ({
      ...base,
      fontSize: '14px',
      fontWeight: 'semibold',
      color: 'rgba(0, 0, 0, 0.5)',
      // borderRadius: '8px',
      padding: '4px 5px',
      height: "48px",
      border: '1px solid #fff !important',
      boxShadow: 'none',
      '&:focus': {
        border: '0 !important',
      },
    }),
  }

  return (
    <>
      <Header />
      <div className={styles.FondOpportuniy}>
        <div className="container">
          <div className={styles.SearchData}>
            <div className={`${styles.FormGroup}`}>
              <span className="SeachIc me-2" style={{ width: '20px' }}>
                <img src={blsearch} alt="" />
              </span>
              <input
                type="search"
                value={search}
                className="w-100"
                placeholder="Search by: Job tittle, Position, Keyword or People..."
                onChange={(event) => setSearch(event.target.value)}
              />
              <i className="btn bg-lightblue">
                <FontAwesomeIcon
                  icon={isListening ? faMicrophoneLines : faMicrophone}
                  color={isListening ? "green" : "text-primary"}
                  size="1x"
                  onClick={toggleMic}
                  style={{ cursor: "pointer" }}
                />
              </i>
              <span>{isListening ? "Listening..." : ""}</span>
            </div>

            <div className={`${styles.FormGroup}`}>
              <span className="SeachIc me-2" style={{ width: '20px' }}>
                <img src={loc} alt="" />
              </span>
              <input type=" text" value={locationSearch} onChange={(event) => setLocationSearch(event.target.value)} placeholder="City, state or zip code" />
              <span className={styles.SeachIcgps}>
                <img src={gprs} alt="" />
              </span>
            </div>
            <div className={`${styles.FormGroup}`} style={{ width: "10%" }}>
              <button
                className="btn btn-primary text-white">
                Search
              </button>
            </div>
          </div>

          <div className={styles.FilerData}>
            <ul className="dropdownfilterdata justify-content-end">
              <li>
                <Select
                  styles={selectStyles}
                  name="occupations"
                  placeholder="Occupation"
                  options={primaryData}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleSelectChange}
                  value={
                    primaryData.find(
                      (option) => option.value === occupationId
                    ) || null
                  }
                />
              </li>
              <li>
                <Select
                  styles={selectStyles}
                  name="opportunityType"
                  placeholder="Hourly contract"
                  options={workType}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(option: any) => {
                    setSelectedWorkType(option); // Update selected option
                    handleSelectChangePlanType(option); // Call your handler
                  }}
                  value={
                    workType.find((option) => option.value === planTypeValue) ||
                    null
                  }
                />
              </li>
              <li>
                <Select
                  styles={selectStyles}
                  name="byStatus"
                  placeholder="By Status"
                  options={byStatus}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleSelectChangeByStatus}
                  value={
                    byStatus.find((option) => option.value === byStatusValue) ||
                    null
                  }
                />
              </li>
              <li>
                <span className="bg-danger text-white fw-semibold text-14 cursor-pointer px-3 d-flex align-items-center rounded-pill" onClick={resetFilters}>
                  Reset
                </span>
              </li>
              <li>
                <button onClick={handleMapViewClick}>
                  <img src={view} alt="" />
                  <span className="bg-transparent p-0 border-0 h-auto me-0 text-14"> {isMapView ? "List View" : "Map View"}</span>
                </button>
              </li>
            </ul>
          </div>
          <div className="row mt-4">
           
              <>
                {/* Conditionally render skeletons or actual components */}
                {!showContract ? (
                  <>
                    
                      <UserList
                      loading={loading}
                        users={jobs || []}
                        onSelectUser={handleUserSelect}
                        fetchMoreData={fetchMoreData}
                        onViewFullContract={handleShowContract}
                      />
                 

                    {/* Conditionally render UserDetails or SkeletonLoader */}
                    {jobs.length > 0 ? (
                      <>
                        {!isMapView ? (
                          selectedUser ? (
                            <UserDetails
                              user={selectedUser}
                              workDetails={workDetails}
                              onViewFullContract={handleShowContract}
                            />
                          ) : (
                            <SkeletonLoader type="details" />
                          )
                        ) : (
                          <GoogleMaps
                            users={jobs}
                            onSelectUser={handleUserSelect}
                            selectedUser={selectedUser}
                          />
                        )}
                      </>
                    ):<div className="col-lg-8"><Placeholder  as={"div"} style={{ height: "100vh" }} animation="glow">
            
                    <Placeholder style={{ height: "100vh" }}   lg={12} />
                  </Placeholder></div>}
                  </>
                ) : (
                  <>
                    <FullContract  onBack={handleBackToList} workDetails={workDetails} user={selectedUser} />
                  </>
                )}
              </>
            
          </div>
        </div>
        <Feeds showHeader={false} showShoulder={false} />
      </div>

    </>
  );
};

export default FindOpportunities;
