import React, { useCallback, useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../../assets/images/penbl.png";
import tip from "../../../../assets/images/tip.png";
import EditCompanyInfoModal from "../modals/EditCompanyInfoModal";
import { companyInfoProps } from "../../interface";
import Toltip from "../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";

const CompanyInfo: React.FC<companyInfoProps> = ({ companyContact, refresh }) => {
  console.log(companyContact, "the contact")
  const [showModal, setShowModal] = useState<boolean>(false);
  const [tooltipVisibility, setTooltipVisibility] = useState<{
      [key: string]: boolean;
    }>({
      companyinfo: false
    })
  
    const handleIconClick = (tooltipKey: string) => {
      setTooltipVisibility(prev => ({
        companyinfo: false,
        [tooltipKey]: !prev[tooltipKey]
      }));
    };
    const handleClickOutside = useCallback((event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const isTooltipIcon = target.closest(".tooltip-icon");
      const isTooltipContent = target.closest(".tooltip-content");
      const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line
  
      if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
        setTooltipVisibility({
          companyinfo: false
        });
      }
    }, []);
    useEffect(() => {
      document.addEventListener('click', handleClickOutside);
      return () => document.removeEventListener('click', handleClickOutside);
    }, [handleClickOutside])
  const openModal = () => {
    setShowModal(true);
  }
  const closeModal = () => { setShowModal(false) };
  return (
    // <div className={styles.AboutEdit}>
    //   <div className="row align-items-center justify-content-between mb-3">
    //     <div className="col-lg-11 col-12">
    //       <div className="topTipHover">
    //         <div className="d-flex align-items-center">
    //           <h4 className="text-20 fw-bold  font-inter mb-0">
    //             Company Information 
    //           </h4>
    //           <i onClick={() => handleIconClick("companyinfo")} className="tooltip-icon">
    //               <img
    //                 className="customeTolImg"
    //                 src={tip}
    //                 alt="Tooltip"
    //               />
    //             </i>
    //             <Toltip tooltipClass={tooltipVisibility.companyinfo ? "activetooltip" : ""}
    //               slug={CONSTANT_MANAGER.SLUG.EDIT_PROFILE}
    //               labelId={
    //                 CONSTANT_MANAGER.EDIT_PROFILE.COMPANY_INFORMATION
    //               }
    //             />
    //         </div>
    //       </div>
    //     </div>
    //     <div className="col-lg-1 col-12 text-end">
    //       <span>
    //         <img src={penbl} className="cursor-pointer ms-auto" alt="" onClick={openModal} />
    //       </span>
    //     </div>
    //   </div>
    //   <div className={styles.personalinfo}>
    //     <ul className="row align-items-center">
    //       <li className="col-xl-4 col-6">
    //         <span className="">Company Name*</span>
    //         {/* <input
    //       className={styles.editable}
    //       name={PROFILE.FORMIK.name}
    //       onChange={profileDetailsFormik.handleChange}
    //       value={profileDetailsFormik.values?.name}
    //     /> */}
    //         <label className="clamp clamp1">{companyContact?.name}</label>
    //       </li>
    //     </ul>
    //     <ul className="row align-items-center">
    //       <li className="col-xl-4 col-6">
    //         <span className="">Company Address*</span>
    //         {/* <input
    //     className={styles.editable}
    //     name={PROFILE.FORMIK.name}
    //     onChange={profileDetailsFormik.handleChange}
    //     value={profileDetailsFormik.values?.name}
    //   /> */}
    //         <label className="clamp clamp1">{companyContact?.city}</label>
    //       </li>
    //       <li className="col-xl-4 col-6">
    //         <span className="">Company Phone*</span>
    //         {/* <input
    //     className={styles.editable}
    //     name={PROFILE.FORMIK.name}
    //     onChange={profileDetailsFormik.handleChange}
    //     value={profileDetailsFormik.values?.name}
    //   /> */}
    //         <label className="clamp clamp1">{companyContact?.phoneNumber}</label>
    //       </li>
    //       <li className="col-xl-4 col-6">
    //         <span className="">Company Email*</span>
    //         {/* <input
    //     className={styles.editable}
    //     name={PROFILE.FORMIK.name}
    //     onChange={profileDetailsFormik.handleChange}
    //     value={profileDetailsFormik.values?.name}
    //   /> */}
    //         <label className="clamp clamp1">{companyContact?.email}</label>
    //       </li>
    //     </ul>
    //   </div>
    //   <EditCompanyInfoModal
    //     refresh={refresh}
    //     showModal={showModal}
    //     closeModal={closeModal}
    //     companyContact={
    //       companyContact
    //         ? {
    //           name: String(companyContact.name),
    //           city: String(companyContact.city), // Convert city to string
    //           phoneNumber: String(companyContact.phoneNumber),
    //           email: String(companyContact.email),
    //         }
    //         : undefined
    //     }
    //     title="Edit Company Information (Mandatory for job postings....)"
    //   />
    // </div>
    <></>
  );
};

export default CompanyInfo;
