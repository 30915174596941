import React, { useCallback, useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import tip from "../../../../assets/images/tip.png";
import penbl from "../../../../assets/images/penbl.png";
import { ProfessInfo } from "../../interface";
import ProfessionalInfoModal from "../modals/ProfessionalInfoModal";
import Toltip from "../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";

const ProfessionalInfo: React.FC<ProfessInfo> = ({
  primaryOccu = [],
  secondaryOccu = [],
  refresh,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<ProfessInfo | null>(null);
  const [indexNumber, setIndexNumber] = useState<number | null>(null);
     const [tooltipVisibility, setTooltipVisibility] = useState<{
        [key: string]: boolean;
      }>({
        professionalinformation: false
      })

  console.log(primaryOccu);
  console.log(secondaryOccu);

  const openModal = (data?: ProfessInfo, index?: number) => {
    setEditData(data || null); // Set data to edit or null for new entry
    setIsModalOpen(true);
    console.log("index---", index);
    if (index !== null && index !== undefined && index >= 0) {
      console.log("index-if", index);
      setIndexNumber(index);
    }
  };
  const handleIconClick = (tooltipKey: string) => {
    setTooltipVisibility(prev => ({
      professionalinformation: false,
      [tooltipKey]: !prev[tooltipKey]
    }));
  };

     const handleClickOutside = useCallback((event: MouseEvent) => {
        const target = event.target as HTMLElement;
        const isTooltipIcon = target.closest(".tooltip-icon");
        const isTooltipContent = target.closest(".tooltip-content");
        const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line
    
        if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
          setTooltipVisibility({
            professionalinformation:false
          });
        }
      }, []);
      useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
      }, [handleClickOutside]);

  const closeModal = () => {
    setIsModalOpen(false);
    setEditData(null); // Clear data when closing modal
  };

  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-10">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">
              Occupations
              </h4>
              <i onClick={() => handleIconClick("professionalinformation")} className="tooltip-icon">
                <img
                  className="customeTolImg"
                  src={tip}
                  alt="Tooltip"
                />
              </i>
              <Toltip tooltipClass={tooltipVisibility.professionalinformation ? "activetooltip" : ""}
                slug={CONSTANT_MANAGER.SLUG.EDIT_PROFILE}
                labelId={
                  CONSTANT_MANAGER.EDIT_PROFILE.PROFESSIONAL_INFORMATION
                }
              />
            </div>
          </div>
        </div>
        <div className="col-2 text-end">
          <span>
            <img
              src={penbl}
              className="cursor-pointer ms-auto"
              alt=""
              onClick={() => openModal()}
            />
          </span>
        </div>
      </div>
      <div className={styles.personalinfo}>
        <ul className="row align-items-center">
          <li className="col-xl-4 col-6">
            <span className="">Primary Occupation*</span>
            {primaryOccu && primaryOccu.length > 0 ? (
              primaryOccu.map((data, ind) => (
                <label key={ind}>{data?.name}</label>
              ))
            ) : (
              <label>No primary occupation selected.</label>
            )}
          </li>
          <li className="col-12 mt-4">
            <span className="">Additional Occupations</span>
            {secondaryOccu && secondaryOccu.length > 0 ? (
              secondaryOccu.map((data, ind) => (
                <label key={ind}>{data?.name}</label>
              ))
            ) : (
              <label>You have not added any additional occupations yet!</label>
            )}
          </li>
        </ul>
      </div>
      <ProfessionalInfoModal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Edit Occupations"
        primaryOccuDetails={primaryOccu}
        secondaryOccuDetails={secondaryOccu} 
        refresh={refresh}
      />
    </div>
  );
};

export default ProfessionalInfo;
