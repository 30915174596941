import React, { useState, useEffect } from "react";
import { ModalBody, ModalFooter, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import security from "../../../../assets/images/security.svg";
import blackarrow from "../../../../assets/images/blackarrow.svg";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import upload from "../../../../assets/images/upload.svg";
import styles from "../styles/style.module.css";
import { uploadFile } from "../../../../common/api";
import { updateUserProfile } from "../../../home/api";
import { toast } from "react-toastify";

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  title: string;
  refresh: () => void;
  editData: any; 
}

const EditPhotoModal: React.FC<ModalProps> = ({
  showModal,
  closeModal,
  title,
  refresh,
  editData,
}) => {
  const [name, setName] = useState<string>("");
  const [file, setFile] = useState<any>("");
  const [errors, setErrors] = useState<{ name?: string; file?: string }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (editData) {
      setName(editData?.name || "");
      setFile(editData?.filename || "");
    }
  }, [editData]);

  const handleFileUpload = async (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0];

      // Allowed file types
      const validTypes = ["image/jpeg", "image/png"];
      if (!validTypes.includes(file.type)) {
        toast.error("Only JPEG and PNG files are allowed!");
        return;
      }

      // Maximum file size: 2MB
      const maxSize = 2 * 1024 * 1024;
      if (file.size > maxSize) {
        toast.error("File size must not exceed 2MB!");
        return;
      }

      const formData = new FormData();
      formData.append("file", file);

      try {
        toast.info("Uploading file...");
        const response = await uploadFile(formData);
        const newFileUrl = response.data.files;
        setFile(newFileUrl);
        setErrors((prevErrors) => ({ ...prevErrors, file: "" }));
        toast.success("File uploaded successfully!");
      } catch (err) {
        toast.error("Error uploading file. Please try again.");
      }
    }
  };

  const validate = () => {
    let hasError = false;
    const newErrors: { name?: string; file?: string } = {};

    if (!name.trim()) {
      newErrors.name = "Name is required";
      hasError = true;
    }

    if (!file) {
      newErrors.file = "File upload is required";
      hasError = true;
    }

    setErrors(newErrors);
    return !hasError;
  };

  const handleUploadPhoto = async () => {
    if (validate()) {
      setIsSubmitting(true);
      const payload = {
        photoIdDoc: {
          filename: file,
          name: name,
        },
      };

      try {
        await updateUserProfile(payload);
        refresh();
        setIsSubmitting(false);
        toast.success("Updated Successfully");
        closeModal();
      } catch (error) {
        toast.error("Error occurred while uploading the photo.");
      }
    }
  };

  const removeUploadedFiles = (): void => {
    setFile("");
  };

  return (
    <Modal show={showModal} onHide={closeModal} centered size="lg" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <div className="addModalPro">
          <form>
            <div className="row">
              <div className="col-12 mb-3">
                <div className="bg-lightblue row align-items-center justify-content-center p-3 mx-0 rounded-2">
                  <div className="col-1 text-center">
                    <i><img src={security} alt="" className="img-fluid" /></i>
                  </div>
                  <div className="col-11">
                    <p className="text-14 fw-medium text-black mb-0">
                      Upload a copy of your photo ID. This helps verify your identity.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="formGroup">
                  <label>Name</label>
                  <div>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setErrors((prevErrors) => ({ ...prevErrors, name: "" }));
                      }}
                      className="form-control"
                      placeholder="Enter document name"
                    />
                    {errors.name && <span className="text-danger">{errors.name}</span>}
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="formGroup">
                  <label>Upload Image</label>
                  <div className={styles.uploadimagefile}>
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control"
                      onChange={({ target }) => handleFileUpload(target.files)}
                    />
                    <div className={styles.uploadimage}>
                      <i>
                        <img src={upload} alt="" />
                      </i>
                      <p className="mb-0 text-14 text-blue">
                        Upload JPEG/PNG file
                      </p>
                    </div>
                    {errors.file && <span className="text-danger">{errors.file}</span>}
                  </div>
                </div>

                {file && (
                  <li className="mt-3">
                    <div className={styles.uploadImages}>
                      <img src={file} alt="" className="img-fluid" />
                      <i className="fas fa-times" onClick={removeUploadedFiles}></i>
                    </div>
                  </li>
                )}
              </div>
            </div>
          </form>
        </div>
      </ModalBody>
      <ModalFooter className="btn-hover-effect mb-2 justify-content-end">
        <Button
          type="button"
          variant="secondary"
          onClick={closeModal}
          className="btn bg-white arrowyellow border border-1 rounded-3 position-relative ps-5 backbutton w-auto"
        >
          <span className="px-lg-4 px-2 text-12 fw-semibold text-black">Back</span>
          <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
            <img src={blackarrow} alt="" width={15} height={15} style={{ transform: "rotate(-180deg)" }} />
          </i>
        </Button>
        <Button
          type="button"
          variant="secondary"
          onClick={handleUploadPhoto}
          className="btn bg-white arrowblue border border-1 rounded-3 position-relative pe-5 w-auto"
          disabled={!name || !file}
        >
          <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
            {isSubmitting ? <Spinner animation="border" size="sm" className="me-2" /> : "Save"}
          </span>
          <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
            <img src={whitearrow} alt="" width={15} height={15} />
          </i>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditPhotoModal;
