import React, { useCallback, useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../../assets/images/penbl.png";
import tip from "../../../../assets/images/tip.png";
import dummy from "../../../../assets/images/dummy.jpeg";
import star from "../../../../assets/images/icon_star.svg";
import flag from "../../../../assets/images/flag.svg";
import shareprofile from "../../../../assets/images/share_profile.svg";
import aeroplane from "../../../../assets/images/airoplane.svg";
import selfrating from "../../../../assets/images/self_rating.svg";
import EditBusinessGroupModal from "../modals/EditBusinessGroupModal";
import { useNavigate } from "react-router-dom";
import { getBusinessMembers } from "../../../NewBusiness/api";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";
import Toltip from "../../../common/toltip/page";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { log } from "console";
import Slider from "react-slick";



const BusinessGroupSlider = ({ group }: any) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [workId, setWorkId] = useState("");

  const viewProfileUser = (id: any) => {

    navigate(`${APP_ROUTES.USER_PROFILE}/${id}`);
  }
  
 


  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide:1
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide:1

        },
      },
    ],
  };
  return (
    <>
 
      
     
        <div >
            <Slider {...settings}>
            {group.members.map((member: any, index: number) => (
                <div className="col-xl-6 col-md-6 col-12 mb-4" key={`${group.groupId}-${index}`}>
                  <div className="col border rounded-3">
                    <div className="row flex-column align-items-center justify-content-between mb-2 bg-lightblue py-3 mx-0">
                      <div className="row align-items-center justify-content-center gx-2">
                        <div className="col-3 profile_icon">
                          <figure className="profileCircle">
                            <img
                              src={dummy}
                              alt="Profile"
                              className="img-fluid rounded-circle"
                            />
                          </figure>
                        </div>
                        <div className="col-9 ps-2">
                          <div className="d-flex align-items-start justify-content-between">
                            <div className="col-8">
                              <div className="newheadingstyle">
                                <h4 className="mb-0 text-16 clamp clamp1 fw-semibold">
                                  {member.name || `${member.firstName} ${member.lastName}`}
                                </h4>
                                <p className="text-10 my-0 clamp clamp1">
                                  {member.userType === "manager" ? "Manager" : "Employee"}
                                </p>
                                  Email: 
                                  <p style={{wordWrap:"break-word"}}>{member.email || "Not Provided"}</p>
                                <p  className="text-10 my-0 ">
                                  Phone: {member.phoneNumber || "Not Provided"}
                                </p>
                              </div>
                            </div>
                            <div className="col-2">
                              <i>
                                <img src={flag} alt="Flag" />
                              </i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-2">
                      <div className="d-flex">
                        <div className="skills">
                          <h4 className="fw-semibold text-14 mb-0">Primary Occupation</h4>
                          <ul className="ps-0 d-flex flex-wrap align-items-center justify-content-start gap-2 mx-0">
                            {member.occupationDetails?.map((occupation: any) => (
                              <li key={occupation._id} className="d-inline-block pe-2">
                                <p className="mb-0 bg-lightblue text-blue rounded-pill text-center text-11 d-inline-block px-2 py-1 fw-medium">
                                  {occupation.name}
                                </p>
                              </li>
                            )) || (
                                <li>
                                  <p className="text-muted text-11">No Primary Occupation</p>
                                </li>
                              )}
                          </ul>
                        </div>

                        <div className="skills ms-3">
                          <h4 className="fw-semibold text-14 mb-0">Secondary Occupation</h4>
                          <ul className="ps-0 d-flex flex-wrap align-items-center justify-content-start gap-2 mx-0">
                            {member.secondaryOccupation?.map((secOccupation: any) => (
                              <li key={secOccupation._id} className="d-inline-block pe-2">
                                <p className="mb-0 bg-lightblue text-blue rounded-pill text-center text-11 d-inline-block px-2 py-1 fw-medium">
                                  {secOccupation.name}
                                </p>
                              </li>
                            )) || (
                                <li>
                                  <p className="text-muted text-11">No Secondary Occupation</p>
                                </li>
                              )}
                          </ul>
                        </div>
                      </div>
                      <div>
                      </div>
                      <div className="mt-3">
                        <div className="row align-items-center g-2">
                          <div className="col">
                            <button
                              type="button"
                              className="btn btn-primary w-100 text-10 px-1 text-center"
                              onClick={() =>
                                viewProfileUser(
                                  member?.userId
                                )
                              }
                            >

                              View Profile
                            </button>
                          </div>
                          {/* <div className="col">
                            <button
                              type="button"
                              className="btn btn-primary w-100 text-10 px-1 text-center"
                            >
                              Shared Data
                            </button>
                          </div> */}
                          <div className="col">
                            <button
                              type="button"
                              className="btn btn-white w-100 text-center border text-10 px-1"
                            >
                              Message
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
        </div>
      
    </>
  );
};

const BusinessGroup = () => {
  const [showModal, setShowModal] = useState(false);
  const [businessMembers, setBusinessMembers] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: string]: boolean;
  }>({
    businessgroup: false
  })
  const businessId=useSelector(
    (state: RootState) =>state.business?._id)

    console.log(businessId,"the id for the business")
    const addToList = (id: any) => {
      navigate('/newBusiness/businessGroup', {
        state: {
          groupId: id,
          businessId: businessId
        }
      });
    };
  const handleIconClick = (tooltipKey: string) => {
    setTooltipVisibility(prev => ({
      businessgroup: false,
      [tooltipKey]: !prev[tooltipKey]
    }));
  };





  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isTooltipIcon = target.closest(".tooltip-icon");
    const isTooltipContent = target.closest(".tooltip-content");
    const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line

    if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
      setTooltipVisibility({
        businessgroup: false
      });
    }
  }, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside])

  useEffect(() => {
    const fetchBusinessMembers = async () => {
      try {
        setLoading(true);
        const response = await getBusinessMembers();
        console.log(response.data, "member API response");
        setBusinessMembers(response.data);
      } catch (err) {
        setError("Failed to load business members");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessMembers();
  }, []);
  const viewProfileUser = (id: any) => {

    navigate(`${APP_ROUTES.USER_PROFILE}/${id}`);
  }


  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const createBusiness = () => navigate("/newBusiness");

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-danger">{error}</p>;

  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-8">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold font-inter mb-0">Business Group</h4>
              <i onClick={() => handleIconClick("businessgroup")} className="tooltip-icon">
                <img
                  className="customeTolImg"
                  src={tip}
                  alt="Tooltip"
                />
              </i>
              <Toltip tooltipClass={tooltipVisibility.businessgroup ? "activetooltip" : ""}
                slug={CONSTANT_MANAGER.SLUG.EDIT_PROFILE}
                labelId={
                  CONSTANT_MANAGER.EDIT_PROFILE.BUSINESS_GROUP
                }
              />
            </div>
          </div>
        </div>
      </div>

      {businessMembers.map((group) => (
        <div key={group.groupId} className={styles.personalinfo}>
          <div className="">
            <ul className="row align-items-center">
              <li className=" d-flex align-items-center justify-content-between w-100">
                <div >
                <span>Group Name</span>
                
                
                <label className="clamp clamp1">{group.groupName}</label></div>
                <button
                        className="border-blue bg-blue text-white py-2 text-14 px-2 rounded-2"
                        onClick={()=>{navigate('/newBusiness/businessGroup', { state: { businessID: businessId,_id:group.groupId} });}}
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        + Add Member
                    </button>
              </li>
            </ul>
            {/* <button onClick={()=>addToList(group?.groupId)} style={{
              backgroundColor: '#007bff',
              color: 'white',
              border: '1px solid #007bff',
              padding: '6px 4px',
              fontSize: '13px',
              borderRadius: '4px',
              cursor: 'pointer',
              fontWeight: 'bold',
              height:'100%',
              maxHeight:'36px'
            }}>
              Add Group Member
            </button> */}
          </div>
          <div>
            <h4 className="text-16 fw-semibold">Group Members</h4>
           <BusinessGroupSlider group={group}/>
           
          </div>
        </div>
      ))}

      <EditBusinessGroupModal
        showModal={showModal}
        closeModal={closeModal}
        title="Edit Business Group"
      />
    </div>
  );
};

export default BusinessGroup;
