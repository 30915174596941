import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOption } from "../../Interfaces";


interface UserData {
  firstName: string;
  lastName: string;
}
interface Contact {
  phoneNum: string;
  countryCode: string;
  phoneNumVerifyId: string;
  number: string;
}
interface Otp {
  verificationCode: number | string;
  isVerified: boolean;
}

interface verificationToken {
  userName: number | string;
  verificationToken: string;
}
interface Email {
  email: string;
}
interface Password {
  password: string;
}
interface Occupation {
  occupation: IOption;
  occupationName:string;
}
interface Citizenship {
  citizenship: string;
  countyName:string
}
interface Aboutus {
  //selectedOption: string;
  About: string;
  other?:string
}
interface PrivacyPolicy {
  isPrivacyPolicy: boolean
}
interface JoinData{
  userType:string,
  businessId:string,
  businessGroupId:string
}
interface UserDataState {
  data: UserData;
  contact: Contact;
  verificationCode: Otp;
  emailData: Email;
  password: Password;
  occupationData: Occupation;
  occupationsList: IOption[];
  citizenshipData: Citizenship;
  aboutData: Aboutus;
  PrivacyPolicy: PrivacyPolicy;
  verificationToken: verificationToken;
  otpMethod: 'sms' | 'call'; 
  joinData: JoinData
}

const initialState: UserDataState = {
  data: {
    firstName: "",
    lastName: "",
  },
  otpMethod: 'sms', 
  contact: {
    phoneNum: "",
    phoneNumVerifyId: "",
    countryCode: "",
    number: "",
  },
  verificationCode: {
    verificationCode: "",
    isVerified: false,
  },
  verificationToken: {
    verificationToken: "",
    userName: "",
  },
  emailData: {
    email: "",
  },
  password: {
    password: "",
  },
  PrivacyPolicy: {
    isPrivacyPolicy: false,
  },
  occupationData: {
    occupation: {label: '', value: ''},
    occupationName:""
  },
  occupationsList: [],
  citizenshipData: {
    citizenship: "",
    countyName:""
  },
  aboutData: {
    About: "",
    //selectedOption:""
  },
  joinData:{
    userType:"",
    businessId:"",
    businessGroupId:""
  }
};

const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    // setActiveStep: (state, action: PayloadAction<Signup_steps>) => {
    //   state.act = action.payload;
    // },
    setUserData: (state, action: PayloadAction<UserData>) => {
      state.data = action.payload;
    },
    setContact: (state, action: PayloadAction<Contact>) => {
      state.contact = { ...action.payload };
    },
    setOtp: (state, action: PayloadAction<Otp>) => {
      state.verificationCode = { ...action.payload };
    },
    setEmail: (state, action: PayloadAction<Email>) => {
      state.emailData = { ...action.payload };
    },
    setPassword: (state, action: PayloadAction<Password>) => {
      state.password = action.payload;
    },
    setOccupation: (state, action: PayloadAction<Occupation>) => {
      state.occupationData = {...action.payload};
    },
    setOccupationsList: (state, action: PayloadAction<IOption[]>) => {
      state.occupationsList = [...action.payload];
    },
    setCitizenship: (state, action: PayloadAction<Citizenship>) => {
      state.citizenshipData = action.payload;
    },
    setAboutData: (state, action: PayloadAction<Aboutus>) => {
      state.aboutData = action.payload;
    },
    setPrivacyPolicies: (state, action: PayloadAction<PrivacyPolicy>) => {
      state.PrivacyPolicy = action.payload;
    },
    setVerificationToken: (state, action: PayloadAction<verificationToken>) => {
      state.verificationToken = action.payload;
    },
    setOtpTypeMethod: (state, action: PayloadAction<'sms' | 'call'>) => {
      state.otpMethod = action.payload;
    },
    setUserType:(state, action: PayloadAction<JoinData>)=>
    {
      state.joinData=action.payload
    }
  },
});

export const {
  setUserData,
  setContact,
  setOtp,
  setEmail,
  setOccupation,
  setOccupationsList,
  setCitizenship,
  setAboutData,
  setPassword,
  setVerificationToken,
  setPrivacyPolicies,
  setOtpTypeMethod ,
  setUserType
} = userDataSlice.actions;
export default userDataSlice.reducer;
