import React, { useState, useEffect } from "react";
import { Dropdown, FormControl, ModalBody, ModalFooter, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import security from "../../../../assets/images/security.svg";
import blackarrow from "../../../../assets/images/blackarrow.svg";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import upload from "../../../../assets/images/upload.svg";
import styles from "../styles/style.module.css";
import { updateUserProfile } from "../../../home/api";
import { uploadFile } from "../../../../common/api";
import { toast } from "react-toastify";

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  title: string;
  refresh: () => void;
  editData: any; 
}

const EditBackgroundCheckModal: React.FC<ModalProps> = ({
  showModal,
  closeModal,
  title,
  refresh,
  editData,
}) => {
  const [name, setName] = useState<string>("");
  const [file, setFile] = useState<any>("");
  const [errors, setErrors] = useState<{ name?: string; file?: string }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Prefill the form with editData
  useEffect(() => {
    if (editData) {
      setName(editData?.name || "");
      setFile(editData?.filename || "");
    }
  }, [editData]);

  const handleFileUpload = async (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0];
      const allowedTypes = ["image/png", "image/jpeg"];
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes
  
      // Validate file type
      if (!allowedTypes.includes(file.type)) {
        toast.error("Only PNG and JPEG images are allowed");
        return;
      }
  
      // Validate file size
      if (file.size > maxSize) {
        toast.error("File size must be 2MB or less");
        return;
      }
  
      const formData = new FormData();
      formData.append("file", file);
  
      try {
        const response = await uploadFile(formData);
        const newFileUrl = response.data.files;
        setFile(newFileUrl);
        toast.success("File uploaded successfully!");
      } catch (err) {
        toast.error("File upload failed. Please try again.");
        console.error(err);
      }
    }
  };
  
  

  const validate = () => {
    let hasError = false;
    const newErrors: { name?: string; file?: string } = {};

    if (!name.trim()) {
      newErrors.name = "Name is required";
      hasError = true;
    }

    if (!file) {
      newErrors.file = "File upload is required";
      hasError = true;
    }

    setErrors(newErrors);
    return !hasError;
  };

  const handleUploadPhoto = async () => {
    if (validate()) {
      setIsSubmitting(true);
      const payload = {
        backgroundCheckDoc: {
          filename: file,
          name: name,
        },
      };

      try {
        await updateUserProfile(payload);
        refresh();
        setIsSubmitting(false);
        toast.success("Updated Successfully")
        closeModal(); // Close the modal after a successful upload
      } catch (error) {
        console.log("Error occurred while uploading the resume", error);
      }
    }
  };
  const removeUploadedFiles = (): void => {
    setFile("");
  };

  return (
    <>
      <Modal show={showModal} onHide={closeModal} centered size="lg" backdrop='static'>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <ModalBody>
          <div className="addModalPro">
            <form>
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="bg-lightblue row align-items-center justify-content-center p-3 mx-0 rounded-2">
                    <div className="col-1 text-center">
                      <i><img src={security} alt="" className="img-fluid" /></i>
                    </div>
                    <div className="col-11">
                      <p className="text-14 fw-medium text-black mb-0">
                      Upload a copy of your background check. This adds an extra layer of trust for potential clients.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="formGroup">
                    <label>Name</label>
                    <div>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          setErrors((prevErrors) => ({ ...prevErrors, name: "" })); // Clear name error
                        }}
                        className="form-control"
                        placeholder="Add or choose Certificate"
                      />
                      {errors.name && (
                        <span className="text-danger">{errors.name}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="formGroup">
                    <label>Upload Image</label>
                    <div className={styles.uploadimagefile}>
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        onChange={({ target }) => handleFileUpload(target.files)}
                      />
                      <div className={styles.uploadimage}>
                        <i>
                          <img src={upload} alt="" />
                        </i>
                        <p className="mb-0 text-14 text-blue">
                          Upload png/jpeg file
                        </p>
                      </div>
                      {errors.file && (
                        <span className="text-danger">{errors.file}</span>
                      )}
                    </div>
                  </div>
                </div>

                {file && (
                        <li className="mt-3">
                          <div className={styles.uploadImages}>
                            <img src={file} alt="" className="img-fluid" />
                            <i
                              className="fas fa-times"
                              onClick={removeUploadedFiles} // Call the function to remove the image
                            ></i>
                          </div>
                        </li>
                      )}
              </div>
            </form>
          </div>
        </ModalBody>
        <ModalFooter className="btn-hover-effect mb-2 justify-content-end">
          <Button
            type="button"
            variant="secondary"
            onClick={closeModal}
            className="btn bg-white arrowyellow border border-1 rounded-3 position-relative ps-5 backbutton w-auto"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              Back
            </span>
            <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
              <img
                src={blackarrow}
                alt=""
                width={15}
                height={15}
                style={{ transform: "rotate(-180deg)" }}
              />
            </i>
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={handleUploadPhoto}
            className="btn bg-white arrowblue border border-1 rounded-3 position-relative pe-5 w-auto"
            disabled={!name || !file} // Disable button if form is invalid
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              {isSubmitting ? (
                <Spinner animation="border" size="sm" className="me-2" />
              ) : (
                "Save"
              )}
            </span>
            <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
              <img src={whitearrow} alt="" width={15} height={15} />
            </i>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditBackgroundCheckModal;
