import React, { useCallback, useEffect, useState } from "react";
import "react-markdown-editor-lite/lib/index.css";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import storage from "../../../../../utils/storage";
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setEligibilityQuestions,
} from "../../../../../redux/reducers/addDirectJob";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import styles from "../../../../../components/contractBeforeLogin/styles/style.module.css";
import { DIRECT_JOB_STEPS } from "../Enums";
import TextEditorCustomComp from "../../../HourlyContractType/HourlyContract/StepsComonents/TextEditorCustomComp/TextEditorCustomComp";
import Toltip from "../../../../common/toltip/page";
import tip from "../../../../../assets/images/tip.png";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import * as Yup from "yup";

const AddEligibilityQusForDirectJob: React.FC = () => {
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    eligibilityQuestions: Yup.array()
      .of(
        Yup.string()
          .trim()
          .test("not-only-br", "Eligibility question cannot be empty", (value) => {
            if (!value) return false; // Ensures value exists
            return value.replace(/<br\s*\/?>/g, "").trim() !== "";
          })
          .test("word-count", "Eligibility question must be between 3 and 50 words", (value) => {
            if (!value) return false;
            const wordCount = value.trim().split(/\s+/).length;
            return wordCount >= 3 && wordCount <= 50;
          })
          .required("Eligibility question cannot be empty")
      )
      .min(1, "At least one eligibility question is required"),
  });
  
  const { eligibilityQuestions } = useSelector(
    (state: RootState) => state.directJob
  );
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: string]: boolean;
  }>({
    eligQues: false,
  });

  const formIk: any = useFormik({
    initialValues: {
      eligibilityQuestions,
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(values)
      handleGoToNextStep();
      // dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_INTERVIEW_QUESTIONS));
    },
  });

  const addNewQuestion = (): void => {
    const updatedQuestion = [...eligibilityQuestions];
    updatedQuestion.push("");
    dispatch(setEligibilityQuestions([...updatedQuestion]));
  };

  const onValueChange = (value: string, index: number): void => {
    const updatedQuestions = [...eligibilityQuestions];
    updatedQuestions[index] = value;

    dispatch(setEligibilityQuestions(updatedQuestions));
    formIk.setFieldValue("eligibilityQuestions", updatedQuestions);
  };

  const deleteQuestion = (index: number) => {
    const updatedQuestion = [...eligibilityQuestions];
    updatedQuestion.splice(index, 1);
    dispatch(setEligibilityQuestions([...updatedQuestion]));
  };

  const handleGoBack = () => {
    dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_ADDITIONALS));
  };
  const handleGoToNextStep = () => {
    dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_INTERVIEWQUESTIONS));
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isTooltipIcon = target.closest(".tooltip-icon");
    const isTooltipContent = target.closest(".tooltip-content");
    const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line

    if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
      setTooltipVisibility({
        eligQues: false,
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [handleClickOutside]);

  const handleIconClick = (tooltipKey: string) => {
    setTooltipVisibility((prev) => ({
      eligQues: false,
      [tooltipKey]: !prev[tooltipKey],
    }));
  };
  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formIk.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div className={styles.registerLeft}>
                  <div>
                    {/* <p>Add a Legal information of the work that you need</p> */}
                    <div className="col-lg-11 col-12 text-center mx-auto">
                      <figure>
                        <img
                          src={choosetype}
                          alt=""
                          className="img-fluid w-100"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <h4 className="text-white text-32 fw-semibold">
                  Eligibility Questions
                </h4>
                <p className="text-14 text-white opacity-75" style={{marginBottom: '30px'}}>
                  To ensure candidates meet essential job requirements and legal
                  standards, Automate America Company uses the following yes or
                  no eligibility questions.
                </p>
                <div className={styles.ScrollDiv}>
                  <div className={styles.signUPContainers}>
                    <div className={`mt-0 ${styles.registerRight}`}>
                      <div className="topTipHover">
                        <label className='text-16 text-white fw-medium'>
                          Create Eligibility Questions
                        </label>
                        <i
                          onClick={() => handleIconClick("eligQues")}
                          className="tooltip-icon"
                        >
                          <img
                            className="customeTolImg"
                            src={tip}
                            alt="Tooltip"
                          />
                        </i>
                        <Toltip
                          tooltipClass={
                            tooltipVisibility.eligQues ? "activetooltip" : ""
                          }
                          slug={CONSTANT_MANAGER.SLUG.DIRECT_JOB}
                          labelId={
                            CONSTANT_MANAGER.DIRECT_JOB.ELIGIBILITY_QUESTION
                          }
                        />
                      </div>
                      {eligibilityQuestions?.map((x: string, i: number) => (
                        <>
                          <div
                            className={`mt-2 ${styles.fromGroup}`}
                            key={i}
                            style={{ maxWidth: "100%" }}
                          >
                            <label className='text-16 text-white fw-medium'>
                              Question{i + 1}:
                            </label>

                            <div>
                              <div className="row align-items-center justify-content-between w-100">
                                <div className="col-11">
                                  <TextEditorCustomComp
                                    data={x}
                                    setDesc={(value: string) =>
                                      onValueChange(value, i)
                                    }
                                    index={i}
                                  />
                                </div>

                                <div className={`col-1 px-0 ${styles.AddBtn}`}>
                                  {i === eligibilityQuestions.length - 1 &&
                                    eligibilityQuestions.length < 5 && (
                                      <button
                                        type="button"
                                        className="btn btn-primary py-2 my-1 rounded-circle text-14"
                                        onClick={addNewQuestion}
                                      >
                                        +
                                      </button>
                                    )}
                                  {eligibilityQuestions.length > 1 && (
                                    <button
                                      type="button"
                                      className="btn btn-primary py-2 my-1 rounded-circle text-14"
                                      onClick={() => deleteQuestion(i)}
                                    >
                                      -
                                    </button>
                                  )}
                                </div>
                                {formIk.errors.eligibilityQuestions && formIk.errors.eligibilityQuestions[i] && (
  <div className={styles.error}>
    {formIk.errors.eligibilityQuestions[i]}
  </div>
)}
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <div className="col-xl-4 col-12 text-start my-3 btn-hover-effect">
                    <h6
                      onClick={() => handleGoBack()}
                      className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold">
                        Back
                      </span>
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="" />
                      </i>
                    </h6>
                  </div>
                  <div className="col-xl-8 xol-12">
                    <div className="d-flex align-items-center jusify-content-end">
                      <div className="col-0">{/* <SaveAsDraftButton /> */}</div>
                      <div className="col-12 text-end my-3 btn-hover-effect">
                        <h6
                          onClick={formIk.handleSubmit}
                          className="btn arrowblue border-grey border-1 position-relative pe-5"
                        >
                          <span className="px-lg-4 px-2 text-12 fw-semibold">
                            Next
                          </span>
                          <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                            <img src={whitearrow} alt="" />
                          </i>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddEligibilityQusForDirectJob;
