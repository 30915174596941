import React, { useCallback, useEffect, useState } from "react";
import styles from "../../../styles/style.module.css";
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import storage from "../../../../../utils/storage";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setExpenseData,
  setTravelData,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import Toltip from "../../../../common/toltip/page";
import tip from "../../../../../assets/images/tip.png";
import { SaveAsDraftButton } from "../../../../work/contractSteps/Components";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import { Dropdown, FormControl } from "react-bootstrap";
import { expenseSchema } from "../../../../work/validations";
import { toast } from "react-toastify";

const AddTravel: React.FC = () => {
  const dispatch = useDispatch();
  const token = storage.getToken();

  const { travelData, expenseData } = useSelector(
    (state: RootState) => state.addContract
  );
  const [selectedTrip, setSelectedTrip] = useState<any>(
    travelData?.travelTipAllowedPerMonth
  );
  const trips = ["1", "2", "3", "4", "5", "6"];
  const [isTravel, setIsTravel] = useState<boolean>(
    travelData?.isTravelRequired || false
  );
  const [isExpense, setIsExpense] = useState<boolean>(
    expenseData?.isExpenseReimbursed
  );
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: string]: boolean;
  }>({
    isTravel: false,
    travelTime: false,
    travelTrip: false,
    travelHours: false,
    socialPost: false,
    isExpense: false,
    daily: false,
    travelDay: false,
    hotel: false,
    car: false,
    fuel: false,
    airefare: false,
    mileage: false,
    luggage: false,
  });

  const formIk: any = useFormik({
    initialValues: {
      isTravelRequired: isTravel,
      // tavelTimeReceipt: travelData?.travelTimeReceipt,
      // travelTimeCustomerSupp: travelData?.travelTimeCustomerSupp,
      travelTimeRate: travelData.travelTimeRate,
      travelTipAllowedPerMonth: travelData.travelTipAllowedPerMonth,
      // tripReceipt: travelData?.tripReceipt,
      // tripCustomerSupp: travelData?.tripCustomerSupp,
      travelHoursPerRoundTrip: travelData.travelHoursPerRoundTrip,
      // travelHrsReceipt: travelData?.travelHrsReceipt,
      // travelHrsCustomerSup: travelData?.travelHrsCustomerSup,
      dailyPerDiem: expenseData.dailyPerDiem,
      travelDayPerDiem: expenseData.travelDayPerDiem, 
hotelAllowance: expenseData.hotelAllowance,
rentalCarAllowance:expenseData.rentalCarAllowance,
fuelAllowance:expenseData.fuel,
airFareAllowance:expenseData.airFareAllowance,
mileageAllowance:expenseData.mileage,
luggageAllowance:expenseData.luggage,
    },
    // validationSchema: expenseSchema,
    onSubmit: () => {
      // dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_CUSTOMER_CONTACT));
    },
  });

  const handleSelect = (value: string | number | boolean, key: string) => {
    formIk.setFieldValue(key, value);
    formIk.validateField(key);
    dispatch(
      setTravelData({
        ...travelData,
        [key]: value,
      })
    );
    setSelectedTrip(value);
  };

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_RATES));
  };

  const _onChange = (value: string | number | boolean, key: string) => {
    if (key === "isTravelRequired") {
      if (value === true) {
        setIsTravel(true);
      } else if (value === false) {
        setIsTravel(false);
      }
    }

    if (key === "travelTimeReceipt" || key === "travelTimeCustomerSupp") {
      const updatedTravelData = {
        ...travelData,
        travelTimeReceipt: key === "travelTimeReceipt" ? true : false,
        travelTimeCustomerSupp: key === "travelTimeCustomerSupp" ? true : false,
      };
      dispatch(setTravelData(updatedTravelData));
    } else if (key === "tripReceipt" || key === "tripCustomerSupp") {
      const updatedTravelData = {
        ...travelData,
        tripReceipt: key === "tripReceipt" ? true : false,
        tripCustomerSupp: key === "tripCustomerSupp" ? true : false,
      };
      dispatch(setTravelData(updatedTravelData));
    } else if (key === 'travelHrsReceipt' || key === 'travelHrsCustomerSup') {
      const updatedTravelData = {
        ...travelData,
        travelHrsReceipt: key === "travelHrsReceipt" ? true : false,
        travelHrsCustomerSup: key === "travelHrsCustomerSup" ? true : false,
      };
      dispatch(setTravelData(updatedTravelData));
    } else {
      dispatch(setTravelData({
        ...travelData,
        [key]: value,
      }));
    }
    formIk.setFieldValue(key, value);
    formIk.validateField(key);
    if (key === 'isTravelRequired') {
      formIk.validateForm();
    }
  };

  const onExpenseChange = (value: string | boolean | number, key: string) => {
    if (key === "isExpenseReimbursed") {
      if (value === true) {
        setIsExpense(true);
      } else if (value === false) {
        setIsExpense(false);
      }
    }

    if (key === 'dailyReceipt' || key === 'dailyCustomerSup') {
      const updatedTravelData = {
        ...expenseData,
        dailyReceipt: key === "dailyReceipt" ? true : false,
        dailyCustomerSup: key === "dailyCustomerSup" ? true : false,
      };
      dispatch(setExpenseData(updatedTravelData));
    } else if (key === 'travelReceipt' || key === 'travelCustomerSup') {
      const updatedTravelData = {
        ...expenseData,
        travelReceipt: key === "travelReceipt" ? true : false,
        travelCustomerSup: key === "travelCustomerSup" ? true : false,
      };
      dispatch(setExpenseData(updatedTravelData));
    } else if (key === 'hotelReceipt' || key === 'hotelCustomerSup') {
      const updatedTravelData = {
        ...expenseData,
        hotelReceipt: key === "hotelReceipt" ? true : false,
        hotelCustomerSup: key === "hotelCustomerSup" ? true : false,
      };
      dispatch(setExpenseData(updatedTravelData));
    } else if (key === 'carReceipt' || key === 'carCustomerSup') {
      const updatedTravelData = {
        ...expenseData,
        carReceipt: key === "carReceipt" ? true : false,
        carCustomerSup: key === "carCustomerSup" ? true : false,
      };
      dispatch(setExpenseData(updatedTravelData));
    } else if (key === 'fuelReceipt' || key === 'fuelCustomerSup') {
      const updatedTravelData = {
        ...expenseData,
        fuelReceipt: key === "fuelReceipt" ? true : false,
        fuelCustomerSup: key === "fuelCustomerSup" ? true : false,
      };
      dispatch(setExpenseData(updatedTravelData));
    } else if (key === 'airFareReceipt' || key === 'airFareCustSup') {
      const updatedTravelData = {
        ...expenseData,
        airFareReceipt: key === "airFareReceipt" ? true : false,
        airFareCustSup: key === "airFareCustSup" ? true : false,
      };
      dispatch(setExpenseData(updatedTravelData));
    } else if (key === 'mileageReceipt' || key === 'mileageCustomerSup') {
      const updatedTravelData = {
        ...expenseData,
        mileageReceipt: key === "mileageReceipt" ? true : false,
        mileageCustomerSup: key === "mileageCustomerSup" ? true : false,
      };
      dispatch(setExpenseData(updatedTravelData));
    } else if (key === 'luggageReceipt' || key === 'luggageCustomerSup') {
      const updatedTravelData = {
        ...expenseData,
        luggageReceipt: key === "luggageReceipt" ? true : false,
        luggageCustomerSup: key === "luggageCustomerSup" ? true : false,
      };
      dispatch(setExpenseData(updatedTravelData));
    } else {
      dispatch(
        setExpenseData({
          ...expenseData,
          [key]: value,
        })
      );

    }
    formIk.setFieldValue(key, value);
    formIk.validateField(key);
    if (key === 'isExpenseReimbursed') {
      formIk.validateForm();
    }
    // formIk.setFieldValue(key, value);
    // formIk.validateField(key);
  };

  console.log(travelData.isTravelRequired, 'is req');

  const handleNext = () => {
    console.log(expenseData,formIk.values, 'expenseData');

    if (travelData.isTravelRequired===true && !formIk.values.travelTimeRate) {
      toast.error('Travel Time Rate is Required')
      return;
    } else if (travelData.isTravelRequired===true && !formIk.values.travelTipAllowedPerMonth) {
      toast.error('Travel Trip Allowed Per Month is Required')
      return;
    } else if (travelData.isTravelRequired===true && !formIk.values.travelHoursPerRoundTrip) {
      toast.error('Travel Hours Per Round is Required')
      return;
    }
    console.log(formIk.values.fuel, 'fuel allowance');
    if(expenseData.isExpenseReimbursed===true && !formIk.values.dailyPerDiem) {
      toast.error('Daily Per USD is Required')
      return;}
    else if(expenseData.isExpenseReimbursed===true && !formIk.values.travelDayPerDiem) { 
      toast.error('Travel Day Per USD is Required')
      return;
       }
    else if(expenseData.isExpenseReimbursed===true && !formIk.values.hotelAllowance) {
      toast.error('Travel Day Per USD is Required')
      return;
    }
    else if(expenseData.isExpenseReimbursed===true && !formIk.values.rentalCarAllowance) {
      toast.error('Rental Car Allowance is Required')
      return;
    }
    else if(expenseData.isExpenseReimbursed===true && !formIk.values.fuel) {
      toast.error('Fuel Allowance is Required')
      return;
    }
    else if(expenseData.isExpenseReimbursed===true && !formIk.values.airFareAllowance) {
      toast.error('Air Fare Allowance is Required')
      return;
    }
    else if(expenseData.isExpenseReimbursed===true && !formIk.values.mileage) {
      toast.error('Mileage Allowance is Required')
      return;
    }
    else if(expenseData.isExpenseReimbursed===true && !formIk.values.luggage) {
      toast.error('Luggage Allowance is Required')
      return;
    }

    else {
      dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_CUSTOMER_CONTACT));
    }
  }

    const handleClickOutside = useCallback((event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const isTooltipIcon = target.closest(".tooltip-icon");
      const isTooltipContent = target.closest(".tooltip-content");
      const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line
    
      if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
        setTooltipVisibility({
          isTravel: false,
          travelTime: false,
          travelTrip: false,
          travelHours: false,
          socialPost: false,
          isExpense: false,
          daily: false,
          travelDay: false,
          hotel: false,
          car: false,
          fuel: false,
          airefare: false,
          mileage: false,
          luggage: false,
        });
      }
    }, []);
  
    useEffect(() => {
      document.addEventListener('click', handleClickOutside);
      return () => document.removeEventListener('click', handleClickOutside);
    }, [handleClickOutside]);
  
    const handleIconClick = (tooltipKey: string) => {
      setTooltipVisibility(prev => ({
        isTravel: false,
        travelTime: false,
        travelTrip: false,
        travelHours: false,
        socialPost: false,
        isExpense: false,
        daily: false,
        travelDay: false,
        hotel: false,
        car: false,
        fuel: false,
        airefare: false,
        mileage: false,
        luggage: false,
        [tooltipKey]: !prev[tooltipKey]
      }));
    };

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formIk.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div className={styles.registerLeft}>
                  <div className="col-lg-11 col-12 mx-auto text-center">
                    <figure>
                      <img
                        src={choosetype}
                        alt=""
                        className="img-fluid w-100"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <h4 className="text-white text-32 fw-semibold">
                  Travel and Expenses
                </h4>
                <p className="text-16 text-white opacity-75">
                  You can provide all the information here regarding the
                  contract; you just need to fill in all the details here.
                </p>
                <div className={styles.ScrollDiv}>
                  <div className={styles.signUPContainer}>
                    <div className={styles.registerRight}>
                      <div
                        className={styles.formGroup}
                        style={{ marginBottom: "30px" }}
                      >
                        {" "}
                        <div className="topTipHover">
                          <label className={styles.starlabel}>
                            Will You Pay Travel Expenses for This Contract?
                          </label>
                          <i onClick={() => handleIconClick("isTravel")} className="tooltip-icon">
                            <img className="customeTolImg" src={tip} alt="" />
                          </i>
                          <Toltip tooltipClass={tooltipVisibility.isTravel ? "activetooltip" : ""}
                            slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                            labelId={
                              CONSTANT_MANAGER.ADD_CONTRACT
                                .WHERE_TRAVEL_FOR_THIS_JOB
                            }
                          />
                        </div>
                        <div className={styles.rateradio}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="isTravelRequired"
                              id="flexRadioDefault1"
                              checked={travelData.isTravelRequired}
                              onChange={() =>
                                _onChange(true, "isTravelRequired")
                              }
                            />
                            <label className="form-check-label text-white">
                              Yes
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="isTravelRequired2"
                              id="flexRadioDefault2"
                              checked={!travelData.isTravelRequired}
                              onChange={() =>
                                _onChange(false, "isTravelRequired")
                              }
                            />
                            <label className="form-check-label text-white">
                              No
                            </label>
                          </div>
                        </div>
                      </div>

                      {travelData.isTravelRequired === true && (
                        <>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            {" "}
                            <div className="topTipHover">
                              <label className={styles.starlabel}>
                                Travel Time Rate
                              </label>
                              <i onClick={() => handleIconClick("travelTime")} className="tooltip-icon">
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip tooltipClass={tooltipVisibility.travelTime ? "activetooltip" : ""}
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={
                                  CONSTANT_MANAGER.ADD_CONTRACT.TRAVEL_TIME_RATE
                                }
                              />
                            </div>
                            <input
                              type="number"
                              name="travelRate"
                              className="form-control rounded-3 h-48"
                              placeholder="Enter Travel Rate "
                              value={travelData?.travelTimeRate}
                              onChange={(e) =>
                                _onChange(
                                  Number(e.target.value),
                                  "travelTimeRate"
                                )
                              }
                            />
                            {travelData.isTravelRequired === true &&
                              formIk.errors.travelTimeRate ? (
                              <div className={styles.error}>
                                {formIk.errors.travelTimeRate}
                              </div>
                            ) : null}
                            <div>
                              {/* <div className={styles.formGroup}>
                                <label className={`mt-3 text-16 text-white`}>Receipt</label>
                                <div className={styles.rateradio}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="receiptOptions"
                                      id="receiptRequired"
                                      checked={
                                        travelData?.travelTimeReceipt === true
                                      }
                                      onChange={() =>
                                        _onChange(true, "travelTimeReceipt")
                                      }
                                    />
                                    <label
                                      className="form-check-label fst-normal text-white"
                                      htmlFor="receiptRequired"
                                    >
                                      Receipt required
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="receiptOptions"
                                      id="customerSupplied"
                                      checked={
                                        !travelData?.travelTimeReceipt === true
                                      }
                                      onChange={() =>
                                        _onChange(true, "travelTimeCustomerSupp")
                                      }
                                    />
                                    <label
                                      className="form-check-label fst-normal text-white"
                                      htmlFor="customerSupplied"
                                    >
                                      Customer supplied
                                    </label>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            {" "}
                            <div className="topTipHover">
                              <label className={styles.starlabel}>
                                Travel Trip Allowed(per month)?
                              </label>{" "}
                              <i onClick={() => handleIconClick("travelTrip")} className="tooltip-icon">
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip tooltipClass={tooltipVisibility.travelTrip ? "activetooltip" : ""}
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={
                                  CONSTANT_MANAGER.ADD_CONTRACT
                                    .TRAVEL_TRIP_ALLOWED
                                }
                              />
                            </div>
                            <Dropdown>
                              <Dropdown.Toggle
                                id="dropdown-basic"
                                name="travelTip"
                              >
                                {selectedTrip || "Please Select Travel Tip"}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <div
                                  style={{
                                    maxHeight: "300px",
                                    overflowY: "scroll",
                                  }}
                                >
                                  {trips.map((trip) => (
                                    <Dropdown.Item
                                      key={trip}
                                      onClick={() =>
                                        handleSelect(
                                          Number(trip),
                                          "travelTipAllowedPerMonth"
                                        )
                                      }
                                    >
                                      {trip}
                                    </Dropdown.Item>
                                  ))}
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                            {travelData.isTravelRequired === true &&
                              formIk.errors.travelTipAllowedPerMonth ? (
                              <div className={styles.error}>
                                {formIk.errors.travelTipAllowedPerMonth}
                              </div>
                            ) : null}
                            {/* <div>
                              <label className={`mt-3 text-16 text-white`}>Receipt</label>
                              <div className={styles.formGroup}>
                                <div className={styles.rateradio}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="trip"
                                      id="tripReceipt"
                                      checked={travelData?.tripReceipt === true}
                                      onChange={() =>
                                        _onChange(true, "tripReceipt")
                                      }
                                    />
                                    <label className="form-check-label  fst-normal text-white" htmlFor="tripReceipt">
                                      Receipt required
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="trip"
                                      id="tripCustomerSupplied"
                                      checked={!travelData?.tripReceipt === true}
                                      onChange={() =>
                                        _onChange(true, "tripCustomerSupp")
                                      }
                                    />
                                    <label className="form-check-label fst-normal text-white" htmlFor="tripCustomerSupplied">
                                      Customer supplied
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </div>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            {" "}
                            <div className="topTipHover">
                              <label className={styles.starlabel}>
                                Travel Hours Per Round Trip?
                              </label>{" "}
                              <i onClick={() => handleIconClick("travelHours")} className="tooltip-icon">
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip tooltipClass={tooltipVisibility.travelHours ? "activetooltip" : ""}
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={
                                  CONSTANT_MANAGER.ADD_CONTRACT
                                    .TRAVEL_HOURS_PER_ROUND_TRIP
                                }
                              />
                            </div>
                            <input
                              type="number"
                              className="form-control rounded-3 h-48"
                              name="travelHours"
                              placeholder="Enter Travel hours"
                              value={travelData.travelHoursPerRoundTrip}
                              onChange={(e) =>
                                _onChange(
                                  Number(e.target.value),
                                  "travelHoursPerRoundTrip"
                                )
                              }
                            />
                            {travelData.isTravelRequired == true &&
                              formIk.errors.travelHoursPerRoundTrip ? (
                              <div className={styles.error}>
                                {formIk.errors.travelHoursPerRoundTrip}
                              </div>
                            ) : null}
                            {/* <div>
                              <label className={`mt-3 text-16 text-white`}>Receipt</label>
                              <div className={styles.formGroup}>
                                <div className={styles.rateradio}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="travelHrs"
                                      id="flexRadioDefault1"
                                      checked={travelData?.travelHrsReceipt === true}
                                      onChange={() =>
                                        _onChange(true, "travelHrsReceipt")
                                      }
                                    />
                                    <label className="form-check-label fst-normal text-white">
                                      Receipt required
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="travelHrs"
                                      id="flexRadioDefault1"
                                      checked={!travelData?.travelHrsReceipt === true}
                                      onChange={() =>
                                        _onChange(true, "travelHrsCustomerSup")
                                      }
                                    />
                                    <label className="form-check-label fst-normal text-white">
                                      Customer supplied
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </>
                      )}

                      <div
                        className={styles.formGroup}
                        style={{ marginBottom: "30px" }}
                      >
                        {" "}
                        <div className="topTipHover">
                          <label className="text-16 text-white">
                            Are Travel Expenses Reimbursed?
                          </label>
                          <i onClick={() => handleIconClick("isExpense")} className="tooltip-icon">
                            <img className="customeTolImg" src={tip} alt="" />
                          </i>
                          <Toltip tooltipClass={tooltipVisibility.isExpense ? "activetooltip" : ""}
                            slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                            labelId={
                              CONSTANT_MANAGER.ADD_CONTRACT
                                .WHERE_TRAVEL_FOR_THIS_JOB
                            }
                          />
                        </div>
                        <div className={styles.rateradio}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="isTravelRequired3"
                              id="flexRadioDefault1"
                              checked={expenseData?.isExpenseReimbursed}
                              onChange={() =>
                                onExpenseChange(true, "isExpenseReimbursed")
                              }
                            />
                            <label className="form-check-label text-white">
                              Yes
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="isTravelRequired4"
                              id="flexRadioDefault2"
                              checked={!expenseData?.isExpenseReimbursed}
                              onChange={() =>
                                onExpenseChange(false, "isExpenseReimbursed")
                              }
                            />
                            <label className="form-check-label text-white">
                              No
                            </label>
                          </div>
                        </div>
                      </div>

                      {expenseData?.isExpenseReimbursed === true && (
                        <>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            {" "}
                            <div className="topTipHover">
                              <label className="text-white text-16">
                              Daily Per Diem USD
                                                            </label>
                              <i onClick={() => handleIconClick("daily")} className="tooltip-icon">
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip tooltipClass={tooltipVisibility.daily ? "activetooltip" : ""}
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={
                                  CONSTANT_MANAGER.ADD_CONTRACT.DAILY_PER_DIEM
                                }
                              />
                            </div>
                            <input
                              type="number"
                              name="dailyPerDiem"
                              placeholder="Enter Daily Per Diem USD"
                              className="form-control rounded-3 h-48"
                              value={expenseData?.dailyPerDiem}
                              onChange={(e) =>
                                onExpenseChange(Number(e.target.value), "dailyPerDiem")
                              }
                              onBlur={formIk.handleBlur}
                            />
                            {formIk.touched.dailyPerDiem &&
                              formIk.errors.dailyPerDiem ? (
                              <div className={styles.error}>
                                {formIk.errors.dailyPerDiem}
                              </div>
                            ) : null}
                            <div>
                              <label className={`mt-3 text-16 text-white`}>Receipt Required</label>
                              <div className={styles.formGroup}>
                                <div className={styles.rateradio}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="daily"
                                      id="flexRadioDefault1"
                                      checked={expenseData?.dailyReceipt === true}
                                      onChange={() =>
                                        onExpenseChange(true, "dailyReceipt")
                                      }
                                    />
                                    <label className="form-check-label fst-normal text-white">
                                     Yes
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="daily"
                                      id="flexRadioDefault1"
                                      checked={!expenseData?.dailyReceipt === true}
                                      onChange={() =>
                                        onExpenseChange(true, "dailyCustomerSup")
                                      }
                                    />
                                    <label className="form-check-label fst-normal text-white">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            {" "}
                            <div className="topTipHover">
                              <label className="text-16 text-white">
                              Travel Day Per Diem USD?
                              </label>{" "}
                              <i onClick={() => handleIconClick("travelDay")} className="tooltip-icon">
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip tooltipClass={tooltipVisibility.travelDay ? "activetooltip" : ""}
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={
                                  CONSTANT_MANAGER.ADD_CONTRACT
                                    .TRAVEL_DAY_PER_DIEM
                                }
                              />
                            </div>
                            <input
                              type="number"
                              name="travelDayPerDiem"
                              placeholder="Travel Day Per Diem USD"
                              className="form-control rounded-3 h-48"
                              value={expenseData?.travelDayPerDiem}
                              onChange={(e) =>
                                onExpenseChange(
                                  Number(e.target.value),
                                  "travelDayPerDiem"
                                )
                              }
                            />
                            {formIk.touched.travelDayPerDiem &&
                              formIk.errors.travelDayPerDiem ? (
                              <div className={styles.error}>
                                {formIk.errors.travelDayPerDiem}
                              </div>
                            ) : null}
                            <div>
                              <label className={`mt-3 text-16 text-white`}>Receipt Required</label>
                              <div className={styles.formGroup}>
                                <div className={styles.rateradio}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="travelDay"
                                      id="flexRadioDefault1"
                                      checked={expenseData?.travelReceipt === true}
                                      onChange={() =>
                                        onExpenseChange(true, "travelReceipt")
                                      }
                                    />
                                    <label className="form-check-label fst-normal text-white">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="customerSup5"
                                      id="flexRadioDefault1"
                                      checked={!expenseData?.travelReceipt === true}
                                      onChange={() =>
                                        onExpenseChange(true, "travelCustomerSup")
                                      }
                                    />
                                    <label className="form-check-label fst-normal  text-white">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            {" "}
                            <div className="topTipHover">
                              <label className="text-16 text-white">
                                Hotel Allowance Per Day?
                              </label>{" "}
                              <i onClick={() => handleIconClick("hotel")} className="tooltip-icon">
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip tooltipClass={tooltipVisibility.hotel ? "activetooltip" : ""}
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={
                                  CONSTANT_MANAGER.ADD_CONTRACT
                                    .HOTEL_ALLOWANCE_PER_DAY
                                }
                              />
                            </div>
                            <input
                              type="number"
                              name="hotelAllowance"
                              placeholder="Enter hotel allowance"
                              className="form-control rounded-3 h-48"
                              value={expenseData?.hotelAllowance}
                              onChange={(e) =>
                                onExpenseChange(
                                  Number(e.target.value),
                                  "hotelAllowance"
                                )
                              }
                            />
                            {formIk.touched.hotelAllowance &&
                              formIk.errors.hotelAllowance ? (
                              <div className={styles.error}>
                                {formIk.errors.hotelAllowance}
                              </div>
                            ) : null}
                            <div>
                              <label className={`mt-3 text-16 text-white`}>Receipt Required</label>
                              <div className={styles.formGroup}>
                                <div className={styles.rateradio}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="hotel"
                                      id="flexRadioDefault1"
                                      checked={expenseData?.hotelReceipt === true}
                                      onChange={() =>
                                        onExpenseChange(true, "hotelReceipt")
                                      }
                                    />
                                    <label className="form-check-label fst-normal  text-white">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="hotel"
                                      id="flexRadioDefault1"
                                      checked={!expenseData?.hotelReceipt === true}
                                      onChange={() =>
                                        onExpenseChange(true, "hotelCustomerSup")
                                      }
                                    />
                                    <label className="form-check-label  fst-normal text-white">
                                     No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <label className={`mt-3 text-16 text-white`}>Customer Supplied</label>
                              <div className={styles.formGroup}>
                                <div className={styles.rateradio}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="customerHotel"
                                      // id="flexRadioDefault1"
                                      // checked={true}
                                      // onChange={() =>
                                      //   // onExpenseChange(true, "hotelReceipt")
                                      // }
                                    />
                                    <label className="form-check-label fst-normal  text-white">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="customerHotel"
                                      // id="flexRadioDefault1"
                                      // checked={false}
                                      // onChange={() =>
                                      //   // onExpenseChange(true, "hotelCustomerSup")
                                      // }
                                    />
                                    <label className="form-check-label  fst-normal text-white">
                                     No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            {" "}
                            <div className="topTipHover">
                              <label className="text-16 text-white">
                                Rental Car Allowance?
                              </label>{" "}
                              <i onClick={() => handleIconClick("car")} className="tooltip-icon">
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip tooltipClass={tooltipVisibility.car ? "activetooltip" : ""}
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={
                                  CONSTANT_MANAGER.ADD_CONTRACT
                                    .RENTAL_CAR_ALLOWANCE
                                }
                              />
                            </div>
                            <input
                              type="number"
                              name="rentalCarAllowance"
                              className="form-control rounded-3 h-48"
                              placeholder="Enter rental car allowance"
                              value={expenseData?.rentalCarAllowance}
                              onChange={(e) =>
                                onExpenseChange(
                                  Number(e.target.value),
                                  "rentalCarAllowance"
                                )
                              }
                              onBlur={formIk.handleBlur}
                            />
                            {formIk.touched.rentalCarAllowance &&
                              formIk.errors.rentalCarAllowance ? (
                              <div className={styles.error}>
                                {formIk.errors.rentalCarAllowance}
                              </div>
                            ) : null}
                            <div>
                              <label className={`mt-3 text-16 text-white`}>Receipt Required</label>
                              <div className={styles.formGroup}>
                                <div className={styles.rateradio}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="car"
                                      id="flexRadioDefault1"
                                      checked={expenseData?.carReceipt === true}
                                      onChange={() =>
                                        onExpenseChange(true, "carReceipt")
                                      }
                                    />
                                    <label className="form-check-label fst-normal text-white">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="car"
                                      id="flexRadioDefault1"
                                      checked={!expenseData?.carReceipt === true}
                                      onChange={() =>
                                        onExpenseChange(true, "carCustomerSup")
                                      }
                                    />
                                    <label className="form-check-label fst-normal text-white">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <label className={`mt-3 text-16 text-white`}>Customer Supplied</label>
                              <div className={styles.formGroup}>
                                <div className={styles.rateradio}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="customerRental"

                                      // name="hotel"
                                      // id="flexRadioDefault1"
                                      // checked={true}
                                      // onChange={() =>
                                      //   // onExpenseChange(true, "hotelReceipt")
                                      // }
                                    />
                                    <label className="form-check-label fst-normal  text-white">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      // name="hotel"
                                      // id="flexRadioDefault1"
                                      // checked={false}
                                      name="customerRental"

                                      // onChange={() =>
                                      //   // onExpenseChange(true, "hotelCustomerSup")
                                      // }
                                    />
                                    <label className="form-check-label  fst-normal text-white">
                                     No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            {" "}
                            <div className="topTipHover">
                              <label className="text-16 text-white">
                                Fuel Allowance?
                              </label>{" "}
                              <i onClick={() => handleIconClick("fuel")} className="tooltip-icon">
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip tooltipClass={tooltipVisibility.fuel ? "activetooltip" : ""}
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={CONSTANT_MANAGER.ADD_CONTRACT.FUEL}
                              />
                            </div>
                            <input
                              type="number"
                              name="fuel"
                              placeholder="Enter fuel"
                              className="form-control rounded-3 h-48"
                              value={expenseData?.fuel}
                              onChange={(e) =>
                                onExpenseChange(Number(e.target.value), "fuel")
                              }
                              onBlur={formIk.handleBlur}
                            />
                            {formIk.touched.fuel && formIk.errors.fuel ? (
                              <div className={styles.error}>
                                {formIk.errors.fuel}
                              </div>
                            ) : null}
                            <div>
                              <label className={`mt-3 text-16 text-white`}>Receipt Required</label>
                              <div className={styles.formGroup}>
                                <div className={styles.rateradio}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="fuel"
                                      id="flexRadioDefault1"
                                      checked={expenseData?.fuelReceipt === true}
                                      onChange={() =>
                                        onExpenseChange(true, "fuelReceipt")
                                      }
                                    />
                                    <label className="form-check-label  fst-normal text-white">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="fuel"
                                      id="flexRadioDefault1"
                                      checked={!expenseData?.fuelReceipt === true}
                                      onChange={() =>
                                        onExpenseChange(true, "fuelCustomerSup")
                                      }
                                    />
                                    <label className="form-check-label  fst-normal text-white">
                                    No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <label className={`mt-3 text-16 text-white`}>Customer Supplied</label>
                              <div className={styles.formGroup}>
                                <div className={styles.rateradio}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="customerFuel"

                                      // name="hotel"
                                      // id="flexRadioDefault1"
                                      // checked={true}
                                      // onChange={() =>
                                      //   // onExpenseChange(true, "hotelReceipt")
                                      // }
                                    />
                                    <label className="form-check-label fst-normal  text-white">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="customerFuel"

                                      // name="hotel"
                                      // id="flexRadioDefault1"
                                      // checked={false}
                                      // onChange={() =>
                                      //   // onExpenseChange(true, "hotelCustomerSup")
                                      // }
                                    />
                                    <label className="form-check-label  fst-normal text-white">
                                     No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            {" "}
                            <div className="topTipHover">
                              <label className="text-16 text-white">
                                Airfare Allowance?
                              </label>{" "}
                              <i onClick={() => handleIconClick("airefare")} className="tooltip-icon">
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip tooltipClass={tooltipVisibility.airefare ? "activetooltip" : ""}
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={
                                  CONSTANT_MANAGER.ADD_CONTRACT.AIRFAREALLOWANCE
                                }
                              />
                            </div>
                            <input
                              type="number"
                              name="airFareAllowance"
                              placeholder="Enter airfare allowance"
                              className="form-control rounded-3 h-48"
                              value={expenseData?.airFareAllowance}
                              onChange={(e) =>
                                onExpenseChange(
                                  Number(e.target.value),
                                  "airFareAllowance"
                                )
                              }
                              onBlur={formIk.handleBlur}
                            />
                            {formIk.touched.airFareAllowance &&
                              formIk.errors.airFareAllowance ? (
                              <div className={styles.error}>
                                {formIk.errors.airFareAllowance}
                              </div>
                            ) : null}
                            <div>
                              <label className={`mt-3 text-16 text-white`}>Receipt Required</label>
                              <div className={styles.formGroup}>
                                <div className={styles.rateradio}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="airFare"
                                      id="flexRadioDefault1"
                                      checked={expenseData?.airFareReceipt === true}
                                      onChange={() =>
                                        onExpenseChange(true, "airFareReceipt")
                                      }
                                    />
                                    <label className="form-check-label fst-normal text-white">
                                     Yes
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="airFare"
                                      id="flexRadioDefault1"
                                      checked={!expenseData?.airFareReceipt === true}
                                      onChange={() =>
                                        onExpenseChange(true, "airFareCustSup")
                                      }
                                    />
                                    <label className="form-check-label fst-normal text-white">
                                     No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <label className={`mt-3 text-16 text-white`}>Customer Supplied</label>
                              <div className={styles.formGroup}>
                                <div className={styles.rateradio}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      // name="hotel"
                                      // id="flexRadioDefault1"
                                      // checked={true}
                                      // onChange={() =>
                                      //   // onExpenseChange(true, "hotelReceipt")
                                      name="customerAirfare"

                                      // }
                                    />
                                    <label className="form-check-label fst-normal  text-white">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="customerAirfare"

                                      // name="hotel"
                                      // id="flexRadioDefault1"
                                      // checked={false}
                                      // onChange={() =>
                                      //   // onExpenseChange(true, "hotelCustomerSup")
                                      // }
                                    />
                                    <label className="form-check-label  fst-normal text-white">
                                     No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            {" "}
                            <div className="topTipHover">
                              <label className="text-16 text-white">
                                Milleage Allowance?
                              </label>{" "}
                              <i onClick={() => handleIconClick("mileage")} className="tooltip-icon">
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip tooltipClass={tooltipVisibility.mileage ? "activetooltip" : ""}
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={CONSTANT_MANAGER.ADD_CONTRACT.MILEAGE}
                              />
                            </div>
                            <input
                              type="number"
                              name="mileage"
                              className="form-control rounded-3 h-48"
                              placeholder="Enter Amount"
                              value={expenseData?.mileage}
                              onChange={(e) =>
                                onExpenseChange(Number(e.target.value), "mileage")
                              }
                              onBlur={formIk.handleBlur}
                            />
                            {formIk.touched.mileage && formIk.errors.mileage ? (
                              <div className={styles.error}>
                                {formIk.errors.mileage}
                              </div>
                            ) : null}
                            {/* <div>
                              <label className={`mt-3 text-16 text-white`}>Receipt</label>
                              <div className={styles.formGroup}>
                                <div className={styles.rateradio}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="mileage"
                                      id="flexRadioDefault1"
                                      checked={expenseData?.mileageReceipt === true}
                                      onChange={() =>
                                        onExpenseChange(true, "mileageReceipt")
                                      }
                                    />
                                    <label className="form-check-label fst-normal text-white">
                                      Receipt required
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="mileage"
                                      id="flexRadioDefault1"
                                      checked={!expenseData?.mileageReceipt === true}
                                      onChange={() =>
                                        onExpenseChange(true, "mileageCustomerSup")
                                      }
                                    />
                                    <label className="form-check-label  fst-normal text-white">
                                      Customer supplied
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                            <input
                            style={{marginTop: "10px"}}
                              type="number"
                              name="$ Per Mile"
                              className="form-control rounded-3 h-48"
                              placeholder="Enter $ Per Mile"
                              // value={expenseData?.mileage}
                              // onChange={(e) =>
                              //   onExpenseChange(Number(e.target.value), "mileage")
                              // }
                              onBlur={formIk.handleBlur}
                            />
                            <input
                              type="number"
                              name="Max Miled Allowed"
                              className="form-control rounded-3 h-48"
                              placeholder="Enter Max Miles"
                              style={{marginTop: "10px"}}
                              // value={expenseData?.mileage}
                              // onChange={(e) =>
                              //   onExpenseChange(Number(e.target.value), "mileage")
                              // }
                              onBlur={formIk.handleBlur}
                            />
                          </div>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            {" "}
                            <div className="topTipHover">
                              <label className="text-16 text-white">
                                Luggage Fees Allowance?
                              </label>{" "}
                              <i onClick={() => handleIconClick("luggage")} className="tooltip-icon">
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip tooltipClass={tooltipVisibility.luggage ? "activetooltip" : ""}
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={CONSTANT_MANAGER.ADD_CONTRACT.MILEAGE}
                              />
                            </div>
                            <input
                              type="number"
                              name="mileage"
                              className="form-control rounded-3 h-48"
                              placeholder="Enter luggage"
                              value={expenseData?.luggage}
                              onChange={(e) =>
                                onExpenseChange(Number(e.target.value), "luggage")
                              }
                              onBlur={formIk.handleBlur}
                            />
                            {formIk.touched.mileage && formIk.errors.mileage ? (
                              <div className={styles.error}>
                                {formIk.errors.mileage}
                              </div>
                            ) : null}
                            <div>
                              <label className={`mt-3 text-16 text-white`}>Receipt Required</label>
                              <div className={styles.formGroup}>
                                <div className={styles.rateradio}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="luggage"
                                      id="flexRadioDefault1"
                                      checked={ true}
                                      onChange={() =>
                                        onExpenseChange(true, "luggageReceipt")
                                      }
                                    />
                                    <label className="form-check-label fst-normal text-white">
                                     Yes
                                    </label>
                                  </div>
                                  {/* <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="luggage"
                                      id="flexRadioDefault1"
                                      checked={!expenseData?.luggageReceipt === true}
                                      onChange={() =>
                                        onExpenseChange(true, "luggageCustomerSup")
                                      }
                                    />
                                    <label className="form-check-label fst-normal text-white">
                                      Customer supplied
                                    </label>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-4 col-6 text-start my-3 btn-hover-effect">
                    <h6
                      onClick={() => handleGoBack()}
                      className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                    >
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="" />
                      </i>
                      <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                        Back
                      </span>
                    </h6>
                  </div>

                  <div className=" col-md-4 col-6">
                    <SaveAsDraftButton />
                  </div>

                  <div className="col-md-4 col-12 text-end my-3 btn-hover-effect">
                    <button
                      type="button"
                      className="btn arrowblue border-grey border-1 position-relative pe-5"
                      onClick={handleNext}
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                        Next
                      </span>
                      <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                        <img src={whitearrow} alt="" />
                      </i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default AddTravel;
