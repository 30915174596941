import React, { FC, useEffect, useRef, useState } from "react";
import styles from "../../../../../components/work/reviewcontract/styles/style.module.css";
import { Dropdown, FormControl } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useAppSelector } from "../../../../../redux/hooks";
import { RootState } from "../../../../../redux/store";
import {
  initDirectJob,
  setActiveStep,
  setAdditionalFields,
  setEligibilityQuestions,
  setInterviewQuestions,
  setKeyInformation,
  setLocation,
  setOccupation,
  setRates,
  setWorkDescription,
} from "../../../../../redux/reducers/addDirectJob";
import { IOption } from "../../../../../Interfaces";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import overviewpage from "../../../../../assets/images/overviewpage.png";
import print from "../../../../../assets/images/print_white.svg";
import download from "../../../../../assets/images/download_white.svg";
import Delete from "../../../../../assets/images/delete.svg";
import Header from "../../../../header/routes";
import OverviewCollapseItem from "../../../../work/contractSteps/Components/OverviewCollapseItem/OverviewCollapseItem";
import {
  SaveAsDraftAuthErrorDialog,
  SaveAsDraftButton,
} from "../../../../work/contractSteps/Components";
import CustomModal from "../../../../../shared/CustomModal/customModal";
import storage from "../../../../../utils/storage";
import { APIResponseError } from "../../../../work/interface";
import { AddDirectJobRootState } from "../../../../../models";
import { WORK_STATUS_TYPE } from "../../../../../utils/enums/status";
import {
  getManufacturerModelList,
  postMasterType,
  saveDirectjob,
} from "../../../../work/api";
import Swal from "sweetalert2";
import tip from "../../../../../assets/images/tip.png";
import { APP_ROUTES } from "../../../../../utils/routes/AppRoutes";
import { useNavigate } from "react-router-dom";
import { setTechnicalSkills } from "../../../../../redux/reducers/addDirectJob";
import { getMasterTypes } from "../../../../../feature/auth/signup/api";
import { MASTER_TYPE } from "../../../../../utils/enums/masterTypes.enum";
import { OVERVIEW_FORM_FIELDS } from "../../../../work/enums";
import moment from "moment";
import DirectJobDraft from "../../../../work/contractSteps/Components/SaveAsDraftButton/SaveAsDraftAuthErrorDialog/DirectjobSaveasDraft";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import CreatableSelect from "react-select/creatable";
import { MESSAGES } from "../../../../../utils/messages";
import { toast } from "react-toastify";
import { DIRECT_JOB_STEPS } from "../Enums";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import TextEditorCustomComp from "../../../HourlyContractType/HourlyContract/StepsComonents/TextEditorCustomComp/TextEditorCustomComp";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { addImagesToPdf } from "../../../../../common/api";
import { getPaymentTerms } from "../../../../paymentTerms/api";
import { uploadFileImg } from "../../../../Posts/api";
import insc from "../../../../../assets/images/health_inc.svg";
import vacation from "../../../../../assets/images/vacation.png";
import incentive from "../../../../../assets/images/incentive.png";
import { useFormik } from "formik";

interface HourlyPaymentTerm {
  _id: string;
  paymentTerm: number;
}

const libraries: any = ["places"];

type OtherCheckboxKey =
  | "isHealthInsurance"
  | "isVacationAndTimeOff"
  | "isOtherBenefits";

const OverViewDirectjob: FC = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIKEY as string,
    libraries,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = storage.getToken();
  const contentRef = useRef(null);
  const contentRef1 = useRef(null);
  const contract = useAppSelector((state: RootState) => state.directJob);
  const {
    occupation,
    occupationsList,
    keyInformations,
    interviewQuestions,
    eligibilityQuestions,
    location,
    rates,
    technicalSkillsData,
  } = contract;
  const {
    selectedCity,
    selectedCountry,
    selectedState,
    selectedStreet,
    selectedZipcode,
  } = location;
  const [inEditField, setInEditField] = useState<OVERVIEW_FORM_FIELDS | null>(
    null
  );
  const jobStatus = WORK_STATUS_TYPE.PENDING;
  const { authentication } = useSelector((state: RootState) => state);
  const { isAuthenticated } = authentication;
  const directJob = useSelector((state: RootState) => state.directJob);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [key, setKey] = useState(Math.random());
  const [downloading, setDownloading] = useState<Boolean>(false);
  const [occupationFilterValue, setOccupationFilterValue] =
    useState<string>("");
  const [activeItem, setActiveItem] = useState<any | null>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");
  const [imgUrls, setImgUrls] = useState<string[]>(
    technicalSkillsData?.skillImg
  );
  const [desc, setDesc] = useState<any>(contract?.workDescription);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const [lat, setLat] = useState<number>(0);
  const [long, setLong] = useState<number>(0);
  const shifts = ["days", "afternoons", "nightshift", "variable"];
  const hours = ["6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16"];
  const days = ["1", "2", "3", "4", "5", "6", "7"];
  const options = ["Remote", "Hybrid", "Onsite"];
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const [addressDetails, setAddressDetails] = useState({
    city: "",
    state: "",
    country: "",
    zipcode: "",
    streets: "",
  });
  const [address, setAddress] = useState("");
  const [isAddressEditable, setIsAddressEditable] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState<HourlyPaymentTerm[]>([]);
  const { additionfiels } = useSelector((state: RootState) => state.directJob);
  const [citizenCheck, setCitizencheck] = useState<boolean>(
    additionfiels?.isSameCitizenship
  );
  const { workLocation } = additionfiels;
  const [isOtherChecked, setIsOtherChecked] = useState<{
    isHealthInsurance: boolean;
    isVacationAndTimeOff: boolean;
    isOtherBenefits: boolean;
  }>({
    isHealthInsurance: additionfiels?.isHealthInsurance,
    isVacationAndTimeOff: additionfiels?.isVacationAndTimeOff,
    isOtherBenefits: additionfiels?.isOtherBenefits,
  });

  const formik = useFormik({
    initialValues: {
      selectedCountry: selectedCountry.label,
      selectedStreet: selectedStreet.label,
      selectedState: selectedState.label,
      selectedCity: selectedCity.value,
      selectedZipcode: selectedZipcode.label,
    },
    onSubmit: () => {
    },
  });

  const refresh = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    async function fetchPaymentTerms() {
      try {
        const response = await getPaymentTerms();
        if (response.status === 200 && response.data) {
          setPaymentTerms(response.data.hourly);
        }
      } catch (error) {
        console.error("Error fetching payment terms:", error);
      }
    }
    fetchPaymentTerms();
  }, []);

  useEffect(() => {
    handleLocation();
  }, []);

  const handleLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
  };

  const success = (position: any) => {
    setLat(position.coords.latitude);
    setLong(position.coords.longitude);
  };

  const error = () => {
    console.log("Unable to retrieve your location");
  };

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
  };

  const primaryOccOptions =
    occupationFilterValue && occupationFilterValue !== ""
      ? occupationsList.filter((x) =>
        x.label.toLowerCase().includes(occupationFilterValue.toLowerCase())
      )
      : occupationsList;

  const handleOccupationChange = (value: any, key: string) => {
    dispatch(setOccupation({ ...occupation, [key]: value }));
  };

  const _handleSkillChange = (value: any, key: string) => {
    dispatch(
      setTechnicalSkills({
        ...technicalSkillsData,
        skillName: [
          {
            label: value.label,
            value: value.value,
          },
        ],
      })
    );
  };

  const toggleOption = (item: any) => {
    setActiveItem(item === activeItem ? null : item);
    setIsEditing(false);
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const onDescriptionChange = () => {
    dispatch(setWorkDescription(desc));
  };

  useEffect(() => {
    onDescriptionChange();
  }, [desc]);

  const onKeyInforChange = (value: string | number, key: string) => {
    dispatch(
      setKeyInformation({
        ...keyInformations,
        [key]: value,
      })
    );
  };

  const onChangeDate = (value: Date | string | number | null, key: string) => {
    const start =
      key === "startDate"
        ? moment.utc(value)
        : moment.utc(keyInformations.startDate);
    dispatch(
      setKeyInformation({
        ...keyInformations,
        startDate: keyInformations.startDate,

        [key]: value,
      })
    );
  };
  const onRateChange = (value: any, key: string) => {
    dispatch(setRates({ ...rates, [key]: value }));
  };

  const onInputChange = (value: any, key: string) => {
    if (value.label === "") {
      return;
    }
    dispatch(setLocation({ ...location, [key]: value }));
  };

  const onChangeWorkEnv = (value: string | boolean, key: string) => {
    dispatch(setAdditionalFields({ ...additionfiels, [key]: value }));
  };

  const filteredOptions = location.countries.filter((option: IOption) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const addNewQuestion = (): void => {
    const updatedQuestion = [...interviewQuestions];
    updatedQuestion.push("");
    dispatch(setInterviewQuestions([...updatedQuestion]));
  };

  const onInyterviewQuestionChange = (value: string, index: number): void => {
    const updatedQuestions = [...interviewQuestions];
    updatedQuestions[index] = value;
    dispatch(setInterviewQuestions(updatedQuestions));
  };

  const deleteQuestion = (index: number) => {
    const updatedQuestion = [...interviewQuestions];
    updatedQuestion.splice(index, 1);
    dispatch(setInterviewQuestions([...updatedQuestion]));
  };

  const addNewEligibilityQuestion = (): void => {
    const updatedQuestion = [...eligibilityQuestions];
    updatedQuestion.push("");
    dispatch(setEligibilityQuestions([...updatedQuestion]));
  };

  const onEligibilityQusChange = (value: string, index: number): void => {
    const updatedQuestions = [...eligibilityQuestions];
    updatedQuestions[index] = value;
    dispatch(setEligibilityQuestions(updatedQuestions));
  };

  const deleteEligibilityQuestion = (index: number) => {
    const updatedQuestion = [...eligibilityQuestions];
    updatedQuestion.splice(index, 1);
    dispatch(setEligibilityQuestions([...updatedQuestion]));
  };

  const deleteSkill = (i: number) => {
    const updatedSkills = [...technicalSkillsData.skills];
    updatedSkills.splice(i, 1);
    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills })
    );
  };

  useEffect(() => {
    const getManufacturerData = async () => {
      const response = await getMasterTypes(MASTER_TYPE.MANUFACTURERS);
      response.data.forEach((x) => {
        x.label = x.name;
        x.value = x._id;
      });
      dispatch(
        setTechnicalSkills({
          ...technicalSkillsData,
          skillTypes: response.data,
        })
      );
    };
    getManufacturerData();
  }, []);

  const onSkillsChange = (
    value: string | boolean | IOption[],
    key: string,
    index: number
  ): void => {
    const updatedSkills = [...technicalSkillsData.skills];
    const updatedSkill = { ...updatedSkills[index], [key]: value };
    updatedSkills[index] = updatedSkill;
    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills })
    );
  };

  const handleChangeManufacturer = async (
    option: any,
    index: number,
    key: string
  ) => {
    const updatedSkills = [...technicalSkillsData.skills];
    const updatedSkill = { ...updatedSkills[index] };
    if (key === "category") {
      const response = await getManufacturerModelList(
        MASTER_TYPE.MANUFACTURERS_MODELS,
        option.value
      );
      response.data.forEach((x) => {
        x.label = x.name;
        x.value = x._id;
      });
      updatedSkill.subCategoriesList = response.data ?? [];
      updatedSkill.category = option;
      updatedSkill.subCategory = [];
    }
    if (key === "subCategory") {
      updatedSkill.subCategory = option;
    }
    (updatedSkill as any)[key] = option;
    updatedSkills[index] = updatedSkill;
    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills })
    );
  };

  const filteredCategories = technicalSkillsData.skillTypes.filter((option) =>
    option?.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const publishDirectJob = async () => {
    const payload = {
      ...new AddDirectJobRootState({
        ...directJob,
        jobStatus,
      }).serialize(),
      location: {
        type: "Point",
        coordinates: [lat, long],
      },
      countryValueType: "string",
    };

    try {
      Swal.fire({
        title: "Sending job for approval...",
        text: "Please wait...",
        icon: "info",
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
      await saveDirectjob(payload);
      Swal.fire({
        title: "Congratulations!",
        text: "Direct Job Sent for approval successfully!",
        icon: "success",
        confirmButtonText: "View Dashboard",
      }).then((result) => {
        if (result.isConfirmed) {
          storage.clearSavedJobs();
          dispatch(initDirectJob(new AddDirectJobRootState()));
          navigate(APP_ROUTES.HOME);
        }
      });
    } catch (error) {
      let errorMessage =
        (error as APIResponseError)?.data?.data?.message || "An error occurred";
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleSendForApproval = async () => {
    dispatch(
      setLocation({
        ...location,
        selectedCity: { label: formik.values.selectedCity, value: formik.values.selectedCity },
        selectedState: { label: formik.values.selectedState, value: formik.values.selectedState },
        selectedCountry: { label: formik.values.selectedCountry, value: formik.values.selectedCountry },
        selectedZipcode: { label: formik.values.selectedZipcode, value: formik.values.selectedZipcode },
        selectedStreet: { label: formik.values.selectedStreet, value: formik.values.selectedStreet },
        isFilled: true,
      })
    );
    if (!token && !isAuthenticated) {
      setShowErrorDialog(true);
      return;
    } else {
      await publishDirectJob();
    }
  };

  const handleSubmitCustomOccupation = async (
    newValue?: any,
    type?: string
  ) => {
    if (type === "SkillName") {
      const payload = {
        type: MASTER_TYPE.OCCUPATIONS,
        name: newValue || searchTerm,
        alias: newValue || searchTerm,
      };
      toast.promise(postMasterType(payload), {
        pending: {
          render() {
            return MESSAGES.POSTING_CONTRACTS.VERIFYING;
          },
        },
        success: {
          render(newOccupation) {
            const newId = newOccupation?.data?.data?._id || newValue;
            const _obj: any = {
              label: newOccupation?.data?.data?.name || newValue,
              value: newId,
            };
            // dispatch(setOccupation({ ...occupation, primaryOccupation: _obj }));
            dispatch(
              setTechnicalSkills({
                ...technicalSkillsData,
                skillName: [
                  {
                    label: _obj.label,
                    value: _obj.value,
                  },
                ],
              })
            );
            return MESSAGES.POSTING_CONTRACTS.ADDING;
          },
        },
        error: {
          render(error) {
            let errorMesage = error as APIResponseError;
            return errorMesage?.data?.data?.message;
          },
        },
      });
    }

    if (type === "PRIMARY_OCCUPATION" || type === "SECONDARY_OCCUPATION") {
      const payload = {
        type: MASTER_TYPE.OCCUPATIONS,
        name: newValue || searchTerm,
        alias: newValue || searchTerm,
      };
      toast.promise(postMasterType(payload), {
        pending: {
          render() {
            return MESSAGES.POSTING_CONTRACTS.VERIFYING;
          },
        },
        success: {
          render(newOccupation) {
            const newId = newOccupation?.data?.data?._id || newValue;
            if (type === "SECONDARY_OCCUPATION") {
              const _obj: any = {
                label: newOccupation?.data?.data?.name || newValue,
                value: newId,
              };
              const newArr = [...occupation.secondaryOccupations, _obj];
              dispatch(
                setOccupation({ ...occupation, secondaryOccupations: newArr })
              );
              return MESSAGES.POSTING_CONTRACTS.ADDING;
            } else {
              const _obj: any = {
                label: newOccupation?.data?.data?.name || newValue,
                value: newId,
              };
              dispatch(
                setOccupation({ ...occupation, primaryOccupation: _obj })
              );
              return MESSAGES.POSTING_CONTRACTS.ADDING;
            }
          },
        },
        error: {
          render(error) {
            let errorMesage = error as APIResponseError;
            return errorMesage?.data?.data?.message;
          },
        },
      });
    }
  };

  const handleCreate = (inputValue: string, type?: string) => {
    handleSubmitCustomOccupation(inputValue, type);
  };

  const _handleBack = () => {
    dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_TECHNICAL_SKILLS));
  };

  const onLoad = (autocomplete: any) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const addressComponents: any = place.address_components;
      const result = {
        city: "",
        state: "",
        country: "",
        zipcode: "",
        streets: "",
      };
      const streetComponents: string[] = [];

      addressComponents.forEach(
        (component: google.maps.GeocoderAddressComponent) => {
          const types = component.types;
          if (types.includes("locality")) {
            result.city = component.long_name;
          } else if (types.includes("administrative_area_level_1")) {
            result.state = component.long_name;
          } else if (types.includes("country")) {
            result.country = component.long_name;
          } else if (types.includes("postal_code")) {
            result.zipcode = component.long_name;
          } else if (types.includes("premise")) {
            streetComponents.push(component.long_name); // Collect street numbers
          } else if (types.includes("street_number")) {
            streetComponents.push(component.long_name); // Collect street numbers
          } else if (types.includes("route")) {
            streetComponents.push(component.long_name); // Collect routes
          } else if (
            types.includes("sublocality") ||
            types.includes("sublocality_level_1") ||
            types.includes("sublocality_level_2")
          ) {
            streetComponents.push(component.long_name); // Collect sublocalities
          }
        }
      );
      // Join the collected street components into a single string
      result.streets = streetComponents.join(", ");
      formik.setFieldValue("selectedCity", result.city);
      formik.setFieldValue("selectedState", result.state);
      formik.setFieldValue("selectedCountry", result.country);
      formik.setFieldValue("selectedZipcode", result.zipcode);
      formik.setFieldValue("selectedStreet", result.streets);
      // Optionally update Redux state
      dispatch(
        setLocation({
          ...location,
          selectedCity: { label: result.city, value: result.city },
          selectedState: { label: result.state, value: result.state },
          selectedCountry: { label: result.country, value: result.country },
          selectedZipcode: { label: result.zipcode, value: result.zipcode },
          selectedStreet: { label: result.streets, value: result.streets },
        })
      );
      setAddressDetails(result);
    }
  };

  const handleAddressChange = (event: any) => {
    setAddress(event.target.value);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      setIsAddressEditable(false);
    }
  };

  const _handleSelect = (value: any, key: string) => {
    dispatch(setRates({ ...rates, [key]: value }));
  };
  const handleFileUpload = async (files: FileList | null) => {
    if (files) {
      const formData = new FormData();
      Array.from(files).forEach((img) => {
        formData.append("files", img); // Append multiple files
      });

      try {
        const response = await uploadFileImg(formData);
        const newImageUrls = response.data.files; // Assuming response gives an array of image URLs

        // Append new image URLs to the existing array
        setImgUrls((prevUrls) => [...prevUrls, ...newImageUrls]);

        // Dispatch with updated skillImg
        dispatch(
          setTechnicalSkills({
            ...technicalSkillsData,
            skillImg: [...technicalSkillsData.skillImg, ...newImageUrls],
          })
        );
      } catch (err) {
        console.log("Error occurred while uploading image", err);
      }
    }
  };

  const removeUploadedFiles = (index: number): void => {
    setImgUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
  };

  const _handleCitizenChange = (value: string | boolean, key: string) => {
    setCitizencheck(!citizenCheck);
    if (!citizenCheck) {
      setIsOtherChecked({
        isHealthInsurance: false,
        isVacationAndTimeOff: false,
        isOtherBenefits: false,
      });
    }
    dispatch(setAdditionalFields({ ...additionfiels, [key]: value }));
  };

  const handleOtherCheckboxChange = (
    value: string | boolean,
    key: OtherCheckboxKey
  ) => {
    setIsOtherChecked({
      ...isOtherChecked,
      [key]: !isOtherChecked[key],
    });
    dispatch(setAdditionalFields({ ...additionfiels, [key]: value }));
  };

  return (
    <>
      <Header />
      <div className="Reviewcontarctdetailform">
        <div className={styles.Reviewcontarctdetail}>
          <div className={styles.Reviewcontarct}>
            <div className="container text-center">
              <figure>
                <img src={overviewpage} alt="" className="mx-auto" />
              </figure>
              <h4 className="text-white mb-2">Hey !</h4>
              <h3 className="text-white mb-3">
                We are excited to see you reach your goal!
              </h3>
              <p className="text-white text-14">
                You have done a fantastic job so far. Please take a moment to
                review your preview. If there are any changes
                <br /> needed, feel free to edit your form. Once you are ready,
                we can move forward together to the next step.
              </p>
            </div>
          </div>
          {loading ? (<div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>) :
            (<div className="p-3">
              <div className="col-lg-10 mx-auto col-12 reviewquestion">
                <div
                  className="d-flex align-items-center justify-content-end mb-3"
                  style={{ gap: "15px" }}
                >
                  <button
                    type="button"
                    className="btn btn-transparent border border-2 d-flex align-items-center rounded-pill"
                  >
                    <i className="pe-2">
                      <img src={print} alt="" />
                    </i>
                    <span className="text-white">Print</span>
                  </button>
                  <button
                    onClick={() =>
                      addImagesToPdf(
                        [contentRef, contentRef1],
                        setDownloading,
                        "Review"
                      )
                    }
                    type="button"
                    className="btn btn-transparent border border-2 d-flex align-items-center rounded-pill"
                  >
                    <i className="pe-2">
                      <img src={download} alt="" />
                    </i>
                    <span className="text-white">
                      {downloading ? "Downloading..." : "Download"}
                    </span>
                  </button>
                </div>
              </div>
              <div
                id="agreement-content"
                className="col-lg-10 mx-auto col-12 p-2"
              >
                <div
                  className="position-relative p-0 rounded-4"
                  style={{ background: "#E7EFFB", border: "1px solid #E7EFFB" }}
                >
                  <div className={styles.Reviewcontarctform}>
                    <ul className="ps-0">
                      <div ref={contentRef}>
                        <OverviewCollapseItem
                          toggleEditing={toggleEditing}
                          toggle={(item) => toggleOption(item)}
                          name={"What Occupation are you looking for?"}
                          isActive={
                            activeItem == "What Occupation are you looking for?"
                          }
                        >
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Choose Primary Occupation</label>
                              </div>
                              <div className="col-12">
                                <CreatableSelect
                                  onCreateOption={(_cb) =>
                                    handleCreate(_cb, "PRIMARY_OCCUPATION")
                                  }
                                  name="dropdown-basic"
                                  options={primaryOccOptions as any}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(val) =>
                                    handleOccupationChange(
                                      val,
                                      "primaryOccupation"
                                    )
                                  }
                                  value={occupation.primaryOccupation}
                                />
                              </div>
                            </div>
                          </div>

                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Choose Secondary Occupation</label>
                              </div>

                              <div className="col-12 text-center">
                                <CreatableSelect
                                  isMulti
                                  onCreateOption={(_cb) =>
                                    handleCreate(_cb, "SECONDARY_OCCUPATION")
                                  }
                                  name="colors"
                                  options={occupationsList as any}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(val) =>
                                    handleOccupationChange(
                                      val,
                                      "secondaryOccupations"
                                    )
                                  }
                                  value={occupation.secondaryOccupations}
                                />
                              </div>
                            </div>
                          </div>
                        </OverviewCollapseItem>

                        <OverviewCollapseItem
                          toggleEditing={toggleEditing}
                          toggle={(item) => toggleOption(item)}
                          name={"Describe the Work!"}
                          isActive={activeItem == "Describe the Work!?"}
                        >
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Work Description</label>
                              </div>

                              <div className="col-12 text-center">
                                <TextEditorCustomComp
                                  data={contract?.workDescription}
                                  setDesc={setDesc}
                                />
                              </div>
                            </div>
                          </div>
                        </OverviewCollapseItem>

                        <OverviewCollapseItem
                          toggleEditing={toggleEditing}
                          toggle={(item) => toggleOption(item)}
                          name={"Timing"}
                          isActive={activeItem == "Timing"}
                        >
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className={styles.fromGroup}>
                                <div className="row align-items-center justify-content-between">
                                  <div className="col-12">
                                    <label>Start Date</label>
                                  </div>

                                  <div className="col-12 text-center">
                                    <DatePicker
                                      selected={keyInformations.startDate}
                                      onChange={(date) =>
                                        onChangeDate(date, "startDate")
                                      }
                                      placeholderText="StartDate"
                                      minDate={new Date()}
                                      dateFormat="MM-dd-yyyy"
                                      className=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </OverviewCollapseItem>

                        <OverviewCollapseItem
                          toggleEditing={toggleEditing}
                          toggle={(item) => toggleOption(item)}
                          name={"Shifts"}
                          isActive={activeItem == "Shifts"}
                        >
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Shift</label>
                              </div>

                              <div className="col-12 text-center">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    name="shift"
                                  >
                                    {keyInformations.shift}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <div
                                      style={{
                                        maxHeight: "300px",
                                        overflowY: "scroll",
                                      }}
                                    >
                                      {shifts.map((shiftData) => (
                                        <Dropdown.Item
                                          key={shiftData}
                                          onClick={() =>
                                            onKeyInforChange(shiftData, "shift")
                                          }
                                        >
                                          {shiftData}
                                        </Dropdown.Item>
                                      ))}
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Planned hours per Day</label>
                              </div>

                              <div className="col-12 text-center">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    name="plannedHoursPerDay"
                                  >
                                    {keyInformations.plannedHoursPerDay}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <div
                                      style={{
                                        maxHeight: "300px",
                                        overflowY: "scroll",
                                      }}
                                    >
                                      {hours.map((hourData) => (
                                        <Dropdown.Item
                                          key={hourData}
                                          onClick={() =>
                                            onKeyInforChange(
                                              Number(hourData),
                                              "plannedHoursPerDay"
                                            )
                                          }
                                        >
                                          {hourData}
                                        </Dropdown.Item>
                                      ))}
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>

                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Planned Days per Week</label>
                              </div>
                              <div className="col-12 text-center">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    name="shift"
                                  >
                                    {keyInformations.plannedDaysPerWeek}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <div
                                      style={{
                                        maxHeight: "300px",
                                        overflowY: "scroll",
                                      }}
                                    >
                                      {days.map((dayData) => (
                                        <Dropdown.Item
                                          key={dayData}
                                          onClick={() =>
                                            onKeyInforChange(
                                              Number(dayData),
                                              "plannedDaysPerWeek"
                                            )
                                          }
                                        >
                                          {dayData}
                                        </Dropdown.Item>
                                      ))}
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        </OverviewCollapseItem>

                        <OverviewCollapseItem
                          toggleEditing={toggleEditing}
                          toggle={(item) => toggleOption(item)}
                          name={"Address of the Job Site"}
                          isActive={
                            activeItem == "Where will the work take place?"
                          }
                        >
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Start typing your address.....</label>
                              </div>

                              <div className="col-12 text-center">
                                {isLoaded && (
                                  <Autocomplete
                                    onLoad={onLoad}
                                    onPlaceChanged={onPlaceChanged}
                                  >
                                    <input
                                      type="text"
                                      placeholder="Start typing your address"
                                      value={address}
                                      onChange={handleAddressChange}
                                      onKeyPress={handleKeyPress}
                                    // disabled={!isAddressEditable}
                                    />
                                  </Autocomplete>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Country</label>
                              </div>

                              <div className="col-12 text-center">
                                <input
                                  type="text"
                                  value={formik.values.selectedCountry}
                                  name="selectedCountry"
                                  onChange={formik.handleChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>State</label>
                              </div>

                              <div className="col-12 text-center">
                                <input
                                  type="text"
                                  disabled
                                  value={selectedState?.label}
                                />
                              </div>
                            </div>
                          </div>
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>City</label>
                              </div>

                              <div className="col-12 text-center">
                                <input
                                  type="text"
                                  disabled
                                  value={selectedCity.label}
                                />
                              </div>
                            </div>
                          </div>
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Street</label>
                              </div>

                              <div className="col-12 text-center">
                                <input
                                  type="text"
                                  disabled
                                  value={selectedStreet?.label}
                                />
                              </div>
                            </div>
                          </div>
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>ZipCode</label>
                              </div>

                              <div className="col-12 text-center">
                                <input
                                  type="text"
                                  disabled
                                  value={selectedZipcode.label}
                                />
                              </div>
                            </div>
                          </div>
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Select Work Location</label>
                              </div>

                              <div className="col-12 text-center">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    name="travelTip"
                                  >
                                    {workLocation ||
                                      "Select your work location"}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <div
                                      style={{
                                        maxHeight: "300px",
                                        overflowY: "scroll",
                                      }}
                                    >
                                      {options.map((item) => (
                                        <Dropdown.Item
                                          key={item}
                                          onClick={() =>
                                            onChangeWorkEnv(
                                              item,
                                              "workLocation"
                                            )
                                          }
                                        >
                                          {item}
                                        </Dropdown.Item>
                                      ))}
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        </OverviewCollapseItem>
                      </div>
                      <div ref={contentRef1}>
                        <OverviewCollapseItem
                          toggleEditing={toggleEditing}
                          toggle={(item) => toggleOption(item)}
                          name={"Pay Rates / Hour"}
                          isActive={activeItem == "Pay Rates / Hour"}
                        >
                          <div className={styles.fromGroup}>
                            <div className="row align-items-start justify-content-between">
                              <div className="col-12">
                                <label>Select the net payment terms</label>
                              </div>
                              <div className="col-12 text-center">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="primary"
                                    id="dropdown-basic"
                                  >
                                    {contract?.rates.paymentTerms
                                      ? contract?.rates.paymentTerms.label
                                      : "Please select an option"}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {contract?.rates?.paymentTermsList?.map(
                                      (term, ind) => (
                                        <Dropdown.Item
                                          key={ind}
                                          onClick={() =>
                                            _handleSelect(term, "paymentTerms")
                                          }
                                        >
                                          {term?.label}
                                        </Dropdown.Item>
                                      )
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>

                          <div className={styles.fromGroup}>
                            <div className="row align-items-start justify-content-between">
                              <div className="col-12">
                                <label>Base Rate($ per hour)</label>
                              </div>

                              <div className="col-12 text-center">
                                <input
                                  type="text"
                                  name="baseRate"
                                  placeholder="$/hour"
                                  value={rates.baseRate}
                                  onChange={({ target }) =>
                                    onRateChange(Number(target.value), "baseRate")
                                  }
                                />

                                <p className={styles.error}>
                                  0-40 Hours Monday-Saturday
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className={styles.fromGroup}>
                            <div className="row align-items-start justify-content-between">
                              <div className="col-12">
                                <label>OT Rate($ per hour)</label>
                              </div>

                              <div className="col-12 text-center">
                                <input
                                  type="text"
                                  name="OTrate"
                                  placeholder="$/hour"
                                  value={rates.otRate}
                                  onChange={({ target }) =>
                                    onRateChange(Number(target.value), "otRate")
                                  }
                                />

                                <p className={styles.error}>
                                  41+ Hours Monday-Saturday
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className={styles.fromGroup}>
                            <div className="row align-items-start justify-content-between">
                              <div className="col-12">
                                <label>DT Rate($ per hour)</label>
                              </div>

                              <div className="col-12 text-center">
                                <input
                                  type="text"
                                  name="DTrate"
                                  placeholder="$/hour"
                                  value={rates.dtRate}
                                  onChange={({ target }) =>
                                    onRateChange(Number(target.value), "dtRate")
                                  }
                                />

                                <p className={styles.error}>
                                  Sunday and US Holidays
                                </p>
                              </div>
                            </div>
                          </div>
                        </OverviewCollapseItem>

                        <OverviewCollapseItem
                          toggleEditing={toggleEditing}
                          toggle={(item) => toggleOption(item)}
                          name={"Programming / Technical Skills"}
                          isActive={
                            activeItem == "Programming / Technical Skills"
                          }
                        >
                          {technicalSkillsData.skills.map((x, i) => (
                            <>
                              <div className="text-end my-4">
                                {technicalSkillsData.skills.length > 1 &&
                                  technicalSkillsData.skills.length < 5 && (
                                    <button
                                      className="p-1 btn btn-white"
                                      type="button"
                                      onClick={() => deleteSkill(i)}
                                    >
                                      <i>
                                        <img
                                          src={Delete}
                                          alt=""
                                          width={25}
                                          height={25}
                                        />
                                      </i>
                                    </button>
                                  )}
                              </div>
                              <div
                                className="rounded-3 p-3"
                                style={{ background: "rgba(255, 255,255, 0.5)" }}
                              >
                                <div
                                  className={styles.fromGroup}
                                  style={{ marginBottom: "30px" }}
                                >
                                  <label>Skill Name</label>
                                  <CreatableSelect
                                    onCreateOption={(_cb) =>
                                      handleCreate(_cb, "SkillName")
                                    }
                                    name="dropdown-basic"
                                    options={primaryOccOptions as any}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(val) =>
                                      _handleSkillChange(val, "SkillName")
                                    }
                                    value={technicalSkillsData?.skillName}
                                  />
                                </div>
                                <div className={styles.fromGroup}>
                                  <div className="row align-items-center justify-content-between mx-0">
                                    <div className="col-12 px-0">
                                      <label>Manufacturer</label>
                                    </div>

                                    <div className="col-12 text-start border rounded-2">
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          variant="primary"
                                          id="dropdown-basic"
                                          className="ms-0"
                                          disabled={
                                            inEditField !==
                                            OVERVIEW_FORM_FIELDS.PROGRAMMING_SKILLS
                                          }
                                        >
                                          {x.category.label ??
                                            "Please select an option"}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <FormControl
                                            autoFocus
                                            className="mx-3 my-2 w-auto"
                                            placeholder="Search..."
                                            disabled={
                                              inEditField !==
                                              OVERVIEW_FORM_FIELDS.PROGRAMMING_SKILLS
                                            }
                                            onChange={handleSearch}
                                            value={searchTerm}
                                          />
                                          {filteredCategories.length === 0 ? (
                                            <Dropdown.Item disabled>
                                              No options found
                                            </Dropdown.Item>
                                          ) : (
                                            filteredCategories.map(
                                              (option, index: number) => (
                                                <Dropdown.Item
                                                  key={index}
                                                  onClick={() =>
                                                    handleChangeManufacturer(
                                                      option,
                                                      i,
                                                      "category"
                                                    )
                                                  }
                                                >
                                                  {option.label}
                                                </Dropdown.Item>
                                              )
                                            )
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                                <div className={styles.fromGroup}>
                                  <div className="row align-items-center justify-content-between">
                                    <div className="col-12">
                                      <label>Model / Controller</label>
                                    </div>

                                    <div className="col-12 text-start">
                                      <Select
                                        isMulti
                                        name="colors"
                                        options={x.subCategoriesList}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={(val) =>
                                          onSkillsChange(
                                            val as any,
                                            "subCategory",
                                            i
                                          )
                                        }
                                        value={x.subCategory}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={styles.fromGroup}
                                  style={{ marginBottom: "30px" }}
                                >
                                  <label>Upload Image</label>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    className="form-control"
                                    placeholder="Upload image"
                                    onChange={({ target }) =>
                                      handleFileUpload(target.files)
                                    }
                                    multiple
                                  />
                                  <ul className="mt-3">
                                    {imgUrls?.map((imgUrl, index) => (
                                      <li
                                        key={index}
                                        className="position-relative"
                                      >
                                        <div
                                          className={`col-3 position-relative  ${styles.uploadImages}`}
                                        >
                                          <img
                                            src={imgUrl}
                                            alt={`Uploaded image ${index}`}
                                            className="img-fluid"
                                          />
                                          {/* Remove icon */}
                                          <i
                                            className="fas fa-times position-absolute top-0 end-0"
                                            onClick={() =>
                                              removeUploadedFiles(index)
                                            }
                                          ></i>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                                <div className={styles.fromGroup}>
                                  <div className="row align-items-center justify-content-between">
                                    <div className="col-12">
                                      <label>Skills</label>
                                    </div>
                                    <div className="col-12 text-start">
                                      <div
                                        className={`${styles.radioBTn} ${styles.radStepOne}`}
                                      >
                                        <div>
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name={`flexRadioDefault${i}`}
                                              id={`flexRadioDefault1${i}`}
                                              checked={x.isRequired}
                                              onChange={(e) =>
                                                onSkillsChange(
                                                  true,
                                                  "isRequired",
                                                  i
                                                )
                                              }
                                            />
                                            <label className="form-check-label">
                                              Mandatory
                                            </label>
                                          </div>
                                        </div>
                                        <div>
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name={`flexRadioDefault2${i}`}
                                              id={`flexRadioDefault2${i}`}
                                              checked={!x.isRequired}
                                              onChange={(e) =>
                                                onSkillsChange(
                                                  false,
                                                  "isRequired",
                                                  i
                                                )
                                              }
                                            />
                                            <label className="form-check-label">
                                              Good to have
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </OverviewCollapseItem>

                        <OverviewCollapseItem
                          toggleEditing={toggleEditing}
                          toggle={(item) => toggleOption(item)}
                          name={"Additional Details"}
                          isActive={activeItem == "Additional Details"}
                        >
                          <div className="col-md-6">
                            <div className={styles.registerRight}>
                              <div className="col-12 mx-auto">
                                <div className="checkboxes__item">
                                  <label className="checkbox style-c">
                                    {" "}
                                    Same citizenship is required for the job
                                    <input
                                      type="checkbox"
                                      checked={citizenCheck}
                                      onChange={(e) =>
                                        _handleCitizenChange(
                                          e.target.value,
                                          "isSameCitizenship"
                                        )
                                      }
                                      className=""
                                    />
                                    <div className="checkbox__checkmark"></div>
                                  </label>
                                </div>
                                {/* <div className="form-group mt-3">
                              <label>
                                <input
                                  type="checkbox"
                                  checked={citizenCheck}
                                  onChange={(e) =>
                                    _handleCitizenChange(
                                      e.target.value,
                                      "isSameCitizenship"
                                    )
                                  }
                                />
                                Same citizenship is required for the job
                              </label>
                            </div> */}
                                <div className="form-group mt-3">
                                  <div className="topTipHover">
                                    <label>Health Insurance</label>
                                  </div>
                                  <div className="col-12 mt-3">
                                    <div className="checkboxes__item">
                                      <label className="checkbox style-c text-white">
                                        <div className="row align-items-center bg-lightwhiteblue p-3 rounded-4 mx-0">
                                          <div className="col-1 text-center px-0">
                                            <i>
                                              <img src={insc} alt="" />
                                            </i>
                                          </div>
                                          <div className="col-11">
                                            <label className="startlabel text-14 text-white">
                                              Comprehensive health insurance
                                              coverage available upon permanent
                                              employment.
                                            </label>
                                          </div>
                                        </div>
                                        <input
                                          type="checkbox"
                                          checked={
                                            isOtherChecked.isHealthInsurance
                                          }
                                          onChange={(e) =>
                                            handleOtherCheckboxChange(
                                              e.target.value,
                                              "isHealthInsurance"
                                            )
                                          }
                                          disabled={!citizenCheck} // Disable if citizenship is not checked
                                        />
                                        <div className="checkbox__checkmark mt-4"></div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group mt-3">
                                  <div className="topTipHover">
                                    <label>Vacation and Time Off</label>
                                  </div>
                                  <div className="col-12 mt-3">
                                    <div className="checkboxes__item">
                                      <label className="checkbox style-c text-white">
                                        <div className="row align-items-center bg-lightwhiteblue p-3 rounded-4 mx-0">
                                          <div className="col-1 text-center px-0">
                                            <i>
                                              <img src={vacation} alt="" />
                                            </i>
                                          </div>
                                          <div className="col-11">
                                            <label className="startlabel text-14 text-white">
                                              Comprehensive health insurance
                                              coverage available upon permanent
                                              employment.
                                            </label>
                                          </div>
                                        </div>

                                        <input
                                          type="checkbox"
                                          checked={
                                            isOtherChecked.isVacationAndTimeOff
                                          }
                                          onChange={(e) =>
                                            handleOtherCheckboxChange(
                                              e.target.value,
                                              "isVacationAndTimeOff"
                                            )
                                          }
                                          disabled={!citizenCheck} // Disable if citizenship is not checked
                                        />
                                        <div className="checkbox__checkmark mt-4"></div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group mt-3">
                                  <div className="topTipHover">
                                    <label>Other Benefits</label>
                                  </div>
                                  <div className="col-12 mt-3">
                                    <div className="checkboxes__item">
                                      <label className="checkbox style-c text-white">
                                        <div className="row align-items-center bg-lightwhiteblue p-3 rounded-4 mx-0">
                                          <div className="col-1 text-center px-0">
                                            <i>
                                              <img src={incentive} alt="" />
                                            </i>
                                          </div>
                                          <div className="col-11">
                                            <label className="startlabel text-14 text-white">
                                              Comprehensive health insurance
                                              coverage available upon permanent
                                              employment.
                                            </label>
                                          </div>
                                        </div>

                                        <input
                                          type="checkbox"
                                          checked={isOtherChecked.isOtherBenefits}
                                          onChange={(e) =>
                                            handleOtherCheckboxChange(
                                              e.target.value,
                                              "isOtherBenefits"
                                            )
                                          }
                                          disabled={!citizenCheck} // Disable if citizenship is not checked
                                        />
                                        <div className="checkbox__checkmark mt-4"></div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </OverviewCollapseItem>

                        <OverviewCollapseItem
                          toggleEditing={toggleEditing}
                          toggle={(item) => toggleOption(item)}
                          name={"Eligibility Questions"}
                          isActive={activeItem == "Eligibility Questions"}
                        >
                          {eligibilityQuestions.map((x, i: number) => (
                            <>
                              <div
                                className={styles.fromGroup}
                                style={{ maxWidth: "100%" }}
                              >
                                <div className={styles.AddBtns}>
                                  <label>Question{i + 1}:</label>
                                  <div
                                    className={styles.AddBtn}
                                    style={{
                                      display: "d-flex",
                                      alignItems: "center",
                                      justifyContent: "between",
                                    }}
                                  >
                                    <div className="d-flex align-items-center justify-content-between w-100">
                                      <div className="col-11">
                                        <TextEditorCustomComp
                                          data={x}
                                          setDesc={(value: string) =>
                                            onEligibilityQusChange(value, i)
                                          }
                                          index={i}
                                        />
                                      </div>
                                      <div className="col-1">
                                        <div className="d-flex align-items-center justify-content-center">
                                          {i ===
                                            eligibilityQuestions.length - 1 &&
                                            eligibilityQuestions.length < 5 && (
                                              <button
                                                type="button"
                                                onClick={
                                                  addNewEligibilityQuestion
                                                }
                                                style={{
                                                  background: "#0053CD",
                                                  padding: "10px",
                                                  width: "40px",
                                                  height: "40px",
                                                  color: "#fff",
                                                  borderRadius: "50%",
                                                  fontSize: "20px",
                                                  lineHeight: "0",
                                                }}
                                              >
                                                +
                                              </button>
                                            )}
                                          {eligibilityQuestions.length > 1 && (
                                            <button
                                              type="button"
                                              style={{
                                                background: "#0053CD",
                                                padding: "10px",
                                                width: "40px",
                                                height: "40px",
                                                color: "#fff",
                                                borderRadius: "50%",
                                                fontSize: "20px",
                                                lineHeight: "0",
                                              }}
                                              onClick={() =>
                                                deleteEligibilityQuestion(i)
                                              }
                                            >
                                              -
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </OverviewCollapseItem>

                        <OverviewCollapseItem
                          toggleEditing={toggleEditing}
                          toggle={(item) => toggleOption(item)}
                          name={"Interview Questions"}
                          isActive={activeItem == "Interview Questions"}
                        >
                          {interviewQuestions.map((x, i) => (
                            <>
                              <div
                                className={styles.fromGroup}
                                style={{ maxWidth: "100%" }}
                              >
                                <div className={styles.AddBtns}>
                                  <div className="row align-items-center justify-content-between">
                                    <div className="col-12">
                                      <label>Question{i + 1}:</label>
                                    </div>

                                    <div className="col-12 text-start">
                                      <div
                                        className={styles.AddBtn}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "between",
                                        }}
                                      >
                                        <div className="d-flex align-items-center justify-content-between w-100">
                                          <div className="col-11">
                                            <TextEditorCustomComp
                                              data={x}
                                              setDesc={(value: string) =>
                                                onInyterviewQuestionChange(
                                                  value,
                                                  i
                                                )
                                              }
                                              index={i}
                                            />
                                          </div>
                                          <div className="col-1 ps-2">
                                            {i ===
                                              interviewQuestions.length - 1 &&
                                              interviewQuestions.length < 5 && (
                                                <button
                                                  type="button"
                                                  onClick={addNewQuestion}
                                                  style={{
                                                    background: "#0053CD",
                                                    padding: "10px",
                                                    width: "40px",
                                                    height: "40px",
                                                    color: "#fff",
                                                    borderRadius: "50%",
                                                    fontSize: "20px",
                                                    lineHeight: "0",
                                                  }}
                                                >
                                                  +
                                                </button>
                                              )}
                                            {interviewQuestions.length > 1 && (
                                              <button
                                                type="button"
                                                style={{
                                                  background: "#0053CD",
                                                  padding: "10px",
                                                  width: "40px",
                                                  height: "40px",
                                                  color: "#fff",
                                                  borderRadius: "50%",
                                                  fontSize: "20px",
                                                  lineHeight: "0",
                                                }}
                                                onClick={() => deleteQuestion(i)}
                                              >
                                                -
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </OverviewCollapseItem>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-10 mx-auto col-12">
                <div className={styles.regisFooter}>
                  <div className="w-100">
                    <div className="col-12 ms-auto">
                      <div className="row align-items-center justify-content-end">
                        <div className="col-xl-4 col-lg-4 col-6 text-start my-3 btn-hover-effect">
                          <h6
                            onClick={_handleBack}
                            className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                          >
                            <span className="px-lg-3 px-2 text-12 fw-semibold d-inline-block">
                              Back
                            </span>
                            <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                              <img src={blackarrow} alt="" />
                            </i>
                          </h6>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-6 text-center">
                          <div className={styles.DraftBtns}>
                            <DirectJobDraft />
                          </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-12 text-end my-3 btn-hover-effect">
                          <h6
                            onClick={handleSendForApproval}
                            className="btn arrowblue border-grey border-1 position-relative pe-5"
                          >
                            <span className="px-lg-4 px-2 text-12 fw-semibold">
                              Send For Approval
                            </span>
                            <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ">
                              <img src={whitearrow} alt="" />
                            </i>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>)}
        </div>
      </div>
      {modalDetail.show && (
        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={true}
          isRightSideModal={false}
          mediumWidth={false}
          className={modalDetail.flag === "boost" ? "boost" : ""}
          header={
            <div className="modalHeader_">
              <div className="common_">
                {modalDetail.flag === "infoBusiness" ? (
                  <h2 className="text-22 text-white mb-0 text-center live-modal-title">
                    Business Details
                  </h2>
                ) : (
                  ""
                )}
              </div>
            </div>
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      )}
    </>
  );
};

export default OverViewDirectjob;
