import React, { useCallback, useEffect, useState } from "react";
import styles from "../../../styles/style.module.css";
import { useFormik } from "formik";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import storage from "../../../../../utils/storage";
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import Delete from "../../../../../assets/images/delete.svg";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setTechnicalSkills,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import { getMasterTypes } from "../../../../../feature/auth/signup/api";
import { MASTER_TYPE } from "../../../../../utils/enums/masterTypes.enum";
import { getManufacturerModelList } from "../../../../work/api";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import { Dropdown, FormControl } from "react-bootstrap";
import { IOption } from "../../../../../Interfaces";
import { SaveAsDraftButton } from "../../../../work/contractSteps/Components";
import { MESSAGES } from "../../../../../utils/messages";
import { toast } from "react-toastify";
import { postMasterType } from "../../../../work/api";
import { APIResponseError } from "../../../../work/interface";
import CreatableSelect from "react-select/creatable";
import Toltip from "../../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import tip from "../../../../../assets/images/tip.png";

const AddTechnicalSkills: React.FC = () => {
  const dispatch = useDispatch();
  const token = storage.getToken();
  const { technicalSkillsData } = useSelector(
    (state: RootState) => state.addContract
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: string]: boolean;
  }>({
    manuf: false,
    model: false,
  });

  const formIk: any = useFormik({
    initialValues: {
      skills: technicalSkillsData.skills,
    },

    onSubmit: (values) => {
      // dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_ELIGIBILITY_QUESTION));
    },
  });

  console.log(technicalSkillsData, "technicalSkillsData");

  useEffect(() => {
    const getManufacturerData = async () => {
      const response = await getMasterTypes(MASTER_TYPE.MANUFACTURERS);
      response.data.forEach((x) => {
        x.label = x.name;
        x.value = x._id;
      });

      dispatch(
        setTechnicalSkills({
          ...technicalSkillsData,
          skillTypes: response.data,
        })
      );
    };

    getManufacturerData();
  }, []);

  const addNewManufacture = (): void => {
    const updatedSkills = [...technicalSkillsData.skills];
    updatedSkills.push({
      isRequired: false,
      subCategoriesList: [],
      category: { label: "", value: "" },
      subCategory: [],
    });

    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills })
    );
  };

  const onValueChange = (
    value: string | boolean | IOption[],
    key: string,
    index: number
  ): void => {
    const updatedSkills = [...technicalSkillsData.skills];
    const updatedSkill = { ...updatedSkills[index], [key]: value };
    updatedSkills[index] = updatedSkill;

    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills })
    );
    formIk.setFieldValue("skills", updatedSkills);
  };

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_BASIC_INFO));
  };
  const handleGoToNextStep = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_ELIGIBILITY_QUESTION));
  };

  const handleSkip = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_ELIGIBILITY_QUESTION));
  };

  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const handleSubmitCustomOccupation = async (
    newValue?: any,
    type?: string
  ) => {
    const payload = {
      type: MASTER_TYPE.MANUFACTURERS,
      name: newValue || searchTerm,
      alias: newValue || searchTerm,
    };

    toast.promise(postMasterType(payload), {
      pending: {
        render() {
          return MESSAGES.POSTING_CONTRACTS.VERIFYING;
        },
      },
      success: {
        render(newManufacture) {
          if (type === "_MANUFACTURERS") {
            _addNewSkill(newManufacture?.data?.data, 1, "category");

            return MESSAGES.POSTING_CONTRACTS.ADDING;
          }
          setSearchTerm("");
          dispatch(
            setTechnicalSkills({
              ...technicalSkillsData,
              skillTypes: [
                {
                  label: newManufacture.data.data.name,
                  value: newManufacture.data.data._id,
                },
              ],
              // skillTypes: {
              //   label: newManufacture.data.data.name,
              //   value: newManufacture.data.data._id,
              // },
            })
          );

          return MESSAGES.POSTING_CONTRACTS.ADDING;
        },
      },
      error: {
        render(error) {
          let errorMesage = error as APIResponseError;
          return errorMesage?.data?.data?.message;
        },
      },
    });
  };

  const _addNewSkill = async (option: any, index: number, key: string) => {
    const updatedSkills = [];
    let _newSkill = {
      isRequired: false,
      subCategoriesList: [],
      category: {
        ...option,
        label: option.name,
        value: option._id,
      },
      subCategory: [],
    };
    updatedSkills.push(_newSkill);

    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills })
    );

    // console.log(updatedSkills, 'updatedSkillsupdatedSkills');
  };

  const handleChangeManufacturer = async (
    option: any,
    index: number,
    key: string
  ) => {
    const updatedSkills = [...technicalSkillsData.skills];
    const updatedSkill = { ...updatedSkills[index] };

    console.log(
      option,
      " option-handleChangeManufacturer ",
      index,
      " index-handleChangeManufacturer ",
      key,
      " key-handleChangeManufacturer"
    );

    if (key === "category") {
      const response = await getManufacturerModelList(
        MASTER_TYPE.MANUFACTURERS_MODELS,
        option.value
      );
      response.data.forEach((x) => {
        x.label = x.name;
        x.value = x._id;
      });

      updatedSkill.subCategoriesList = response.data ?? [];
      updatedSkill.category = option;
      updatedSkill.subCategory = [];
    }

    if (key === "subCategory") {
      updatedSkill.subCategory = option;
    }

    (updatedSkill as any)[key] = option;
    updatedSkills[index] = updatedSkill;

    formIk.setFieldValue("skills", updatedSkills);
    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills })
    );
  };

  const deleteSkill = (i: number) => {
    const updatedSkills = [...technicalSkillsData.skills];
    updatedSkills.splice(i, 1);

    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills })
    );
  };

  const filteredCategories = technicalSkillsData.skillTypes.filter((option) =>
    option?.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCreate = (inputValue: string, type?: string) => {
    handleSubmitCustomOccupation(inputValue, type);
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isTooltipIcon = target.closest(".tooltip-icon");
    const isTooltipContent = target.closest(".tooltip-content");
    const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line

    if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
      setTooltipVisibility({
        manuf: false,
        model: false,
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  const handleIconClick = (tooltipKey: string) => {
    setTooltipVisibility(prev => ({
      manuf: false,
      model: false,
      [tooltipKey]: !prev[tooltipKey]
    }));
  };

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formIk.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div className={styles.registerLeft}>
                  <div className="col-lg-11 col-12 mx-auto text-center">
                    <figure>
                      <img
                        src={choosetype}
                        alt=""
                        className="img-fluid w-100"
                      />
                    </figure>
                  </div>
                </div> </div>

              <div className="col-md-6">
                <div className={styles.ScrollDiv}>
                  <h4 className="text-white text-32 fw-semibold">
                    Programming / Technical Skills
                  </h4>
                  <p className="text-16 text-white opacity-75">
                    Select manufacturer of the hardware or software that
                    you need help with. Examples: AutoCAD, FANUC,
                    Siemens, Allen Bradley, Kuka, ABB
                  </p>
                  <div className={styles.signUPContainers}>
                    <div className={styles.registerRight}>
                      <div className="technicalskill">

                        {technicalSkillsData?.skills?.map((x, i) => (
                          <>
                            <>
                              {technicalSkillsData.skills.length >
                                1 && (
                                  <div className="mb-4 text-end">
                                    <button
                                      type="button"
                                      className="btn"
                                      onClick={() => deleteSkill(i)}
                                    >
                                      {/* <span className=" px-lg-4 px-2">Delete</span> */}
                                      <i className="bg-white rounded-circle p-2 d-block">
                                        <img
                                          src={Delete}
                                          alt=""
                                          width={25}
                                          height={25}
                                        />
                                      </i>
                                    </button>
                                  </div>
                                )}
                            </>
                            <div
                              className="p-3 rounded-4 mb-3"
                              style={{
                                background: "rgba(255, 255, 255, 0.2)",
                              }}
                            >
                              <div
                                className={styles.fromGroup}
                                style={{ marginBottom: "30px" }}
                              >
                                <div className="topTipHover">
                                  <label className='text-16 text-white fw-medium'>Manufacturer</label>
                                  <i onClick={() => handleIconClick("manuf")} className="tooltip-icon">
                                    <img
                                      className="customeTolImg"
                                      src={tip}
                                      alt="Tooltip"
                                    />
                                  </i>
                                  <Toltip tooltipClass={tooltipVisibility.manuf ? "activetooltip" : ""}
                                    slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                    labelId={
                                      CONSTANT_MANAGER.ADD_CONTRACT.MANUFACTURE
                                    }
                                  />
                                </div>
                                <CreatableSelect
                                  onCreateOption={(_cb) =>
                                    handleCreate(_cb, "_MANUFACTURERS")
                                  }
                                  name="dropdown-basic"
                                  options={filteredCategories as any}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(val) =>
                                    handleChangeManufacturer(
                                      val,
                                      i,
                                      "category"
                                    )
                                  }
                                  value={x.category}
                                />
                              </div>
                              <div
                                className={styles.fromGroup}
                                style={{ marginBottom: "30px" }}
                              >
                                <div className="topTipHover">
                                  <label className='text-16 text-white fw-medium'>
                                    Model / Version / Controller
                                  </label>
                                  <i onClick={() => handleIconClick("model")} className="tooltip-icon">
                                    <img
                                      className="customeTolImg"
                                      src={tip}
                                      alt="Tooltip"
                                    />
                                  </i>
                                  <Toltip tooltipClass={tooltipVisibility.model ? "activetooltip" : ""}
                                    slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                    labelId={
                                      CONSTANT_MANAGER.ADD_CONTRACT.MODEL
                                    }
                                  />
                                </div>
                                <CreatableSelect
                                  // onCreateOption={(_cb) => handleCreate(_cb, "PRIMARY_OCCUPATION")}
                                  name="colors"
                                  options={x.subCategoriesList}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(val) =>
                                    onValueChange(
                                      val as any,
                                      "subCategory",
                                      i
                                    )
                                  }
                                  isMulti
                                  value={x.subCategory}
                                />
                              </div>

                              <div className={styles.fromGroup}>
                                <label className='text-16 text-white fw-medium'>Skills</label>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={`flexRadioDefault${i}`}
                                    id={`flexRadioDefault1${i}`}
                                    checked={x.isRequired}
                                    onChange={(e) =>
                                      onValueChange(
                                        true,
                                        "isRequired",
                                        i
                                      )
                                    }
                                  />
                                  <label className="form-check-label text-white ms-2 text-14">
                                    Mandatory
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={`flexRadioDefault2${i}`}
                                    id={`flexRadioDefault2${i}`}
                                    checked={!x.isRequired}
                                    onChange={(e) =>
                                      onValueChange(
                                        false,
                                        "isRequired",
                                        i
                                      )
                                    }
                                  />
                                  <label className="form-check-label text-white ms-2 text-14">
                                    Good to have
                                  </label>
                                </div>
                                {formIk.touched.flexRadioDefault &&
                                  formIk.errors.flexRadioDefault ? (
                                  <div className={styles.error}>
                                    {formIk.errors.flexRadioDefault}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center justify-content-end mx-0">
                  <div className="col-xl-4 col-lg-6 col-6 text-start my-3 btn-hover-effect">
                    <h6
                      onClick={() => handleGoBack()}
                      className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                    >
                      <span className="px-lg-3 px-2 text-12 fw-semibold d-inline-block">
                        Back
                      </span>
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="" />
                      </i>
                    </h6>
                  </div>


                  <div className="col-xl-4 col-md-6 col-6 btn-hover-effect text-center">
                    <button
                      onClick={handleSkip}
                      className="btn arrowyellow border-grey border-1 position-relative"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                        Skip
                      </span>
                    </button>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-6 text-end my-3 btn-hover-effect">
                    <h6
                      onClick={handleGoToNextStep}
                      className="btn arrowblue border-grey border-1 position-relative pe-5"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold">
                        Next
                      </span>
                      <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                        <img src={whitearrow} alt="" />
                      </i>
                    </h6>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-6 text-end my-3 btn-hover-effect saveasdraft">
                    <h6
                      onClick={addNewManufacture}
                      className="btn arrowblue border-grey border-1 rounded-3 position-relative"
                    >
                      <span className="py-1 text-12 fw-semibold d-inline-block">
                        Add Another +
                      </span>
                    </h6>
                  </div>

                </div>
                {/* <div className="row align-items-center justify-content-between">
            <div className="col-3 text-start my-3 btn-hover-effect">
              <h6
                onClick={() => handleGoBack()}
                className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
              >
                <span className="px-lg-4 px-2 text-12 fw-semibold">
                  Back
                </span>
                <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                  <img src={blackarrow} alt="" />
                </i>
              </h6>
            </div>
            <div className="col-9">
              <div className="row align-items-center jusify-content-end">
                
                <div className="col-md-4 col-12 text-end my-3 btn-hover-effect">
                  <button
                    onClick={handleSkip}
                    className="btn arrowyellow border-grey border-1 position-relative"
                  >
                    <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                      Skip
                    </span>
                  </button>
                </div>
                <div className="col-md-4 col-12 text-end my-3 btn-hover-effect">
                  <h6
                    onClick={handleGoToNextStep}
                    className="btn arrowblue border-grey border-1 position-relative pe-5"
                  >
                    <span className="px-lg-4 px-2 text-12 fw-semibold">
                      Next
                    </span>
                    <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                      <img src={whitearrow} alt="" />
                    </i>
                  </h6>
                </div>
                <div className="col-4 text-start btn-hover-effect saveasdraft">
                  <h6
                    onClick={addNewManufacture}
                    className="btn arrowblue border-white border-1 rounded-3 position-relative py-lg-3 py-2 px-3 text-14"
                  >
                    <span className=" text-12 fw-semibold">
                      Add Another +
                    </span>
                  </h6>
                </div>
              </div>
            </div>
          </div> */}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddTechnicalSkills;
