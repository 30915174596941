export const MESSAGES = {
  CALENDAR: {
    PENDING: 'Event is not added yet..!',
    SUCCESS: 'Event Added Successfully!',
    ERROR: 'Something went wrong!',
  },
  LOGIN: {
    VERIFY: "Verifying User",
    SUCCESS: "User Logged In Successfully!",
    INVALID: "Invalid Credentials",
  },
  RECOVER_PASSWORD: {
    VERIFY: "Updating Password",
    SUCCESS: "Password Changed Successfully!",
  },
  SIGNUP: {
    SUCCESS: "User Registered Successfully",
    INVALID: "Something went wrong!",
  },
  SENDOTP: {
    VERIFY: "Sending OTP",
    SUCCESS: "OTP sent successfully!",
    VERIFIED_OTP: "OTP Verified",
  },
  NODATA: {
    NODATA: "No Data Found",
  },
  POSTING_CONTRACTS: {
    VERIFY: "Saving Post",
    SUCCESS: "Contract Posted Successfully",
    DRAFT: "Contract saved in Draft",
    PUBLISH: "Contract Sent for Approval",
    ADDING: "Added Successfully",
    VERIFYING: "Adding",
  },
  WORK: {
    APPLY: "Applying",
    SUCCESS: "Applied Successfully",
  },
  TIMESHEET: {
    SENTFORPPROVAL: "Sent for approval",
  },
  INQUIRY: {
    PENDING: 'Message is still pending',
    SUCCESS: 'Message sent successfully!',
    ERROR: "Something went wrong",
  },
  SEND_REQ: {
    PENDING: 'Request is still pending',
    SUCCESS: 'Request sent successfully!',
    ERROR: "Something went wrong",
  },
  Ads: {
    PENDING: 'Ad is still pending',
    SUCCESS: 'Ad placed successfully!',
    ERROR: "Something went wrong",
  },
  ACCEPT_REQ: {
    PENDING: 'Request is still pending',
    SUCCESS: 'You both are now friends',
    ERROR: "Something went wrong",
  },
  SCHOOL_ADDED: {
    SUCCESS: 'New School Added Successfully',
    PENDING: 'Listing is still pending',
    ERROR: "Something went wrong",
  },
  SKILL:{
    SUCCESS: 'New Skill Added Successfully',
    PENDING: 'Listing is still pending',
    ERROR: "Something went wrong",
  }
};
