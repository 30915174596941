import React, { useEffect, useState } from "react";
import { Dropdown, FormControl, Modal, ModalFooter, Spinner } from "react-bootstrap";
import penbl from "../../../../assets/images/penbl.png";
import upload from "../../../../../assets/images/upload.svg";
import styles from "../styles/style.module.css";
import thumb from "../../../../assets/images/profile_picture.png";
import blackarrow from "../../../../assets/images/blackarrow.svg";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import { IOption } from "../../../../Interfaces";
import { PersonalDetails } from "../../interface";
import { getCitizenshipList } from "../../../../feature/auth/signup/api";
import { updateUserProfile } from "../../../home/api";
import avtar from "../../../../assets/images/avatar.png";
import EditImageModal from "./EditImageModal";
import ViewImageModal from "./ViewImageModal";
import { CloseButton, toast } from "react-toastify";
import { capitalizeFirstLetter } from "../../../../shared";

interface ModalProps {
  title: string;
  data: PersonalDetails;
  openModal2: () => void;
  openModal3: () => void;
  closeModal1: () => void;
  closeModal2: () => void;
  closeModal3: () => void;
  refresh: () => void;
  modal1: boolean;
  modal2: boolean;
  modal3: boolean;
}

const PersonalInfo: React.FC<ModalProps> = ({
  title,
  openModal2,
  openModal3,
  closeModal1,
  closeModal2,
  closeModal3,
  refresh,
  modal1,
  modal2,
  modal3,
  data,
}) => {
  // if (!modal1) return null;
  const [filterValue, setFilterValue] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [citizenshipData, setCitizenshipData] = useState<IOption[]>([]);
  const [citizenship, setCitizenship] = useState(data?.countoryDetails?.name);
  const [citizenshipId, setCitizenshipId] = useState(
    data?.countoryDetails?._id
  );
  const [firstName, setFirstName] = useState(data?.firstName);
  const [lastName, setLastName] = useState(data?.lastName);
  const [address, setAddress] = useState(data?.address);
  const [lang, setLang] = useState(data?.languages);
  const [email, setEmail] = useState(data?.email);
  const [phoneNumber, setPhoneNumber] = useState(data?.phoneNumber);
  const [profilePic, setProfilePic] = useState<string | any>(
    data?.profilePicture
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const loadDropDowns = async () => {
    try {
      const res = await getCitizenshipList();
      if (res?.data) {
        setCitizenshipData(
          res?.data?.map((ele: any) => ({
            label: ele.name,
            value: ele._id,
          }))
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadDropDowns();
  }, []);

  const handleCitizinshipChange = (label: string, value: string) => {
    setCitizenship(label);
    setCitizenshipId(value);
  };

  const handleInfoChange = async () => {
    if (
      data?.profilePicture ||
      data?.firstName ||
      data?.lastName ||
      data?.countoryDetails?._id ||
      data?.address ||
      data?.languages
    ) {
      setIsSubmitting(true); 
      const payload = {
        firstName: firstName,
        lastName: lastName,
        name: `${firstName} ${lastName}`,
        address: address,
        countryId: citizenshipId,
        languages: lang,
      };
  
      try {
        const response = await updateUserProfile(payload);
        refresh();
        toast.success(response?.message || "Updated Profile Successfully"); 
        closeModal1();
      } catch (err: any) {
        console.error("Error while updating personal information:", err);
        toast.error(err?.data?.message || "An unexpected error occurred.");
      } finally {
        setIsSubmitting(false)
      }
    }
  };
  
  const filteredCitizenshipData =
    filterValue && filterValue !== ""
      ? citizenshipData.filter((x) =>
          x.label.toLowerCase().includes(filterValue.toLowerCase())
        )
      : citizenshipData;

  return (
    <>
      <Modal show={modal1} onHide={closeModal1} centered size="lg" backdrop='static'>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className="addModalPro">
              <div className="row">
                <div className="col-12">
                  <div className="col-2 mb-3">
                    <div className={styles.ProfileEditHead}>
                      <img
                        src={profilePic ? profilePic : avtar}
                        className="img-fluid rounded-circle"
                        width={150}
                        height={150}
                        alt=""
                      />
                      <div className={styles.middle}>
                        <img
                          src={penbl}
                          className="cursor-pointer"
                          alt=""
                          onClick={() => openModal2()}
                        />
                        {/* <div className={styles.deletAcount}></div> */}
                        {/* <input type="file" className="formcontrol" /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="formGroup">
                    <label>
                      First Name<span className="text-danger">*</span>
                    </label>
                    <div style={{ marginBottom: "30px" }}>
                      <input
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(capitalizeFirstLetter(e.target.value))}
                        className="form-control"
                        placeholder="First name"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="formGroup">
                    <label>
                      Last Name<span className="text-danger">*</span>
                    </label>
                    <div style={{ marginBottom: "30px" }}>
                      <input
                        type="text"
                        value={lastName}
                        onChange={(e) => capitalizeFirstLetter(e.target.value)}
                        className="form-control"
                        placeholder="Last name"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="formGroup">
                    <label>
                      Email ID<span className="text-danger">*</span>
                    </label>
                    <div style={{ marginBottom: "30px" }}>
                      <input
                        type="email"
                        value={email}
                        disabled
                        className="form-control"
                        placeholder="text@gmail.com"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="formGroup">
                    <label>
                      Phone Number<span className="text-danger">*</span>
                    </label>
                    <div style={{ marginBottom: "30px" }}>
                      <input
                        type="tel"
                        value={phoneNumber}
                        disabled
                        className="form-control"
                        placeholder="+1 6253405561"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="formGroup">
                    <label>
                      Citizenship<span className="text-danger">*</span>
                    </label>
                    <div style={{ marginBottom: "30px" }}>
                      <Dropdown>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                          {capitalizeFirstLetter(citizenship||"") || "Please select an option"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div className="">
                            <FormControl
                              autoFocus
                              className="mx-3 my-2 w-auto"
                              placeholder="Search..."
                              onChange={(e) => setFilterValue(e.target.value)}
                              value={filterValue}
                            />
                          </div>
                          <div
                            style={{
                              maxHeight: "300px",
                              overflowY: "scroll",
                            }}
                          >
                            {filteredCitizenshipData.length === 0 ? (
                              <Dropdown.Item disabled>
                                No options found
                              </Dropdown.Item>
                            ) : (
                              filteredCitizenshipData.map(
                                (option, index: number) => (
                                  <>
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() =>
                                        handleCitizinshipChange(
                                          option.label,
                                          option.value
                                        )
                                      }
                                    >
                                      {capitalizeFirstLetter(option.label)}
                                    </Dropdown.Item>
                                  </>
                                )
                              )
                            )}
                            <div>
                              <div className="col-8">
                                <input
                                  type="text"
                                  placeholder="Type degree/certificate here you want to add..."
                                  name="degreeCertificate"
                                  value={searchTerm}
                                  onChange={(e) =>
                                    setSearchTerm(e.target.value)
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      // handleSubmitCustomSchool();
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="formGroup">
                    <label>
                      Address<span className="text-danger">*</span>
                    </label>
                    <div style={{ marginBottom: "30px" }}>
                      <input
                        type="text"
                        value={capitalizeFirstLetter(address||"")}
                        onChange={(e) => setAddress(e.target.value)}
                        className="form-control"
                        placeholder="Address"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="formGroup">
                    <label>Language Spoke</label>
                    <div style={{ marginBottom: "30px" }}>
                      <input
                        type="text"
                        value={lang}
                        onChange={(e) => setLang([capitalizeFirstLetter(e.target.value)])}
                        className="form-control"
                        placeholder="Language"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <ModalFooter className="btn-hover-effect mb-2 justify-content-end">
            <div className="btn-hover-effect">
              <h6 
                onClick={closeModal1}
                className={`btn bg-white arrowyellow border border-1 rounded-3 position-relative ps-5 backbutton w-auto ${
                  isSubmitting ? "disabled" : ""
                }`}
              >
                <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                  Back
                </span>
                <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                  <img
                    src={blackarrow}
                    alt=""
                    width={15}
                    height={15}
                    style={{ transform: "rotate(-180deg)" }}
                  />
                </i>
              </h6>
            </div>
            <div className="btn-hover-effect">
              <h6
                onClick={handleInfoChange}
                className="btn bg-white arrowblue border border-1 rounded-3 position-relative pe-5 w-auto"
              >
                <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
            {isSubmitting ? (
              <Spinner animation="border" size="sm" className="me-2" />
            ) : (
              "Save"
            )}
          </span>
                <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                  <img src={whitearrow} alt="" width={15} height={15} />
                </i>
              </h6>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {modal2 && (
        <ViewImageModal
          modal2={modal2}
          modal3={modal3}
          openModal3={openModal3}
          closeModal2={closeModal2}
          closeModal3={closeModal3}
          title="Edit OR View Image"
          img={profilePic}
          refresh={refresh}
        />
      )}
    </>
  );
};

export default PersonalInfo;
