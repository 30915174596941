import React, { useCallback, useEffect, useRef, useState } from "react";
import "react-markdown-editor-lite/lib/index.css";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import tip from "../../../../../assets/images/tip.png";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setEligibilityQuestions,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import styles from "../../../../../components/contractBeforeLogin/styles/style.module.css";
import TextEditorCustomComp from "../StepsComonents/TextEditorCustomComp/TextEditorCustomComp";
import Toltip from "../../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";

const EligibilityQuestions: React.FC = () => {
  const dispatch = useDispatch();
  const { eligibilityQuestions } = useSelector(
    (state: RootState) => state.addContract
  );
  const [ques, setQues] = useState<any>([...eligibilityQuestions]);
  const timeoutRef = useRef<number | null>(null);
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: string]: boolean;
  }>({
    eligQues: false,
  });

  useEffect(() => {
    setQues([...eligibilityQuestions]); // Update ques when eligibilityQuestions changes
  }, [eligibilityQuestions]);

  const formIk: any = useFormik({
    initialValues: {
      eligibilityQuestions,
    },
    onSubmit: () => {
      dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_INTERVIEW_QUESTIONS));
    },
  });

  const addNewQuestion = (): void => {
    const updatedQuestion = [...ques, ""];
    setQues(updatedQuestion);
    dispatch(setEligibilityQuestions(updatedQuestion));
  };

  const onValueChange = (value: string, index: number): void => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => {
      const updatedQuestions = [...ques];
      updatedQuestions[index] = value;

      setQues(updatedQuestions);
      dispatch(setEligibilityQuestions(updatedQuestions));
      formIk.setFieldValue("eligibilityQuestions", updatedQuestions);
    }, 300);
  };

  const deleteQuestion = (index: number) => {
    const updatedQuestions = [...ques];
    updatedQuestions.splice(index, 1);

    setQues(updatedQuestions);
    dispatch(setEligibilityQuestions(updatedQuestions));
  };

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_MANUFACTURER));
  };

  const handleGoToNextStep = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_INTERVIEW_QUESTIONS));
  };

  const handleSkip = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_INTERVIEW_QUESTIONS));
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isTooltipIcon = target.closest(".tooltip-icon");
    const isTooltipContent = target.closest(".tooltip-content");
    const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line

    if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
      setTooltipVisibility({
        eligQues: false,
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  const handleIconClick = (tooltipKey: string) => {
    setTooltipVisibility(prev => ({
      eligQues: false,
      [tooltipKey]: !prev[tooltipKey]
    }));
  };

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formIk.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div className={styles.registerLeft}>
                  <div className="col-lg-11 col-12 text-center mx-auto">
                    <figure>
                      <img
                        src={choosetype}
                        alt=""
                        className="img-fluid w-100"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className={styles.ScrollDiv}>
                  <h4 className="text-white text-32 fw-semibold">
                    Eligibility Questions
                  </h4>
                  <p className="text-16 text-white opacity-75">
                    To ensure candidates meet essential job
                    requirements and legal standards, Automate
                    America Company uses the following yes or no
                    eligibility questions.
                  </p>
                  <div className={styles.signUPContainers}>
                    <div className={styles.registerRight}>
                      <div className="topTipHover">
                        <label className={`mb-2 text-16 text-white fw-medium`}>Create Eligibility Questions</label>
                        <i onClick={() => handleIconClick("eligQues")} className="tooltip-icon">
                          <img
                            className="customeTolImg"
                            src={tip}
                            alt="Tooltip"
                          />
                        </i>
                        <Toltip tooltipClass={tooltipVisibility.eligQues ? "activetooltip" : ""}
                          slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                          labelId={
                            CONSTANT_MANAGER.ADD_CONTRACT.ELIGIBILITY_QUESTION
                          }
                        />
                      </div>
                      {ques.map((x: string, i: number) => (
                        <div
                          className={styles.fromGroup}
                          key={i}
                          style={{ maxWidth: "100%" }}
                        >
                          <div className={styles.AddBtns}>
                            <label className={`mb-2 text-16 text-white fw-medium`}>Question{i + 1}:</label>

                            <div className="row align-items-center justify-content-between g-0">
                              <div className="col-10">
                                <TextEditorCustomComp
                                  data={x}
                                  setDesc={(value: string) => onValueChange(value, i)}
                                  index={i}
                                />
                              </div>

                              <div className="col-2 px-0">
                                <div className={styles.AddBtn}>
                                  {i === ques.length - 1 && ques.length < 5 && (
                                    <button
                                      type="button"
                                      className="btn btn-primary py-2 my-1 rounded-circle text-20"
                                      onClick={addNewQuestion}
                                    >
                                      +
                                    </button>
                                  )}
                                  {ques.length > 1 && (
                                    <button
                                      type="button"
                                      className="btn btn-primary py-2 my-1 rounded-circle text-20"
                                      onClick={() => deleteQuestion(i)}
                                    >
                                      -
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-4 col-6 text-start my-3 btn-hover-effect">
                    <h6
                      onClick={() => handleGoBack()}
                      className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold">Back</span>
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="" />
                      </i>
                    </h6>
                  </div>
                  <div className="col-md-4 col-6 text-end my-3 btn-hover-effect text-center">
                    <button
                      onClick={handleSkip}
                      className="btn arrowyellow border-grey border-1 position-relative"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold text-black">Skip</span>
                    </button>
                  </div>
                  <div className="col-md-4 col-6 text-end my-3 btn-hover-effect">
                    <h6
                      onClick={handleGoToNextStep}
                      className="btn arrowblue border-grey border-1 position-relative pe-5"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold">Next</span>
                      <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                        <img src={whitearrow} alt="" />
                      </i>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EligibilityQuestions;
