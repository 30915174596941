import React, { useCallback, useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import tip from "../../../../assets/images/tip.png";
import penbl from "../../../../assets/images/penbl.png";
import selfrating from "../../../../assets/images/self_rating.svg";
import thumb from "../../../../assets/images/profile_picture.png";
import TopSkillModal from "../modals/TopSkillModal";
import { TechSkills } from "../../interface";
import { Link } from "react-router-dom";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";
import Toltip from "../../../common/toltip/page";
import { FaStar, FaRegStar, FaStarHalfAlt } from "react-icons/fa";

interface ModalProps {
  topSkill: TechSkills[];
  refresh: () => void;
}

const TopSkill: React.FC<ModalProps> = ({ topSkill = [], refresh }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<string | null>(null);
  const [indexNumber, setIndexNumber] = useState<number | null>(null);
  const [skillName, setSkillName] = useState("");
  const [about, setAbout] = useState<any>("");
  const [manufacturer, setManufacturer] = useState("");
  const [model, setModel] = useState("");
  const [img, setImg] = useState<any>("");
  const [rating, setRating] = useState<any>()
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: string]: boolean;
  }>({
    topskill: false
  })
  const handleIconClick = (tooltipKey: string) => {
    setTooltipVisibility(prev => ({
      topskill: false,
      [tooltipKey]: !prev[tooltipKey]
    }));
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isTooltipIcon = target.closest(".tooltip-icon");
    const isTooltipContent = target.closest(".tooltip-content");
    const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line

    if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
      setTooltipVisibility({
        topskill: false
      });
    }
  }, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);


  const renderStars = (rating: number) => {
    const maxStars = 5; // Maximum number of stars
    const fullStars = Math.floor(rating); // Full stars
    const hasHalfStar = rating % 1 >= 0.5; // Check if there's a half star
    const emptyStars = maxStars - Math.ceil(rating); // Empty stars
  
    return (
      <div style={{ display: "flex", gap: "4px" }}>
        {Array.from({ length: maxStars }, (_, index) => {
          if (index < fullStars) {
            return <FaStar key={index} color="gold" size={20} />;
          } else if (index === fullStars && hasHalfStar) {
            return <FaStarHalfAlt key={index} color="gold" size={20} />;
          } else {
            return <FaRegStar key={index} color="gray" size={20} />;
          }
        })}
      </div>
    );
  };

  // const [topObj,setTopObj] = useState<object>({})

  const topSkillData = () => {
    topSkill?.map((data, ind) => {
      if (data?.type === "top") {
        console.log("type", data);
        setSkillName(data?.occupationDetail?.name);
        setAbout(data?.description);
        setManufacturer(data?.manufacturerDetail?.name);
        setModel(data?.manufacturerModelDetail?.name);
        setImg(data?.image);
        setRating(data?.rating)

      }
    });
  };

  useEffect(() => {
    topSkillData();
  }, []);

  const openModal = (data?: string, index?: number) => {
    setEditData(data || null); // Set data to edit or null for new entry
    setIsModalOpen(true);
    console.log("index---", index);
    if (index !== null && index !== undefined && index >= 0) {
      console.log("index-if", index);
      setIndexNumber(index);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditData(null); // Clear data when closing modal
  };

  return (
    <div className={styles.AboutEdit}>
      <div className=" mt-3">
        <div className="row align-items-center">
          <div className="col-9">
            <div className="topTipHover">
              <div className="d-flex align-items-center">
                <h4 className="text-20 fw-bold  font-inter mb-0">Top Skill*</h4>
                <i onClick={() => handleIconClick("topskill")} className="tooltip-icon">
                  <img
                    className="customeTolImg"
                    src={tip}
                    alt="Tooltip"
                  />
                </i>
                <Toltip tooltipClass={tooltipVisibility.topskill ? "activetooltip" : ""}
                  slug={CONSTANT_MANAGER.SLUG.EDIT_PROFILE}
                  labelId={
                    CONSTANT_MANAGER.EDIT_PROFILE.PROFESSIONAL_INFORMATION
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-3 text-end">
            <i>
              {skillName ? (
                <img
                  src={penbl}
                  className="cursor-pointer ms-auto"
                  alt=""
                  onClick={() => openModal()}
                />
              ) : (
                <Link to={""} onClick={() => openModal()} className="fw-normal text-blue fst-normal fw-semibold">
                  Add Skill
                </Link>
              )}
            </i>
          </div>
        </div>
        {skillName ? (
          <div>
            <div className={styles.personalinfo} style={{ marginTop: "20px" }}>
              <span className="d-block text-14">Skill Name*</span>
              <label>{skillName}</label>
            </div>
            <div className="newheadingstyle mt-3">
              <h4 className="text-16 fw-semibold m-0">About</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: about ? about : "",
                }}
              />
              <h5 className="text-14 d-flex align-items-center mt-3">
                Self Rating: {renderStars(rating)}
              </h5>
              <ul className={styles.personalinfo} style={{ marginBottom: "3" }}>
                <li className="mb-3">
                  <span className="d-block text-14 mt-3">Manufacturer</span>
                  <label>{manufacturer}</label>
                </li>
                <li className="mb-1">
                  <span className="d-block text-14">
                    Model/Version/Controller
                  </span>
                  <label>{model}</label>
                </li>
                {img && (
                  <li className="mb-3">
                    <span className="d-block text-14">Image</span>
                    <img
                      src={img}
                      alt="Skill"
                      className="rounded-2"
                      width={100}
                      height={100}
                      onError={(e) => (e.currentTarget.style.display = "none")} // Hide if image fails to load
                    />
                  </li>
                )}
              </ul>
            </div>
          </div>
        ) : (
          "You don't add other skill yet!"
        )}
      </div>
      <TopSkillModal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Edit Top Skill"
        topSkill={topSkill}
        refresh={refresh}
        about={about}
      />
    </div>
  );
};

export default TopSkill;
