import React, { useCallback, useEffect, useState } from "react";
import styles from "../../../../../components/contractBeforeLogin/styles/style.module.css";
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setKeyInformation,
} from "../../../../../redux/reducers/addDirectJob";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import tip from "../../../../../assets/images/tip.png";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import Toltip from "../../../../common/toltip/page";
import { DIRECT_JOB_STEPS } from "../Enums";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import {
  keyInfoDirectJobValidationSchema,
  keyInfoValidationSchema,
} from "../../../../work/validations";

const AddKeyInfoDirectJob: React.FC = () => {
  const dispatch = useDispatch();
  const { keyInformations } = useSelector(
    (state: RootState) => state.directJob
  );
  const shifts = ["days", "afternoons", "nightshift", "variable"];
  const hours = ["6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16"];
  const days = ["1", "2", "3", "4", "5", "6", "7"];
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: string]: boolean;
  }>({
    shift: false,
    hours: false,
    days: false,
  });

  const formik: any = useFormik({
    initialValues: {
      noOfPeople: 0 || "",
      shift: keyInformations.shift || "",
      plannedHoursPerDay: keyInformations.plannedHoursPerDay || "",
      plannedDaysPerWeek: keyInformations.plannedDaysPerWeek || "",
    },
    validationSchema: keyInfoDirectJobValidationSchema,
    onSubmit: (value) => {
      dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_LOCATIONS));
    },
  });

  const onChange = (value: string | number, key: string) => {
    formik.setFieldValue(key, value);
    dispatch(
      setKeyInformation({
        ...keyInformations,
        [key]: value,
      })
    );
  };

  const handleGoBack = () => {
    dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_TIMINGS));
  };

  const onChangeDate = (value: Date | string | number | null, key: string) => {
    formik.setFieldValue(key, value);
    dispatch(
      setKeyInformation({
        ...keyInformations,
        startDate: keyInformations.startDate,
        [key]: value,
      })
    );
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isTooltipIcon = target.closest(".tooltip-icon");
    const isTooltipContent = target.closest(".tooltip-content");
    const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line

    if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
      setTooltipVisibility({
        shift: false,
        hours: false,
        days: false,
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [handleClickOutside]);

  const handleIconClick = (tooltipKey: string) => {
    setTooltipVisibility((prev) => ({
      shift: false,
      hours: false,
      days: false,
      [tooltipKey]: !prev[tooltipKey],
    }));
  };

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formik.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div className={styles.registerLeft}>
                  <div className="col-lg-11 col-12 text-center mx-auto">
                    <figure>
                      <img
                        src={choosetype}
                        alt=""
                        className="img-fluid w-100"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <h4 className="text-white text-32 fw-semibold">
                  {" "}
                  How Many People Are Needed And <br />
                  How Much Will They Work?
                </h4>
                <p className="text-16 text-white opacity-75">
                  How many Professionals do you need?
                </p>
                <div className={styles.ScrollDiv}>
                  <div className={styles.signUPContainer}>
                    <div className={styles.registerRight}>
                      <div className={styles.fromGroup}>
                        {" "}
                        <div className="topTipHover">
                          <label className={styles.starlabel}>Shift</label>{" "}
                          <i
                            onClick={() => handleIconClick("shift")}
                            className="tooltip-icon"
                          >
                            <img className="customeTolImg" src={tip} alt="" />
                          </i>
                          <Toltip
                            tooltipClass={
                              tooltipVisibility.shift ? "activetooltip" : ""
                            }
                            slug={CONSTANT_MANAGER.SLUG.DIRECT_JOB}
                            labelId={CONSTANT_MANAGER.DIRECT_JOB.SHIFT}
                          />
                        </div>
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic" name="shift">
                            {formik.values.shift || "Select shift"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div
                              style={{
                                maxHeight: "300px",
                                overflowY: "scroll",
                              }}
                            >
                              {shifts.map((shiftData) => (
                                <Dropdown.Item
                                  key={shiftData}
                                  onClick={() => onChange(shiftData, "shift")}
                                >
                                  {shiftData}
                                </Dropdown.Item>
                              ))}
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                        {formik.touched.shift && formik.errors.shift ? (
                          <div className={styles.error}>
                            {formik.errors.shift}
                          </div>
                        ) : null}
                      </div>
                      <div className={styles.fromGroup}>
                        {" "}
                        <div className="topTipHover">
                          <label className={styles.starlabel}>
                            Planned Hours Per Day
                          </label>{" "}
                          <i
                            onClick={() => handleIconClick("hours")}
                            className="tooltip-icon"
                          >
                            <img className="customeTolImg" src={tip} alt="" />
                          </i>
                          <Toltip
                            tooltipClass={
                              tooltipVisibility.hours ? "activetooltip" : ""
                            }
                            slug={CONSTANT_MANAGER.SLUG.DIRECT_JOB}
                            labelId={
                              CONSTANT_MANAGER.DIRECT_JOB.PLANNED_HOURS_PER_DAY
                            }
                          />
                        </div>
                        <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            name="plannedHoursPerDay"
                          >
                            {formik.values.plannedHoursPerDay ||
                              "Select planned Hours per day"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div
                              style={{
                                maxHeight: "300px",
                                overflowY: "scroll",
                              }}
                            >
                              {hours.map((hourData) => (
                                <Dropdown.Item
                                  key={hourData}
                                  onClick={() =>
                                    onChange(hourData, "plannedHoursPerDay")
                                  }
                                >
                                  {hourData}
                                </Dropdown.Item>
                              ))}
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                        {formik.touched.plannedHoursPerDay &&
                        formik.errors.plannedHoursPerDay ? (
                          <div className={styles.error}>
                            {formik.errors.plannedHoursPerDay}
                          </div>
                        ) : null}
                      </div>

                      <div className={styles.fromGroup}>
                        {" "}
                        <div className="topTipHover">
                          <label className={styles.starlabel}>
                            Planned Days Per Week
                          </label>{" "}
                          <i
                            onClick={() => handleIconClick("days")}
                            className="tooltip-icon"
                          >
                            <img className="customeTolImg" src={tip} alt="" />
                          </i>
                          <Toltip tooltipClass={tooltipVisibility.days ? "activetooltip" : ""}
                            slug={CONSTANT_MANAGER.SLUG.DIRECT_JOB}
                            labelId={
                              CONSTANT_MANAGER.DIRECT_JOB
                                .PLANNED_DAYS_PER_WEEK
                            }
                          />
                        </div>
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic" name="shift">
                            {formik.values.plannedDaysPerWeek ||
                              "Select planned Days per week"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div
                              style={{
                                maxHeight: "300px",
                                overflowY: "scroll",
                              }}
                            >
                              {days.map((dayData) => (
                                <Dropdown.Item
                                  key={dayData}
                                  onClick={() =>
                                    onChange(dayData, "plannedDaysPerWeek")
                                  }
                                >
                                  {dayData}
                                </Dropdown.Item>
                              ))}
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                        {formik.touched.plannedDaysPerWeek &&
                        formik.errors.plannedDaysPerWeek ? (
                          <div className={styles.error}>
                            {formik.errors.plannedDaysPerWeek}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <div className="col-4 text-start my-3 btn-hover-effect">
                    <h6
                      onClick={() => handleGoBack()}
                      className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                        Back
                      </span>
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="" />
                      </i>
                    </h6>
                  </div>
                  <div className="col-8">
                    <div
                      className="d-flex align-items-center jusify-content-end"
                      style={{ gap: "10px" }}
                    >
                      <div className="col-12 text-end my-3 btn-hover-effect">
                        <button
                          type="submit"
                          className="btn arrowblue border-white border-1 position-relative pe-5"
                        >
                          <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                            Next
                          </span>
                          <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                            <img src={whitearrow} alt="" />
                          </i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default AddKeyInfoDirectJob;
