import React, { useEffect, useState } from "react";
import styles from "./styles/style.module.css";
import userlog from "../../assets/images/userlog.png";
import topskill from "../../assets/images/top_skill.svg";
import manufacture from "../../assets/images/manufacture.svg";
import modal from "../../assets/images/modal.svg";
import selfrating from "../../assets/images/self_rating.svg";
import picture from "../../assets/images/profilepicture.svg";
import pictureblue from "../../assets/images/profilepicture-blue.svg";
import loc from "../../assets/images/icon_location.svg";
import Header from "../header/routes";
import dummy from "../../assets/images/dummy.jpeg";
import profilepic from "../../assets/images/profile_picture.png";
import revies from "../../assets/images/revies.png";
// import stars from "../../assets/images/stars.png";
import orbag from "../../assets/images/orbag.png";
import star from "../../assets/images/star.svg";
import { Link, useParams } from "react-router-dom";
import { getUserProfileById } from "./api";
import { UserProfileByIdData } from "./interface";
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import { sendRequest } from "../ConnectFrnds/api";
import { MESSAGES } from "../../utils/messages";

const UserProfile = () => {
  const { id } = useParams();
  const [getUserProfile, setUserProfile] = useState<UserProfileByIdData>();
  const [loading, setLoading] = useState<boolean>(true);
  const [requestedConnections, setRequestedConnections] = useState<Set<string>>(new Set());

  const userprofileData = async () => {
    const response = await getUserProfileById(id as string);
    console.log("User Profile data", response.data);
    setUserProfile(response?.data);
    setLoading(false);
  };

  useEffect(() => {
    userprofileData();
  }, []);

  const handleConnect = async (id: string) => {
    const payload = {
      connectionId: id,
    };
    const initialResult = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to send a connection request?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Connect!',
      cancelButtonText: 'No, cancel',
    });
    if (initialResult.isConfirmed) {
      try {
        await toast.promise(sendRequest(payload), {
          pending: MESSAGES.SEND_REQ.PENDING,
          success: MESSAGES.SEND_REQ.SUCCESS,
          error: MESSAGES.SEND_REQ.ERROR,
        });

        setRequestedConnections(prev => new Set(prev.add(id)));
        await Swal.fire({
          title: 'Request Sent',
          text: 'Your connection request has been successfully sent.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  return (
    <>
      <Header />
      {loading ? (
        <div className="spinner-grow text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <div className="container">
          <div className={`mt-4 rounded-4 ${styles.UserProfileOuter}`}>
            <div className={styles.UerImage}>
              <figure><img
                src={
                  getUserProfile?.profilePicture
                    ? getUserProfile?.profilePicture
                    : dummy
                }
                alt=""
              />
              </figure>
              <h6 className="text-18 mb-0 fw-medium fw-semibold">
                {getUserProfile?.name} 
              </h6>
              <h5>{getUserProfile?.occupation?.name}</h5>
              <h4 className="d-flex align-items-center justify-content-start my-2">
                <span className="text-green bg-lightgreen px-2 py-2 rounded-pill me-2 text-14">Available</span>
                For one Week
              </h4>
              <h4 className="d-flex align-items-center justify-content-start">
                <i className="me-2"><img src={loc} alt="" /></i>
                {getUserProfile?.country?.name}{" "}
              </h4>
              <p className="d-flex align-items-center">Reviews : <span className="ms-2">200</span> <span className="ms-2">4.5</span>{" "}<i><img src={star} alt="" /></i></p>
              <p className="d-flex align-items-center">Member Since : 12 Jan 1998</p>
              <p>
                <span className="d-block text-20 fw-medium">
                  About us
                  {/* </span>{getUserProfile?.bio}</p> */}
                  </span><div dangerouslySetInnerHTML={{__html:getUserProfile?.bio ? getUserProfile.bio : "No data available"}}></div>{}</p>
              <div className={styles.payRates}>
                <span>Pay Rates / Hour</span>
                <ul className="mb-2">
                  <li>
                    <h6 className="fw-medium">Base</h6>
                    <label>${getUserProfile?.rates?.baseRate}</label>
                  </li>
                  <li>
                    <h6 className="fw-medium">Overtime</h6>
                    <label>${getUserProfile?.rates?.overTimeRate}</label>
                  </li>
                  <li>
                    <h6 className="fw-medium">Sun/Holidays</h6>
                    <label>${getUserProfile?.rates?.doubleTimeRate}</label>
                  </li>
                </ul>
              </div>
              <div className={styles.SipBtns}>
                <button className={` ${requestedConnections.has(getUserProfile?._id as string)
                    ? "bg-white px-3 py-2 text-16 border text-grey"
                    : "bg-blue px-3 py-2 me-2 text-16 text-white"

                  }`} onClick={() =>
                    !requestedConnections.has(getUserProfile?._id as string) &&
                    handleConnect(getUserProfile?._id as string)
                  }>
                  {requestedConnections.has(getUserProfile?._id as string)
                    ? "Requested"
                    : "+ Connect"}
                </button>
                <button className="bg-white border text-blue px-3 py-2 text-16">Request This Professional</button>
                {/* <span>
                  <i className="fas fa-ellipsis-h"></i>
                </span> */}
              </div>
            </div>
          </div>
          {/* <div className={styles.aboutProfile}>
            <h4 className="fw-semibold">About</h4>
            <p>{getUserProfile?.bio ? getUserProfile.bio : "No data available"}</p>
          </div> */}
          <div className={styles.ProfileReview}>
            <h4 className="fw-semibold">Reviews</h4>
            <ul className={`mt-3 ${styles.review_media}`}>
              <li>
                <div className={styles.reviesTop}>
                  <span>
                    <span>
                      <img src={revies} alt="" />
                    </span>
                  </span>
                  <div>
                    Legacy Industries | Robot Programmer - Jimmy G
                    {getUserProfile?.reviews}
                    <ul className="d-flex align-items-center justify-content-start mb-0">
                      <li><img src={star} alt="" /></li>
                      <li><img src={star} alt="" /></li>
                      <li><img src={star} alt="" /></li>
                      <li><img src={star} alt="" /></li>
                      <li><img src={star} alt="" /></li>
                    </ul>
                  </div>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  dapibus eros eu vehicula interdum. Cras nec ultricies massa.
                  Curabitur rutrum, diam id consequat consequat. Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit. Sed dapibus eros
                  eu vehicula interdum. Cras nec ultricies massa. Curabitur
                  rutrum, diam id consequat consequat.
                </p>
              </li>
            </ul>
            <div className={styles.SeeAll}>
            <Link to="#">See all reviews</Link>
          </div>
          </div>
          <div className={styles.ProfileReview}>
          <h4 className="fw-semibold">Top Skills</h4>
            <div className={`d-flex ${styles.personalinfo}`} style={{ marginTop: "20px" }}>
              <i className="me-2"><img src={topskill} alt="" /></i>
              <div className="d-inline-block">
                <span className="d-block text-14 fw-semibold text-black">Skill Name*</span>
                <label className="text-muted fw-normal">Autocad</label>
              </div>
              
            </div>
            <div className="newheadingstyle mt-2">
            <h4 className="text-16 fw-semibold mb-2">About</h4>
             <p className="text-muted text-14">Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae esse pariatur, temporibus non est quas, quis iure rerum quos corrupti ipsum mollitia ab dicta ducimus eum placeat commodi inventore? Inventore?</p>
              <h5 className="text-14 my-3 d-flex align-items-center">
                Self Rating :{" "}
                <i className="ms-2">
                  <img src={selfrating} alt="" />
                </i>
              </h5>
              <ul className={styles.personalinfo} style={{ marginBottom: "0" }}>
                <li className="mb-2 d-flex">
                <i className="me-2"><img src={manufacture} alt="" /></i>
                <div className="d-inline-block">
                  <span className="d-block text-14 text-muted">Manufacturer</span>
                  <label className="text-muted fw-semibold text-black">Autocad</label>
                  </div>
                </li>
                <li className="mb-2 d-flex">
                <i className="me-2"><img src={modal} alt="" /></i>
                <div className="d-inline-block">
                  <span className="d-block text-14 text-muted">
                    Model/Version/Controller
                  </span>
                  <label className="text-muted fw-semibold text-black">Version 1.4</label>
                  </div>
                </li>
               
              </ul>
            </div>
          </div>
          <div className={styles.ProfileReview}>
          <h4 className="fw-semibold">Second Skills</h4>
            <div className={`d-flex ${styles.personalinfo}`} style={{ marginTop: "20px" }}>
              <i className="me-2"><img src={topskill} alt="" /></i>
              <div className="d-inline-block">
                <span className="d-block text-14 fw-semibold text-black">Skill Name*</span>
                <label className="text-muted fw-normal">Autocad</label>
              </div>
              
            </div>
            <div className="newheadingstyle mt-2">
            <h4 className="text-16 fw-semibold mb-2">About</h4>
             <p className="text-muted text-14">Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae esse pariatur, temporibus non est quas, quis iure rerum quos corrupti ipsum mollitia ab dicta ducimus eum placeat commodi inventore? Inventore?</p>
              <h5 className="text-14 my-3 d-flex align-items-center">
                Self Rating :{" "}
                <i className="ms-2">
                  <img src={selfrating} alt="" />
                </i>
              </h5>
              <ul className={styles.personalinfo} style={{ marginBottom: "0" }}>
                <li className="mb-2 d-flex">
                <i className="me-2"><img src={manufacture} alt="" /></i>
                <div className="d-inline-block">
                  <span className="d-block text-14 text-muted">Manufacturer</span>
                  <label className="text-muted fw-semibold text-black">Autocad</label>
                  </div>
                </li>
                <li className="mb-2 d-flex">
                <i className="me-2"><img src={modal} alt="" /></i>
                <div className="d-inline-block">
                  <span className="d-block text-14 text-muted">
                    Model/Version/Controller
                  </span>
                  <label className="text-muted fw-semibold text-black">Version 1.4</label>
                  </div>
                </li>
                <li className="mb-3">
                  <span className="d-block text-14">Image</span>
                  <i className="d-inline-block rounded-3 mt-2">
                  <img
                    src={profilepic}
                    alt=""
                    className="rouned-2"
                    width={70}
                    height={70}
                  />
                  </i>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.ProfileReview}>
          <h4 className="fw-semibold">Other Skills</h4>
            <div className={`d-flex ${styles.personalinfo}`} style={{ marginTop: "20px" }}>
              <i className="me-2"><img src={topskill} alt="" /></i>
              <div className="d-inline-block">
                <span className="d-block text-14 fw-semibold text-black">Skill Name*</span>
                <label className="text-muted fw-normal">Autocad</label>
              </div>
              
            </div>
            <div className="newheadingstyle mt-2">
            <h4 className="text-16 fw-semibold mb-2">About</h4>
             <p className="text-muted text-14">Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae esse pariatur, temporibus non est quas, quis iure rerum quos corrupti ipsum mollitia ab dicta ducimus eum placeat commodi inventore? Inventore?</p>
              <h5 className="text-14 my-3 d-flex align-items-center">
                Self Rating :{" "}
                <i className="ms-2">
                  <img src={selfrating} alt="" />
                </i>
              </h5>
              <ul className={styles.personalinfo} style={{ marginBottom: "0" }}>
                <li className="mb-2 d-flex">
                <i className="me-2"><img src={manufacture} alt="" /></i>
                <div className="d-inline-block">
                  <span className="d-block text-14 text-muted">Manufacturer</span>
                  <label className="text-muted fw-semibold text-black">Autocad</label>
                  </div>
                </li>
                <li className="mb-2 d-flex">
                <i className="me-2"><img src={modal} alt="" /></i>
                <div className="d-inline-block">
                  <span className="d-block text-14 text-muted">
                    Model/Version/Controller
                  </span>
                  <label className="text-muted fw-semibold text-black">Version 1.4</label>
                  </div>
                </li>
                <li className="mb-3">
                  <span className="d-block text-14">Image</span>
                  <i className="d-inline-block rounded-3 mt-2">
                  <img
                    src={profilepic}
                    alt=""
                    className="rouned-2"
                    width={70}
                    height={70}
                  />
                  </i>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.aboutProfile}>
            <h4 className="fw-semibold">Technical Skills </h4>
            <ul className={styles.skillsTab}>
              {getUserProfile?.skilledTrade && getUserProfile.skilledTrade.length > 0 ? (
                getUserProfile.skilledTrade.map((data, idx) => (
                  <li key={idx}>
                    <span>{data?.name}</span>
                  </li>
                ))
              ) : (
                <li>No data available</li>
              )}
            </ul>
          </div>
          <div className={styles.aboutProfile}>
            <h4 className="fw-semibold">Industries </h4>
            <ul className={styles.skillsTab}>
              {getUserProfile?.industries && getUserProfile.industries.length > 0 ? (
                getUserProfile.industries.map((data, idx) => (
                  <li key={idx}>
                    <span>{data?.name}</span>
                  </li>
                ))
              ) : (
                <li>No data available</li>
              )}
            </ul>

          </div>
          <div className={styles.aboutProfile}>
            <h4 className="fw-semibold">Occupation </h4>
            {/* <ul className={styles.skillsTab}>
              <li>
                <span>{getUserProfile?.occupation?.name ? getUserProfile?.occupation?.name : "No data available"}</span>
              </li>
            </ul> */}
            <ul className="row g-3 ps-0 mb-0 mt-4">
              <li className="col-12 mt-0 mb-3">
                <div className="d-flex align-items-start">
                  <div className="">
                    <i>
                      <img src={picture} alt="" />
                    </i>
                  </div>
                  <div className="ps-3">
                    <h3 className="text-13 fw-noraml mb-0">
                      Freelance UX/UI designer
                    </h3>
                    <p className="text-black text-12 mb-0 fw-normal">
                      Lorem ipsum
                    </p>
                    <p className="text-black text-12 mb-0 fw-normal">
                      Jun 2022 — Present{" "}
                      <span className="text-blue">
                        1 yrs 02 mos
                      </span>
                    </p>
                    <p className="mt-2">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veritatis at magni ipsum quas odit eaque tempora saepe recusandae, laborum assumenda nisi natus vel molestiae error ipsa sunt ad. Aut, dicta.</p>
                  </div>
                </div>
              </li>
              <li className="col-12 my-3">
                <div className="d-flex align-items-start">
                  <div className="">
                    <i>
                      <img src={pictureblue} alt="" />
                    </i>
                  </div>
                  <div className="ps-3">
                    <h3 className="text-13 fw-noraml mb-0">
                      Freelance UX/UI designer
                    </h3>
                    <p className="text-black text-12 mb-0 fw-normal">
                      Lorem ipsum
                    </p>
                    <p className="text-black text-12 mb-0 fw-normal">
                      Jun 2022 — Present{" "}
                      <span className="text-blue">
                        1 yrs 02 mos
                      </span>
                    </p>
                    <p className="mt-2">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate totam ex fugit deserunt aperiam adipisci commodi quasi, debitis quibusdam et enim suscipit perspiciatis voluptatum quia qui minus est delectus ipsum.</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className={`${styles.aboutProfile} ${styles.Ediuca}`}>
            <h4 className="fw-semibold">Educations, Certificates and training</h4>
            <ul className={`${styles.OccupationTab} ${styles.InterviewQueston}`}>
              {getUserProfile?.educations && getUserProfile.educations.length > 0 ? (
                getUserProfile.educations.map((data, idx) => (
                  <li key={idx}>
                    <span><img src={userlog} alt="" /></span>
                    <div className="row align-items-center flex-column gap-2 mb-0 pb-0 mt-3">
                      <h6>{data?.schoolDetails?.name || "No school name provided"}</h6>
                      <h6>{data?.degreeDetails?.name || "No degree name provided"}</h6>
                      <h6>{data?.specializationDetails?.name || "No specialization provided"}</h6>
                      <h6>{data?.projects || "No projects provided"}</h6>
                      <h6>{data?.completionYear || "No completion year provided"}</h6>
                    </div>
                  </li>
                  
                ))
              ) : (
                <li>No data available</li>
              )}
            </ul>

          </div>
          {/* <div className={styles.aboutProfile}>
            <h4 className="fw-semibold">Social Media </h4>
            <ul className="row align-items-center justify-content-start g-3 social-media">
              <li>
                Instagram:{" "}
                <Link to={`${getUserProfile?.instagramLink}`}>
                  {getUserProfile?.instagramLink}
                </Link>
              </li>
              <li>
                LinkedIn:{" "}
                <Link to={`${getUserProfile?.linkedinLink}`}>
                  {getUserProfile?.linkedinLink}
                </Link>
              </li>
              <li>
                Facebook:{" "}
                <Link to={`${getUserProfile?.facebookLink}`}>
                  {getUserProfile?.facebookLink}
                </Link>
              </li>
              <li>
                Twitter:{" "}
                <Link to={`${getUserProfile?.twitterLink}`}>
                  {getUserProfile?.twitterLink}
                </Link>
              </li>
            </ul>
          </div> */}
          {/* <div className={styles.ProfileReview}>
            <h4 className="fw-semibold">Reviews</h4>
            <ul className="mt-3 review_media">
              <li>
                <div className={styles.reviesTop}>
                  <span>
                    <span>
                      <img src={revies} alt="" />
                    </span>
                  </span>
                  <div>
                    Legacy Industries | Robot Programmer - Jimmy G
                    {getUserProfile?.reviews}
                    <ul className="d-flex align-items-center justify-content-start">
                      <li><img src={star} alt="" /></li>
                      <li><img src={star} alt="" /></li>
                      <li><img src={star} alt="" /></li>
                      <li><img src={star} alt="" /></li>
                      <li><img src={star} alt="" /></li>
                    </ul>
                  </div>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  dapibus eros eu vehicula interdum. Cras nec ultricies massa.
                  Curabitur rutrum, diam id consequat consequat. Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit. Sed dapibus eros
                  eu vehicula interdum. Cras nec ultricies massa. Curabitur
                  rutrum, diam id consequat consequat.
                </p>
              </li>
            </ul>
            <div className={styles.SeeAll}>
            <Link to="#">See all reviews</Link>
          </div>
          </div> */}
          {/* <div className={styles.aboutProfile}>
            <h4 className="fw-semibold">
              Endorsements <i className="fas fa-plus-circle"></i>
            </h4>
            <ul>
              <li className={styles.active}>
                <Link to="#">Received</Link>
              </li>
              <li>
                <Link to="#">Given</Link>
              </li>
            </ul>
            <div className={styles.Endrosement}>
              <span>
                {" "}
                <img src={getUserProfile?.profilePicture ? getUserProfile?.profilePicture : dummy} alt="" />
              </span>
              <div>
                <h6>{getUserProfile?.name}</h6>
                <h5>{getUserProfile?.occupation?.name}</h5>
                <span>2 months ago</span>
              </div>
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed luctus,
              augue eget scelerisque efficitur.Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Sed luctus, augue eget scelerisque
              efficitur.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Sed luctus, augue eget scelerisque efficitur.Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Sed luctus, augue eget
              scelerisque efficitur.Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Sed luctus, augue eget scelerisque efficitur.Lorem
              ipsum dolor sit amet, consectetur adipiscing elit. Sed luctus, augue
              eget scelerisque efficitur.
            </p>
            <div className={styles.SeeAll}>
              <Link to="#">See all reviews</Link>
            </div>
          </div> */}

          {/* <div className={styles.aboutProfile}>
            <h4 className="fw-semibold">Applications</h4>
            <div className="row">
              <div className="col-md-4">
                <div className={styles.ApplicatioInner}>
                  <h4>
                    Robot Programming{" "}
                    <span className={styles.Open}>(Open)</span>
                  </h4>
                  <span className="d-flex align-items-center justify-content-start">
                    <i className="me-2"><img src={loc} alt="" /></i> City, State
                  </span>
                  <label className="d-inline-flex align-items-center justify-content-start px-3">
                    <i className="me-2"><img src={orbag} alt="" /></i> Direct Job
                  </label>
                  <h6>total Applications(5)</h6>
                </div>
              </div>
              <div className="col-md-4">
                <div className={styles.ApplicatioInner}>
                  <h4>
                    Robot Programming{" "}
                    <span className={styles.close}>(Close)</span>
                  </h4>
                  <span className="d-flex align-items-center justify-content-start">
                    <i className="me-2"><img src={loc} alt="" /></i> City, State
                  </span>
                  <label className="d-inline-flex align-items-center justify-content-start px-3">
                    <i className="me-2"><img src={orbag} alt="" /></i> Direct Job
                  </label>
                  <h6>total Applications(5)</h6>
                </div>
              </div>
            </div>
          </div> */}
          <div className={styles.aboutProfile}>
            <h4 className="fw-semibold">Interview Question</h4>
            <ul
              className={`${styles.OccupationTab} ${styles.InterviewQueston}`}
            >
              <li className="col-lg-6 col-12 me-0">
                <div>
                  <span>
                    <img src={dummy} alt="" />
                  </span>
                  <div>
                    <h6>I have a work VISA that allows me to work legally in the US</h6>
                  </div>
                </div>
              
              </li>

              <li className="col-lg-6 col-12 me-0">
              <div>
                  <span>
                    <img src={dummy} alt="" />
                  </span>
                  <div>
                    <h6>Willing to work more than 60 miles from home city</h6>
                  </div>
                </div>
              </li>
              <li className="col-lg-6 col-12 me-0">
              <div>
                  <span>
                    <img src={dummy} alt="" />
                  </span>
                  <div>
                    <h6>Willing to work more than 60 miles from home city</h6>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          {/* <div className={styles.aboutProfile}>
            <h4 className="fw-semibold">Work Experiences</h4>
            <ul className={styles.OccupationTab}>
              {getUserProfile?.workExperiences && getUserProfile.workExperiences.length > 0 ? (
                getUserProfile.workExperiences.map((data, idx) => (
                  <li key={idx}>
                    <span>
                      <img src={userlog} alt="" />
                    </span>
                    <div>
                      <h6>{data?.occupation?.name}</h6>
                      <h5>{data?.company?.name}</h5>
                      <h4>
                        <span>{data?.from}</span> <span>{data?.to}</span>
                      </h4>
                      <p>{data?.description}</p>
                    </div>
                  </li>
                ))
              ) : (
                <li>No data available</li>
              )}
            </ul>

          </div> */}
          {/* <div className={styles.aboutProfile}>
            <h4 className="fw-semibold">
              Endorsements <i className="fas fa-plus-circle"></i>
            </h4>
            <ul>
              <li className={styles.active}>
                <Link to="#">Received</Link>
              </li>
              <li>
                <Link to="#">Given</Link>
              </li>
            </ul>
            <div className={styles.Endrosement}>
              <span>
                {" "}
                <img
                  src={
                    getUserProfile?.profilePicture
                      ? getUserProfile?.profilePicture
                      : dummy
                  }
                  alt=""
                />
              </span>
              <div>
                <h6>{getUserProfile?.name}</h6>
                <h5>{getUserProfile?.occupation?.name}</h5>
                <span>2 months ago</span>
              </div>
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              luctus, augue eget scelerisque efficitur.Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Sed luctus, augue eget
              scelerisque efficitur.Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Sed luctus, augue eget scelerisque
              efficitur.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Sed luctus, augue eget scelerisque efficitur.Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Sed luctus, augue eget
              scelerisque efficitur.Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Sed luctus, augue eget scelerisque efficitur.
            </p>
            <div className={styles.SeeAll}>
              <Link to="#">See all Endorsements</Link>
            </div>
          </div> */}
        </div>
      )}
    </>
  );
};

export default UserProfile;
