import React, { useCallback, useEffect, useState } from "react";
import styles from "../styles/style.module.css"
import penbl from "../../../../assets/images/penbl.png";
import tip from "../../../../assets/images/tip.png";
import security from "../../../../assets/images/security.svg";
import downloadbalck from "../../../../assets/images/download_black.svg";
import printblack from "../../../../assets/images/print_black.svg";
import certificate from "../../../../assets/images/certificate.png";
import penciledit from "../../../../assets/images/penciledit.svg";
import EditBackgroundCheckModal from "../modals/EditBackgroundCheckModal";
import { backgroundCheckProps } from "../../interface";
import Toltip from "../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";

const BackgroundCheck: React.FC<backgroundCheckProps> = ({ refresh, docFile }) => {

  const [showModal, setShowModal] = useState<boolean>(false);

   const [tooltipVisibility, setTooltipVisibility] = useState<{
                [key: string]: boolean;
              }>({
                backgroundcheck: false
              })
          
              const handleIconClick = (tooltipKey: string) => {
                setTooltipVisibility(prev => ({
                  backgroundcheck: false,
                  [tooltipKey]: !prev[tooltipKey]
                }));
              };
                  const handleClickOutside = useCallback((event: MouseEvent) => {
                      const target = event.target as HTMLElement;
                      const isTooltipIcon = target.closest(".tooltip-icon");
                      const isTooltipContent = target.closest(".tooltip-content");
                      const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line
                  
                      if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
                        setTooltipVisibility({
                          backgroundcheck: false
                        });
                      }
                    }, []);
                    useEffect(() => {
                      document.addEventListener('click', handleClickOutside);
                      return () => document.removeEventListener('click', handleClickOutside);
                    }, [handleClickOutside]);
  const openModal = () => {
    setShowModal(true);
  }
  const closeModal = () => { setShowModal(false) };
  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between">
        <div className="col-10">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">
                Background Check
              </h4>
              <i onClick={() => handleIconClick("backgroundcheck")} className="tooltip-icon">
                  <img
                    className="customeTolImg"
                    src={tip}
                    alt="Tooltip"
                  />
                </i>
                <Toltip tooltipClass={tooltipVisibility.backgroundcheck ? "activetooltip" : ""}
                  slug={CONSTANT_MANAGER.SLUG.EDIT_PROFILE}
                  labelId={
                    CONSTANT_MANAGER.EDIT_PROFILE.RESUME
                  }
                />
            </div>
          </div>
        </div>
        <div className="col-2">
          <div className=" text-end">
            <span>
              <img src={penbl} alt="" className="cursor-pointer ms-auto" onClick={openModal} />
            </span>
          </div>
        </div>
      </div>
      <ul className={styles.experienceSet}>
        <div className="row align-items-center justify-content-start g-3 mt-2">
          <li className="col-lg-6 col-12">
            <div className="w-100 border rounded-3 p-2">
              <h4>{docFile?.name}.pdf</h4>

              <div className="position-relative overflow-hidden rounded-3">
                <figure className="mb-0 photobanner">
                  <img src={docFile?.filename} alt="" className="img-fluid w-100 objectfircover" height={"280px"} />
                </figure>
                <ul
                  className="d-flex align-items-center justify-content-end position-absolute top-0 end-0"
                  style={{ gap: "15px" }}
                >
                  <li onClick={openModal} className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2 cursor-pointer">
                    <i className="me-1">
                      <img  src={penciledit} alt="" width={12} height={12} />
                    </i>
                    <span className="text-10">Edit</span>
                  </li>
                  <li
                    className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2 cursor-pointer"
                    onClick={() => {
                      const printContent = `
      <div style="text-align: center; font-family: Arial, sans-serif; padding: 20px;">
        <h1>${docFile?.name}</h1>
        <img src="${docFile?.filename}" alt="Background Check Document" style="max-width: 100%; height: auto; margin: 20px 0; border: 1px solid #ccc; padding: 10px;" />
      </div>
    `;

                      const printWindow = window.open("", "_blank");
                      if (printWindow) {
                        printWindow.document.open();
                        printWindow.document.write(`
        <!DOCTYPE html>
        <html>
          <head>
            <title>Print Background Check</title>
          </head>
          <body onload="window.print(); window.close();" style="margin: 0;">
            ${printContent}
          </body>
        </html>
      `);
                        printWindow.document.close();
                      }
                    }}
                  >
                    <i className="me-1">
                      <img src={printblack} alt="" width={12} height={12} />
                    </i>
                    <span className="text-10">Print</span>
                  </li>

                  <li
  className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2 cursor-pointer"
  onClick={async () => {
    try {
      const response = await fetch(docFile?.filename || ""); // Fetch the image file
      if (!response.ok) {
        throw new Error("Failed to fetch the image");
      }

      const blob = await response.blob(); // Convert response to a Blob
      const blobURL = window.URL.createObjectURL(blob); // Create a Blob URL

      const link = document.createElement("a");
      link.href = blobURL;
      link.download = docFile?.name || "download"; // Set a default filename
      link.click();

      // Cleanup
      window.URL.revokeObjectURL(blobURL); // Revoke Blob URL to release memory
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  }}
>
  <i className="me-1">
    <img src={downloadbalck} alt="" width={12} height={12} />
  </i>
  <span className="text-10">Download</span>
</li>

                </ul>
              </div>
            </div>
          </li>

          <li>
            <div className={styles.note}>
              <div className="d-flex align-items-center bg-lightblue p-3 rounded-2">
                <div className="col-lg-1 col-2 text-center">
                  <i>
                    <img src={security} alt="" />
                  </i>
                </div>
                <div className="col-lg-11 col-10">
                  <p className="text-14 fw-medium mb-0 ps-2">
                    Upload a copy of your background check. This adds an extra layer of trust for potential clients.
                  </p>
                </div>
              </div>
            </div>
          </li>
        </div>
      </ul>
      <EditBackgroundCheckModal showModal={showModal}
        closeModal={closeModal}
        title="Edit Background Check"
        refresh={refresh}
        editData={docFile} />
    </div>
  );
};

export default BackgroundCheck;
