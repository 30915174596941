import React, { useCallback, useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../../assets/images/penbl.png";
import tip from "../../../../assets/images/tip.png";
import { BioInterface } from "../../interface";
import Toltip from "../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";
import ShortBioModal from "../modals/ShortBioModal";

const ShortBio: React.FC<BioInterface> = ({ bio, refresh }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<string | null>(null);
  const [indexNumber, setIndexNumber] = useState<number | null>(null);
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: string]: boolean;
  }>({
    shortbio: false
  });

  const openModal = (data?: string, index?: number) => {
    setEditData(data || null); 
    setIsModalOpen(true);
    console.log("index---", index);
    if (index !== null && index !== undefined && index >= 0) {
      console.log("index-if", index);
      setIndexNumber(index);
    }
  };


  const closeModal = () => {
    setIsModalOpen(false);
    setEditData(null); 
  };

  const handleIconClick = (tooltipKey: string) => {
    setTooltipVisibility(prev => ({
      shortbio: false,
      [tooltipKey]: !prev[tooltipKey]
    }));
  };
  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isTooltipIcon = target.closest(".tooltip-icon");
    const isTooltipContent = target.closest(".tooltip-content");
    const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line

    if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
      setTooltipVisibility({
     shortbio:false
      });
    }
  }, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-10">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">About Me</h4>
              <i onClick={() => handleIconClick("shortbio")} className="tooltip-icon">
                <img
                  className="customeTolImg"
                  src={tip}
                  alt="Tooltip"
                />
              </i>
              <Toltip tooltipClass={tooltipVisibility.shortbio ? "activetooltip" : ""}
                slug={CONSTANT_MANAGER.SLUG.EDIT_PROFILE}
                labelId={
                  CONSTANT_MANAGER.EDIT_PROFILE.SHORT_BIO
                }
              />
            </div>
          </div>
        </div>
        <div className="col-2 text-end">
          <span>
            <img
              src={penbl}
              className="cursor-pointer ms-auto"
              alt=""
              onClick={() => openModal()}
            />
          </span>
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: bio ? bio : "",
        }}
      />
      <ShortBioModal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Edit About Me"
        bio={bio}
        refresh={refresh}
      />
    </div>
  );
};

export default ShortBio;
