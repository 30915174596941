import React, { useCallback, useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../../assets/images/penbl.png";
import tip from "../../../../assets/images/tip.png";
import EditHRModal from "../modals/EditHRModal";
import { HRContactsData } from "../../interface";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";
import Toltip from "../../../common/toltip/page";
interface HrDataProps {
  HrData: HRContactsData[];
  refresh: () => void;
}

const HRContacts: React.FC<HrDataProps> = ({ refresh, HrData }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [editData, setEditData] = useState<HRContactsData | null>(null);
  const [indexNumber, setIndexNumber] = useState<number | null>(null);

   const [tooltipVisibility, setTooltipVisibility] = useState<{
      [key: string]: boolean;
    }>({
      hr: false
    })
  
    const handleIconClick = (tooltipKey: string) => {
      setTooltipVisibility(prev => ({
        hr: false,
        [tooltipKey]: !prev[tooltipKey]
      }));
    };
    const handleClickOutside = useCallback((event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const isTooltipIcon = target.closest(".tooltip-icon");
      const isTooltipContent = target.closest(".tooltip-content");
      const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line
  
      if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
        setTooltipVisibility({
          hr: false
        });
      }
    }, []);
    useEffect(() => {
      document.addEventListener('click', handleClickOutside);
      return () => document.removeEventListener('click', handleClickOutside);
    }, [handleClickOutside])

  const openModal = (data?: HRContactsData, index?: number) => {
    setEditData(data || null);

    if (index !== null && index !== undefined && index >= 0) {
      setIndexNumber(index);
    }

    setShowModal(true);
  }
  const closeModal = () => { setShowModal(false) };
  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-10">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">
                Human Resources Contact
              </h4>
              <i onClick={() => handleIconClick("hr")} className="tooltip-icon">
                  <img
                    className="customeTolImg"
                    src={tip}
                    alt="Tooltip"
                  />
                </i>
                <Toltip tooltipClass={tooltipVisibility.hr ? "activetooltip" : ""}
                  slug={CONSTANT_MANAGER.SLUG.EDIT_PROFILE}
                  labelId={
                    CONSTANT_MANAGER.EDIT_PROFILE.HUMAN_RESOURCES_CONTACT
                  }
                />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.personalinfo}>
        {HrData?.length === 0 ? (
          <p>No Data found.</p>
        ) : (
          HrData?.map((admin, index) => (
            <ul className="row align-items-center rounded-2 border bg-light p-2 mx-0 mb-2" key={index}>
               <li className="col-12">
                <img
                  src={penbl}
                  alt="Edit"
                  className="cursor-pointer ms-auto"
                  onClick={() => openModal(admin, index)}
                />
              </li>
              <li className="col-xl-4 col-12 AccountReceivalcol-6">
                <span>Name</span>
                <label className="clamp clamp1">{admin.name}</label>
              </li>
              <li className="col-xl-4 col-md-6 col-12">
                <span>Email</span>
                <label className="clamp clamp1">{admin.email}</label>
              </li>
              <li className="col-xl-4 col-md-6 col-12">
                <span>Phone</span>
                <label className="clamp clamp1">{admin.phoneNumber}</label>
              </li>
             
              <li className="col-xl-4 col-md-6 col-12">
                <span>City</span>
                <label className="clamp clamp1">{admin.city}</label>
              </li>

              {/* Add Edit Icon */}

            </ul>
          ))
        )}

        {/* Add more button */}
        <div className="mt-2">
          <h4 className="text-14 fw-semibold text-blue cursor-pointer" onClick={() => openModal()}>
            + Add more
          </h4>
        </div>
      </div>
      <EditHRModal showModal={showModal}
       refresh={refresh}
        closeModal={closeModal}
         title="Edit Human Resources Contact"
         editData={editData}        // Pass the selected data for editing
         index={indexNumber}
         HumanResourceData={HrData}
         />

    </div>
  );
};

export default HRContacts;
