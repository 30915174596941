import React, { useCallback, useEffect, useState } from "react";
import styles from "../../../../../components/contractBeforeLogin/styles/style.module.css";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../redux/hooks";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setDrugTest,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { Dropdown, FormControl } from "react-bootstrap";
import tip from "../../../../../assets/images/tip.png";
import Toltip from "../../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import { toast } from "react-toastify";

const DrugTest: React.FC = () => {
  const dispatch = useDispatch();
  const { drugTest } = useAppSelector((state: RootState) => state.addContract);
  const { isDrugTestRequired, drugRegacy } = drugTest;
  const [selectedOption, setSelectedOption] = useState<any>(drugTest.drugRegacy);
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: string]: boolean;
  }>({
    drug: false,
  });

  const options = [
    "In the last week",
    "Month",
    "Three Months",
    "Six Months",
    "One Year",
  ];

  const formIk = useFormik({
    initialValues: { isDrugTestRequired: drugTest.isDrugTestRequired, drugRegacy: drugTest.drugRegacy },
    onSubmit: () => {
      // dispatch(setActiveStep(ADD_CONTRACT_STEPS.BACKGROUND_CHECK));
    },
  });

  const onValueChange = (value: string | boolean, key: string): void => {
    formIk.setFieldValue(key, value);
    const updatedSafetyRequirements = {
      ...drugTest,
      [key]: value,
    };
    dispatch(setDrugTest(updatedSafetyRequirements));
    setSelectedOption(value);
  };

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_SOFWARE_LICENSES));
  };
  const handleGoToNextStep = () => {
    if (isDrugTestRequired === true && !drugRegacy) {
      toast.error('Please Select Drug Test Recency');
      return;
    }
    else {
      dispatch(setActiveStep(ADD_CONTRACT_STEPS.BACKGROUND_CHECK));
    }
  };

  const handleSkip = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.BACKGROUND_CHECK));
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isTooltipIcon = target.closest(".tooltip-icon");
    const isTooltipContent = target.closest(".tooltip-content");
    const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line

    if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
      setTooltipVisibility({
        drug: false,
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  const handleIconClick = (tooltipKey: string) => {
    setTooltipVisibility(prev => ({
      drug: false,
      [tooltipKey]: !prev[tooltipKey]
    }));
  };

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formIk.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div className={styles.registerLeft}>
                  <div className="col-lg-11 col-12 text-center mx-auto">
                    {/* <p>Add a Legal information of the work that you need</p> */}
                    <figure>
                      <img
                        src={choosetype}
                        alt=""
                        className="img-fluid w-100"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <h4 className="text-white text-32 fw-semibold">
                  Will applicants be required to have a recent drug
                  test?
                </h4>
                {/* <p className="text-16 text-white opacity-75">
                      You can provide all the information here regarding the
                      contract; you just need to fill in all the details here.
                    </p> */}
                <div className={styles.signUPContaine}>
                  <div className={styles.registerRight}>
                    <>
                      <div className={styles.fromGroup}>
                        <div className="topTipHover">
                          <label className='text-16 text-white fw-medium'>Drug Test Required</label>
                          <i onClick={() => handleIconClick("drug")} className="tooltip-icon">
                            <img
                              className="customeTolImg"
                              src={tip}
                              alt="Tooltip"
                            />
                          </i>
                          <Toltip tooltipClass={tooltipVisibility.drug ? "activetooltip" : ""}
                            slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                            labelId={
                              CONSTANT_MANAGER.ADD_CONTRACT.DRUG_TEST
                            }
                          />
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name={`isDrugTestRequired$`}
                            id={`isDrugTestRequired$`}
                            checked={isDrugTestRequired}
                            onChange={() =>
                              onValueChange(
                                true,
                                "isDrugTestRequired"
                              )
                            }
                          />
                          <label className="form-check-label text-white ms-2 text-14">
                            Yes
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name={`isDrugTestRequired$`}
                            id={`isDrugTestRequired$`}
                            checked={!isDrugTestRequired}
                            onChange={() =>
                              onValueChange(
                                false,
                                "isDrugTestRequired"
                              )
                            }
                          />
                          <label className="form-check-label text-white ms-2 text-14">
                            No
                          </label>
                        </div>
                      </div>

                      {isDrugTestRequired && (
                        <>
                          <div className={styles.fromGroup}>
                            <label className={styles.starlabel}>
                              Drug Test Recency
                            </label>
                            <Dropdown>
                              <Dropdown.Toggle
                                id="dropdown-basic"
                                name="travelTip"
                              >
                                {selectedOption ||
                                  "How many days old can the test be?"}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <div
                                  style={{
                                    maxHeight: "300px",
                                    overflowY: "scroll",
                                  }}
                                >
                                  {options.map((item) => (
                                    <Dropdown.Item
                                      key={item}
                                      onClick={() =>
                                        onValueChange(
                                          item,
                                          "drugRegacy"
                                        )
                                      }
                                    >
                                      {item}
                                    </Dropdown.Item>
                                  ))}
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                            {/* </div> */}
                          </div>
                        </>
                      )}
                    </>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <div className="col-xl-4 col-6 text-start my-3 btn-hover-effect">
                    <h6
                      onClick={() => handleGoBack()}
                      className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold">
                        Back
                      </span>
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="" />
                      </i>
                    </h6>
                  </div>
                  <div className="col-xl-4 col-6 text-end my-3 btn-hover-effect text-center">
                    <button
                      onClick={handleSkip}
                      className="btn arrowyellow border-grey border-1 position-relative"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                        Skip
                      </span>
                    </button>
                  </div>
                  <div className="col-xl-4 col-6 text-end my-3 btn-hover-effect">
                    <h6
                      onClick={handleGoToNextStep}
                      className="btn arrowblue border-grey border-1 position-relative pe-5"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold">
                        Next
                      </span>
                      <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                        <img src={whitearrow} alt="" />
                      </i>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default DrugTest;
