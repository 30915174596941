import React, { useCallback, useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../../assets/images/penbl.png";
import tip from "../../../../assets/images/tip.png";
import downloadbalck from "../../../../assets/images/download_black.svg";
import printblack from "../../../../assets/images/print_black.svg";
import check from "../../../../assets/images/check_green.svg";
import EditResumeModal from "../modals/EditResumeModal";
import { ResumeProps } from "../../interface";
import Toltip from "../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";

  const Resume: React.FC<ResumeProps> = ({refresh, docFile}) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const openModal = () => {
      setShowModal(true);
    }
    const [tooltipVisibility, setTooltipVisibility] = useState<{
              [key: string]: boolean;
            }>({
              resume: false
            })
        
            const handleIconClick = (tooltipKey: string) => {
              setTooltipVisibility(prev => ({
                resume: false,
                [tooltipKey]: !prev[tooltipKey]
              }));
            };
                const handleClickOutside = useCallback((event: MouseEvent) => {
                    const target = event.target as HTMLElement;
                    const isTooltipIcon = target.closest(".tooltip-icon");
                    const isTooltipContent = target.closest(".tooltip-content");
                    const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line
                
                    if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
                      setTooltipVisibility({
                        resume: false
                      });
                    }
                  }, []);
                  useEffect(() => {
                    document.addEventListener('click', handleClickOutside);
                    return () => document.removeEventListener('click', handleClickOutside);
                  }, [handleClickOutside]);
    const closeModal = () => { setShowModal(false)};
  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-center">
        <div className="col-6">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">Resume</h4>
              <i onClick={() => handleIconClick("resume")} className="tooltip-icon">
                  <img
                    className="customeTolImg"
                    src={tip}
                    alt="Tooltip"
                  />
                </i>
                <Toltip tooltipClass={tooltipVisibility.resume ? "activetooltip" : ""}
                  slug={CONSTANT_MANAGER.SLUG.EDIT_PROFILE}
                  labelId={
                    CONSTANT_MANAGER.EDIT_PROFILE.RESUME
                  }
                />
            </div>
          </div>
        </div>
        <div className="col-6">
          <ul
            className="d-flex align-items-center justify-content-end mb-0"
            style={{ gap: "15px" }}
          >
            <li>
              <div className="text-end">
                <i>
                  <img src={penbl} className="cursor-pointer" alt="" onClick={openModal}/>
                </i>
              </div>
            </li>
          </ul>
        </div>
        <div className="text-start mt-2">
          <h4 className="justify-content-start text-black fw-semibold text-14">
            <i className="me-2">
              <img src={check} alt="" width={15} />
            </i>
            {docFile?.name}.pdf
          </h4>
        </div>
      </div>
      <EditResumeModal 
      showModal={showModal} 
      closeModal={closeModal} 
      title="Edit Resume" 
      refresh={refresh}
      editData={docFile}/>
    </div>
  );
};

export default Resume;
