import React, { useCallback, useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../../assets/images/penbl.png";
import tip from "../../../../assets/images/tip.png";
import security from "../../../../assets/images/security.svg";
import EditFinancialInfoModal from "../modals/EditFinancialInfoModal";
import { PayRateInterface } from "../../interface";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";
import Toltip from "../../../common/toltip/page";

const FinancialInfo: React.FC<PayRateInterface> = ({ rates, refresh }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
    const [tooltipVisibility, setTooltipVisibility] = useState<{
        [key: string]: boolean;
      }>({
        financialinfo: false
      })
  
      const handleIconClick = (tooltipKey: string) => {
        setTooltipVisibility(prev => ({
          financialinfo: false,
          [tooltipKey]: !prev[tooltipKey]
        }));
      };
          const handleClickOutside = useCallback((event: MouseEvent) => {
              const target = event.target as HTMLElement;
              const isTooltipIcon = target.closest(".tooltip-icon");
              const isTooltipContent = target.closest(".tooltip-content");
              const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line
          
              if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
                setTooltipVisibility({
                  financialinfo: false
                });
              }
            }, []);
            useEffect(() => {
              document.addEventListener('click', handleClickOutside);
              return () => document.removeEventListener('click', handleClickOutside);
            }, [handleClickOutside]);
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-10">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">
                Pay Rates / Hour
              </h4>
              <i onClick={() => handleIconClick("financialinfo")} className="tooltip-icon">
                  <img
                    className="customeTolImg"
                    src={tip}
                    alt="Tooltip"
                  />
                </i>
                <Toltip tooltipClass={tooltipVisibility.financialinfo ? "activetooltip" : ""}
                  slug={CONSTANT_MANAGER.SLUG.EDIT_PROFILE}
                  labelId={
                    CONSTANT_MANAGER.EDIT_PROFILE.FINANCIAL_INFORMATION
                  }
                />
            </div>
          </div>
        </div>
        <div className="col-2 text-end">
          <span>
            <img
              src={penbl}
              className="cursor-pointer ms-auto"
              alt=""
              onClick={openModal}
            />
          </span>
        </div>
      </div>
      <div className={styles.personalinfo}>
        <ul className="row align-items-center">
          <li className="col-xl-4 col-6 mb-3">
            <span className="">Base Rate (0-40 hours)</span>
            <label className="clamp clamp1">${rates?.baseRate}</label>
          </li>
          <li className="col-xl-4 col-6 mb-3">
            <span>Overtime Rate (41+ hours)</span>
            <label className="clamp clamp1">${rates?.overTimeRate}</label>
          </li>
          <li className="col-xl-4 col-6 mb-3">
            <span>Sunday and Holiday Rate</span>
            <label className="clamp clamp1">${rates?.doubleTimeRate}</label>
          </li>
          <li className="col-xl-4 col-6 mb-3">
            <span>Flat Rate (All-inclusive, no overtime)</span>
            <label className="clamp clamp1">${rates?.flatRate}</label>
          </li>
        </ul>
      </div>
      <div className={styles.note}>
        <div className="d-flex align-items-center bg-lightblue p-3 rounded-2">
          <div className="col-lg-1 col-2 text-center">
            <i>
              <img src={security} alt="" />
            </i>
          </div>
          <div className="col-lg-11 col-10">
            <p className="text-14 fw-medium mb-0 ps-2">
              Please provide your financial information securely to facilitate
              payments. Your security is extremely important to us, and only
              Automate America Financial Administration will have access to this
              information.
            </p>
          </div>
        </div>
      </div>
      <EditFinancialInfoModal
        showModal={showModal}
        closeModal={closeModal}
        title="Edit Pay Rates / Hour"
        rates={rates}
        refresh={refresh}
        editData={rates}
      />
    </div>
  );
};

export default FinancialInfo;
