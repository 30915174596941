import React, { useState, useEffect } from "react";
import styles from "../styles/style.module.css";
import editprofile from "../../../assets/images/edit_profile.svg";
import { useNavigate } from "react-router-dom";
import shareprofile from "../../../assets/images/share_profile.svg";
import { getuserProfile } from "../../home/api";
import { userProfileResponse } from "../../home/interface";
import userImg from "../../../assets/images/user.jpg";
import location from "../../../assets/images/icon_location.svg";
import art from "../../../assets/images/art.png";
import star from "../../../assets/images/icon_star.svg";
import blink from "../../../assets/images/blink.png";
import bchat from "../../../assets/images/bchat.png";
import bemail from "../../../assets/images/bemail.png";
import blinked from "../../../assets/images/blinked.png";
import bfacebook from "../../../assets/images/bfacebook.png";
import bwhatsapp from "../../../assets/images/bwhatsapp.png";
import { APP_ROUTES } from "../../../utils/routes/AppRoutes";
import { Link } from "react-router-dom";
import { updateUserProfile } from "../../home/api";
import { ref } from "yup";
import { UseDispatch, useDispatch } from "react-redux";

interface Prop {
  refresh: () => void;
}

const UserProfile: React.FC<Prop> = ({refresh}) => {
  const navigate = useNavigate();
  const dispatch=useDispatch()
  const [profileDetetails, setProfileDetails] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  // const [isAvail, setIsAvail] = useState<boolean>(false);

  console.log(profileDetetails?.data?.isAvailable, "Avail");
  // console.log(isAvail, 'AvailState');

  const _refresh = () => {
    setLoading(true);
    getuserProfile().then((res: any) => {
      setProfileDetails(res);
     
      console.log(res, "profilowe details ");
      setLoading(false);
      // if (res?.data?.isAvailable !== undefined) {
      //   setIsAvail(res?.data?.isAvailable);
      // }
    });
  };

  useEffect(() => {
    _refresh();
    // refresh();
  }, []);

  const handleToggle = () => {
    updateUserProfile({
      isAvailable: !profileDetetails?.data?.isAvailable,
    }).then((res) => {
      // _refresh();
      refresh();
    });
    // setIsAvail(!isAvail);
  };

  return (
    <div className="col-lg-7 col-12 mb-4 mb-lg-0">
      <div className="border bg-white rounded-4">
        <div className="position-relative">
          <figure className={styles.bntColor}>
            <></>
          </figure>
          <div className={styles.edit_share}>
            <ul className="ps-0 position-absolute top-0 end-0 d-flex align-items-center justify-content-end mb-0">
              <li
                className="bg-white p-2 rounded-circle"
                onClick={() => navigate("/editProfile")}
              >
                <i>
                  <img src={editprofile} alt="" />
                </i>
              </li>
              <li className="bg-white p-2 rounded-circle">
                <i>
                  <img src={shareprofile} alt="" />
                </i>
              </li>
            </ul>
          </div>
        </div>
        <div className="px-lg-4 px-2 pb-lg-4 pb-2">
          <div className="d-flex align-items-center justify-content-between flex-md-row flex-column mb-md-0 mb-3">
            <div className={styles.profilepicture}>
              <i>
                <img
                  src={
                    profileDetetails?.data?.profilePicture
                      ? profileDetetails?.data?.profilePicture
                      : userImg
                  }
                  alt=""
                  width={130}
                  height={170}
                  className="img-fluid rounded-2"
                />
              </i>
            </div>
            <div className="bostProfile">
              <div className="d-flex align-items-center availabe-text">
                <span className="me-2 ">
                  {profileDetetails?.data?.isAvailable
                    ? "I am available"
                    : "Unavailable"}
                </span>
                <div className={styles.switchToggle}>
                  <input
                    type="checkbox"
                    id="switch1"
                    checked={profileDetetails?.data?.isAvailable}
                    // checked={isAvail}
                    onChange={handleToggle}
                  />
                  <label htmlFor="switch1"></label>
                </div>
              </div>
              <div
                className={`gap-3 d-flex align-items-center ${styles.bostns}`}
              >
                <button
                  className="btn btn-orange text-16"
                  onClick={() =>
                    navigate(APP_ROUTES.BOOST_PROFILE, {
                      state: { profileDetetails },
                    })
                  }
                >
                  Boost My Profile
                </button>
                <div className="dropdown">
                  <button
                    className={`dropdown-toggle rounded-2 ${styles.ShareProfile}`}
                    type="button"
                    id="sharebuttons"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Share{" "}
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="sharebuttons">
                    <li>
                      <Link to="#">
                        <img src={blink} alt="" />
                        Copy Link
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img src={bchat} alt="" />
                        SMS/Message
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img src={bemail} alt="" />
                        Email
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img src={blinked} alt="" />
                        Linkedin
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img src={bfacebook} alt="" />
                        Facebook
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img src={bwhatsapp} alt="" />
                        Whatsapp
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="newheadingstyle newHeadProfile">
              <h3 className="mb-1">
                {profileDetetails?.data?.name}{" "}
                <span className="d-inline-flex">
                  View Profile Settings{" "}
                  <i className="ps-2">
                    <img src={art} alt="" />
                  </i>
                </span>
              </h3>
              <p className="text-14 mb-2">
                {profileDetetails?.data?.occupationDetails[0]?.name}
              </p>
              <div className={styles.TOpSkils}>
                <h5>TOP SKILL</h5>
                <ul>
                  <li>
                    <span>Figma</span>
                  </li>
                  <li>
                    <span>XD</span>
                  </li>
                </ul>
              </div>
              <div className={styles.selRating}>
                <label>Self Rating :</label>
                <span className={styles.filled}></span>
                <span className={styles.filled}></span>
                <span className={styles.filled}></span>
                <span></span>
                <span></span>
              </div>
              <p>Member Since : 12 Jan 1998</p>
              <ul className="ps-0 mb-0">
                <li className="text-14 mb-2 d-flex align-items-center">
                  <i className="me-2">
                    <img src={star} alt="" />
                  </i>
                  {profileDetetails?.data?.avgRating
                    ? profileDetetails?.data?.avgRating
                    : 0}
                  ( {profileDetetails?.data?.totalReview}
                  reviews)
                </li>
                {/* <li className="text-14 mb-2">
                  <i className="me-2">
                    <img src={location} alt="" />
                  </i>
                  {profileDetetails?.data?.countoryDetails?.name}{" "}
                </li> */}
              </ul>
              <div className={styles.AboutProfile}>
                <h6>About Me </h6>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  luctus, augue eget scelerisque efficitur. Lorem ipsum dolor
                  sit amet
                </p>
              </div>
              {/* <p className=" mb-2">
                {profileDetetails?.data?.countoryDetails?.description}
              </p> */}
            </div>
            {/* <div className={styles.payrates}>
              <h3 className="fw-semibold mt-4">Pay Rates / Hour</h3>
              <ul className="row g-3 ps-0 mb-0">
                <li className="col-md-2 col-3">
                  <div className="">
                    <h3 className="text-14 fw-noraml mb-2">Base</h3>
                    <p className="text-blue text-16 mb-2 fw-semibold">
                      $ {profileDetetails?.data?.rates?.baseRate}
                    </p>
                  </div>
                </li>
                <li className="col-md-2 col-3">
                  <div className="">
                    <h3 className="text-14 fw-noraml mb-2">Overtime</h3>
                    <p className="text-blue text-16 mb-2 fw-semibold">
                      $ {profileDetetails?.data?.rates?.overTimeRate}
                    </p>
                  </div>
                </li>
                <li className="col-md-2 col-3">
                  <div className="">
                    <h3 className="text-14 fw-noraml mb-2">Sun/Holidays</h3>
                    <p className="text-blue text-16 mb-2 fw-semibold">
                      $ {profileDetetails?.data?.rates?.doubleTimeRate}
                    </p>
                  </div>
                </li>
              </ul>
            </div> */}
            {/* <div className="row align-items-center justify-content-between social_button mt-4">
              <div className="col-lg-3 col-sm-4 col-5">
                
              </div>
              <div className="col-lg-9 col-sm-8 col-12">
                <ul className="ps-0">
                  <span>Share :</span>
                  <li className="">
                    <i className="fas fa-envelope"></i>
                  </li>
                  <li>
                    <i className="fab fa-linkedin-in"></i>
                  </li>
                  <li>
                    <i className="fab fa-facebook-f"></i>
                  </li>
                  <li>
                    <i className="fab fa-instagram"></i>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
