import React, { useState } from "react";
// import Header from "../../../../header/routes";
import { useNavigate } from "react-router-dom";
import shareprofile from "../../../assets/images/share_profile.svg";
import Jobs from "../../../assets/images/job.svg";
import location from "../../../assets/images/icon_location.svg";
import profilepicture from "../../../assets/images/professional_profile.png";
import picture from "../../../assets/images/profilepicture.svg";
import pictureblue from "../../../assets/images/profilepicture-blue.svg";
import flag from "../../../assets/images/flag.svg";
import fly from "../../../assets/images/airoplane.svg";
import share from "../../../assets/images/share_profile.svg";
import info from "../../../assets/images/info.png";
import styles from "../styles/style.module.css";
import Slider from "react-slick";
// import { RecommendedjobsProps } from "../../../interface";
// import { APP_ROUTES } from "../../../utils/routes/AppRoutes";
// import EligibiltyModal from "../../../../common/modals/eligibilty/page";
import tip from "../../../../src/assets/images/tip.png";
import { CONSTANT_MANAGER } from "../../../utils/constans/constants";
import Toltip from "../../common/toltip/page";
const Myteams = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [workId, setWorkId] = useState("");

  const handleWorkDetails = (id: string) => {
    // navigate(`${APP_ROUTES.WORK}/${id}`);
  };
  const handleShowModal = (value: string) => {
    setShowModal(true);
    setWorkId(value);
    localStorage.setItem("workId", value);
  };

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3.1,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3.1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={`bg-white p-lg-4 p-2 shadow rounded-3 mt-3`}>
      {/* {showModal && (
        <EligibiltyModal onClose={() => setShowModal(false)} Id={workId} />
      )} */}
      <div className="">
        <div className={styles.Jobs}>
          <div>
            <div className="topTipHover">
              <div className="d-flex align-items-center">
                <h4 className="form-check-label text-20 fw-bold  font-inter">
                  My Teams
                </h4>
                <i>
                  <img className="customeTolImg" src={tip} alt="" />
                </i>
                <Toltip
                  slug={CONSTANT_MANAGER.SLUG_HOME.HOME}
                  labelId={CONSTANT_MANAGER.HOME_DATA.MY_TEAMS}
                />
              </div>
            </div>
            <div className="recommendedjobs">
              <div className="row align-items-center justify-content-between">
                <Slider {...settings}>
                  {/* {workList?.map((item, ind) => {
              return ( */}
                  <div className="col border mt-3 rounded-3 overflow-hidden bg-lightblue">
                    <div className="row flex-column align-items-center justify-content-between pt-3 mx-0">
                      <div className="row align-items-center justify-content-center g-0 position-relative">
                        <div className="col-3 profile_icon text-center mx-auto">
                          <figure className="text-center p-2">
                            <img
                              src={profilepicture}
                              alt=""
                              className="text-center mx-auto img-fluid"
                            />
                          </figure>
                        </div>
                        <div className="col-9 ps-2">
                          <div className="d-flex align-items-start justify-content-between">
                            <div className="col-7">
                              <div className="newheadingstyle">
                                <h3 className="mb-0 text-16 clamp clamp1">
                                  occupation
                                </h3>
                                <p className="text-12 my-1">Robot Programmer</p>
                              </div>
                            </div>
                            <div className="col-4">
                              <span className={styles.status}>available</span>
                            </div>
                          </div>
                          <div className="">
                            <p className="text-12 d-flex align-items-center mb-2">
                              <i className="me-1">
                                <img
                                  src={location}
                                  alt=""
                                  width={15}
                                  height={14}
                                />
                              </i>
                              address
                            </p>
                          </div>
                          <div className={styles.myfriendsshare}>
                            <figure className="bg-white rounded-circle p-2 mb-0">
                              <img src={share} alt="" />
                            </figure>
                          </div>
                        </div>
                      </div>

                      <div className="skills border-top pt-2">
                        <div className="newheadingstyle">
                          <h3 className="text-14">Programming Skills</h3>
                        </div>
                        <ul className="ps-0 d-flex flex-wrap align-items-center justify-content-start gap-2 mx-0">
                          {/* {item.skilledInfo.map((skill, ind) => {
                          return ( */}
                          <li
                            // key={ind}
                            className="col badge bg-lightblue text-black rounded-pill fw-normal text-12"
                          >
                            skill
                          </li>
                          <li
                            // key={ind}
                            className="col badge bg-lightblue text-black rounded-pill fw-normal text-12"
                          >
                            figma
                          </li>
                          <li
                            // key={ind}
                            className="col badge bg-lightblue text-black rounded-pill fw-normal text-12"
                          >
                            html
                          </li>
                          <li
                            // key={ind}
                            className="col badge bg-lightblue text-black rounded-pill fw-normal text-12"
                          >
                            name
                          </li>
                          {/* );
                        })} */}
                        </ul>
                      </div>
                    </div>
                    <div className="p-3 pt-0 border-top">
                      {/* <div className="">
                      <p className="alert light-danger text-12 p-2 d-inline-flex align-items-center mb-2  py-2">
                        <i className="me-1">
                          <img src={Jobs} alt="" width={15} height={14} />
                        </i>
                        Hourly Contract
                      </p>
                    </div> */}

                      <div className={styles.payrates}>
                        <h3 className="fw-semibold pt-2 text-14 opacity-75">
                          Pay Rates / Hour
                        </h3>
                        <ul className="row g-3 ps-0 mb-0 mt-2">
                          <li className="col mt-0">
                            <div className="">
                              <h3 className="text-13 fw-noraml mb-2">Base</h3>
                              <p className="text-primary text-16 mb-2 fw-semibold">
                                $12
                              </p>
                            </div>
                          </li>
                          <li className="col mt-0">
                            <div className="">
                              <h3 className="text-13 fw-noraml mb-2">
                                Overtime
                              </h3>
                              <p className="text-primary text-16 mb-2 fw-semibold">
                                $12
                              </p>
                            </div>
                          </li>
                          <li className="col mt-0">
                            <div className="">
                              <h3 className="text-13 fw-noraml mb-2">
                                Sun/Holidays
                              </h3>
                              <p className="text-primary text-16 mb-2 fw-semibold">
                                $12
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="border-top">
                        <h3 className="fw-semibold pt-2 text-14 opacity-75">
                          Working Experience
                        </h3>
                        <ul className="row g-3 ps-0 mb-0 mt-2">
                          <li className="col-12 mt-0">
                            <div className="d-flex align-items-center">
                              <div className="">
                                <i>
                                  <img src={picture} alt="" />
                                </i>
                              </div>
                              <div className="ps-3">
                                <h3 className="text-13 fw-noraml mb-0">
                                  Freelance UX/UI designer
                                </h3>
                                <p className="text-black text-12 mb-0 fw-normal">
                                  Lorem ipsum
                                </p>
                                <p className="text-black text-12 mb-0 fw-normal">
                                  Jun 2022 — Present{" "}
                                  <span className="text-blue">
                                    1 yrs 02 mos
                                  </span>
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="col-12 mt-2">
                            <div className="d-flex align-items-center">
                              <div className="">
                                <i>
                                  <img src={pictureblue} alt="" />
                                </i>
                              </div>
                              <div className="ps-3">
                                <h3 className="text-13 fw-noraml mb-0">
                                  Freelance UX/UI designer
                                </h3>
                                <p className="text-black text-12 mb-0 fw-normal">
                                  Lorem ipsum
                                </p>
                                <p className="text-black text-12 mb-0 fw-normal">
                                  Jun 2022 — Present{" "}
                                  <span className="text-blue">
                                    1 yrs 02 mos
                                  </span>
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="mt-3 border-top pt-3">
                        <div className="row align-items-center g-2">
                          {/* {item.isApplied ? ( */}
                          <div className="col">
                            <button
                              type="button"
                              className="btn btn-transparent w-100 text-12 border-2 border-blue text-blue"
                            >
                              Remove
                            </button>
                          </div>
                          {/* ) : ( */}
                          <div className="col">
                            <button
                              // onClick={() => handleShowModal(item._id)}
                              type="button"
                              className="btn btn-primary w-100 text-12 border-2 border-blue"
                            >
                              Message
                            </button>
                          </div>
                          {/* )} */}
                          <div className="col">
                            <button
                              // onClick={() => handleWorkDetails(item._id)}
                              type="button"
                              className="btn btn-primary w-100 text-center border-2 border-blue text-12"
                            >
                              Endorse
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col border rounded-3 overflow-hidden bg-lightblue">
                    <div className="row flex-column align-items-center justify-content-between pt-3 mx-0">
                      <div className="row align-items-center justify-content-center g-0 position-relative">
                        <div className="col-3 profile_icon text-center mx-auto">
                          <figure className="text-center p-2">
                            <img
                              src={profilepicture}
                              alt=""
                              className="text-center mx-auto"
                            />
                          </figure>
                        </div>
                        <div className="col-9 ps-2">
                          <div className="d-flex align-items-start justify-content-between">
                            <div className="col-7">
                              <div className="newheadingstyle">
                                <h3 className="mb-0 text-16 clamp clamp1">
                                  occupation
                                </h3>
                                <p className="text-12 my-1">Robot Programmer</p>
                              </div>
                            </div>
                            <div className="col-4">
                              <span className={styles.status}>available</span>
                            </div>
                          </div>
                          <div className="">
                            <p className="text-12 d-flex align-items-center mb-2">
                              <i className="me-1">
                                <img
                                  src={location}
                                  alt=""
                                  width={15}
                                  height={14}
                                />
                              </i>
                              address
                            </p>
                          </div>
                          <div className={styles.myfriendsshare}>
                            <figure className="bg-white rounded-circle p-2 mb-0">
                              <img src={share} alt="" />
                            </figure>
                          </div>
                        </div>
                      </div>

                      <div className="skills border-top pt-2">
                        <div className="newheadingstyle">
                          <h3 className="text-14">Programming Skills</h3>
                        </div>
                        <ul className="ps-0 d-flex flex-wrap align-items-center justify-content-start gap-2 mx-0">
                          {/* {item.skilledInfo.map((skill, ind) => {
                          return ( */}
                          <li
                            // key={ind}
                            className="col badge bg-lightblue text-black rounded-pill fw-normal text-12"
                          >
                            skill
                          </li>
                          <li
                            // key={ind}
                            className="col badge bg-lightblue text-black rounded-pill fw-normal text-12"
                          >
                            figma
                          </li>
                          <li
                            // key={ind}
                            className="col badge bg-lightblue text-black rounded-pill fw-normal text-12"
                          >
                            html
                          </li>
                          <li
                            // key={ind}
                            className="col badge bg-lightblue text-black rounded-pill fw-normal text-12"
                          >
                            name
                          </li>
                          {/* );
                        })} */}
                        </ul>
                      </div>
                    </div>
                    <div className="p-3 pt-0 border-top">
                      {/* <div className="">
                      <p className="alert light-danger text-12 p-2 d-inline-flex align-items-center mb-2  py-2">
                        <i className="me-1">
                          <img src={Jobs} alt="" width={15} height={14} />
                        </i>
                        Hourly Contract
                      </p>
                    </div> */}

                      <div className={styles.payrates}>
                        <h3 className="fw-semibold pt-2 text-14 opacity-75">
                          Pay Rates / Hour
                        </h3>
                        <ul className="row g-3 ps-0 mb-0 mt-2">
                          <li className="col mt-0">
                            <div className="">
                              <h3 className="text-13 fw-noraml mb-2">Base</h3>
                              <p className="text-primary text-16 mb-2 fw-semibold">
                                $12
                              </p>
                            </div>
                          </li>
                          <li className="col mt-0">
                            <div className="">
                              <h3 className="text-13 fw-noraml mb-2">
                                Overtime
                              </h3>
                              <p className="text-primary text-16 mb-2 fw-semibold">
                                $12
                              </p>
                            </div>
                          </li>
                          <li className="col mt-0">
                            <div className="">
                              <h3 className="text-13 fw-noraml mb-2">
                                Sun/Holidays
                              </h3>
                              <p className="text-primary text-16 mb-2 fw-semibold">
                                $12
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="border-top">
                        <h3 className="fw-semibold pt-2 text-14 opacity-75">
                          Working Experience
                        </h3>
                        <ul className="row g-3 ps-0 mb-0 mt-2">
                          <li className="col-12 mt-0">
                            <div className="d-flex align-items-center">
                              <div className="">
                                <i>
                                  <img src={picture} alt="" />
                                </i>
                              </div>
                              <div className="ps-3">
                                <h3 className="text-13 fw-noraml mb-0">
                                  Freelance UX/UI designer
                                </h3>
                                <p className="text-black text-12 mb-0 fw-normal">
                                  Lorem ipsum
                                </p>
                                <p className="text-black text-12 mb-0 fw-normal">
                                  Jun 2022 — Present{" "}
                                  <span className="text-blue">
                                    1 yrs 02 mos
                                  </span>
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="col-12 mt-2">
                            <div className="d-flex align-items-center">
                              <div className="">
                                <i>
                                  <img src={pictureblue} alt="" />
                                </i>
                              </div>
                              <div className="ps-3">
                                <h3 className="text-13 fw-noraml mb-0">
                                  Freelance UX/UI designer
                                </h3>
                                <p className="text-black text-12 mb-0 fw-normal">
                                  Lorem ipsum
                                </p>
                                <p className="text-black text-12 mb-0 fw-normal">
                                  Jun 2022 — Present{" "}
                                  <span className="text-blue">
                                    1 yrs 02 mos
                                  </span>
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="mt-3 border-top pt-3">
                        <div className="row align-items-center g-2">
                          {/* {item.isApplied ? ( */}
                          <div className="col">
                            <button
                              type="button"
                              className="btn btn-transparent w-100 text-12 border-2 border-blue text-blue"
                            >
                              Remove
                            </button>
                          </div>
                          {/* ) : ( */}
                          <div className="col">
                            <button
                              // onClick={() => handleShowModal(item._id)}
                              type="button"
                              className="btn btn-primary w-100 text-12 border-2 border-blue"
                            >
                              Message
                            </button>
                          </div>
                          {/* )} */}
                          <div className="col">
                            <button
                              // onClick={() => handleWorkDetails(item._id)}
                              type="button"
                              className="btn btn-primary w-100 text-center border-2 border-blue text-12"
                            >
                              Endorse
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col border rounded-3 overflow-hidden bg-lightblue">
                    <div className="row flex-column align-items-center justify-content-between pt-3 mx-0">
                      <div className="row align-items-center justify-content-center g-0 position-relative">
                        <div className="col-3 profile_icon text-center mx-auto">
                          <figure className="text-center p-2">
                            <img
                              src={profilepicture}
                              alt=""
                              className="text-center mx-auto"
                            />
                          </figure>
                        </div>
                        <div className="col-9 ps-2">
                          <div className="d-flex align-items-start justify-content-between">
                            <div className="col-7">
                              <div className="newheadingstyle">
                                <h3 className="mb-0 text-16 clamp clamp1">
                                  occupation
                                </h3>
                                <p className="text-12 my-1">Robot Programmer</p>
                              </div>
                            </div>
                            <div className="col-4">
                              <span className={styles.status}>available</span>
                            </div>
                          </div>
                          <div className="">
                            <p className="text-12 d-flex align-items-center mb-2">
                              <i className="me-1">
                                <img
                                  src={location}
                                  alt=""
                                  width={15}
                                  height={14}
                                />
                              </i>
                              address
                            </p>
                          </div>
                          <div className={styles.myfriendsshare}>
                            <figure className="bg-white rounded-circle p-2 mb-0">
                              <img src={share} alt="" />
                            </figure>
                          </div>
                        </div>
                      </div>

                      <div className="skills border-top pt-2">
                        <div className="newheadingstyle">
                          <h3 className="text-14">Programming Skills</h3>
                        </div>
                        <ul className="ps-0 d-flex flex-wrap align-items-center justify-content-start gap-2 mx-0">
                          {/* {item.skilledInfo.map((skill, ind) => {
                          return ( */}
                          <li
                            // key={ind}
                            className="col badge bg-lightblue text-black rounded-pill fw-normal text-12"
                          >
                            skill
                          </li>
                          <li
                            // key={ind}
                            className="col badge bg-lightblue text-black rounded-pill fw-normal text-12"
                          >
                            figma
                          </li>
                          <li
                            // key={ind}
                            className="col badge bg-lightblue text-black rounded-pill fw-normal text-12"
                          >
                            html
                          </li>
                          <li
                            // key={ind}
                            className="col badge bg-lightblue text-black rounded-pill fw-normal text-12"
                          >
                            name
                          </li>
                          {/* );
                        })} */}
                        </ul>
                      </div>
                    </div>
                    <div className="p-3 pt-0 border-top">
                      {/* <div className="">
                      <p className="alert light-danger text-12 p-2 d-inline-flex align-items-center mb-2  py-2">
                        <i className="me-1">
                          <img src={Jobs} alt="" width={15} height={14} />
                        </i>
                        Hourly Contract
                      </p>
                    </div> */}

                      <div className={styles.payrates}>
                        <h3 className="fw-semibold pt-2 text-14 opacity-75">
                          Pay Rates / Hour
                        </h3>
                        <ul className="row g-3 ps-0 mb-0 mt-2">
                          <li className="col mt-0">
                            <div className="">
                              <h3 className="text-13 fw-noraml mb-2">Base</h3>
                              <p className="text-primary text-16 mb-2 fw-semibold">
                                $12
                              </p>
                            </div>
                          </li>
                          <li className="col mt-0">
                            <div className="">
                              <h3 className="text-13 fw-noraml mb-2">
                                Overtime
                              </h3>
                              <p className="text-primary text-16 mb-2 fw-semibold">
                                $12
                              </p>
                            </div>
                          </li>
                          <li className="col mt-0">
                            <div className="">
                              <h3 className="text-13 fw-noraml mb-2">
                                Sun/Holidays
                              </h3>
                              <p className="text-primary text-16 mb-2 fw-semibold">
                                $12
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="border-top">
                        <h3 className="fw-semibold pt-2 text-14 opacity-75">
                          Working Experience
                        </h3>
                        <ul className="row g-3 ps-0 mb-0 mt-2">
                          <li className="col-12 mt-0">
                            <div className="d-flex align-items-center">
                              <div className="">
                                <i>
                                  <img src={picture} alt="" />
                                </i>
                              </div>
                              <div className="ps-3">
                                <h3 className="text-13 fw-noraml mb-0">
                                  Freelance UX/UI designer
                                </h3>
                                <p className="text-black text-12 mb-0 fw-normal">
                                  Lorem ipsum
                                </p>
                                <p className="text-black text-12 mb-0 fw-normal">
                                  Jun 2022 — Present{" "}
                                  <span className="text-blue">
                                    1 yrs 02 mos
                                  </span>
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="col-12 mt-2">
                            <div className="d-flex align-items-center">
                              <div className="">
                                <i>
                                  <img src={pictureblue} alt="" />
                                </i>
                              </div>
                              <div className="ps-3">
                                <h3 className="text-13 fw-noraml mb-0">
                                  Freelance UX/UI designer
                                </h3>
                                <p className="text-black text-12 mb-0 fw-normal">
                                  Lorem ipsum
                                </p>
                                <p className="text-black text-12 mb-0 fw-normal">
                                  Jun 2022 — Present{" "}
                                  <span className="text-blue">
                                    1 yrs 02 mos
                                  </span>
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="mt-3 border-top pt-3">
                        <div className="row align-items-center g-2">
                          {/* {item.isApplied ? ( */}
                          <div className="col">
                            <button
                              type="button"
                              className="btn btn-transparent w-100 text-12 border-2 border-blue text-blue"
                            >
                              Remove
                            </button>
                          </div>
                          {/* ) : ( */}
                          <div className="col">
                            <button
                              // onClick={() => handleShowModal(item._id)}
                              type="button"
                              className="btn btn-primary w-100 text-12 border-2 border-blue"
                            >
                              Message
                            </button>
                          </div>
                          {/* )} */}
                          <div className="col">
                            <button
                              // onClick={() => handleWorkDetails(item._id)}
                              type="button"
                              className="btn btn-primary w-100 text-center border-2 border-blue text-12"
                            >
                              Endorse
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col border rounded-3 overflow-hidden bg-lightblue">
                    <div className="row flex-column align-items-center justify-content-between pt-3 mx-0">
                      <div className="row align-items-center justify-content-center g-0 position-relative">
                        <div className="col-3 profile_icon text-center mx-auto">
                          <figure className="text-center p-2">
                            <img
                              src={profilepicture}
                              alt=""
                              className="text-center mx-auto"
                            />
                          </figure>
                        </div>
                        <div className="col-9 ps-2">
                          <div className="d-flex align-items-start justify-content-between">
                            <div className="col-7">
                              <div className="newheadingstyle">
                                <h3 className="mb-0 text-16 clamp clamp1">
                                  occupation
                                </h3>
                                <p className="text-12 my-1">Robot Programmer</p>
                              </div>
                            </div>
                            <div className="col-4">
                              <span className={styles.status}>available</span>
                            </div>
                          </div>
                          <div className="">
                            <p className="text-12 d-flex align-items-center mb-2">
                              <i className="me-1">
                                <img
                                  src={location}
                                  alt=""
                                  width={15}
                                  height={14}
                                />
                              </i>
                              address
                            </p>
                          </div>
                          <div className={styles.myfriendsshare}>
                            <figure className="bg-white rounded-circle p-2 mb-0">
                              <img src={share} alt="" />
                            </figure>
                          </div>
                        </div>
                      </div>

                      <div className="skills border-top pt-2">
                        <div className="newheadingstyle">
                          <h3 className="text-14">Programming Skills</h3>
                        </div>
                        <ul className="ps-0 d-flex flex-wrap align-items-center justify-content-start gap-2 mx-0">
                          {/* {item.skilledInfo.map((skill, ind) => {
                          return ( */}
                          <li
                            // key={ind}
                            className="col badge bg-lightblue text-black rounded-pill fw-normal text-12"
                          >
                            skill
                          </li>
                          <li
                            // key={ind}
                            className="col badge bg-lightblue text-black rounded-pill fw-normal text-12"
                          >
                            figma
                          </li>
                          <li
                            // key={ind}
                            className="col badge bg-lightblue text-black rounded-pill fw-normal text-12"
                          >
                            html
                          </li>
                          <li
                            // key={ind}
                            className="col badge bg-lightblue text-black rounded-pill fw-normal text-12"
                          >
                            name
                          </li>
                          {/* );
                        })} */}
                        </ul>
                      </div>
                    </div>
                    <div className="p-3 pt-0 border-top">
                      {/* <div className="">
                      <p className="alert light-danger text-12 p-2 d-inline-flex align-items-center mb-2  py-2">
                        <i className="me-1">
                          <img src={Jobs} alt="" width={15} height={14} />
                        </i>
                        Hourly Contract
                      </p>
                    </div> */}

                      <div className={styles.payrates}>
                        <h3 className="fw-semibold pt-2 text-14 opacity-75">
                          Pay Rates / Hour
                        </h3>
                        <ul className="row g-3 ps-0 mb-0 mt-2">
                          <li className="col mt-0">
                            <div className="">
                              <h3 className="text-13 fw-noraml mb-2">Base</h3>
                              <p className="text-primary text-16 mb-2 fw-semibold">
                                $12
                              </p>
                            </div>
                          </li>
                          <li className="col mt-0">
                            <div className="">
                              <h3 className="text-13 fw-noraml mb-2">
                                Overtime
                              </h3>
                              <p className="text-primary text-16 mb-2 fw-semibold">
                                $12
                              </p>
                            </div>
                          </li>
                          <li className="col mt-0">
                            <div className="">
                              <h3 className="text-13 fw-noraml mb-2">
                                Sun/Holidays
                              </h3>
                              <p className="text-primary text-16 mb-2 fw-semibold">
                                $12
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="border-top">
                        <h3 className="fw-semibold pt-2 text-14 opacity-75">
                          Working Experience
                        </h3>
                        <ul className="row g-3 ps-0 mb-0 mt-2">
                          <li className="col-12 mt-0">
                            <div className="d-flex align-items-center">
                              <div className="">
                                <i>
                                  <img src={picture} alt="" />
                                </i>
                              </div>
                              <div className="ps-3">
                                <h3 className="text-13 fw-noraml mb-0">
                                  Freelance UX/UI designer
                                </h3>
                                <p className="text-black text-12 mb-0 fw-normal">
                                  Lorem ipsum
                                </p>
                                <p className="text-black text-12 mb-0 fw-normal">
                                  Jun 2022 — Present{" "}
                                  <span className="text-blue">
                                    1 yrs 02 mos
                                  </span>
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="col-12 mt-2">
                            <div className="d-flex align-items-center">
                              <div className="">
                                <i>
                                  <img src={pictureblue} alt="" />
                                </i>
                              </div>
                              <div className="ps-3">
                                <h3 className="text-13 fw-noraml mb-0">
                                  Freelance UX/UI designer
                                </h3>
                                <p className="text-black text-12 mb-0 fw-normal">
                                  Lorem ipsum
                                </p>
                                <p className="text-black text-12 mb-0 fw-normal">
                                  Jun 2022 — Present{" "}
                                  <span className="text-blue">
                                    1 yrs 02 mos
                                  </span>
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="mt-3 border-top pt-3">
                        <div className="row align-items-center g-2">
                          {/* {item.isApplied ? ( */}
                          <div className="col">
                            <button
                              type="button"
                              className="btn btn-transparent w-100 text-12 border-2 border-blue text-blue"
                            >
                              Remove
                            </button>
                          </div>
                          {/* ) : ( */}
                          <div className="col">
                            <button
                              // onClick={() => handleShowModal(item._id)}
                              type="button"
                              className="btn btn-primary w-100 text-12 border-2 border-blue"
                            >
                              Message
                            </button>
                          </div>
                          {/* )} */}
                          <div className="col">
                            <button
                              // onClick={() => handleWorkDetails(item._id)}
                              type="button"
                              className="btn btn-primary w-100 text-center border-2 border-blue text-12"
                            >
                              Endorse
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col border rounded-3 overflow-hidden bg-lightblue">
                    <div className="row flex-column align-items-center justify-content-between pt-3 mx-0">
                      <div className="row align-items-center justify-content-center g-0 position-relative">
                        <div className="col-3 profile_icon text-center mx-auto">
                          <figure className="text-center p-2">
                            <img
                              src={profilepicture}
                              alt=""
                              className="text-center mx-auto"
                            />
                          </figure>
                        </div>
                        <div className="col-9 ps-2">
                          <div className="d-flex align-items-start justify-content-between">
                            <div className="col-7">
                              <div className="newheadingstyle">
                                <h3 className="mb-0 text-16 clamp clamp1">
                                  occupation
                                </h3>
                                <p className="text-12 my-1">Robot Programmer</p>
                              </div>
                            </div>
                            <div className="col-4">
                              <span className={styles.status}>available</span>
                            </div>
                          </div>
                          <div className="">
                            <p className="text-12 d-flex align-items-center mb-2">
                              <i className="me-1">
                                <img
                                  src={location}
                                  alt=""
                                  width={15}
                                  height={14}
                                />
                              </i>
                              address
                            </p>
                          </div>
                          <div className={styles.myfriendsshare}>
                            <figure className="bg-white rounded-circle p-2 mb-0">
                              <img src={share} alt="" />
                            </figure>
                          </div>
                        </div>
                      </div>

                      <div className="skills border-top pt-2">
                        <div className="newheadingstyle">
                          <h3 className="text-14">Programming Skills</h3>
                        </div>
                        <ul className="ps-0 d-flex flex-wrap align-items-center justify-content-start gap-2 mx-0">
                          {/* {item.skilledInfo.map((skill, ind) => {
                          return ( */}
                          <li
                            // key={ind}
                            className="col badge bg-lightblue text-black rounded-pill fw-normal text-12"
                          >
                            skill
                          </li>
                          <li
                            // key={ind}
                            className="col badge bg-lightblue text-black rounded-pill fw-normal text-12"
                          >
                            figma
                          </li>
                          <li
                            // key={ind}
                            className="col badge bg-lightblue text-black rounded-pill fw-normal text-12"
                          >
                            html
                          </li>
                          <li
                            // key={ind}
                            className="col badge bg-lightblue text-black rounded-pill fw-normal text-12"
                          >
                            name
                          </li>
                          {/* );
                        })} */}
                        </ul>
                      </div>
                    </div>
                    <div className="p-3 pt-0 border-top">
                      {/* <div className="">
                      <p className="alert light-danger text-12 p-2 d-inline-flex align-items-center mb-2  py-2">
                        <i className="me-1">
                          <img src={Jobs} alt="" width={15} height={14} />
                        </i>
                        Hourly Contract
                      </p>
                    </div> */}

                      <div className={styles.payrates}>
                        <h3 className="fw-semibold pt-2 text-14 opacity-75">
                          Pay Rates / Hour
                        </h3>
                        <ul className="row g-3 ps-0 mb-0 mt-2">
                          <li className="col mt-0">
                            <div className="">
                              <h3 className="text-13 fw-noraml mb-2">Base</h3>
                              <p className="text-primary text-16 mb-2 fw-semibold">
                                $12
                              </p>
                            </div>
                          </li>
                          <li className="col mt-0">
                            <div className="">
                              <h3 className="text-13 fw-noraml mb-2">
                                Overtime
                              </h3>
                              <p className="text-primary text-16 mb-2 fw-semibold">
                                $12
                              </p>
                            </div>
                          </li>
                          <li className="col mt-0">
                            <div className="">
                              <h3 className="text-13 fw-noraml mb-2">
                                Sun/Holidays
                              </h3>
                              <p className="text-primary text-16 mb-2 fw-semibold">
                                $12
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="border-top">
                        <h3 className="fw-semibold pt-2 text-14 opacity-75">
                          Working Experience
                        </h3>
                        <ul className="row g-3 ps-0 mb-0 mt-2">
                          <li className="col-12 mt-0">
                            <div className="d-flex align-items-center">
                              <div className="">
                                <i>
                                  <img src={picture} alt="" />
                                </i>
                              </div>
                              <div className="ps-3">
                                <h3 className="text-13 fw-noraml mb-0">
                                  Freelance UX/UI designer
                                </h3>
                                <p className="text-black text-12 mb-0 fw-normal">
                                  Lorem ipsum
                                </p>
                                <p className="text-black text-12 mb-0 fw-normal">
                                  Jun 2022 — Present{" "}
                                  <span className="text-blue">
                                    1 yrs 02 mos
                                  </span>
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="col-12 mt-2">
                            <div className="d-flex align-items-center">
                              <div className="">
                                <i>
                                  <img src={pictureblue} alt="" />
                                </i>
                              </div>
                              <div className="ps-3">
                                <h3 className="text-13 fw-noraml mb-0">
                                  Freelance UX/UI designer
                                </h3>
                                <p className="text-black text-12 mb-0 fw-normal">
                                  Lorem ipsum
                                </p>
                                <p className="text-black text-12 mb-0 fw-normal">
                                  Jun 2022 — Present{" "}
                                  <span className="text-blue">
                                    1 yrs 02 mos
                                  </span>
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="mt-3 border-top pt-3">
                        <div className="row align-items-center g-2">
                          {/* {item.isApplied ? ( */}
                          <div className="col">
                            <button
                              type="button"
                              className="btn btn-transparent w-100 text-12 border-2 border-blue text-blue"
                            >
                              Remove
                            </button>
                          </div>
                          {/* ) : ( */}
                          <div className="col">
                            <button
                              // onClick={() => handleShowModal(item._id)}
                              type="button"
                              className="btn btn-primary w-100 text-12 border-2 border-blue"
                            >
                              Message
                            </button>
                          </div>
                          {/* )} */}
                          <div className="col">
                            <button
                              // onClick={() => handleWorkDetails(item._id)}
                              type="button"
                              className="btn btn-primary w-100 text-center border-2 border-blue text-12"
                            >
                              Endorse
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* );
            })} */}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Myteams;
