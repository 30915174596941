import React, { FC, useCallback, useEffect, useState } from "react";
import styles from "../../../contractBeforeLogin/styles/style.module.css";
import HeaderContent from "../../../../shared/Components/HeaderContent/headerContent";
import blackarrow from "../../../../assets/images/blackarrow.svg";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import Occupations from "../../../../assets/images/choosetype.png";
import folder from "../../../../assets/images/folder.png";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveStep,
  setUploadedFiles,
  setVideoLink,
} from "../../../../redux/reducers/productPaidAd";
import { ADD_PAID_ADS } from "../../../../shared/enums";
import { RootState } from "../../../../redux/store";
import Toltip from "../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";
import tip from "../../../../assets/images/tip.png";
import { uploadFileImg } from "../../../Posts/api";

const ProductAdImage: React.FC = () => {
  const dispatch = useDispatch();
  const { uploadedImages, videoLink } = useSelector(
    (state: RootState) => state.productAd
  );
  const [img, setImg] = useState<string[]>(uploadedImages); // Ensure img is always an array
  const [video, setVideo] = useState<string>(videoLink);
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: string]: boolean;
  }>({
    img: false,
    link: false,
  });

  const handleClickNext = () => {
    dispatch(setActiveStep(ADD_PAID_ADS.ADD_PROMOTION));
  };

  const handleClickBack = () => {
    dispatch(setActiveStep(ADD_PAID_ADS.AD_DESCRIPTION));
  };

  const handleFileUpload = async (files: FileList | null) => {
    if (files) {
      const formData = new FormData();
      Array.from(files).forEach((img) => {
        formData.append("files", img);
      });
      try {
        const response = await uploadFileImg(formData);
        // Ensure that you're setting the response as an array of files
        const uploadedFiles = response.data.files; // Assuming it's an array of uploaded files
        setImg(uploadedFiles); // Append new files to the existing array
        dispatch(setUploadedFiles(uploadedFiles)); // Sync with Redux if needed
      } catch (err) {
        console.log(err);
      }
    }
  };

  const removeUploadedFiles = (fileIndex: number): void => {
    // Explicitly typing the `prevUrls` array and the filter parameters
    setImg((prevUrls: any[]) =>
      prevUrls.filter((_: any, i: number) => i !== fileIndex)
    );
  };

  const _onVideoLinkChange = (val: string) => {
    setVideo(val);
    dispatch(setVideoLink(val));
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isTooltipIcon = target.closest(".tooltip-icon");
    const isTooltipContent = target.closest(".tooltip-content");
    const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line

    if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
      setTooltipVisibility({
        img: false,
        link: false,
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [handleClickOutside]);

  const handleIconClick = (tooltipKey: string) => {
    setTooltipVisibility((prev) => ({
      img: false,
      link: false,
      [tooltipKey]: !prev[tooltipKey],
    }));
  };

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div className={styles.registerLeft}>
                  <div className="col-lg-11 col-12 text-center mx-auto">
                    <figure>
                      <img
                        src={Occupations}
                        alt=""
                        className="img-fluid w-100"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className={styles.registerBord}>
                  <div className={styles.signUPContainer}>
                    <div className={styles.registerLeft}>
                      <div className="topTipHover">
                        <div className="d-flex align-items-center">
                          <h4 className="form-check-label text-20 fw-bold text-white font-inter">
                            Upload Image
                          </h4>
                          <i onClick={() => handleIconClick("img")} className="tooltip-icon">
                            <img className="customeTolImg" src={tip} alt="" />
                          </i>
                          <Toltip tooltipClass={tooltipVisibility.img ? "activetooltip" : ""}
                            slug={CONSTANT_MANAGER.SLUG.PAID_ADS}
                            labelId={
                              CONSTANT_MANAGER.PAID_ADS.IMAGE
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <p className="text-white text-14 opacity-75">
                      Make your ad visually appealing by uploading high-quality
                      images that represent your job, contract, or RFQ. Click
                      the 'Upload' button to select images from your device and{" "}
                      attach them to your ad.
                    </p>
                    <div className={styles.registerRight}>
                      <div className={styles.uploadSection}>
                        <div className={styles.uploadbox}>
                          <input
                            type="file"
                            multiple={true}
                            accept="image/*"
                            className={styles.filePos}
                            onChange={({ target }) =>
                              handleFileUpload(target.files)
                            }
                          />
                          <div className={styles.uploadcontent}>
                            <img src={folder} alt="" className="mx-auto" />
                            <h6 className="text-white text-14 ">
                              Drag & Drop or choose Image to upload
                            </h6>
                            <p className=" ">Select Image</p>
                          </div>
                        </div>
                      </div>

                      <ul className={`nav ${styles.uploadnav}`}>
                        {img?.map((imgSrc, index) => (
                          <li key={index}>
                            <div
                              className={`position-relative ${styles.uploadImages}`}
                            >
                              <img
                                src={imgSrc}
                                alt={`Uploaded image ${index}`}
                                className="img-fluid"
                              />
                              <i
                                className="fas fa-times position-absolute top-0 end-0"
                                onClick={
                                  () => removeUploadedFiles(index) // Pass the correct index
                                }
                              ></i>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="col-md-12">
                      <div className={styles.registerBord}>
                        <div className={styles.signUPContainer}>
                          <div className={styles.registerRight}>
                            <div className={styles.uploadSection}>
                              <label className={styles.starlabel}>
                                Video Link
                              </label>
                              <i onClick={() => handleIconClick("link")} className="tooltip-icon">
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt="Tooltip"
                                  />
                                </i>
                                <Toltip tooltipClass={tooltipVisibility.link ? "activetooltip" : ""}
                                  slug={CONSTANT_MANAGER.SLUG.PAID_ADS}
                                  labelId={
                                    CONSTANT_MANAGER.PAID_ADS.LINK
                                  }
                                />
                              <input
                                type="text"
                                className="form-control"
                                value={video}
                                onChange={(e) =>
                                  _onVideoLinkChange(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center justify-content-between">
                      <div
                        className="col-4 text-start my-3 btn-hover-effect"
                        onClick={handleClickBack}
                      >
                        <h6 className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton">
                          <span className="px-lg-4 px-2 text-12 fw-semibold">
                            Back
                          </span>
                          <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                            <img src={blackarrow} alt="" />
                          </i>
                        </h6>
                      </div>
                      <div className="col-8">
                        <div
                          className="d-flex align-items-center jusify-content-end"
                          style={{ gap: "10px" }}
                        >
                          <div
                            className="col-12 text-end my-3 btn-hover-effect"
                            onClick={handleClickNext}
                          >
                            <h6 className="btn arrowblue border-grey border-1 position-relative pe-5">
                              <span className="px-lg-4 px-2 text-12 fw-semibold">
                                Continue
                              </span>
                              <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                <img src={whitearrow} alt="" />
                              </i>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProductAdImage;
