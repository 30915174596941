import React, { FC, useCallback, useEffect, useState } from "react";
import HeaderContent from "../../shared/Components/HeaderContent/headerContent";
import styles from "../../components/contractBeforeLogin/styles/style.module.css";
import { useNavigate } from "react-router-dom";
import choosetype from "../../assets/images/choosetype.png";
import blackarrow from "../../assets/images/blackarrow.svg";
import whitearrow from "../../assets/images/whitearrow.svg";
import tip from "../../assets/images/tip.png";
import { CONSTANT_MANAGER } from "../../utils/constans/constants";
import Toltip from "../../components/common/toltip/page";
import { APP_ROUTES } from "../../utils/routes/AppRoutes";
import storage from "../../utils/storage";

const ChoosePostType: FC = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<string>(
    APP_ROUTES.ADD_HOURLY_CONTRACT
  );
  const [isTooltipActive, setIsTooltipActive] = useState<boolean>(false);
  const onInputChange = (step: string): void => setActiveStep(step);
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: string]: boolean;
  }>({
    hourlyContract: false,
    directJob: false,
    paidAd: false,
    socialPost: false,
  });

  const goToNextStep = (): void => {
    if (activeStep === APP_ROUTES.ADD_HOURLY_CONTRACT || activeStep === APP_ROUTES.ADD_DIRECT_JOBS) {
      storage.clearSavedContract();
    }
    navigate(activeStep);
  };

  const handleGoBack = () => {
    navigate(APP_ROUTES.DASHBOARD);
  };

  const isActive = (step: string) => activeStep === step;

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isTooltipIcon = target.closest(".tooltip-icon");
    const isTooltipContent = target.closest(".tooltip-content");
    const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line
  
    if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
      setTooltipVisibility({
        hourlyContract: false,
        directJob: false,
        paidAd: false,
        socialPost: false,
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  const handleIconClick = (tooltipKey: string) => {
    setTooltipVisibility(prev => ({
      hourlyContract: false,
      directJob: false,
      paidAd: false,
      socialPost: false,
      [tooltipKey]: !prev[tooltipKey]
    }));
  };

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div className={styles.registerLeft}>
                  <div className="text-center col-lg-11 col-12 mx-auto">
                    <figure className="mb-0">
                      <img
                        src={choosetype}
                        alt="Choose Type"
                        className="img-fluid w-100"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <h4 className="text-white text-32 fw-semibold font-inter">
                  What opportunity do you want to post?
                </h4>
                <p className="text-white text-14 opacity-75">
                  Choose from Post a job, Post an hourly contract, post
                  an ad or Social Media Post.
                </p>
                <div className={styles.signUPContainer}>
                  <div className={styles.registerRight}>
                    <ul
                      className={`customRadio row align-items-start ${styles.radioBTn}`}
                    >
                      {/* Hourly Contract */}
                      <li
                        className={`col-lg-6 mb-4 ${isActive(APP_ROUTES.ADD_HOURLY_CONTRACT)
                          ? styles.active
                          : ""
                          }`}
                      >
                        <div className="row align-items-center py-4 g-0">
                          <div className="col-2 text-center">
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id={
                                  CONSTANT_MANAGER.ADD_CONTRACT.HOURLY_CONTRACT
                                }
                                onChange={() =>
                                  onInputChange(APP_ROUTES.ADD_HOURLY_CONTRACT)
                                }
                                checked={isActive(
                                  APP_ROUTES.ADD_HOURLY_CONTRACT
                                )}
                              />
                            </label>
                          </div>
                          <div className="col-10">
                            <div className="topTipHover w-100">
                              <div className="d-flex align-items-center mb-2">
                                <h4 className="form-check-label text-14 fw-bold font-inter mb-0">
                                  Hourly Contract
                                </h4>
                                <i onClick={() => handleIconClick("hourlyContract")} className="tooltip-icon">
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt="Tooltip"
                                  />
                                </i>
                                <Toltip tooltipClass={tooltipVisibility.hourlyContract ? "activetooltip" : ""}
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT
                                      .HOURLY_CONTRACT
                                  }
                                />
                              </div>
                            </div>
                            <p
                              className="text-12 fw-normal mb-0"
                              style={{ zIndex: "0" }}
                            >
                              You want to pay a professional by the hour.
                            </p>
                          </div>
                        </div>
                      </li>

                      {/* Direct Job */}
                      <li
                        className={`col-lg-6 mb-4 ${isActive(APP_ROUTES.ADD_DIRECT_JOBS)
                          ? styles.active
                          : ""
                          }`}
                      >
                        <div className="row align-items-center py-4 g-0">
                          <div className="col-2 text-center">
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id={
                                  CONSTANT_MANAGER.ADD_CONTRACT.DIRECT_JOB
                                }
                                onChange={() =>
                                  onInputChange(APP_ROUTES.ADD_DIRECT_JOBS)
                                }
                                checked={isActive(APP_ROUTES.ADD_DIRECT_JOBS)}
                              />
                            </label>
                          </div>
                          <div className="col-10">
                            <div className="topTipHover w-100">
                              <div className="d-flex align-items-center mb-2">
                                <h4 className="form-check-label text-14 fw-bold font-inter mb-0">
                                  Direct Job
                                </h4>
                                <i onClick={() => handleIconClick("directJob")} className="tooltip-icon">
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt="Tooltip"
                                  />
                                </i>
                                <Toltip tooltipClass={tooltipVisibility.directJob ? "activetooltip" : ""}
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT.DIRECT_JOB
                                  }
                                />
                              </div>
                            </div>
                            <p
                              className="text-12 fw-normal mb-0"
                              style={{ zIndex: "0" }}
                            >
                              I am looking to hire a direct<br /> employee.
                            </p>
                          </div>
                        </div>
                      </li>
                      {/* Paid Ad */}
                      <li
                        className={`col-lg-6 mb-4 ${activeStep === APP_ROUTES.ADD_PAID_ADS.PAID_AD_ROOT ? styles.active : ""
                          }`}
                      >
                        <div className="row align-items-center py-4 g-0">
                          <div className="col-2 text-center">
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id={CONSTANT_MANAGER.ADD_CONTRACT.DIRECT_JOB}
                                onChange={() => onInputChange(APP_ROUTES.ADD_PAID_ADS.PAID_AD_ROOT)}
                                checked={activeStep === APP_ROUTES.ADD_PAID_ADS.PAID_AD_ROOT}
                              />
                            </label>
                          </div>
                          <div className="col-10">
                            <div className="topTipHover w-100">
                              <div className="d-flex align-items-center mb-2">
                                <h4 className="form-check-label text-14 fw-bold font-inter mb-0">
                                  Paid Ad
                                </h4>
                                <i onClick={() => handleIconClick("paidAd")} className="tooltip-icon">
                                  <img className="customeTolImg" src={tip} alt="" />
                                </i>
                                <Toltip tooltipClass={tooltipVisibility.paidAd ? "activetooltip" : ""}
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={CONSTANT_MANAGER.ADD_CONTRACT.POST_YOUR_AD}
                                />
                              </div>
                            </div>
                            <p
                              className="text-12 fw-normal mb-0"
                              style={{ zIndex: "0" }}
                            >
                              Post an ad for your companies
                              <br /> products or services
                            </p>
                          </div>
                        </div>
                      </li>

                      {/* Social Post For Feed */}
                      <li
                        className={`col-lg-6 mb-4 ${activeStep === APP_ROUTES.ADD_SOCIAL_POST ? styles.active : ""
                          }`}
                      >
                        <div className="row align-items-center py-4 g-0">
                          <div className="col-2 text-center">
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id={
                                  CONSTANT_MANAGER.ADD_CONTRACT.SOCIAL_POST_FOR_FEED.DATA
                                    .SOCIAL_POST_FOR_FEED
                                }
                                onChange={() => onInputChange(APP_ROUTES.ADD_SOCIAL_POST)}
                                checked={activeStep === APP_ROUTES.ADD_SOCIAL_POST}
                              />
                            </label>
                          </div>
                          <div className="col-10">
                            <div className="topTipHover w-100">
                              <div className="d-flex align-items-center mb-2">
                                <h4 className="form-check-label text-14 fw-bold font-inter">
                                  Social Post For Feed
                                </h4>
                                <i onClick={() => handleIconClick('socialPost')} className="tooltip-icon">
                                  <img className="customeTolImg" src={tip} alt="" />
                                </i>
                                <Toltip tooltipClass={tooltipVisibility.socialPost ? "activetooltip" : ""}
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT.SOCIAL_POST_FOR_FEED.DATA
                                      .SOCIAL_POST_FOR_FEED
                                  }
                                />
                              </div>
                            </div>
                            <p
                              className="text-12 fw-normal mb-0"
                              style={{ zIndex: "0" }}
                            >
                              Just like Facebook posts! Keep
                              <br /> them professional
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row align-items-center justify-content-bewteen g-0">
                  <div className="col-6 mb-3 btn-hover-effect">
                    <h6
                      onClick={handleGoBack}
                      className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                    >
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="Back Arrow" />
                      </i>
                      <span className="px-lg-4 px-2 text-12 fw-semibold">
                        Back
                      </span>
                    </h6>
                  </div>
                  <div className="col-6 text-end mb-3 btn-hover-effect">
                    <h6
                      onClick={goToNextStep}
                      className="btn arrowblue border-grey border-1 position-relative pe-5"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold">
                        Continue
                      </span>
                      <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                        <img src={whitearrow} alt="Next Arrow" />
                      </i>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ChoosePostType;
