
import { ContractResponse } from '../components/common/Contracts/interface';
import { IOption } from '../Interfaces';
import { ISaveContractApiRequestBody } from '../Interfaces/SaveContractApiRequestBody';
import { ADD_CONTRACT_STEPS } from '../shared/enums';
import { WORK_STATUS_TYPE } from '../utils/enums/status';
import { PLAN_TYPE } from '../utils/enums/types';

export interface ChooseOccupation {
  primaryOccupation?: IOption;
  secondaryOccupations: IOption[];
  ids?: string[]
}

export interface Durations {
  startDate: Date | any;
  endDate: Date | any;
  duration: number | string;
}

export interface KeyInformation {
  noOfPeople: number;
  shift: string;
  plannedHoursPerDay: number;
  plannedDaysPerWeek: number;
}

export interface Location {
  selectedCountry: IOption;
  selectedStreet: IOption;
  selectedState: IOption;
  selectedCity: IOption;
  selectedZipcode: IOption;
  isFilled: boolean | undefined;

  // for UI only
  countries: IOption[];
  states: IOption[];
  cities: IOption[];
  streets: IOption[];
  zipcode: IOption[];
}

export interface Rates {
  paymentTerms: IOption;
  baseRate: number | string;
  otRate: number | string;
  dtRate: number | string;

  // for UI only
  paymentTermsList: IOption[];
}

export interface WorkLocation {
  workLocation: string;
}
export interface TravelData {
  isTravelRequired: boolean;
  travelTimeRate?: number;
  travelTimeReceipt?: boolean;
  travelTimeCustomerSupp?: boolean;
  travelTipAllowedPerMonth?: number;
  tripReceipt?: boolean;
  tripCustomerSupp?: boolean;
  travelHoursPerRoundTrip?: number;
  travelHrsReceipt?: boolean;
  travelHrsCustomerSup?: boolean;
}
export interface ExpenseData {
  isExpenseReimbursed: boolean;
  dailyPerDiem?: number;
  dailyReceipt?: boolean;
  dailyCustomerSup?: boolean;
  travelDayPerDiem?: number;
  travelReceipt?: boolean;
  travelCustomerSup?: boolean;
  hotelAllowance?: number;
  hotelReceipt?: boolean;
  hotelCustomerSup?: boolean;
  rentalCarAllowance?: number;
  carReceipt?: boolean;
  carCustomerSup?: boolean;
  fuel?: number;
  fuelReceipt?: boolean;
  fuelCustomerSup?: boolean;
  airFareAllowance?: number;
  airFareReceipt?: boolean;
  airFareCustSup?: boolean;
  mileage?: number;
  mileageReceipt?: boolean;
  mileageCustomerSup?: boolean;
  luggage?: number;
  luggageReceipt?: boolean;
  luggageCustomerSup?: boolean;
}
export interface CustomerDetails {
  sameAsCustomer: boolean;
  primaryName: string;
  primaryEmail: string;
  primaryPhoneNumber: string;
  secondaryName?: string;
  secondaryEmail?: string;
  secondaryPhoneNumber?: string;
}

export interface LegalDetails {
  isAutomateAmerica?: boolean;
  isAnonymous?: boolean;
  isVisa?: boolean;
  isLegalResidence?: boolean;
}

export interface TechnicalSkill {
  category: IOption;
  subCategory: IOption[];
  isRequired: boolean;
  subCategoriesList: IOption[];
}

export interface TechnicalSkillsData {
  skillTypes: IOption[];
  skills: TechnicalSkill[];
}
export interface DrugTest {
  isDrugTestRequired: boolean;
  drugRegacy: string;
}
export interface BackgroundCheck {
  isBackgroundCheckRequired: boolean;
  backgroundRegecy: string;
}

export interface SoftwareLicenseDetails {
  softwareLicenses: IOption[];
  isRequired: boolean;
  options: IOption[];
}

export interface proceduresAndPolicies {
  proceduresAndPolicies: string;
  // file?: File;
}
export interface EligibilityAnswers {
  Answers: boolean;
  questionId: string;
}
export interface InterviewAnswers {
  Answers: string;
  questionId: string;
}
export class AddContractRootState {
  _id?: string | null = null;
  started: boolean = false;
  contractStatus: WORK_STATUS_TYPE = WORK_STATUS_TYPE.PENDING;
  activeStep: ADD_CONTRACT_STEPS = ADD_CONTRACT_STEPS.CHOOSE_OCCUPATION;
  contractType: string = 'hourly_contract';
  Plantype: PLAN_TYPE = PLAN_TYPE.WHITEGLOVE;
  occupationsList: IOption[] = [];
  occupation: ChooseOccupation = {
    primaryOccupation: { label: '', value: '' },
    secondaryOccupations: [],
    ids: []
  };
  workDescription: string = '';
  durations: Durations = {
    startDate: new Date(),
    endDate: new Date(),
    duration: 0,
  };
  keyInformation: KeyInformation = {
    noOfPeople: 0,
    shift: '',
    plannedHoursPerDay: 0,
    plannedDaysPerWeek: 0,
  };
  location: Location = {
    selectedCountry: { label: '', value: '' },
    selectedCity: { label: '', value: '' },
    selectedState: { label: '', value: '' },
    selectedStreet: { label: '', value: '' },
    selectedZipcode: { label: '', value: '' },
    isFilled: false,
    cities: [],
    countries: [],
    states: [],
    streets: [],
    zipcode: [],
  };
  rates: Rates = {
    paymentTerms: { label: '', value: '' },
    baseRate: '',
    dtRate: '',
    otRate: '',
    paymentTermsList: [],
  };
  travelData: TravelData = {
    isTravelRequired: false,
    travelTimeReceipt: false,
    travelTimeCustomerSupp: false,
    tripReceipt: false,
    tripCustomerSupp: false,
    travelHrsReceipt: false,
    travelHrsCustomerSup: false,
  };
  expenseData: ExpenseData = {
    isExpenseReimbursed: false,
    travelReceipt: false,
    travelCustomerSup: false,
    dailyReceipt: false,
    dailyCustomerSup: false,
    hotelReceipt: false,
    hotelCustomerSup: false,
    carReceipt: false,
    carCustomerSup: false,
    fuelReceipt: false,
    fuelCustomerSup: false,
    airFareReceipt: false,
    airFareCustSup: false,
    mileageReceipt: false,
    mileageCustomerSup: false,
    luggageReceipt: false,
    luggageCustomerSup: false,
  };
  customerDetails: CustomerDetails = {
    sameAsCustomer: false,
    primaryName: '',
    primaryEmail: '',
    primaryPhoneNumber: '',
  };
  legalDetails: LegalDetails = {};
  technicalSkillsData: TechnicalSkillsData = {
    skillTypes: [],
    skills: [
      {
        subCategory: [],
        isRequired: false,
        category: { label: '', value: '' },
        subCategoriesList: [],
      },
    ],
  };
  drugTest: DrugTest = {
    isDrugTestRequired: false,
    drugRegacy: '',
  };
  backgroundCheck: BackgroundCheck = {
    isBackgroundCheckRequired: false,
    backgroundRegecy: '',
  };
  workLocation: WorkLocation = {
    workLocation: '',
  };
  eligibilityQuestions: string[] = [''];
  interviewQuestions: string[] = [''];

  softwareLicenseDetails: SoftwareLicenseDetails = {
    isRequired: false,
    softwareLicenses: [],
    options: [],
  };
  proceduresAndPolicies: proceduresAndPolicies = { proceduresAndPolicies: '' };

  constructor(props?: Partial<AddContractRootState>) {
    Object.assign(this, props);
  }

  // Mapping UI model to Backend Model
  public serialize(): ISaveContractApiRequestBody {
    return {
      workType: this.contractType,
      description: this.workDescription,
      countryId: this.location.selectedCountry?.value,
      state: this.location.selectedState?.label,
      city: this.location.selectedCity?.label,
      workStatus: this.contractStatus,
      planType: this.Plantype,
      occupationIds: this.occupation.primaryOccupation
        ? [this.occupation.primaryOccupation.value]
        : [],
      secondaryOccupationIds: this.occupation.secondaryOccupations?.map(
        (x) => x.value
      ),
      technicalSkills: this.technicalSkillsData.skills?.map((x) => ({
        manufacturerIds: x.category.value,
        isGoodToHave: !x.isRequired,
        isMandatory: x.isRequired,
        manufacturerModelIds:
          x.subCategoriesList.length > 0 ? x.subCategoriesList[0].value : '',
      })),
      eligibilityQuestions: this.eligibilityQuestions?.map((x) => ({
        question: x,
      })),
      interviewQuestions: this.interviewQuestions?.map((x) => ({
        question: x,
      })),
      times: {
        startDate: this.durations.startDate
          ? new Date(this.durations.startDate).toISOString()
          : '',
        endDate: this.durations.endDate
          ? new Date(this.durations.endDate).toISOString()
          : '',
        duration: this.durations.duration.toString(),
        noOfPepole: this.keyInformation.noOfPeople,
        plannedDaysPerWeek: this.keyInformation.plannedDaysPerWeek,
        plannedHoursPerDay: this.keyInformation.plannedHoursPerDay,
        shift: this.keyInformation.shift,
      },
      hourlyPaymentTermId: this.rates.paymentTerms.value,
      rates: {
        baseRate: this.rates.baseRate,
        overTimeRate: this.rates.otRate,
        doubleTimeRate: this.rates.dtRate,
      },
      travelForJob: this.travelData.isTravelRequired,
      travels: {
        travelTimeRate: String(
          this.travelData.travelTimeRate ? this.travelData.travelTimeRate : ''
        ),
        travelTimeReceipt: Boolean(this?.travelData?.travelTimeReceipt ? this?.travelData?.travelTimeReceipt : false),
        travelTimeCustomerSupp: Boolean(this?.travelData?.travelTimeCustomerSupp ? this?.travelData?.travelTimeCustomerSupp : false),
        travelTipAllowedPerMonth: String(
          this.travelData.travelTipAllowedPerMonth
            ? this.travelData.travelTipAllowedPerMonth
            : ''
        ),
        tripReceipt: Boolean(this?.travelData?.tripReceipt ? this.travelData?.tripReceipt : false),
        tripCustomerSupp: Boolean(this?.travelData.tripCustomerSupp ? this.travelData?.tripCustomerSupp : false),
        travelHoursPerRoundTrip: String(
          this.travelData.travelHoursPerRoundTrip
            ? this.travelData.travelHoursPerRoundTrip
            : ''
        ),
        travelHrsReceipt: Boolean(this?.travelData?.travelHrsReceipt ? this.travelData?.travelHrsReceipt : false),
        travelHrsCustomerSup: Boolean(this?.travelData?.travelHrsCustomerSup ? this?.travelData?.travelHrsCustomerSup : false),
      },
      location: {
        type: 'Point',
        coordinates: [0, 0],
      },
      expenseReimbursed: this.expenseData.isExpenseReimbursed,
      expense: {
        dailyPerDiem: Number(this.expenseData.dailyPerDiem),
        dailyReceipt: Boolean(this?.expenseData?.travelReceipt ? this.expenseData?.travelReceipt : false),
        dailyCustomerSup: Boolean(this?.expenseData?.travelCustomerSup ? this.expenseData?.travelCustomerSup : false),
        travelDayPerDiem: Number(this.expenseData.travelDayPerDiem),
        travelReceipt: Boolean(this.expenseData?.travelReceipt ? this.expenseData?.travelReceipt : false),
        travelCustomerSup: Boolean(this.expenseData?.travelCustomerSup ? this.expenseData?.travelCustomerSup : false),
        hotelAllowance: Number(this.expenseData.hotelAllowance),
        hotelReceipt: Boolean(this.expenseData?.hotelReceipt ? this.expenseData?.hotelReceipt : false),
        hotelCustomerSup: Boolean(this.expenseData?.hotelCustomerSup ? this.expenseData?.hotelCustomerSup : false),
        rentalCarAllowance: Number(this.expenseData.rentalCarAllowance),
        carReceipt: Boolean(this.expenseData?.carReceipt ? this.expenseData?.carReceipt : false),
        carCustomerSup: Boolean(this.expenseData?.carCustomerSup ? this.expenseData?.carCustomerSup : false),
        fuel: Number(this.expenseData.fuel),
        fuelReceipt: Boolean(this.expenseData?.fuelReceipt ? this.expenseData?.fuelReceipt : false),
        fuelCustomerSup: Boolean(this.expenseData?.fuelCustomerSup ? this.expenseData?.fuelCustomerSup : false),
        airFareAllowance: Number(this.expenseData.airFareAllowance),
        airFareReceipt: Boolean(this.expenseData?.airFareReceipt ? this.expenseData?.airFareReceipt : false),
        airFareCustSup: Boolean(this.expenseData?.airFareCustSup ? this.expenseData?.airFareCustSup : false),
        mileage: Number(this.expenseData.mileage),
        mileageReceipt: Boolean(this.expenseData?.mileageReceipt ? this.expenseData?.mileageReceipt : false),
        mileageCustomerSup: Boolean(this.expenseData?.mileageCustomerSup ? this.expenseData?.mileageCustomerSup : false),
        luggage: Number(this.expenseData?.luggage),
        luggageReceipt: Boolean(this.expenseData?.luggageReceipt ? this.expenseData?.luggageReceipt : false),
        luggageCustomerSup: Boolean(this.expenseData?.luggageCustomerSup ? this.expenseData?.luggageCustomerSup : false),
      },
      isSameCustomer: this.customerDetails.sameAsCustomer,
      primaryContact: {
        name: String(this.customerDetails.primaryName),
        email: String(this.customerDetails.primaryEmail),
        mobileNumber: String(this.customerDetails.primaryPhoneNumber),
      },
      secondaryContact: {
        name: String(this.customerDetails.secondaryName),
        email: String(this.customerDetails.secondaryEmail),
        mobileNumber: String(this.customerDetails.secondaryPhoneNumber),
      },
      screeningInfo: {
        drugTest: this.drugTest.isDrugTestRequired,
        drugTestRecency: this.drugTest.drugRegacy,
        backgroundCheck: this.backgroundCheck.isBackgroundCheckRequired,
        backgroundCheckRecency: this.backgroundCheck.backgroundRegecy,
      },
      payForHotel: true,
      payForRentalCar: true,
      payForAirfare: false,
      usLegalResidents: Boolean(this.legalDetails.isLegalResidence),
      isAutomateAmerica: Boolean(this.legalDetails.isAutomateAmerica),
      isAnonymous: Boolean(this.legalDetails.isAnonymous),
      visaAllow: Boolean(this.legalDetails.isVisa),
      proceduresAndPolicies: {
        description: this.proceduresAndPolicies.proceduresAndPolicies,
        // file: '',
      },
      workLocation: this.workLocation.workLocation,
      softwareLicence: this.softwareLicenseDetails.softwareLicenses?.map(
        (x) => ({
          softwareLicenceId: x.value,
          isMandatory: true,
          isGoodToHave: false,
        })
      ),
    };
  }

  public static deserialize(apiData?: ContractResponse) {
    if (!apiData) {
      return new AddContractRootState();
    }

    return new AddContractRootState({
      ...apiData,
      contractType: apiData.workType,
      workDescription: apiData.description,
      occupation: {
        secondaryOccupations: [],
        primaryOccupation: {
          label: apiData?.occupation?.name,
          value: apiData?.occupation?._id,
        },
        ids: []
      },
      location: {
        selectedCountry: {
          label: apiData?.country?.name,
          value: apiData?.country?._id,
        },
        selectedCity: { label: apiData.city, value: apiData.city },
        selectedState: { label: apiData.state, value: apiData.state },
        selectedStreet: {
          label: apiData.street || '', value: apiData.street || '',
        },
        selectedZipcode: { label: apiData.zipcode, value: apiData.zipcode },
        isFilled: apiData.isFilled,
        cities: [],
        states: [],
        streets: [],
        countries: [],
        zipcode: [],
      },
      rates: {
        baseRate: apiData.rates.baseRate,
        dtRate: apiData.rates.doubleTimeRate,
        otRate: apiData.rates.overTimeRate,
        paymentTerms: {
          label: apiData.hourlyPaymentTermId,
          value: apiData.hourlyPaymentTermId,
        },
        paymentTermsList: [],
      },
      durations: {
        startDate: apiData.times.startDate,
        endDate: apiData.times.endDate,
        duration: apiData.times.duration,
      },
      keyInformation: {
        noOfPeople: apiData.times.noOfPepole,
        plannedDaysPerWeek: apiData.times.plannedDaysPerWeek,
        plannedHoursPerDay: apiData.times.plannedHoursPerDay,
        shift: apiData.times.shift,
      },
      eligibilityQuestions: apiData.eligibilityQuestions
        ? apiData.eligibilityQuestions?.map((x) => x.question)
        : [],
      interviewQuestions: apiData.interviewQuestions
        ? apiData.interviewQuestions?.map((x) => x.question)
        : [],
      workLocation: {
        workLocation: apiData.workLocation,
      },
      proceduresAndPolicies: {
        proceduresAndPolicies: apiData.proceduresAndPolicies?.description || '',
      },
      customerDetails: {
        primaryName: apiData.primaryContact?.name || '',
        primaryEmail: apiData.primaryContact?.email || '',
        primaryPhoneNumber: apiData.primaryContact?.mobileNumber || '',
        sameAsCustomer: apiData.isSameCustomer || false,
        secondaryName: apiData.secondaryContact?.name,
        secondaryPhoneNumber: apiData.secondaryContact?.mobileNumber,
        secondaryEmail: apiData.secondaryContact?.email,
      }
    });
  }
}
