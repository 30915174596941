import React, { useEffect, useState } from 'react';
import styles from '../../signup/styles/sigup.module.css';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import left from '../../../../assets/images/left.png';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import whitearrow from '../../../../assets/images/whitearrow.svg';
import HeaderContent from '../../../../shared/Components/HeaderContent/headerContent';
import { APP_ROUTES } from '../../../../utils/routes/AppRoutes';
import { toast } from 'react-toastify';
import { resendOtpLogin, verifyOTPForLogin } from '../api';
import { MESSAGES } from '../../../../utils/messages';
import { authenticateUser } from '../../../../redux/reducers/authentication';
import storage from '../../../../utils/storage';
import { verifyOTPSchema } from '../../signup/validations';
import { APIResponseError } from '../interface';

const LoginVerifications: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const [isTimerActive, setIsTimerActive] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(30);
  const userName = searchParams.get('userName');
  const verificationToken = searchParams.get('verificationToken');

  const verifyLoginFormik = useFormik({
    initialValues: { verificationCode: '' },
    validationSchema: verifyOTPSchema,
    onSubmit: (values) => {
      verifyOtp(values.verificationCode);
    },
  });

  const verifyOtp = (verificationCode: string): void => {
    toast.promise(
      verifyOTPForLogin(verificationCode, userName!, verificationToken!),
      {
        pending: {
          render() {
            return MESSAGES.LOGIN.VERIFY;
          },
        },
        error: {
          render(error) {
            let errorMesage = error as APIResponseError;
            return errorMesage?.data?.data?.message;
          },
        },
      }
    ).then((response) => {
      if (response?.data) {
        storage.setToken(response.data.authToken);
        storage.setAuthUserId(response.data.userId);
        verifyLoginFormik.resetForm();
        dispatch(
          authenticateUser({
            ...response.data,
            isLoading: false,
            token: response.data.authToken,
          })
        );
        
        const incomingRedirection = searchParams.get('redirectTo');
        const incomingActiveStep = searchParams.get('activeStep');
        const redirectToPath = incomingRedirection
          ? `${incomingRedirection}?activeStep=${incomingActiveStep}`
          : APP_ROUTES.FEEDS;
        navigate(redirectToPath);
      }
    });
  };
  

  // const verifyOtp = (verificationCode: string): void => {
  //   toast.promise(
  //     verifyOTPForLogin(verificationCode, userName!, verificationToken!),
  //     {
  //       pending: {
  //         render() {
  //           return MESSAGES.LOGIN.VERIFY;
  //         },
  //       },
  //       success: {
  //         render({ data }) {
  //           let response = data;
  //           storage.setToken(response?.data?.authToken);
  //           storage.setAuthUserId(response?.data?.userId);
  //           verifyLoginFormik.resetForm();
  //           dispatch(
  //             authenticateUser({
  //               ...response.data,
  //               isLoading: false,
  //               token: response.data.authToken,
              
  //             })
  //           );
  //           const incomingRedirection = searchParams.get('redirectTo');
  //           const incomingActiveStep = searchParams.get('activeStep');
  //           const redirectToPath = incomingRedirection
  //             ? `${incomingRedirection}?activeStep=${incomingActiveStep}`
  //             : APP_ROUTES.FEEDS;
  //           navigate(redirectToPath);
  //           return response.message;
  //         },
  //       },
  //       error: {
  //         render(error) {
  //           let errorMesage = error as APIResponseError;
  //           return errorMesage?.data?.data?.message;
  //         },
  //       },
  //     }
  //   );
  // };

  const handleResendotp = () => {
    toast.promise(resendOtpLogin(userName!, verificationToken!), {
      pending: {
        render() {
          return MESSAGES.LOGIN.VERIFY;
        },
      },
      success: {
        render() {
          setIsTimerActive(true);
          setTimer(30);
          return MESSAGES.SENDOTP.SUCCESS;
        },
      },
      error: {
        render(error) {
          let errorMessage = error as APIResponseError;
          toast.error(errorMessage.data.message);
          return MESSAGES.LOGIN.INVALID;
        },
      },
    });
  };

  useEffect(() => {
    if (isTimerActive) {
      if (timer > 0) {
        const interval = setInterval(() => {
          setTimer((prev) => prev - 1);
        }, 1000);

        return () => clearInterval(interval);
      } else {
        setIsTimerActive(false);
      }
    }
  }, [isTimerActive, timer]);
  // const incomingRedirection = searchParams.get('redirectTo');
  // const incomingActiveStep = searchParams.get('activeStep');
  // console.log(incomingRedirection, 'incomingRedirection');
  // console.log(incomingActiveStep, 'incomingActiveStep');

  return (
    <>
      <HeaderContent />
      <div className="bgbluegradient">
        <div className={styles.signuppage}>
          <div className={styles.signUPContainer}>
            <form onSubmit={verifyLoginFormik.handleSubmit}>
              <h5>OTP Verification</h5>
              <span className={styles.Leftntm}>
                <a href="#">
                  <img src={left} alt="" onClick={() => navigate(-1)} />
                </a>
              </span>
              <p> An OTP will be sent to entered number for verification</p>
              <div className={styles.fromGroup}>
                <label>Enter OTP</label>
                <div className={styles.otpFields}>
                  <OtpInput inputType='number'
                    value={verifyLoginFormik.values.verificationCode}
                    onChange={(otp) =>
                      verifyLoginFormik.setFieldValue('verificationCode', otp)
                    }
                    numInputs={6}
                    renderInput={(props) => <input {...props} />}
                  />
                  {verifyLoginFormik.touched.verificationCode &&
                    verifyLoginFormik.errors.verificationCode && (
                      <div className={styles.error}>
                        {verifyLoginFormik.errors.verificationCode}
                      </div>
                    )}
                </div>
                <h6 className="flex-column">
                  Didn’t get the OTP?{' '}
                  <h5>
                    {isTimerActive ? (
                      <p className="mb-2">
                        Resend available in {timer} seconds
                      </p>
                    ) : (
                      <p
                        onClick={handleResendotp}
                        className="mb-2 text-blue"
                        style={{ cursor: 'pointer' }}
                      >
                        Resend OTP
                      </p>
                    )}
                    {/* <a href="#">
                    <img src={emchat} alt="Email Chat" />
                  </a>{" "}
                  <a href="#">
                    <img src={mschat} alt="Message Chat" />
                  </a> */}
                  </h5>
                </h6>
              </div>
              <div className="col-lg-3 col-6 mx-auto">
                <div className="text-center my-3 btn-hover-effect border rounded-3">
                  <button
                    type="submit"
                    className="btn arrowblue border-grey border-1 position-relative w-100 pe-5 text-center"
                  >
                    <span className="px-lg-4 px-2 text-12 fw-semibold w-100">
                      Next
                    </span>
                    <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                      <img src={whitearrow} alt="" />
                    </i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default LoginVerifications;
