import React, { useCallback, useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../../assets/images/penbl.png";
import tip from "../../../../assets/images/tip.png";
import EditServicesModal from "../modals/EditServicesModal";
import Toltip from "../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";

const ServiceOffered = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [tooltipVisibility, setTooltipVisibility] = useState<{
      [key: string]: boolean;
    }>({
      services: false
    })
  
    const handleIconClick = (tooltipKey: string) => {
      setTooltipVisibility(prev => ({
        services: false,
        [tooltipKey]: !prev[tooltipKey]
      }));
    };
    const handleClickOutside = useCallback((event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const isTooltipIcon = target.closest(".tooltip-icon");
      const isTooltipContent = target.closest(".tooltip-content");
      const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line
  
      if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
        setTooltipVisibility({
          services: false
        });
      }
    }, []);
    useEffect(() => {
      document.addEventListener('click', handleClickOutside);
      return () => document.removeEventListener('click', handleClickOutside);
    }, [handleClickOutside])
  const openModal = () => {
    // setShowModal(true);
  }
  const closeModal = () => { setShowModal(false)};
  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-10">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">
                Services Offered
              </h4>
              <i onClick={() => handleIconClick("services")} className="tooltip-icon">
                  <img
                    className="customeTolImg"
                    src={tip}
                    alt="Tooltip"
                  />
                </i>
                <Toltip tooltipClass={tooltipVisibility.services ? "activetooltip" : ""}
                  slug={CONSTANT_MANAGER.SLUG.EDIT_PROFILE}
                  labelId={
                    CONSTANT_MANAGER.EDIT_PROFILE.SERVICES_OFFERED
                  }
                />
            </div>
          </div>
        </div>
        <div className="col-2 text-end">
          <span>
            <img src={penbl} className="cursor-pointer ms-auto" alt="" onClick={openModal} />
          </span>
        </div>
      </div>
      <div className="mt-2">
        <h4 className="text-14 fw-semibold text-blue cursor-pointer">+ Add more</h4>
      </div>
      <EditServicesModal showModal={showModal} closeModal={closeModal} title="Edit Service Offered"/>
    </div>
  );
};

export default ServiceOffered;
