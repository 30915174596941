import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "../EditProfile/styles/style.module.css";
import Header from "../../header/routes";
import Editprofile from "../EditProfile/Editprofile";
import ConnectionPlan from "../Connections/ConnectionPlan";
import { logout } from "../../../feature/auth/login/api";
import { toast } from "react-toastify";
import storage from "../../../utils/storage";
import { APP_ROUTES } from "../../../utils/routes/AppRoutes";
import { deactivateAccount, getuserProfile } from "../../home/api";
import Swal from "sweetalert2";
import trash from "../../../assets/images/trash.png";
import CustomTimesheetTable from "../../common/timesheets/table";

const EditProfileMain = () => {
  const navigate = useNavigate();
  const {tab} = useParams();
  const [selectedComponent, setSelectedComponent] = useState<string>(tab || "Profile");
  const hideHeaderPaths = ["/newBusiness/businessGroup", "/home"];
  const showHeader = !hideHeaderPaths.includes(location.pathname);
  const [lastCreateTimeSheet, setLastCreateTimeSheet] = useState<any>("");
const [currentOngoingData, setCurrentOngoingData] = useState<any>([])
 const handleAddTimesheet = (workId?: any) => {
    // navigate(`${APP_ROUTES.ADDTIMESHEET}/${workId}`, {
    //   state: { activeStatus, currentOngoingData },
    // })
  };
  const [statusFilter, setStatusFilter] = useState<string>("all"); // Default filter

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedFilter = event.target.value;
    setStatusFilter(selectedFilter);
    // refreshdata(TIMESHEET_OR_EXPENSE_TYPE.TIMESHEET, id as string, selectedFilter);
  };

  const [loader, setLoader] = useState<boolean>(false);
  const [timesheetData, setTimeSheetData] = useState<any>();
  const handleViewStatusDetails = (timesheetId: string) => {
    // navigate(`${APP_ROUTES.TIMESHEET_STATUS}/${timesheetId}`, {
    //   state: { activeStatus, currentOngoingData },
    // });
  };
  const handleEditTimesheet = (timesheetId: string) => {
    // navigate(`${APP_ROUTES.EDITTIMESHEET}/${id}/${timesheetId}`);
  };



  const handleDeactivateAccount = async () => {
    const payload = {
      status: false,
    };
    console.log(payload);

    const initialResult = await Swal.fire({
      title: "Are you sure?",
      text: "Are you sure to deactivate your account?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Deactivate!",
      cancelButtonText: "No, cancel",
    });
    if (initialResult.isConfirmed) {
      try {
        await deactivateAccount(payload);
        await getuserProfile()
        await Swal.fire({
          title: "Deactivation Done",
          text: "You can activate your account anytime",
          icon: "success",
          confirmButtonText: "OK",
        });
        
      } catch (err) {
        console.log("Error occuring while deactivating account", err);
      }
    }
  };

  const handleLogout = () => {
    logOutUser();
  };
  const logOutUser = (): void => {
    toast.promise(logout(), {
      pending: {
        render() {
          return "Logout user";
        },
      },
      success: {
        render() {
          storage.clearToken();
          storage.clearAuthUserId();
          storage.clearSavedContract();
          storage.clearProfieImg();
          // dispatch(logoutUser());
          navigate(APP_ROUTES.DASHBOARD);
          return "User Logged out successfully!";
        },
      },
      error: {
        render({ data }) {
          return (data as any)?.data?.message;
        },
      },
    });
  };

  const renderComponent = () => {
    switch (selectedComponent) {
      case "Profile":
        return <Editprofile />;
      case "Connections":
        return <ConnectionPlan />;
        case "Timesheet":
          return <CustomTimesheetTable loader={loader} timesheetData={timesheetData} handleEditTimesheet={handleEditTimesheet} handleViewStatusDetails={handleViewStatusDetails} statusFilter={statusFilter} handleFilterChange={handleFilterChange} />
          case "Expense":
            return <div>Expense Details</div>
      case "Invoices-Payment":
        return <div>Invoices Content</div>;
      case "Drafts Posts":
        return <div>Drafts Posts Content</div>;
      case "Rating & Feedback":
        return <div>Rating</div>;
      case "Contact us":
        return <div>Contact us Content</div>;
      case "Notification Settings":
        return <div>Notification Settings Content</div>;
      case "Logout":
        handleLogout();
        return <div>Logging out...</div>;
      default:
        return <div>Profile Content</div>;
    }
  };

  return (
    <>
      {showHeader && <Header />}
      <div className={`editprofilepage ${styles.EditProfieOuter}`}>
        <div className="container-fluid px-5">
          <div className={styles.EditProfieOuterBord}>
            <div className="row mx-0">
              <div className="col-lg-3 col-12 p-0">
                <div className={styles.LeftditPro}>
                  <h3 className="fw-semibold">My Profile</h3>
                  <ul>
                    <li>
                      <Link
                        to="#"
                        onClick={() => setSelectedComponent("Profile")}
                        className={
                          selectedComponent === "Profile"
                            ? styles.activeLink
                            : ""
                        }
                      >
                        Profile
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                         onClick={() => setSelectedComponent("Connections")}
                        className={
                          selectedComponent === "Connections"
                            ? styles.activeLink
                            : ""
                        }
                      >
                        Connections
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        onClick={() => setSelectedComponent("Timesheet")}
                        className={
                          selectedComponent === "Timesheet"
                            ? styles.activeLink
                            : ""
                        }
                      >
                        Timesheet
                      </Link>
                    </li>
                    <li>
                    <li>
                      <Link
                        to="#"
                        onClick={() => setSelectedComponent("Expense")}
                        className={
                          selectedComponent === "Expense"
                            ? styles.activeLink
                            : ""
                        }
                      >
                        Expense Report
                      </Link>
                    </li>
                      <Link
                        to="#"
                        onClick={() => setSelectedComponent("Invoices-Payment")}
                        className={
                          selectedComponent === "Invoices-Payment"
                            ? styles.activeLink
                            : ""
                        }
                      >
                        Invoices
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        onClick={() => setSelectedComponent("Drafts Posts")}
                        className={
                          selectedComponent === "Drafts Posts"
                            ? styles.activeLink
                            : ""
                        }
                      >
                         Posts
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        onClick={() =>
                          setSelectedComponent("Rating & Feedback")
                        }
                        className={
                          selectedComponent === "Rating & Feedback"
                            ? styles.activeLink
                            : ""
                        }
                      >
                        Reviews
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        onClick={() => setSelectedComponent("Contact us")}
                        className={
                          selectedComponent === "Contact us"
                            ? styles.activeLink
                            : ""
                        }
                      >
                        Contact us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        onClick={() =>
                          setSelectedComponent("Notification Settings")
                        }
                        className={
                          selectedComponent === "Notification Settings"
                            ? styles.activeLink
                            : ""
                        }
                      >
                        Notification Settings
                      </Link>
                    </li>
                        {/* <li>
                      <Link
                        to="#"
                        onClick={() =>
                          setSelectedComponent("Notification Settings")
                        }
                        className={
                          selectedComponent === "Notification Settings"
                            ? styles.activeLink
                            : ""
                        }
                      >
                        Notification Settings
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="#"
                        onClick={handleDeactivateAccount}
                      
                      >
                        <div>
                         <div className="text-danger">Deactivate Account</div></div>
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="#"
                        onClick={() => setSelectedComponent("Logout")}
                        className={
                          selectedComponent === "Logout"
                            ? styles.activeLink
                            : ""
                        }
                      >
                        Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-9 col-12 p-0">{renderComponent()}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfileMain;
