import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"; // Import default styles

const SkeletonCard = () => {
  return (
    <div className="col-xxl-3 col-lg-4 col-6">
      <div className="overflow-hidden border rounded-3 mb-3 p-3 bg-light">
        <div className="d-flex justify-content-center mb-3">
          <Skeleton circle height={80} width={80} />
        </div>
        <Skeleton height={20} width="80%" />
        <Skeleton height={15} width="90%" />
        <Skeleton height={15} width="75%" />
        <div className="mt-3">
          <Skeleton height={30} width="100%" />
        </div>
      </div>
    </div>
  );
};

export default SkeletonCard;
