import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import upload from "../../../../assets/images/upload.svg";
import blackarrow from "../../../../assets/images/blackarrow.svg";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import styles from "../styles/style.module.css";
import { uploadFile } from "../../../../common/api";
import { toast } from "react-toastify";
import { updateUserProfile } from "../../../home/api";
import { UpdateProfileParams } from "../../../home/interface";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  refresh: () => void;
  doc?: { name: string; filename: string; endDate: string };
}

const CertInsuranceModal: React.FC<ModalProps> = ({ isOpen, onClose, title, refresh, doc }) => {
  const [name, setName] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filename, setFileName] = useState<any>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false); // New state for upload status
  const [error, setError] = useState<{ name: string; file: string; date: string }>({
    name: "",
    file: "",
    date: "",
  });

  useEffect(() => {
    if (doc) {
      setName(doc.name || "");
      setEndDate(doc.endDate || "");
      setFileName(doc.filename || "");
    }
  }, [doc]);

  // Handle file upload
  const handleFileUpload = async (files: FileList | null) => {
    if (files) {
      const uploadedFile = files[0];

      // Validate file type and size
      if (uploadedFile.type !== "application/pdf") {
        setError((prev) => ({ ...prev, file: "Only PDF files are allowed." }));
        return;
      }
      if (uploadedFile.size > 5000000) {
        setError((prev) => ({
          ...prev,
          file: "File size is too large. Maximum allowed size is 5MB.",
        }));
        return;
      }

      setError((prev) => ({ ...prev, file: "" })); // Clear error if valid
      setIsUploading(true); // Start upload state

      const formData = new FormData();
      formData.append("file", uploadedFile);

      try {
        const response = await uploadFile(formData);
        setFileName(response.data.files); // Store the uploaded file URL
        toast.success("File uploaded successfully");
      } catch (err) {
        setError((prev) => ({
          ...prev,
          file: "Error occurred while uploading the file.",
        }));
      } finally {
        setIsUploading(false); // End upload state
      }
    }
  };

  // Handle save action
  const handleSave = async () => {
    let valid = true;

    // Validate Insurance Name
    if (!name.trim()) {
      setError((prev) => ({ ...prev, name: "Insurance Name is required." }));
      valid = false;
    } else {
      setError((prev) => ({ ...prev, name: "" }));
    }

    // Validate Expiry Date
    if (!endDate) {
      setError((prev) => ({ ...prev, date: "Expiry Date is required." }));
      valid = false;
    } else {
      setError((prev) => ({ ...prev, date: "" }));
    }

    // Validate File Upload
    if (!filename) {
      setError((prev) => ({ ...prev, file: "Insurance document is required." }));
      valid = false;
    } else {
      setError((prev) => ({ ...prev, file: "" }));
    }

    if (!valid) return;

    setIsSubmitting(true);

    // Construct payload
    const payload: UpdateProfileParams = {
      insuranceDoc: {
        name: name,
        filename: filename,
        endDate: endDate,
      },
    };

    try {
      await updateUserProfile(payload);
      toast.success("Insurance details saved successfully!");
      setIsSubmitting(false);
      onClose();
    } catch (error) {
      toast.error("Failed to save insurance details.");
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <Modal show={isOpen} onHide={onClose} centered size="lg" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <div className="addModalPro">
          <div className="row">
            <div className="col-12">
              <div className="formGroup">
                <label>Insurance Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Insurance Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {error.name && <p className="text-danger">{error.name}</p>}
              </div>
            </div>

            <div className="col-12">
              <div className="formGroup">
                <label>Expiry Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
                {error.date && <p className="text-danger">{error.date}</p>}
              </div>
            </div>

            <div className="col-12">
              <div className="formGroup">
                <label>Upload Document</label>
                <div className={styles.uploadimagefile}>
                  <input
                    type="file"
                    accept="application/pdf"
                    className="form-control"
                    onChange={({ target }) => handleFileUpload(target.files)}
                  />
                  <div className={styles.uploadimage}>
                    <i>
                      <img src={upload} alt="Upload Icon" />
                    </i>
                    <p className="mb-0 text-14 text-blue">Upload PDF file</p>
                  </div>
                </div>
                {error.file && <p className="text-danger">{error.file}</p>}
              </div>
            </div>

            {/* Display Uploaded File */}
            {filename && (
              <div className="col-12 mt-3">
                <div className="uploaded-resume p-3 border rounded d-flex align-items-center justify-content-between">
                  <div>
                    <p className="fw-semibold mb-1">Uploaded Document:</p>
                    <a href={filename} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-sm">
                      View / Download Document
                    </a>
                  </div>
                  <button type="button" className="btn btn-danger btn-sm ms-3" onClick={() => setFileName("")} title="Remove File">
                    ❌
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="btn-hover-effect mb-2 justify-content-end">
        <Button
          type="button"
          variant="secondary"
          onClick={handleSave}
          className="btn bg-white arrowblue border border-1 rounded-3 position-relative pe-5 w-auto"
          disabled={isSubmitting || isUploading} // Disable Save while uploading
        >
          <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
            {isUploading ? "Uploading..." : isSubmitting ? "Saving..." : "Save"}
          </span>
          <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
            <img src={whitearrow} alt="Save" width={15} height={15} />
          </i>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CertInsuranceModal;
