import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "../../../styles/style.module.css";
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useAppSelector } from "../../../../../redux/hooks";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setWorkDescription,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import Toltip from "../../../../common/toltip/page";
import tip from "../../../../../assets/images/tip.png";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import { descriptionSchema } from "../../../../work/validations";
import { SaveAsDraftButton } from "../../../../work/contractSteps/Components";
import JoditEditor from "jodit-react";
import TextEditorCustomComp from "./TextEditorCustomComp/TextEditorCustomComp";

const AddWorkDescription: FC = () => {
  const dispatch = useDispatch();
  const contract = useAppSelector((state: RootState) => state.addContract);
  const [desc, setDesc] = useState<any>(contract?.workDescription);
   const [tooltipVisibility, setTooltipVisibility] = useState<{
      [key: string]: boolean;
    }>({
      work: false,
    });

  const formIk = useFormik({
    initialValues: {
      description: contract.workDescription,
    },
    validationSchema: descriptionSchema,
    onSubmit: () => {
      dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_TIMINGS));
    },
  });

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.CHOOSE_OCCUPATION));
  };

  useEffect(() => {
    formIk.setFieldValue("description", desc);
    dispatch(setWorkDescription(desc));
  }, [desc]);

    const handleClickOutside = useCallback((event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const isTooltipIcon = target.closest(".tooltip-icon");
      const isTooltipContent = target.closest(".tooltip-content");
      const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line
    
      if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
        setTooltipVisibility({
          work: false,
        });
      }
    }, []);
    
  
    useEffect(() => {
      document.addEventListener('click', handleClickOutside);
      return () => document.removeEventListener('click', handleClickOutside);
    }, [handleClickOutside]);
  
  
    const handleIconClick = (tooltipKey: string) => {
      setTooltipVisibility(prev => ({
        work: false,
        [tooltipKey]: !prev[tooltipKey]
      }));
    };

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formIk.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row pt-2 h-100 align-items-center">
              <div className="col-lg-6">
                <div className={styles.registerLeft}>

                  <div className="col-lg-11 col-12 mx-auto text-center">
                    <figure>
                      <img
                        src={choosetype}
                        alt=""
                        className="img-fluid w-100"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <h4 className="text-white text-32 fw-semibold">
                  Describe the work!
                </h4>
                <p className="text-16 text-white opacity-75">
                  Give a detailed description of the work that you would like done.
                </p>
                <div className={styles.signUPContainer}>
                  <div
                    className={`${styles.registerRight} ${styles.editors}`}
                  >
                    <div
                      className={styles.fromGroup}
                      style={{ marginBottom: "30px" }}
                    >
                      <div className="topTipHover">
                        <label className={styles.starlabel}>
                          Work Description
                        </label>
                        <i onClick={() => handleIconClick("work")} className="tooltip-icon">
                          <img
                            className="customeTolImg"
                            src={tip}
                            alt=""
                          />
                        </i>
                        <Toltip tooltipClass={tooltipVisibility.work ? "activetooltip" : ""}
                          slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                          labelId={
                            CONSTANT_MANAGER.ADD_CONTRACT.DESCRIPTION
                          }
                        />
                      </div>
                      <div
                        className={`${styles.desw_editor} custom-editor`}
                      >
                        <TextEditorCustomComp
                          data={contract?.workDescription}
                          setDesc={setDesc}
                        />
                      </div>

                      {formIk.touched.description &&
                        formIk.errors.description ? (
                        <div className={styles.error}>
                          {formIk.errors.description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-4 col-6 text-start my-3 btn-hover-effect">
                    <h6
                      onClick={() => handleGoBack()}
                      className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton mb-0"
                    >
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="" />
                      </i>
                      <span className="px-lg-4 px-2 ps-3 text-12 fw-semibold d-block">
                        Back
                      </span>
                    </h6>
                  </div>

                  <div className=" col-md-4 col-6">
                    <SaveAsDraftButton />
                  </div>
                  <div className="col-md-4 col-12 text-end my-3 btn-hover-effect">
                    <button
                      type="submit"
                      // onClick={handleGoToNextStep}
                      className="btn arrowblue border-grey border-1 position-relative pe-5 mb-0"
                    >
                      <span className="px-lg-4 px-2 pe-lg-3 text-12 fw-semibold d-block">
                        Next
                      </span>
                      <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                        <img src={whitearrow} alt="" />
                      </i>
                    </button>
                  </div>

                  {/* <button  > Next</button> */}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddWorkDescription;
