import React, { useCallback, useEffect, useState } from "react";
import styles from "../../../styles/style.module.css";
import "react-markdown-editor-lite/lib/index.css";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useFormik } from "formik";
import storage from "../../../../../utils/storage";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setProceduresAndPolicies,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import TextEditorCustomComp from "../StepsComonents/TextEditorCustomComp/TextEditorCustomComp";
import tip from "../../../../../assets/images/tip.png";
import Toltip from "../../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";

const ProcessorAndPolicies: React.FC = () => {
  const dispatch = useDispatch();
  const { proceduresAndPolicies } = useSelector(
    (state: RootState) => state.addContract
  );
  const [desc, setDesc] = useState<any>(
    proceduresAndPolicies?.proceduresAndPolicies
  );
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: string]: boolean;
  }>({
    policy: false,
  });

  const formIk = useFormik({
    initialValues: {
      ...proceduresAndPolicies,
    },
    onSubmit: () => {
      dispatch(setActiveStep(ADD_CONTRACT_STEPS.OVERVIEW_FORM));
    },
  });

  useEffect(() => {
    formIk.setFieldValue("description", desc);
    dispatch(
      setProceduresAndPolicies({
        ...proceduresAndPolicies,
        proceduresAndPolicies: desc,
      })
    );
  }, [desc]);

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_WORK_LOCATION));
  };

  const handleSkip = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.OVERVIEW_FORM));
  };
  const handleGoToNextStep = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.OVERVIEW_FORM));
  };

  const onValueChange = (text: string) => {
    dispatch(
      setProceduresAndPolicies({
        ...proceduresAndPolicies,
        proceduresAndPolicies: text,
      })
    );
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isTooltipIcon = target.closest(".tooltip-icon");
    const isTooltipContent = target.closest(".tooltip-content");
    const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line

    if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
      setTooltipVisibility({
        policy: false,
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  const handleIconClick = (tooltipKey: string) => {
    setTooltipVisibility(prev => ({
      policy: false,
      [tooltipKey]: !prev[tooltipKey]
    }));
  };

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formIk.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-md-6">
                <div className={styles.registerLeft}>
                  <div className="col-lg-11 col-12 text-center mx-auto">
                    {/* <p>Add a Legal information of the work that you need</p> */}
                    <figure>
                      <img
                        src={choosetype}
                        alt=""
                        className="img-fluid w-100"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <h4 className="text-white text-32 fw-semibold">
                  Procedures and Policies
                </h4>
                <p className="text-16 text-white opacity-75">
                  This section is for outlining your company’s
                  specific policies and procedures for interactions
                  with contractors. Please provide clear and detailed
                  guidelines that align with your business practices
                  and legal requirements.
                </p>

                <div className={styles.signUPContaine}>
                  <div
                    className={`${styles.registerRight} ${styles.editors}`}
                  >
                    <div className={styles.fromGroup}>
                      <div className="topTipHover">
                        <label className='text-16 text-white fw-medium'>Procedures and Policies</label>
                        <i onClick={() => handleIconClick("policy")} className="tooltip-icon">
                          <img
                            className="customeTolImg"
                            src={tip}
                            alt="Tooltip"
                          />
                        </i>
                        <Toltip tooltipClass={tooltipVisibility.policy ? "activetooltip" : ""}
                          slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                          labelId={
                            CONSTANT_MANAGER.ADD_CONTRACT.POLICY
                          }
                        />
                      </div>
                      <TextEditorCustomComp
                        data={
                          proceduresAndPolicies?.proceduresAndPolicies
                        }
                        setDesc={setDesc}
                      />
                    </div>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <div className="col-xl-4 col-12 text-start my-3 btn-hover-effect">
                    <h6
                      onClick={() => handleGoBack()}
                      className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold">
                        Back
                      </span>
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="" />
                      </i>
                    </h6>
                  </div>
                  <div className="col-xl-4 col-6 text-end my-3 btn-hover-effect text-center">
                    <button
                      onClick={handleSkip}
                      className="btn arrowyellow border-grey border-1 position-relative"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                        Skip
                      </span>
                    </button>
                  </div>
                  <div className="col-xl-4 col-6 text-end my-3 btn-hover-effect">
                    <h6
                      onClick={handleGoToNextStep}
                      className="btn arrowblue border-grey border-1 position-relative pe-5"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold">
                        Next
                      </span>
                      <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                        <img src={whitearrow} alt="" />
                      </i>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default ProcessorAndPolicies;
