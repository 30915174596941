import React, { useEffect, useState } from "react";
import ProfessionalHired from "./ProfessionalHired";
import Header from "../../../../header/routes";
import styles from "../../../css/styles/style.module.css";
import tip from "../../../../../assets/images/tip.png";
import loc from "../../../../../assets/images/icon_location.svg";
import insc from "../../../../../assets/images/health_inc.svg";
import vacation from "../../../../../assets/images/vacation.png";
import incentive from "../../../../../assets/images/incentive.png";
import bags2 from "../../../../../assets/images/bag_orange.svg";
import skill from "../../../../../assets/images/top_skill.svg";
import modal from "../../../../../assets/images/modal.svg";
import doublecheck from "../../../../../assets/images/double_check.svg";
import fullcontractimg from "../../../../../assets/images/fullcontractimg_1.jpg";
import calls from "../../../../../assets/images/calls.svg";
import email from "../../../../../assets/images/email.svg";
import manufacture from "../../../../../assets/images/manufacture.svg";
import peopleneeded from "../../../../../assets/images/peopleneeded.svg";
import duration from "../../../../../assets/images/duration.svg";
import startdate from "../../../../../assets/images/startdate.svg";
import { ContractResponse } from "../../../Contracts/interface";
import { useParams } from "react-router-dom";
import { completedJobDetails } from "../api";
import moment from "moment";

const CompletedJobDetails = () => {
  const { id } = useParams();
  const [jobDetails, setJobDetails] = useState<any>();

  useEffect(() => {
    _getJobDetails();
  }, []);

  const _getJobDetails = async () => {
    const res = await completedJobDetails(id);
    setJobDetails(res?.data);
  };

  return (
    <div>
      <>
        <Header />
        <div className="container mb-5">
          <div className={`bg-white mt-4  ${styles.PendingCOnatctList}`}>
            <div className={`${styles.contractPast}`}>
              <>
                <div className="col-lg-12">
                  <div className={styles.RightOpportunity}>
                    <div className={styles.ContarctOuter}>
                      <div className={styles.opprBox}>
                        <div className={`row align-items-start ${styles.PendingCOnatctList}`}>
                          <div className="col-lg-8">
                            <h6
                              className={`text-capitalize ${styles.name_robo}`}
                            >
                              {jobDetails?.occupation?.name}
                              <span className={` py-2 ${styles.boosted}`}>
                                boosted
                              </span>
                              <span
                                className={`mb-0 ms-2 bg-lightgreen text-lightgreen d-flex align-items-center py-2`}
                              >
                                <i className="me-1">
                                  <img
                                    src={doublecheck}
                                    alt=""
                                    width={18}
                                    height={18}
                                  />
                                </i>
                                {jobDetails?.workTypeState === "closed"
                                  ? "Completed"
                                  : " "}
                              </span>
                            </h6>

                            <div className="row align-items-center gap-3 mt-3 mx-0">
                              <span
                                className={`w-auto rounded-3 mb-3 d-flex align-items-center bg-lightorange text-lightorange py-2`}
                              >
                                {" "}
                                <i className="me-2">
                                  <img src={bags2} alt="" />
                                </i>
                                Standard Job
                              </span>
                              <span
                                className={`btn mb-3 w-auto h-auto py-2 px-3 ${styles.commonbtn}`}
                              >
                                {jobDetails?.workLocation}
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="row align-items-center justify-content-end">
                              <div className="col-4">
                                <button
                                  type="button"
                                  className={`btn w-100 px-lg-3 px-2 d-flex align-items-center ${styles.commonbtn} ${styles.applybtn}`}
                                >
                                  <i className="far fa-copy me-2"></i>Copy
                                </button>
                              </div>
                              <div className="col-4">
                                {" "}
                                <button
                                  className={`btn w-100 px-3 bg-white text-danger border-danger ${styles.commonbtn}`}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className={styles.Loca}>
                            <p className={styles.Locate}>
                              <img src={loc} alt="" />
                              {jobDetails?.address}
                            </p>
                          </div>

                          <div
                            className={`${styles.PenigSkilRate} ${styles.fullcontractSkilRate}`}
                          >
                            <ul className="row align-items-start justify-content-start gap-0">
                              <li className="col-4">
                                <div className="row align-items-start mb-3">
                                  <div className="col-2 text-center ps-0">
                                    <i>
                                      <img
                                        src={skill}
                                        alt=""
                                        className="img-fluid"
                                        width="35px"
                                        height="35px"
                                      />
                                    </i>
                                  </div>
                                  <div className="col-10 ps-0">
                                    <h5 className="mb-0 fw-bold text-14">
                                      Top Skill
                                    </h5>
                                    <p className="text-14 mb-0">
                                      {
                                        jobDetails?.technicalSkills?.[0]
                                          ?.manufacturerDetails?.name
                                      }
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li className="col-4">
                                <div className="row align-items-start mb-3">
                                  <div className="col-2 text-center ps-0">
                                    <i>
                                      <img
                                        src={manufacture}
                                        alt=""
                                        className="img-fluid"
                                        width="35px"
                                        height="35px"
                                      />
                                    </i>
                                  </div>
                                  <div className="col-10 ps-0">
                                    <h5 className="mb-0">MANUFACTURER</h5>
                                    <p className="text-14 mb-0">
                                      {
                                        jobDetails?.technicalSkills?.[0]
                                          ?.manufacturerDetails?.name
                                      }
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li className="col-4">
                                <div className="row align-items-start mb-3">
                                  <div className="col-2 text-center ps-0">
                                    <i>
                                      <img
                                        src={modal}
                                        alt=""
                                        className="img-fluid w-75"
                                        width={15}
                                        height={15}
                                      />
                                    </i>
                                  </div>
                                  <div className="col-10 ps-0">
                                    <h5 className="mb-0">
                                      Model/Version/ Controller
                                    </h5>
                                    <p className="text-14 mb-0">
                                      {
                                        jobDetails?.technicalSkills?.[0]
                                          ?.manufacturerModelDetails?.name
                                      }
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>

                          <div
                            className={`pt-0 ${styles.PenigSkilRate} ${styles.PenigSkilRats}`}
                          >
                            <h5>MANUFACTURER Image</h5>
                            <ul className="row align-items-start justify-conetnt-start gap-2 mt-2 mb-3">
                              {jobDetails?.technicalSkills?.[0]?.skillImg ? (
                                jobDetails?.technicalSkills?.[0]?.skillImg?.map(
                                  (img: any, ind: number) => (
                                    <li key={ind} className="col-2 px-0">
                                      <i>
                                        <img
                                          src={img || fullcontractimg}
                                          alt=""
                                          className="img-fluid"
                                          height={50}
                                          width={50}
                                        />
                                      </i>
                                    </li>
                                  )
                                )
                              ) : (
                                <li className="col-2 px-0">
                                  <i>
                                    <img
                                      src={fullcontractimg}
                                      alt=""
                                      className="img-fluid w-100"
                                    />
                                  </i>
                                </li>
                              )}
                            </ul>
                          </div>
                          <div className={styles.PenigSkilRate}>
                            <h5 className="mb-2">Pay Rates / Hour</h5>
                            <ul>
                              <li>
                                <span>Base</span>
                                <label>${jobDetails?.rates?.baseRate}</label>
                              </li>
                              <li>
                                <span>Overtime</span>
                                <label>
                                  ${jobDetails?.rates?.overTimeRate}
                                </label>
                              </li>
                              <li>
                                <span>Sun/Holidays</span>
                                <label>
                                  ${jobDetails?.rates?.doubleTimeRate}
                                </label>
                              </li>
                            </ul>
                          </div>

                          <div className="col-md-12"></div>
                          <div className={styles.bordeerBottom}></div>
                          <h5 className={styles.payrate}>SCHEDULE</h5>
                          <div className="row">
                            <div className="col-lg-7 col-sm-12">
                              <div className="row">
                                <div className="col-lg-6 col-sm-6">
                                  <div className={styles.datesFlex}>
                                    <h6>Start date </h6>
                                    <p className="mb-0">
                                      {moment(
                                        jobDetails?.times?.startDate
                                      ).format("MM-DD-YYYY")}
                                    </p>
                                    <i className="me-2">
                                      {" "}
                                      <img src={startdate} alt="" width={15} />
                                    </i>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                  {/* <div className={styles.datesFlex}>
                                        <h6>Duration</h6>
                                        <p className="mb-0">
                                          {jobDetails?.times?.duration} Hr
                                        </p>
                                        <i className="me-2">
                                          {" "}
                                          <img src={startdate} alt="" width={15} />
                                        </i>
                                      </div> */}
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                  <div className={styles.datesFlex}>
                                    <h6>People Needed </h6>
                                    <p className="mb-0">
                                      {jobDetails?.times?.noOfPepole}
                                    </p>
                                    <i className="me-2">
                                      {" "}
                                      <img
                                        src={peopleneeded}
                                        alt=""
                                        width={15}
                                      />
                                    </i>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                  <div className={styles.datesFlex}>
                                    <h6> Shift </h6>
                                    <p className="mb-0 text-capitalize">
                                      {jobDetails?.times?.shift}
                                    </p>
                                    <i className="me-2">
                                      {" "}
                                      <img src={duration} alt="" width={15} />
                                    </i>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                  <div className={styles.datesFlex}>
                                    <h6>Planned Hours Per Day </h6>
                                    <p className="mb-0">
                                      {jobDetails?.times?.plannedHoursPerDay}
                                    </p>
                                    <i className="me-2">
                                      {" "}
                                      <img src={startdate} alt="" width={15} />
                                    </i>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                  <div className={styles.datesFlex}>
                                    <h6>Planned Days Per Week </h6>
                                    <p>
                                      {jobDetails?.times?.plannedDaysPerWeek}
                                    </p>
                                    <i className="me-2">
                                      {" "}
                                      <img src={duration} alt="" width={15} />
                                    </i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={styles.bordeerBottom}></div>
                            <div className="row align-items-start justify-content-start g-3">
                              <div className="col-xl-4 col-lg-6 col-12">
                                <div className="form-group">
                                  <div className="topTipHover">
                                    <label className="fw-semibold">
                                      Health Insurance
                                    </label>
                                  </div>
                                  <div className="col-12 mt-3">
                                    <div className="checkboxes__item">
                                      <label className="checkbox style-c text-white">
                                        <div className="row align-items-center bg-lightbluewhite p-3 rounded-4 mx-0">
                                          <div className="col-2 text-center px-0">
                                            <i>
                                              <img src={insc} alt="" />
                                            </i>
                                          </div>
                                          <div className="col-10">
                                            <label className="startlabel text-14 text-black">
                                              Comprehensive health insurance
                                              coverage available upon permanent
                                              employment.
                                            </label>
                                          </div>
                                        </div>
                                        <input type="checkbox" checked={jobDetails?.isHealthInsurance}/>
                                        <div className="checkbox__checkmark mt-4"></div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-4 col-lg-6 col-12">
                                <div className="form-group">
                                  <div className="topTipHover">
                                    <label className="fw-semibold">
                                      Vacation and Time Off
                                    </label>
                                  </div>
                                  <div className="col-12 mt-3">
                                    <div className="checkboxes__item">
                                      <label className="checkbox style-c text-white">
                                        <div className="row align-items-center bg-lightbluewhite p-3 rounded-4 mx-0">
                                          <div className="col-2 text-center px-0">
                                            <i>
                                              <img src={vacation} alt="" />
                                            </i>
                                          </div>
                                          <div className="col-10">
                                            <label className="startlabel text-14 text-black">
                                              Comprehensive health insurance
                                              coverage available upon permanent
                                              employment.
                                            </label>
                                          </div>
                                        </div>

                                        <input type="checkbox" checked={jobDetails?.isVacationAndTimeOff}/>
                                        <div className="checkbox__checkmark mt-4"></div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-4 col-lg-6 col-12">
                                <div className="form-group">
                                  <div className="topTipHover">
                                    <label className="fw-semibold">
                                      Other Benefits
                                    </label>
                                  </div>
                                  <div className="col-12 mt-3">
                                    <div className="checkboxes__item">
                                      <label className="checkbox style-c text-white">
                                        <div className="row align-items-center bg-lightbluewhite p-3 rounded-4 mx-0">
                                          <div className="col-2 text-center px-0">
                                            <i>
                                              <img src={incentive} alt="" />
                                            </i>
                                          </div>
                                          <div className="col-10">
                                            <label className="startlabel text-14 text-black">
                                              Comprehensive health insurance
                                              coverage available upon permanent
                                              employment.
                                            </label>
                                          </div>
                                        </div>

                                        <input type="checkbox" checked={jobDetails?.isOtherBenefits}/>
                                        <div className="checkbox__checkmark mt-4"></div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={styles.bordeerBottom}></div>
                            <div
                              className={styles.PenigSkil}
                              style={{ marginTop: "15px" }}
                            >
                              <h5>Description</h5>
                              <p>{jobDetails?.description}</p>
                            </div>
                            <div className={styles.bordeerBottom}></div>
                            <div className="col-md-12">
                              <h5 className={styles.payrate}>
                                Eligilibility Questions
                              </h5>
                              <div>
                                <ul>
                                  {jobDetails?.eligibilityQuestions?.map(
                                    (ques: any, ind: any) => (
                                      <li key={ques._id}>
                                        <div>
                                          <h6>
                                            {`Question ${ind + 1} : ${
                                              ques.question
                                            }`}
                                          </h6>{" "}
                                          <p className="text-14">
                                            Ans: {ques.required.toString()}
                                          </p>
                                        </div>{" "}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                            <div className={styles.bordeerBottom}></div>
                            <div className="col-md-12">
                              <h5 className={styles.payrate}>
                                Interview Questions
                              </h5>
                              <div>
                                <ul>
                                  {jobDetails?.interviewQuestions?.map(
                                    (ques: any, ind: any) => (
                                      <li key={ques._id}>
                                        <div>
                                          <h6>
                                            {`Question ${ind + 1} : ${
                                              ques.question
                                            }`}
                                          </h6>{" "}
                                          <p className="text-14">
                                            Ans: {ques.required.toString()}
                                          </p>
                                        </div>{" "}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                            <div className={styles.bordeerBottom}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
          <div className="completedcontractshadowone mb-4">
            <ProfessionalHired profDetails={jobDetails?.professionals} />
          </div>
        </div>
      </>
    </div>
  );
};

export default CompletedJobDetails;
