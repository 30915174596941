import React, { useCallback, useEffect, useState } from "react";
import styles from "../../../styles/style.module.css";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import storage from "../../../../../utils/storage";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setSoftwareLicenseDetails,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import { getMasterTypes } from "../../../../../feature/auth/signup/api";
import { MASTER_TYPE } from "../../../../../utils/enums/masterTypes.enum";
import { toast } from "react-toastify";
import { postMasterType } from "../../../../work/api";
import { MESSAGES } from "../../../../../utils/messages";
import { APIResponseError } from "../../../../work/interface";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import Select from "react-select";
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import CreatableSelect from "react-select/creatable";
import tip from "../../../../../assets/images/tip.png";
import Toltip from "../../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";

const SoftWareLicenses: React.FC = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");
  const { softwareLicenseDetails } = useSelector(
    (state: RootState) => state.addContract
  );
  const { softwareLicenses, options, isRequired } = softwareLicenseDetails;
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: string]: boolean;
  }>({
    license: false,
  });

  const formik = useFormik({
    initialValues: {
      softwareLicenses: softwareLicenses || "",
    },
    onSubmit: () => {
      dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_SECURITY_REQUIREMENT));
    },
  });

  useEffect(() => {
    getSoftwareLicense();
  }, []);

  const getSoftwareLicense = async () => {
    const response = await getMasterTypes(MASTER_TYPE.SOFTWARE_LICENCES);
    response.data.forEach((x) => {
      x.label = x.name;
      x.value = x._id;
    });

    dispatch(
      setSoftwareLicenseDetails({
        ...softwareLicenseDetails,
        options: response.data,
      })
    );
  };

  const onInputChange = (value: any, key: string) => {
    console.log("key", key);

    dispatch(
      setSoftwareLicenseDetails({
        ...softwareLicenseDetails,
        [key]: value,
      })
    );
    if (key === "softwareLicenses") {
      formik.setFieldValue(key, value.value);
    } else {
      formik.setFieldValue(key, value);
    }
  };

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_INTERVIEW_QUESTIONS));
  };

  const handleNext = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_SECURITY_REQUIREMENT));
  };
  const handleSkip = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_SECURITY_REQUIREMENT));
  };

  const handleSubmitCustomOccupation = async (
    newValue?: any,
    type?: string
  ) => {
    const payload = {
      type: MASTER_TYPE.SOFTWARE_LICENCES,
      name: newValue || searchTerm,
      alias: newValue || searchTerm,
    };

    toast.promise(postMasterType(payload), {
      pending: {
        render() {
          return MESSAGES.POSTING_CONTRACTS.VERIFYING;
        },
      },
      success: {
        render(newSoftwareLicence) {
          const newId = newSoftwareLicence?.data?.data?._id || newValue;
          if (type === "SOFTWARE_LICENCES") {
            const _obj: any = {
              label: newSoftwareLicence?.data?.data?.name || newValue,
              value: newId,
            };
            const newArr = [...softwareLicenseDetails.softwareLicenses, _obj];
            dispatch(
              setSoftwareLicenseDetails({
                ...softwareLicenseDetails,
                softwareLicenses: newArr,
              })
            );
            return MESSAGES.POSTING_CONTRACTS.ADDING;
          }
        },
      },
      error: {
        render(error) {
          let errorMesage = error as APIResponseError;
          return errorMesage?.data?.data?.message;
        },
      },
    });
  };
  const handleCreate = (inputValue: string, type?: string) => {
    handleSubmitCustomOccupation(inputValue, type);
    // setTimeout(() => {
    //   const newOption = createOption(inputValue);
    //   setOptions((prev) => [...prev, newOption]);
    //   setValue(newOption);
    // }, 1000);
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isTooltipIcon = target.closest(".tooltip-icon");
    const isTooltipContent = target.closest(".tooltip-content");
    const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line

    if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
      setTooltipVisibility({
        license: false,
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  const handleIconClick = (tooltipKey: string) => {
    setTooltipVisibility(prev => ({
      license: false,
      [tooltipKey]: !prev[tooltipKey]
    }));
  };

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formik.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div className={styles.registerLeft}>
                  <div className="col-lg-11 col-12 text-center mx-auto">
                    {/* <p>Add a Legal information of the work that you need</p> */}
                    <figure>
                      <img
                        src={choosetype}
                        alt=""
                        className="img-fluid w-100"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <h4 className="text-white text-38 fw-semibold">
                  Software License Required
                </h4>
                <p className="text-16 text-white opacity-75">
                  You can provide all the information here regarding
                  the contract, you just need to fill in all the
                  details here.
                </p>
                <div className={styles.signUPContainerx}>
                  <div className={styles.registerRight}>
                    <div className={styles.fromGroup}>
                      <div className="topTipHover">
                        <label className='text-16 text-white fw-medium'>Choose Software Licenses</label>
                        <i onClick={() => handleIconClick("license")} className="tooltip-icon">
                          <img
                            className="customeTolImg"
                            src={tip}
                            alt="Tooltip"
                          />
                        </i>
                        <Toltip tooltipClass={tooltipVisibility.license ? "activetooltip" : ""}
                          slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                          labelId={
                            CONSTANT_MANAGER.ADD_CONTRACT.SOFTWARE_LICENSE
                          }
                        />
                      </div>
                      {/* <Select
                        isMulti
                        name="softwareLicenses"
                        options={options}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(val) =>
                          onInputChange(val, "softwareLicenses")
                        }
                        value={softwareLicenses}
                      /> */}
                      <CreatableSelect
                        isMulti
                        onCreateOption={(_cb) =>
                          handleCreate(_cb, "SOFTWARE_LICENCES")
                        }
                        name="softwareLicenses"
                        options={options as any}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(val) =>
                          onInputChange(val, "softwareLicenses")
                        }
                        value={softwareLicenses}
                      />

                      {/* <div
                        className={styles.addOccuatin}
                        style={{
                          marginTop: "15px",
                          marginBottom: "15px",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Other..."
                          name="otherSoftwareLicance"
                          value={text}
                          className="form-control rounded-pill"
                          onChange={(e) => setText(e.target.value)}
                        />

                    
                        <button
                          type="button"
                          className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-3 px-5 mt-3"
                          onClick={handleSubmitCustomOccupation}
                        >
                          Add +
                        </button>
                      </div> */}
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name={`isRequired$`}
                          id={`isRequired1$`}
                          checked={isRequired}
                          onChange={() =>
                            onInputChange(true, "isRequired")
                          }
                        />
                        <label className="form-check-label text-white ms-2 text-14">
                          Mandatory
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name={`flexRadioDefault2$`}
                          id={`flexRadioDefault2$`}
                          checked={!isRequired}
                          onChange={() =>
                            onInputChange(false, "isRequired")
                          }
                        />
                        <label className="form-check-label text-white ms-2 text-14">
                          Good to have
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <div className="col-xl-4 col-6 text-start my-3 btn-hover-effect">
                    <h6
                      onClick={() => handleGoBack()}
                      className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold">
                        Back
                      </span>
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="" />
                      </i>
                    </h6>
                  </div>
                  <div className="col-xl-8 col-12 d-flex justify-content-between">
                    <div className="col-xl-4 col-6 text-end my-3 btn-hover-effect">
                      <button
                        onClick={handleSkip}
                        className="btn arrowyellow border-grey border-1 position-relative"
                      >
                        <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                          Skip
                        </span>
                      </button>
                    </div>
                    <div className="col-xl-4 col-6 text-end my-3 btn-hover-effect">
                      <h6
                        onClick={handleNext}
                        className="btn arrowblue border-grey border-1 position-relative pe-5"
                      >
                        <span className="px-lg-4 px-2 text-12 fw-semibold">
                          Next
                        </span>
                        <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                          <img src={whitearrow} alt="" />
                        </i>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default SoftWareLicenses;
