import React, { useCallback, useEffect, useState } from "react";
import styles from "../../../../../components/contractBeforeLogin/styles/style.module.css";
import tip from "../../../../../assets/images/tip.png";
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import insc from "../../../../../assets/images/health_inc.svg";
import vacation from "../../../../../assets/images/vacation.png";
import incentive from "../../../../../assets/images/incentive.png";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setAdditionalFields,
} from "../../../../../redux/reducers/addDirectJob";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import { DIRECT_JOB_STEPS } from "../Enums";
import { Dropdown } from "react-bootstrap";
import { faL } from "@fortawesome/free-solid-svg-icons";
import Toltip from "../../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";

type OtherCheckboxKey =
  | "isHealthInsurance"
  | "isVacationAndTimeOff"
  | "isOtherBenefits";

const AddAditionalInfo: React.FC = () => {
  const dispatch = useDispatch();
  const { additionfiels } = useSelector((state: RootState) => state.directJob);
  const options = ["Remote", "Hybrid", "Onsite"];
  const [citizenCheck, setCitizencheck] = useState<boolean>(
    additionfiels?.isSameCitizenship
  );
  const [isOtherChecked, setIsOtherChecked] = useState<{
    isHealthInsurance: boolean;
    isVacationAndTimeOff: boolean;
    isOtherBenefits: boolean;
  }>({
    isHealthInsurance: additionfiels?.isHealthInsurance,
    isVacationAndTimeOff: additionfiels?.isVacationAndTimeOff,
    isOtherBenefits: additionfiels?.isOtherBenefits,
  });
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: string]: boolean;
  }>({
    health: false,
    vocation: false,
    benefits: false,
  });

  const formIk: any = useFormik({
    initialValues: {},
    onSubmit: () => {},
  });

  const handleGoBack = () => {
    dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_TECHNICAL_SKILLS));
  };
  const handleGoToNextStep = () => {
    dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_ELIGIBILITYQUESTIONS));
  };

  const onInputChange = (value: string | boolean, key: string) => {
    dispatch(setAdditionalFields({ ...additionfiels, [key]: value }));
  };

  const _handleCitizenChange = (value: string | boolean, key: string) => {
    setCitizencheck(!citizenCheck); // Toggle citizenship checkbox
    // Reset all other checkboxes to false if citizenship is unchecked
    if (!citizenCheck) {
      setIsOtherChecked({
        isHealthInsurance: false,
        isVacationAndTimeOff: false,
        isOtherBenefits: false,
      });
    }
    dispatch(setAdditionalFields({ ...additionfiels, [key]: value }));
  };

  const handleOtherCheckboxChange = (
    value: string | boolean,
    key: OtherCheckboxKey
  ) => {
    setIsOtherChecked({
      ...isOtherChecked,
      [key]: !isOtherChecked[key],
    });
    dispatch(setAdditionalFields({ ...additionfiels, [key]: value }));
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isTooltipIcon = target.closest(".tooltip-icon");
    const isTooltipContent = target.closest(".tooltip-content");
    const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line
    if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
      setTooltipVisibility({
        health: false,
        vocation: false,
        benefits: false,
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [handleClickOutside]);

  const handleIconClick = (tooltipKey: string) => {
    setTooltipVisibility((prev) => ({
      health: false,
      vocation: false,
      benefits: false,
      [tooltipKey]: !prev[tooltipKey],
    }));
  };

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formIk.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div className={styles.registerLeft}>
                  <div className="col-lg-11 col-12 mx-auto text-center">
                    <figure className="mb-0">
                      <img
                        src={choosetype}
                        alt=""
                        className="img-fluid w-100"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                {/* <div className="newheadingstyle p-4"> */}
                <h4 className="text-white text-32 fw-semibold">
                  Additional Fields
                </h4>
                <p className="text-16 text-white opacity-75">
                  Add a Legal information of the work that you need
                </p>
                {/* </div> */}
                <div className={styles.ScrollDiv}>
                  <div className={styles.registerRight}>
                    <div className="col-12 mx-auto">
                      <div className="form-group mt-3">
                        <div className="checkboxes__item">
                          <label className="checkbox style-c text-white text-14">
                            {" "}
                            Same citizenship is required for the job
                            <input
                              type="checkbox"
                              checked={citizenCheck}
                              onChange={(e) =>
                                _handleCitizenChange(
                                  e.target.value,
                                  "isSameCitizenship"
                                )
                              }
                            />
                            <div className="checkbox__checkmark"></div>
                          </label>
                        </div>
                      </div>
                      <div className="form-group mt-3">
                        <div className="topTipHover">
                          <label className="text-white text-14">Health Insurance</label>
                          <i
                            onClick={() => handleIconClick("health")}
                            className="tooltip-icon"
                          >
                            <img className="customeTolImg" src={tip} alt="" />
                          </i>
                          <Toltip
                            tooltipClass={
                              tooltipVisibility.health ? "activetooltip" : ""
                            }
                            slug={CONSTANT_MANAGER.SLUG.DIRECT_JOB}
                            labelId={
                              CONSTANT_MANAGER.DIRECT_JOB.HEALTH_INSURANCE
                            }
                          />
                        </div>

                        <div className="col-12 mt-3">
                          <div className="checkboxes__item">
                            <label className="checkbox style-c text-white">
                              <div className="row align-items-center bg-lightwhiteblue p-3 rounded-4 mx-0">
                                <div className="col-1 text-center px-0">
                                  <i>
                                    <img src={insc} alt="" />
                                  </i>
                                </div>
                                <div className="col-11">
                                  <label className="startlabel text-14 text-white">
                                    Comprehensive health insurance coverage
                                    available upon permanent employment.
                                  </label>
                                </div>
                              </div>
                              <input
                                type="checkbox"
                                checked={isOtherChecked.isHealthInsurance}
                                onChange={(e) =>
                                  handleOtherCheckboxChange(
                                    e.target.value,
                                    "isHealthInsurance"
                                  )
                                }
                                disabled={!citizenCheck} // Disable if citizenship is not checked
                              />
                              <div className="checkbox__checkmark mt-4"></div>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mt-3">
                        <div className="topTipHover">
                          <label className="text-white text-14">
                            Vacation and Time Off
                          </label>
                          <i
                            onClick={() => handleIconClick("vocation")}
                            className="tooltip-icon"
                          >
                            <img className="customeTolImg" src={tip} alt="" />
                          </i>
                          <Toltip
                            tooltipClass={
                              tooltipVisibility.vocation ? "activetooltip" : ""
                            }
                            slug={CONSTANT_MANAGER.SLUG.DIRECT_JOB}
                            labelId={CONSTANT_MANAGER.DIRECT_JOB.VOCATION}
                          />
                        </div>
                        <div className="col-12 mt-3">
                          <div className="checkboxes__item">
                            <label className="checkbox style-c text-white">
                              <div className="row align-items-center bg-lightwhiteblue p-3 rounded-4 mx-0">
                                <div className="col-1 text-center px-0">
                                  <i>
                                    <img src={vacation} alt="" />
                                  </i>
                                </div>
                                <div className="col-11">
                                  <label className="startlabel text-14 text-white">
                                    Comprehensive health insurance coverage
                                    available upon permanent employment.
                                  </label>
                                </div>
                              </div>
                              <input
                                type="checkbox"
                                checked={isOtherChecked.isVacationAndTimeOff}
                                onChange={(e) =>
                                  handleOtherCheckboxChange(
                                    e.target.value,
                                    "isVacationAndTimeOff"
                                  )
                                }
                                disabled={!citizenCheck} // Disable if citizenship is not checked
                              />
                              <div className="checkbox__checkmark mt-4"></div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mt-3">
                        <div className="topTipHover">
                          <label className="text-white text-14">Other Benefits</label>
                          <i
                            onClick={() => handleIconClick("benefits")}
                            className="tooltip-icon"
                          >
                            <img className="customeTolImg" src={tip} alt="" />
                          </i>
                          <Toltip
                            tooltipClass={
                              tooltipVisibility.benefits
                                ? "activetooltip"
                                : ""
                            }
                            slug={CONSTANT_MANAGER.SLUG.DIRECT_JOB}
                            labelId={
                              CONSTANT_MANAGER.DIRECT_JOB.OTHER_BENEFITS
                            }
                          />
                        </div>
                        <div className="col-12 mt-3">
                          <div className="checkboxes__item">
                            <label className="checkbox style-c text-white">
                              <div className="row align-items-center bg-lightwhiteblue p-3 rounded-4 mx-0">
                                <div className="col-1 text-center px-0">
                                  <i>
                                    <img src={incentive} alt="" />
                                  </i>
                                </div>
                                <div className="col-11">
                                  <label className="startlabel text-14 text-white">
                                    Comprehensive health insurance coverage
                                    available upon permanent employment.
                                  </label>
                                </div>
                              </div>
                              <input
                                type="checkbox"
                                checked={isOtherChecked.isOtherBenefits}
                                onChange={(e) =>
                                  handleOtherCheckboxChange(
                                    e.target.value,
                                    "isOtherBenefits"
                                  )
                                }
                                disabled={!citizenCheck} // Disable if citizenship is not checked
                              />
                              <div className="checkbox__checkmark mt-4"></div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between mt-3">
                  <div className="col-4 text-start my-3 btn-hover-effect">
                    <h6
                      onClick={() => handleGoBack()}
                      className="btn bg-white arrowyellow border-grey border-1 rounded-3 position-relative ps-5 backbutton"
                    >
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="" />
                      </i>
                      <span className="px-lg-4 px-2 text-12 fw-semibold">
                        Back
                      </span>
                    </h6>
                  </div>
                  <div className="col-8">
                    <div className="row align-items-center jusify-content-end">
                      <div className="col-12 text-end my-3 btn-hover-effect">
                        <h6
                          onClick={handleGoToNextStep}
                          className="btn arrowblue border-grey border-1 position-relative pe-5"
                        >
                          <span className="px-lg-4 px-2 text-12 fw-semibold">
                            Next
                          </span>
                          <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                            <img src={whitearrow} alt="" />
                          </i>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddAditionalInfo;
