import React, { useCallback, useEffect, useState } from "react";
import styles from "../../../../../components/contractBeforeLogin/styles/style.module.css";
import "react-markdown-editor-lite/lib/index.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setInterviewQuestions,
} from "../../../../../redux/reducers/addDirectJob";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import storage from "../../../../../utils/storage";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import { useFormik } from "formik";
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { DIRECT_JOB_STEPS } from "../Enums";
import TextEditorCustomComp from "../../../HourlyContractType/HourlyContract/StepsComonents/TextEditorCustomComp/TextEditorCustomComp";
import Toltip from "../../../../common/toltip/page";
import tip from "../../../../../assets/images/tip.png";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import * as Yup from "yup";

const InterviewQuestionForDirectJob: React.FC = () => {
  const dispatch = useDispatch();
  const token = storage.getToken();
  const { interviewQuestions } = useSelector(
    (state: RootState) => state.directJob
  );
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: string]: boolean;
  }>({
    intQues: false,
  });
  const validationSchema = Yup.object().shape({
      interviewQuestions: Yup.array()
        .of(
          Yup.string()
            .trim()
            .test("not-only-br", "Interview question cannot be empty", (value) => {
              if (!value) return false; // Ensures value exists
              return value.replace(/<br\s*\/?>/g, "").trim() !== "";
            })
            .test("word-count", "Interview question must be between 3 and 50 words", (value) => {
              if (!value) return false;
              const wordCount = value.trim().split(/\s+/).length;
              return wordCount >= 3 && wordCount <= 50;
            })
            .required("Interview question cannot be empty")
        )
        .min(1, "At least one Interview question is required"),
    });

  const formIk = useFormik({
    initialValues: { interviewQuestions },
    validationSchema: validationSchema,
    onSubmit: () => {
    handleGoToNextStep();
    },
  });

  const addNewQuestion = (): void => {
    const updatedQuestion = [...interviewQuestions];
    updatedQuestion.push("");
    dispatch(setInterviewQuestions([...updatedQuestion]));
  };

  const onValueChange = (value: string, index: number): void => {
    const updatedQuestions = [...interviewQuestions];
    updatedQuestions[index] = value;

    dispatch(setInterviewQuestions(updatedQuestions));
    formIk.setFieldValue("interviewQuestions", updatedQuestions);
  };

  const deleteQuestion = (index: number) => {
    const updatedQuestion = [...interviewQuestions];
    updatedQuestion.splice(index, 1);

    dispatch(setInterviewQuestions([...updatedQuestion]));
  };
  const handleGoBack = () => {
    dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_ELIGIBILITYQUESTIONS));
  };
  const handleGoToNextStep = () => {
    dispatch(setActiveStep(DIRECT_JOB_STEPS.OVERVIEW_FORM));
  };
  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isTooltipIcon = target.closest(".tooltip-icon");
    const isTooltipContent = target.closest(".tooltip-content");
    const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line

    if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
      setTooltipVisibility({
        intQues: false,
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [handleClickOutside]);

  const handleIconClick = (tooltipKey: string) => {
    setTooltipVisibility((prev) => ({
      intQues: false,
      [tooltipKey]: !prev[tooltipKey],
    }));
  };

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formIk.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div className={styles.registerLeft}>
                  <div className="col-lg-11 col-12 text-center mx-auto">
                    {/* <p>Add a Legal information of the work that you need</p> */}
                    <figure>
                      <img
                        src={choosetype}
                        alt=""
                        className="img-fluid w-100"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <h4 className="text-white text-32 fw-semibold">
                  Interview Questions
                </h4>
                <p className="text-14 text-white opacity-75">
                  To ensure candidates meet essential job requirements and legal
                  standards, Automate America Company uses the following yes or
                  no interview questions during the hiring process. Each
                  question is designed to quickly assess critical factors for
                  candidate suitability.
                </p>
                <div className={styles.signUPContainerx}>
                  <div className={styles.registerRight}>
                  <div className="topTipHover">
                    <label className='text-16 text-white fw-medium'>
                      Create Interview Questions
                    </label>
                    <i
                      onClick={() => handleIconClick("intQues")}
                      className="tooltip-icon"
                    >
                      <img className="customeTolImg" src={tip} alt="Tooltip" />
                    </i>
                    <Toltip
                      tooltipClass={
                        tooltipVisibility.intQues ? "activetooltip" : ""
                      }
                      slug={CONSTANT_MANAGER.SLUG.DIRECT_JOB}
                      labelId={
                        CONSTANT_MANAGER.DIRECT_JOB.ELIGIBILITY_QUESTION
                      }
                    />
                    </div>
                    <div className={styles.ScrollDiv}>
                      {interviewQuestions?.map((x, i) => (
                        <>
                          <div
                            className={styles.fromGroup}
                            style={{ maxWidth: "100%" }}
                          >
                            <div>
                              <label className='text-16 text-white fw-medium'>
                                Question{i + 1}:
                              </label>
                              <div>
                                <div className="row align-items-center justify-content-between w-100">
                                  <div className="col-11">
                                    <TextEditorCustomComp
                                      data={x} // Use specific question data
                                      setDesc={(value: string) =>
                                        onValueChange(value, i)
                                      }
                                      index={i}
                                    />
                                  </div>
                                  <div
                                    className={`col-1 px-0 ${styles.AddBtn}`}
                                  >
                                    {i === interviewQuestions.length - 1 &&
                                      interviewQuestions.length < 5 && (
                                        <button
                                          type="button"
                                          className="btn btn-primary py-2 my-1 rounded-circle text-20"
                                          onClick={addNewQuestion}
                                        >
                                          +
                                        </button>
                                      )}
                                    {interviewQuestions.length > 1 && (
                                      <button
                                        type="button"
                                        className="btn btn-primary py-2 my-1 rounded-circle text-20"
                                        onClick={() => deleteQuestion(i)}
                                      >
                                        -
                                      </button>
                                    )}
                                  </div>
                                 
 {formIk.errors.interviewQuestions && formIk.errors.interviewQuestions[i] && (
  <div className={styles.error}>
    {formIk.errors.interviewQuestions[i]}
  </div>
)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <div className="col-xl-4 col-6 text-start my-3 btn-hover-effect">
                    <h6
                      onClick={() => handleGoBack()}
                      className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold">
                        Back
                      </span>
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="" />
                      </i>
                    </h6>
                  </div>
                  <div className="col-xl-8 col-6">
                    <div className="d-flex align-items-center jusify-content-end">
                      {/* <div className="col-6">
                            <SaveAsDraftButton />
                          </div> */}
                      <div className="col-12 text-end my-3 btn-hover-effect">
                        <h6
                          onClick={()=>formIk.handleSubmit()}
                          className="btn arrowblue border-grey border-1 position-relative pe-5"
                        >
                          <span className="px-lg-4 px-2 text-12 fw-semibold">
                            Next
                          </span>
                          <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                            <img src={whitearrow} alt="" />
                          </i>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default InterviewQuestionForDirectJob;
