import React, { useCallback, useEffect, useState } from "react";
import styles from "../../../../../components/contractBeforeLogin/styles/style.module.css";
import { useFormik } from "formik";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import Delete from "../../../../../assets/images/delete.svg";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setOccupation,
  setTechnicalSkills,
} from "../../../../../redux/reducers/addDirectJob";
import upload from "../../../../../assets/images/upload.svg";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import { getMasterTypes } from "../../../../../feature/auth/signup/api";
import { MASTER_TYPE } from "../../../../../utils/enums/masterTypes.enum";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import { Dropdown, FormControl } from "react-bootstrap";
import { IOption } from "../../../../../Interfaces";
import { getManufacturerModelList, postMasterType } from "../../../../work/api";
import { DIRECT_JOB_STEPS } from "../Enums";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import { MESSAGES } from "../../../../../utils/messages";
import { APIResponseError } from "../../../../work/interface";
import { uploadFileImg } from "../../../../Posts/api";
import Toltip from "../../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import tip from "../../../../../assets/images/tip.png";
import { SkillValidationSchema } from "./validation";


const AddTechnicalSkills: React.FC = () => {
  const dispatch = useDispatch();
  const { technicalSkillsData } = useSelector(
    (state: RootState) => state.directJob
  );
  const [filterValue, setFilterValue] = useState<string>("");
  const { occupation, occupationsList } = useSelector(
    (state: RootState) => state.directJob
  );
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: string]: boolean;
  }>({
    manuf: false,
    model: false,
    skill: false,
    img: false,
  });
  const [imgUrls, setImgUrls] = useState<string[]>(
    technicalSkillsData?.skillImg
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [inputFields, setInputFields] = useState<number[]>([0]);
  const formIk: any = useFormik({
    initialValues: {
      skills: technicalSkillsData.skills,
      skillNames: technicalSkillsData.skillName,
      
    },
    validationSchema:SkillValidationSchema,

    onSubmit: (values) => {
      handleGoToNextStep()
      // dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_ELIGIBILITY_QUESTION));
    },
  });

  useEffect(() => {
    const getManufacturerData = async () => {
      const response = await getMasterTypes(MASTER_TYPE.MANUFACTURERS);
      response.data.forEach((x) => {
        x.label = x.name;
        x.value = x._id;
      });

      dispatch(
        setTechnicalSkills({
          ...technicalSkillsData,
          skillTypes: response.data,
        })
      );
    };

    getManufacturerData();
  }, []);

  const addNewManufacture = (): void => {


    const updatedSkills = [...technicalSkillsData.skills];
    updatedSkills.push({
      isRequired: false,
      subCategoriesList: [],
      category: { label: "", value: "" },
      subCategory: [],
    });

    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills, skillName: [...technicalSkillsData.skillName,{label:"",value:""}] })
    );
    
  };

  const onValueChange = (
    value: string | boolean | IOption[],
    key: string,
    index: number
  ): void => {
    const updatedSkills = [...technicalSkillsData.skills];
    const updatedSkill = { ...updatedSkills[index], [key]: value };
    updatedSkills[index] = updatedSkill;

    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills })
    );
    formIk.setFieldValue("skills", updatedSkills);
    console.log(updatedSkills, "updatedSkills");
  };

  const handleGoBack = () => {
    dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_RATES));
  };
  const handleGoToNextStep = () => {
    
     dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_ADDITIONALS));
  };

  const handleChangeManufacturer = async (
    option: any,
    index: number,
    key: string
  ) => {
    const updatedSkills = [...technicalSkillsData.skills];
    const updatedSkill = { ...updatedSkills[index] };

    if (key === "category") {
      const response = await getManufacturerModelList(
        MASTER_TYPE.MANUFACTURERS_MODELS,
        option.value
      );
      response.data.forEach((x) => {
        x.label = x.name;
        x.value = x._id;
      });

      updatedSkill.subCategoriesList = response.data ?? [];
      updatedSkill.category = option;
      updatedSkill.subCategory = [];
    }
    
    if (key === "subCategory") {
      updatedSkill.subCategory = option;
    }

    (updatedSkill as any)[key] = option;
    updatedSkills[index] = updatedSkill;

    formIk.setFieldValue("skills", updatedSkills);
    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills })
    );
  };

  const deleteSkill = (i: number) => {
    const updatedSkills = [...technicalSkillsData.skills];
    updatedSkills.splice(i, 1);
    const SkillsName = [...technicalSkillsData.skillName];
    SkillsName.splice(i, 1);

    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills, skillName:  SkillsName })
    );
   

   

    console.log(updatedSkills,SkillsName)
  };

  const filteredCategories = technicalSkillsData.skillTypes.filter((option) =>
    option?.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const _addNewSkill = async (option: any, index: number, key: string) => {
    const updatedSkills = [];
    let _newSkill = {
      isRequired: false,
      subCategoriesList: [],
      category: {
        ...option,
        label: option.name,
        value: option._id,
      },
      subCategory: [],
    };
    updatedSkills.push(_newSkill);

    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills })
    );

    // console.log(updatedSkills, 'updatedSkillsupdatedSkills');
  };

  const handleSubmitCustomOccupation = async (
    newValue?: any,
    type?: string
  ) => {
    if (type === "PRIMARY_OCCUPATION") {
      const payload = {
        type: MASTER_TYPE.OCCUPATIONS,
        name: newValue || searchTerm,
        alias: newValue || searchTerm,
      };
      toast.promise(postMasterType(payload), {
        pending: {
          render() {
            return MESSAGES.POSTING_CONTRACTS.VERIFYING;
          },
        },
        success: {
          render(newOccupation) {
            const newId = newOccupation?.data?.data?._id || newValue;
            const _obj: any = {
              label: newOccupation?.data?.data?.name || newValue,
              value: newId,
            };
            // dispatch(setOccupation({ ...occupation, primaryOccupation: _obj }));
            dispatch(
              setTechnicalSkills({
                ...technicalSkillsData,
                skillName: [
                  {
                    label: _obj.label,
                    value: _obj.value,
                  },
                ],
              })
            );
            return MESSAGES.POSTING_CONTRACTS.ADDING;
          },
        },
        error: {
          render(error) {
            let errorMesage = error as APIResponseError;
            return errorMesage?.data?.data?.message;
          },
        },
      });
    }

    if (type === "_MANUFACTURERS") {
      const payload = {
        type: MASTER_TYPE.MANUFACTURERS,
        name: newValue || searchTerm,
        alias: newValue || searchTerm,
      };

      toast.promise(postMasterType(payload), {
        pending: {
          render() {
            return MESSAGES.POSTING_CONTRACTS.VERIFYING;
          },
        },
        success: {
          render(newManufacture) {
            if (type === "_MANUFACTURERS") {
              _addNewSkill(newManufacture?.data?.data, 1, "category");
              return MESSAGES.POSTING_CONTRACTS.ADDING;
            }
            setSearchTerm("");
            dispatch(
              setTechnicalSkills({
                ...technicalSkillsData,
                skillTypes: [
                  {
                    label: newManufacture.data.data.name,
                    value: newManufacture.data.data._id,
                  },
                ],
                // skillTypes: {
                //   label: newManufacture.data.data.name,
                //   value: newManufacture.data.data._id,
                // },
              })
            );
            return MESSAGES.POSTING_CONTRACTS.ADDING;
          },
        },
        error: {
          render(error) {
            let errorMesage = error as APIResponseError;
            return errorMesage?.data?.data?.message;
          },
        },
      });
    }
  };

  const handleCreate = (inputValue: string, type?: string) => {
    handleSubmitCustomOccupation(inputValue, type);
  };

  const handleOccupationChange = (value: any, key: string,i:number) => {

    const newSkill:any=[...technicalSkillsData.skillName];
    newSkill[i]= {
      label: value.label,
      value: value.value,
    },
    console.log(newSkill,"newSkill")
    formIk.setFieldValue("skillNames",newSkill);

    dispatch(
      setTechnicalSkills({
        ...technicalSkillsData,
        skillName: newSkill
      })
    );
  };


  const primaryOccOptions =
    filterValue && filterValue !== ""
      ? occupationsList.filter((x) =>
        x.label.toLowerCase().includes(filterValue.toLowerCase())
      )
      : occupationsList;

  const handleFileUpload = async (files:any) => {
    if (files) {
      const formData = new FormData();
      files.forEach((img:any) => {
        formData.append("files", img);
      });
      try {
        const response = await uploadFileImg(formData);
        const newImageUrl = response.data.files;
        // setImgUrls((prevUrls) => [...prevUrls, newImageUrl]);
        setImgUrls(newImageUrl);
        dispatch(
          setTechnicalSkills({ ...technicalSkillsData, skillImg: newImageUrl })
        );
      } catch (err) {
        console.log("Error occuring while uploading image", err);
      }
    }
  };

  const removeUploadedFiles = (index: number): void => {
    setImgUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isTooltipIcon = target.closest(".tooltip-icon");
    const isTooltipContent = target.closest(".tooltip-content");
    const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line
    if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
      setTooltipVisibility({
        manuf: false,
        model: false,
        skill: false,
        img: false,
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [handleClickOutside]);

  const handleIconClick = (tooltipKey: string) => {
    setTooltipVisibility((prev) => ({
      manuf: false,
      model: false,
      skill: false,
      img: false,
      [tooltipKey]: !prev[tooltipKey],
    }));
  };

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formIk.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div className={styles.registerLeft}>
                  <div className="col-lg-11 col-12 text-center mx-auto">
                    {/* <p>Add a Legal information of the work that you need</p> */}
                    <figure>
                      <img
                        src={choosetype}
                        alt=""
                        className="img-fluid w-100"
                      />
                    </figure>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <h4 className="text-white text-32 fw-semibold">
                  Programming / Technical Skills....
                </h4>
                <p className="text-16 text-white opacity-75">
                  Select manufacturer of the hardware or software that you need
                  help with. Examples: AutoCAD, FANUC, Siemens, Allen Bradley,
                  Kuka, ABB
                </p>

                <div className={styles.signUPContainers}>
                  <div className={styles.registerRight}>
                    <div className="technicalskill">
                      <div className={styles.ScrollDiv}>
                        {technicalSkillsData.skills.map((x, i) => (
                          <>
                            <>
                              {technicalSkillsData.skills.length > 1 && (
                                <div className="mb-4 text-end">
                                  <button
                                    type="button"
                                    className="btn"
                                    onClick={() => deleteSkill(i)}
                                  >
                                    {/* <span className=" px-lg-4 px-2">Delete</span> */}
                                    <i className="bg-white rounded-circle p-2 d-block">
                                      <img
                                        src={Delete}
                                        alt=""
                                        width={25}
                                        height={25}
                                      />
                                    </i>
                                  </button>
                                </div>
                              )}
                            </>
                            <div
                              className="p-3 rounded-4 mb-3"
                              style={{
                                background: "rgba(255, 255, 255, 0.2)",
                              }}
                            >
                              <div
                                className={styles.fromGroup}
                                style={{ marginBottom: "30px" }}
                              >
                                <div className="topTipHover">
                                  <label onClick={()=>{console.log(formIk.errors.skillNames,"rrr")

                                    console.log(formIk.values)
                                    console.log(technicalSkillsData.skillName)

                                  }} className='text-16 text-white fw-medium'>
                                    Skill Name
                                  </label>
                                  <i
                                    onClick={() => handleIconClick("skill")}
                                    className="tooltip-icon"
                                  >
                                    <img
                                      className="customeTolImg"
                                      src={tip}
                                      alt="Tooltip"
                                    />
                                  </i>
                                  <Toltip
                                    tooltipClass={
                                      tooltipVisibility.skill
                                        ? "activetooltip"
                                        : ""
                                    }
                                    slug={CONSTANT_MANAGER.SLUG.DIRECT_JOB}
                                    labelId={CONSTANT_MANAGER.DIRECT_JOB.SKILL}
                                  />
                                </div>
                                <CreatableSelect
                                
                                  required={true}
                                  onCreateOption={(_cb) =>
                                    handleCreate(_cb, "PRIMARY_OCCUPATION")
                                  }
                                  name="dropdown-basic"
                                  options={primaryOccOptions as any}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(val) =>
                                    handleOccupationChange(
                                      val,
                                      "primaryOccupation",
                                      i
                                    )
                                  }
                                  placeholder="Select or create an occupation"

                                  value={technicalSkillsData?.skillName[i].label?technicalSkillsData?.skillName[i]:null}
                                />
                                {formIk.touched.skillNames?.[i]?.label && formIk.errors.skillNames?.[i]?.label ? (
    <div className={styles.error}>{"Skill  in Mandatory"}</div>
  ) : null}
                              </div>
                              <div
                                className={styles.fromGroup}
                                style={{ marginBottom: "30px" }}
                              >
                                <div className="topTipHover">
                                  <label className='text-16 text-white fw-medium'>
                                    Manufacturer
                                  </label>
                                  <i
                                    onClick={() => handleIconClick("manuf")}
                                    className="tooltip-icon"
                                  >
                                    <img
                                      className="customeTolImg"
                                      src={tip}
                                      alt="Tooltip"
                                    />
                                  </i>
                                  <Toltip
                                    tooltipClass={
                                      tooltipVisibility.manuf
                                        ? "activetooltip"
                                        : ""
                                    }
                                    slug={CONSTANT_MANAGER.SLUG.DIRECT_JOB}
                                    labelId={
                                      CONSTANT_MANAGER.DIRECT_JOB.MANUFACTURE
                                    }
                                  />
                                </div>
                                <CreatableSelect

                                placeholder={"Select or create a manufacturer"}
                                  onCreateOption={(_cb) =>
                                    handleCreate(_cb, "_MANUFACTURERS")
                                  }
                                  name="dropdown-basic"
                                  options={filteredCategories as any}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(val) =>
                                    handleChangeManufacturer(val, i, "category")
                                  }
                                  value={x.category.label?x.category:null}
                                />
                                  {formIk.touched.skills?.[i]?.category && formIk.errors.skills?.[i]?.category ? (
    <div className={styles.error}>{"Manufacturer field in Mandatory"}</div>
  ) : null}
                              </div>
                              <div
                                className={styles.fromGroup}
                                style={{ marginBottom: "30px" }}
                              >
                                <div className="topTipHover">
                                  <label className='text-16 text-white fw-medium'>
                                    Model / Version / Controller
                                  </label>
                                  <i onClick={() => handleIconClick("model")} className="tooltip-icon">
                                    <img
                                      className="customeTolImg"
                                      src={tip}
                                      alt="Tooltip"
                                    />
                                  </i>
                                  <Toltip tooltipClass={tooltipVisibility.model ? "activetooltip" : ""}
                                    slug={CONSTANT_MANAGER.SLUG.DIRECT_JOB}
                                    labelId={
                                      CONSTANT_MANAGER.DIRECT_JOB.MODEL
                                    }
                                  />
                                </div>
                                <CreatableSelect
                                  // onCreateOption={(_cb) => handleCreate(_cb, "PRIMARY_OCCUPATION")}
                                  placeholder={"Select or create a  Model / Version / Controller"}
                                  name="colors"
                                  options={x.subCategoriesList}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(val) =>
                                    onValueChange(val as any, "subCategory", i)
                                  }
                                  isMulti
                                  value={x.subCategory||null}
                                />
                                {formIk.touched.skills?.[i]?.subCategory && formIk.errors.skills?.[i]?.subCategory ? (
    <div className={styles.error}>{"Model / Version / Controller field in Mandatory"}</div>
  ) : null}
                              </div>
                              <div
                                className={styles.fromGroup}
                                style={{ marginBottom: "30px" }}
                              >
                        <div className="topTipHover">
                                <label className='text-16 text-white fw-medium'>
                                  Upload Image
                                </label>
                                <i onClick={() => handleIconClick("img")} className="tooltip-icon">
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt="Tooltip"
                                  />
                                </i>
                                <Toltip tooltipClass={tooltipVisibility.img ? "activetooltip" : ""}
                                  slug={CONSTANT_MANAGER.SLUG.DIRECT_JOB}
                                  labelId={
                                    CONSTANT_MANAGER.DIRECT_JOB.MODEL
                                  }
                                />
                                </div>
                                <input
                                  type="file"
                                  accept="image/*"
                                  className="form-control"
                                  placeholder="Upload image"
                                  onChange={({ target }) =>{

                                    const fileArr=[]
                                   if(target.files){
                                    let sizeFlag=false
                                    for(let i=0;i<target.files.length;i++){
                                      if(target.files[i].size>5000000){
                                        if(sizeFlag===false){
                                          sizeFlag=true
                                        toast.error("Image size should be less than 5MB")
                                        }
                                                                     
                                      }
                                      else{
                                        fileArr.push(target.files[i])
                                      }
                                   }
                                  console.log(target.value,"target.value")
                                  }

                                    handleFileUpload(fileArr)}
                                  }
                                  multiple
                                />
                                <ul className="mt-3">
                                  {imgUrls?.map((imgUrl, index) => (
                                    <li key={index}>
                                      <div className={styles.uploadImages}>
                                        <img
                                          src={imgUrl}
                                          alt={`Uploaded image ${index}`}
                                          className="img-fluid"
                                        />
                                        {/* Remove icon */}
                                        <i
                                          className="fas fa-times"
                                          onClick={() =>
                                            removeUploadedFiles(index)
                                          }
                                        ></i>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              <div className={styles.fromGroup}>
                                <label className='text-16 text-white fw-medium'>
                                  Skills
                                </label>

                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={`flexRadioDefault${i}`}
                                    id={`flexRadioDefault1${i}`}
                                    checked={x.isRequired}
                                    onChange={(e) =>
                                      onValueChange(true, "isRequired", i)
                                    }
                                  />
                                  <label className="form-check-label text-white text-14">
                                    Mandatory
                                  </label>
                                </div>

                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={`flexRadioDefault2${i}`}
                                    id={`flexRadioDefault2${i}`}
                                    checked={!x.isRequired}
                                    onChange={(e) =>
                                      onValueChange(false, "isRequired", i)
                                    }
                                  />
                                  <label className="form-check-label text-white text-14">
                                    Good to have
                                  </label>
                                </div>
                                {formIk.touched.flexRadioDefault &&
                                  formIk.errors.flexRadioDefault ? (
                                  <div className={styles.error}>
                                    {formIk.errors.flexRadioDefault}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <div className="col-4 text-start my-3 btn-hover-effect">
                    <h6
                      onClick={() => handleGoBack()}
                      className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold">
                        Back
                      </span>
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="" />
                      </i>
                    </h6>
                  </div>
                  <div className="col-8 text-start my-3 btn-hover-effect">
                    <div className="row align-items-center jusify-content-end">
                      <div className="col-6">
                        <h6 className="btn arrowyellow bg-grey border-grey border-1 position-relative mb-0 py-2 h-48">
                          <span onClick={()=>{addNewManufacture()}} className="px-lg-4 px-2 text-12 fw-semibold">
                            Add Another +
                          </span>
                        </h6>
                        {/* <h6
                      onClick={addNewManufacture}
                      className="btn arrowyellow border-grey border-1 position-relative bg-grey"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold">
                      Add Another +
                      </span> */}
                        {/* <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="" />
                      </i> */}
                        {/* </h6> */}
                        {/* <button
                          onClick={addNewManufacture}
                            type="button"
                            className="position-relative w-auto p-0 bg-white py-2 border-0"
                          >
                            <span className="px-lg-4 px-2 text-12 fw-medium text-black text-capitalize">
            
                            Add Another +
                          </span>
                        </button> */}
                      </div>

                      <div className="col-6 text-end my-3 btn-hover-effect">
                        <h6
                          onClick={formIk.handleSubmit}
                          className="btn arrowblue border-grey border-1 position-relative pe-5"
                        >
                          <span className="px-lg-4 px-2 text-12 fw-semibold">
                            Next
                          </span>
                          <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                            <img src={whitearrow} alt="" />
                          </i>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddTechnicalSkills;
