import React from "react";
import moment from "moment";
import styles from "../styles/styles/style.module.css";
import Toltip from "../../toltip/page";
import { getNextMonday } from "../../../../utils/getNextMonday";
import exportt from "../../../../assets/images/exportt.png";
import info from "../../../../assets/images/info_icon.svg"


const CustomTimesheetTable = ({loader, timesheetData, handleEditTimesheet, handleViewStatusDetails,statusFilter, handleFilterChange}:{loader:boolean, timesheetData:any, handleEditTimesheet:any, handleViewStatusDetails:any,statusFilter:any, handleFilterChange:any}) => {
    return (
        <>
          <div className="container">
              <div className={styles.TimeShettTableOuter}>
                <div className={styles.TimeShettTableHead}>
                  <div className="col-5 topTipHover">
                    <div className="d-flex align-items-center w-100">
                      <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                        Timesheet Details
                      </h4>
                      <i className='me-2'><img className="customeTolImg" src={info} alt="" /></i>
                      <Toltip />
                    </div>
                  </div>
                  <div className="col-7 d-flex justify-content-end">
                    {/* Filter Dropdown */}
                    <select 
                      className="form-select me-3"
                      value={statusFilter}
                      onChange={handleFilterChange}
                      style={{ width: "150px" }} // Adjust width if needed
                    >
                      <option value="all">All</option>
                      <option value="approved">Approved</option>
                      <option value="rejected">Rejected</option>
                      <option value="due">Due</option>
                    </select>

                    {/* Export Button */}
                    <button className="btn bg-white text-12 fw-bold font-inter d-flex align-items-center float-end ms-2 px-2">
                      <i className="me-2"><img src={exportt} alt="" width={12} height={15} /></i>
                      Export Data
                    </button>
                  </div>
                </div>

                {/* Timesheet Table */}
                {loader ? (
                  <div className="spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="table align-middle">
                      <thead>
                        <tr>
                          <th>WEEK START DATE</th>
                          <th>WEEK END DATE</th>
                          <th>Straight Time</th>
                          <th>OVER TIME</th>
                          <th>TOTAL HOURS</th>
                          <th>CREATED DATE</th>
                          <th>APPROVED BY</th>
                          <th>APPROVED DATE</th>
                          <th>STATUS</th>
                          <th>ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>

                      {timesheetData?.length > 0 ? (
                        timesheetData?.map((data: any, idx: number) => {
                          return (
                            <tr key={idx}>
                              <td>
                                {moment(data.startDate).format("MMM DD, YYYY")}
                              </td>
                              <td>
                                {moment(data.endDate).format("MMM DD, YYYY")}
                              </td>
                              <td>{data?.totalStraightTime}</td>
                              <td>{data?.overTime}</td>
                              <td>{data?.totalHours}</td>
                              <td>
                                {moment(data.createdAt).format("MMM DD, YYYY")}
                              </td>
                              <td>{data?.approvedBy?.name}</td>
                              <td>
                                {moment(data.approvedDate).format("MMM DD, YYYY")}
                              </td>
                              <td>
                                {data?.status === "draft" ? (
                                  <span>Not filled</span>
                                ) : data?.status === "pending" ? (
                                  <span className={styles.pending}>Pending</span>
                                ) : data?.status === "approved" ? (
                                  <span className={styles.approvedf}>Approved</span>
                                ) : data?.status === "rejected" ? (
                                  <span className={styles.Decloine}>Declined</span>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                {data?.status === "draft" ? (
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => handleEditTimesheet(data?._id)}
                                  >
                                    Edit
                                  </span>
                                ) : data?.status === "approved" ? (
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => handleViewStatusDetails(data?._id)}
                                  >
                                    View Details
                                  </span>
                                ) : data?.status === "pending" ? (
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => handleViewStatusDetails(data?._id)}
                                  >
                                    View Details
                                  </span>
                                ) : data?.status === "rejected" ? (
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => handleEditTimesheet(data?._id)}
                                  >
                                    Edit
                                  </span>
                                ) : data?.isDue === true ? (
                                  <span
                                    onClick={() => handleViewStatusDetails(data?._id)}
                                  >
                                    View Details
                                  </span>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr><td colSpan={12} className='text-center w-100 text-14'>No Timesheet Data Available!</td></tr>
                      )}
                    </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
        </>
    )
}
export default CustomTimesheetTable;