import React, { useCallback, useEffect, useState } from "react";
import styles from "../../../styles/style.module.css";

import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { RootState } from "../../../../../redux/store";
import storage from "../../../../../utils/storage";
import {
  setActiveStep,
  setLegalDetails,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import Toltip from "../../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import tip from "../../../../../assets/images/tip.png";

const AddLegalInfo: React.FC = () => {
  const dispatch = useDispatch();
  const token = storage.getToken();
  const { legalDetails } = useSelector((state: RootState) => state.addContract);
  const { isAutomateAmerica, isLegalResidence, isVisa } = legalDetails;
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: string]: boolean;
  }>({
    resi: false,
    non_resi: false,
  });

  const formIk: any = useFormik({
    initialValues: { isAutomateAmerica, isLegalResidence, isVisa },
    onSubmit: () => { },
  });

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_CUSTOMER_CONTACT));
  };
  const handleGoToNextStep = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_MANUFACTURER));
  };

  const handleSkip = () => {
    dispatch(
      setLegalDetails({
        isAutomateAmerica: false,
        isLegalResidence: false,
        isVisa: false,
      })
    );
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_MANUFACTURER));
  };

  const onInputChange = (value: string | boolean, key: string) => {
    dispatch(setLegalDetails({ ...legalDetails, [key]: value }));
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isTooltipIcon = target.closest(".tooltip-icon");
    const isTooltipContent = target.closest(".tooltip-content");
    const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line

    if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
      setTooltipVisibility({
        resi: false,
        non_resi: false,
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  const handleIconClick = (tooltipKey: string) => {
    setTooltipVisibility(prev => ({
      resi: false,
      non_resi: false,
      [tooltipKey]: !prev[tooltipKey]
    }));
  };

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formIk.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div className={styles.registerLeft}>
                  <div className="col-lg-11 col-12 mx-auto text-center">
                    <figure>
                      <img
                        src={choosetype}
                        alt=""
                        className="img-fluid w-100"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <h4 className="text-white text-32 fw-semibold">
                  Non Mandatory Hourly Contract Fields
                </h4>
                <div className={styles.signUPContainerd}>
                  <div className={`${styles.registerRight} ${styles.editors}`}>

                    <div
                      className={styles.fromGroup}
                      style={{
                        marginBottom: "30px",
                        marginTop: "30px",
                      }}
                    >
                    </div>
                    <div
                      className={styles.fromGroup}
                      style={{ marginBottom: "30px" }}
                    >
                      <div className={styles.ratedRadio}>
                        <div className="topTipHover">
                          <label className={styles.starlabel}> Are US Legal Residents Required for This Contract? </label>
                          <i onClick={() => handleIconClick("resi")} className="tooltip-icon">
                            <img
                              className="customeTolImg"
                              src={tip}
                              alt="Tooltip"
                            />
                          </i>
                          <Toltip tooltipClass={tooltipVisibility.resi ? "activetooltip" : ""}
                            slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                            labelId={
                              CONSTANT_MANAGER.ADD_CONTRACT.LEGAL_RESIDENT
                            }
                          />
                        </div>
                        <div className={styles.rateradio}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="isLegalResidence"
                              checked={isLegalResidence}
                              onChange={(e) =>
                                onInputChange(true, "isLegalResidence")
                              }
                            />
                            <label className="form-check-label text-white">
                              Yes
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="isLegalResidence"
                              checked={!isLegalResidence}
                              onChange={(e) =>
                                onInputChange(false, "isLegalResidence")
                              }
                            />
                            <label className="form-check-label text-white">
                              No
                            </label>
                          </div>
                        </div>
                      </div>

                      {!isLegalResidence && (
                        <div
                          className={styles.ratedRadio}
                          style={{ marginTop: "30px" }}
                        >

                          {" "}
                          <div className="topTipHover">
                            <label className={styles.starlabel}>I will allow Non-Residents with a Legal US Work Visa to Apply</label>
                            <i onClick={() => handleIconClick("non_resi")} className="tooltip-icon">
                              <img
                                className="customeTolImg"
                                src={tip}
                                alt="Tooltip"
                              />
                            </i>
                            <Toltip tooltipClass={tooltipVisibility.non_resi ? "activetooltip" : ""}
                              slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                              labelId={
                                CONSTANT_MANAGER.ADD_CONTRACT.WORK_VISA
                              }
                            />
                          </div>
                          <div className={styles.rateradio}>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="isVisa"
                                id="isVisa"
                                checked={isVisa}
                                onChange={(e) =>
                                  onInputChange(true, "isVisa")
                                }
                              />
                              <label className="form-check-label text-white">
                                Yes
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="isVisa1"
                                id="isVisa1"
                                checked={!isVisa}
                                onChange={(e) =>
                                  onInputChange(false, "isVisa")
                                }
                              />
                              <label className="form-check-label text-white">
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-4 col-6 text-start my-3 btn-hover-effect">
                    <h6
                      onClick={() => handleGoBack()}
                      className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold">Back</span>
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="" />
                      </i>
                    </h6>
                  </div>
                  <div className="col-8 d-flex justify-content-between">
                    <div className="col-md-4 col-12 text-end my-3 btn-hover-effect">
                      <button
                        onClick={handleSkip}
                        className="btn arrowyellow border-grey border-1 position-relative"
                      >
                        <span className="px-lg-4 px-2 text-12 fw-semibold text-black">Skip</span>
                      </button>
                    </div>
                    <div className="col-md-4 col-12 text-end my-3 btn-hover-effect">
                      <h6
                        onClick={handleGoToNextStep}
                        className="btn arrowblue border-grey border-1 position-relative pe-5"
                      >
                        <span className="px-lg-4 px-2 text-12 fw-semibold">Next</span>
                        <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                          <img src={whitearrow} alt="" />
                        </i>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddLegalInfo;
