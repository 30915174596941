import React, { useCallback, useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../../assets/images/penbl.png";
import tip from "../../../../assets/images/tip.png";
import security from "../../../../assets/images/security.svg";
import EditBankingInfoModal from "../modals/EditBankingInfoModal";
import { bankDetailsProps } from "../../interface";
import Toltip from "../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";

// interface ModalProps {
//   refresh: () => void;
//   details: bankDetails;
// }

const BankingInfo: React.FC<bankDetailsProps> = ({ bankDetails, refresh }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  // const [editData, setEditData] = useState<bankDetails | undefined>(undefined); 
   const [tooltipVisibility, setTooltipVisibility] = useState<{
          [key: string]: boolean;
        }>({
          bankinginfo: false
        })
    
        const handleIconClick = (tooltipKey: string) => {
          setTooltipVisibility(prev => ({
            bankinginfo: false,
            [tooltipKey]: !prev[tooltipKey]
          }));
        };
            const handleClickOutside = useCallback((event: MouseEvent) => {
                const target = event.target as HTMLElement;
                const isTooltipIcon = target.closest(".tooltip-icon");
                const isTooltipContent = target.closest(".tooltip-content");
                const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line
            
                if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
                  setTooltipVisibility({
                    bankinginfo: false
                  });
                }
              }, []);
              useEffect(() => {
                document.addEventListener('click', handleClickOutside);
                return () => document.removeEventListener('click', handleClickOutside);
              }, [handleClickOutside]);

  const openModal = () => {
    setShowModal(true);
    // setEditData(data );
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleUpdate = () => {
    // setEditData(updatedDetails); 
  };

  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-10">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">
                Banking Information
              </h4>
              <i onClick={() => handleIconClick("bankinginfo")} className="tooltip-icon">
                  <img
                    className="customeTolImg"
                    src={tip}
                    alt="Tooltip"
                  />
                </i>
                <Toltip tooltipClass={tooltipVisibility.bankinginfo ? "activetooltip" : ""}
                  slug={CONSTANT_MANAGER.SLUG.EDIT_PROFILE}
                  labelId={
                    CONSTANT_MANAGER.EDIT_PROFILE.BANKING_INFORMATION
                  }
                />
            </div>
          </div>
        </div>
        <div className="col-2 text-end">
          <span>
            <img
              src={penbl}
              className="cursor-pointer ms-auto"
              alt=""
              onClick={() => openModal()}
            />
          </span>
        </div>
      </div>
      <div className={styles.personalinfo}>
        <ul className="row align-items-center">
          <li className="col-xl-4 col-6 mb-3">
            <span className="">Bank Name</span>
            <label className="clamp clamp1">{bankDetails?.bankName || "N/A"}</label>
          </li>
          <li className="col-xl-4 col-6 mb-3">
            <span className="">Routing</span>
            <label className="clamp clamp1">{bankDetails?.routing?.toString() || "N/A"}</label>
          </li>
          <li className="col-xl-4 col-6 mb-3">
            <span>Account</span>
            <label className="clamp clamp1">{bankDetails?.account?.toString() || "N/A"}</label>
          </li>
          <li className="col-xl-4 col-6 mb-3">
            <span>Account Name</span>
            <label className="clamp clamp1">{bankDetails?.accountName || "N/A"}</label>
          </li>
        </ul>
      </div>
      <div className={styles.note}>
        <div className="d-flex align-items-center bg-lightblue p-3 rounded-2">
          <div className="col-lg-1 col-2 text-center">
            <i>
              <img src={security} alt="" />
            </i>
          </div>
          <div className="col-lg-11 col-10">
            <p className="text-14 fw-medium mb-0 ps-2">
              Please provide your financial information securely to facilitate
              payments. Your security is extremely important to us, and only
              Automate America Financial Administration will have access to this
              information.
            </p>
          </div>
        </div>
      </div>
      <EditBankingInfoModal
        showModal={showModal}
        closeModal={closeModal}
        title="Edit Banking Info"
        refresh={refresh}
        // onUpdate={handleUpdate} 
        // details={editData}
        bankDetails={bankDetails}
      />
    </div>  
  );
};

export default BankingInfo;

