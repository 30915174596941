import React, { useCallback, useEffect, useState } from "react";
import styles from "../../../../../components/contractBeforeLogin/styles/style.module.css";
import "react-markdown-editor-lite/lib/index.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setInterviewQuestions,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import storage from "../../../../../utils/storage";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import { useFormik } from "formik";
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import TextEditorCustomComp from "../StepsComonents/TextEditorCustomComp/TextEditorCustomComp";
import Toltip from "../../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import tip from "../../../../../assets/images/tip.png";

const AddInterViewQuestion: React.FC = () => {
  const dispatch = useDispatch();
  const token = storage.getToken();
  const { interviewQuestions } = useSelector(
    (state: RootState) => state.addContract
  );
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: string]: boolean;
  }>({
    intQues: false,
  });

  const formIk = useFormik({
    initialValues: { interviewQuestions },
    onSubmit: () => {
      // dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_SOFWARE_LICENSES));
    },
  });

  const addNewQuestion = (): void => {
    const updatedQuestions = [...interviewQuestions, ""];
    dispatch(setInterviewQuestions(updatedQuestions));
  };

  const onValueChange = (value: string, i: number): void => {
    const updatedQuestions = [...interviewQuestions];
    updatedQuestions[i] = value;
    dispatch(setInterviewQuestions(updatedQuestions));
    formIk.setFieldValue("interviewQuestions", updatedQuestions);
  };

  const deleteQuestion = (index: number) => {
    const updatedQuestions = interviewQuestions.filter((_, i) => i !== index);
    dispatch(setInterviewQuestions(updatedQuestions));
  };

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_ELIGIBILITY_QUESTION));
  };
  const handleGoToNextStep = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_SOFWARE_LICENSES));
  };
  const handleSkip = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_SOFWARE_LICENSES));
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isTooltipIcon = target.closest(".tooltip-icon");
    const isTooltipContent = target.closest(".tooltip-content");
    const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line

    if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
      setTooltipVisibility({
        intQues: false,
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  const handleIconClick = (tooltipKey: string) => {
    setTooltipVisibility(prev => ({
      intQues: false,
      [tooltipKey]: !prev[tooltipKey]
    }));
  };

  return (
    <>
      <HeaderContent />

      <div className="heightautoscroll contractradio">
        <form onSubmit={formIk.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div className={styles.registerLeft}>
                  <div className="col-lg-11 col-12 text-center mx-auto">
                    <figure>
                      <img src={choosetype} alt="" className="img-fluid w-100" />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <h4 className="text-white text-32 fw-semibold">
                  Interview Questions
                </h4>
                <p className="text-16 text-white opacity-75">
                  To ensure candidates meet essential job requirements,
                  Automate America Company uses these interview questions.
                </p>
                <div className={styles.ScrollDiv}>
                  <div className={styles.signUPContainerx}>
                    <div className={styles.registerRight}>
                      <div className="topTipHover">
                        <label className={`mb-2 text-16 text-white fw-medium`}>Create Interview Questions</label>
                        <i onClick={() => handleIconClick("intQues")} className="tooltip-icon">
                          <img
                            className="customeTolImg"
                            src={tip}
                            alt="Tooltip"
                          />
                        </i>
                        <Toltip tooltipClass={tooltipVisibility.intQues ? "activetooltip" : ""}
                          slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                          labelId={
                            CONSTANT_MANAGER.ADD_CONTRACT.INTERVIEW_QUESTION
                          }
                        />
                      </div>
                      {interviewQuestions.map((question, i) => (
                        <div
                          key={i}
                          className={styles.fromGroup}
                          style={{ maxWidth: "100%" }}
                        >
                          <div className={styles.AddBtns}>
                            <label className={`mb-2 text-16 text-white fw-medium`}>Question {i + 1}:</label>
                            <div className="row align-items-center w-100">
                              <div className="col-10">
                                <TextEditorCustomComp
                                  data={question} // Use specific question data
                                  setDesc={(value: string) =>
                                    onValueChange(value, i)
                                  }
                                  index={i}
                                />
                              </div>
                              <div className="col-2 px-0">
                                <div className={styles.AddBtn}>
                                  {i === interviewQuestions.length - 1 &&
                                    interviewQuestions.length < 5 && (
                                      <button
                                        type="button"
                                        className="btn btn-primary py-2 my-1 rounded-circle text-20"
                                        onClick={addNewQuestion}
                                      >
                                        +
                                      </button>
                                    )}
                                  {interviewQuestions.length > 1 && (
                                    <button
                                      type="button"
                                      className="btn btn-primary py-2 my-1 rounded-circle text-20"
                                      onClick={() => deleteQuestion(i)}
                                    >
                                      -
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <div className="col-xl-4 col-12 text-start my-3 btn-hover-effect">
                    <h6
                      onClick={handleGoBack}
                      className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold">
                        Back
                      </span>
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="" />
                      </i>
                    </h6>
                  </div>
                  <div className="col-xl-8 col-12 d-flex justify-content-between">
                    <div className="col-xl-4 col-6 text-end my-3 btn-hover-effect">
                      <button
                        onClick={handleSkip}
                        className="btn arrowyellow border-grey border-1 position-relative"
                      >
                        <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                          Skip
                        </span>
                      </button>
                    </div>
                    <div className="col-xl-4 col-6 text-end my-3 btn-hover-effect">
                      <h6
                        onClick={handleGoToNextStep}
                        className="btn arrowblue border-grey border-1 position-relative pe-5"
                      >
                        <span className="px-lg-4 px-2 text-12 fw-semibold">
                          Next
                        </span>
                        <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                          <img src={whitearrow} alt="" />
                        </i>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddInterViewQuestion;
