import React, { useCallback, useEffect, useState } from "react";
import styles from "../../../../../components/contractBeforeLogin/styles/style.module.css";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setRates,
} from "../../../../../redux/reducers/addDirectJob";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import tip from "../../../../../assets/images/tip.png";
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import { rateValidationDirectjob } from "../../../../work/validations";
import { DIRECT_JOB_STEPS } from "../Enums";
import Toltip from "../../../../common/toltip/page";
import { Dropdown } from "react-bootstrap";
import { getPaymentTerms } from "../../../../paymentTerms/api";

const AddRatesForDirectJob: React.FC = () => {
  const dispatch = useDispatch();
  const { rates } = useSelector((state: RootState) => state.directJob);
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: string]: boolean;
  }>({
    payment: false,
    base: false,
    ot: false,
    dt: false,
  });

  const formik: any = useFormik({
    initialValues: {
      paymentTerms: rates?.paymentTerms || null,
      baseRate: rates.baseRate || "",
      otRate: rates.otRate || "",
      dtRate: rates.dtRate || "",
    },
    validationSchema: rateValidationDirectjob,
    onSubmit: () => {
      dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_TECHNICAL_SKILLS));
    },
  });

  const handleBack = () => {
    dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_LOCATIONS));
  };

  // const handleGoToNextStep = () => {
  //   dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_TRAVEL_DATA));
  // };

  const onInputChange = (value: any, key: string) => {
    formik.setFieldValue(key, value);
    dispatch(setRates({ ...rates, [key]: value }));
  };

  useEffect(() => {
    const paymentTerms = async () => {
      const response = await getPaymentTerms();
      response.data.hourly.forEach((x) => {
        x.label = x.paymentTerm.toString();
        x.value = x._id;
      });

      dispatch(setRates({ ...rates, paymentTermsList: response.data.hourly }));
    };
    paymentTerms();
  }, []);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isTooltipIcon = target.closest(".tooltip-icon");
    const isTooltipContent = target.closest(".tooltip-content");
    const isSpeakerButton = target.closest(".speaker-button"); // <-- Add this line
    if (!isTooltipIcon && !isTooltipContent && !isSpeakerButton) {
      setTooltipVisibility({
        payment: false,
    base: false,
    ot: false,
    dt: false,
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [handleClickOutside]);

  const handleIconClick = (tooltipKey: string) => {
    setTooltipVisibility((prev: any) => ({
      payment: false,
      base: false,
      ot: false,
      dt: false,
      [tooltipKey]: !prev[tooltipKey],
    }));
  };

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formik.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div className={styles.registerLeft}>
                  <div className="col-lg-11 col-12 mx-auto text-center">
                    <figure>
                      <img
                        src={choosetype}
                        alt=""
                        className="img-fluid w-100"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <h4 className="text-white text-32 fw-semibold">Pay Rates / Hour</h4>
                <div className={styles.ScrollDiv}>
                  <div className={styles.signUPContainer}>
                    <div className={styles.registerRight}>
                      {/* <div className={styles.fromGroup} style={{ marginBottom: "30px" }}>
                            <div className={styles.ratedRadio}>
                            </div>
                          </div> */}
                      <div
                        className={styles.fromGroup}
                        style={{ marginBottom: "30px" }}
                      >
                        <div className={styles.ratedRadio}>
                          <div className="topTipHover">
                            <label className={styles.starlabel}>
                              Select the net payment terms that in which you
                              will pay for this job
                            </label>
                            <i
                              onClick={() => handleIconClick("payment")}
                              className="tooltip-icon"
                            >
                              <img className="customeTolImg" src={tip} alt="" />
                            </i>
                            <Toltip
                              tooltipClass={
                                tooltipVisibility.payment ? "activetooltip" : ""
                              }
                              slug={CONSTANT_MANAGER.SLUG.DIRECT_JOB}
                              labelId={
                                CONSTANT_MANAGER.DIRECT_JOB
                                  .SELECT_NET_PAYMENT_CONTRACT
                              }
                            />
                          </div>
                          <div className={styles.inputWithDropdown}>
                            <Dropdown
                            // style={{ position: "relative", left: "-110px" }}
                            >
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                {rates.paymentTerms?.label
                                  ? rates.paymentTerms.label
                                  : "Please select an option"}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {rates?.paymentTermsList?.map(
                                  (option, index: number) => (
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() =>
                                        onInputChange(option, "paymentTerms")
                                      }
                                    >
                                      {option.label}
                                    </Dropdown.Item>
                                  )
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                            {formik.touched.paymentTerms &&
                              formik.errors.paymentTerms && (
                                <div className={styles.error}>
                                  {formik.errors.paymentTerms.label}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div
                        className={styles.fromGroup}
                        style={{ marginBottom: "30px" }}
                      >
                        <div className="topTipHover">
                          <label className={styles.starlabel}>
                            Salary or Base Rate($ per hour)
                          </label>
                          <i
                            onClick={() => handleIconClick("base")}
                            className="tooltip-icon"
                          >
                            <img className="customeTolImg" src={tip} alt="" />
                          </i>
                          <Toltip
                            tooltipClass={
                              tooltipVisibility.base ? "activetooltip" : ""
                            }
                            slug={CONSTANT_MANAGER.SLUG.DIRECT_JOB}
                            labelId={CONSTANT_MANAGER.DIRECT_JOB.BASE_RATE}
                          />
                        </div>
                        <input
                          type="number"
                          className="form-control rounded-3 h-48"
                          name="baseRate"
                          placeholder="0"
                          value={rates.baseRate || ''}
                          onChange={({ target }) =>
                            onInputChange(Number(target.value), "baseRate")
                          }
                        />

                        <p className={`${styles.error} text-white opacity-75`}>
                          0-40 Hours Monday-Saturday
                        </p>

                        {formik.touched.baseRate && formik.errors.baseRate ? (
                          <div className={styles.error}>
                            {formik.errors.baseRate}
                          </div>
                        ) : null}
                      </div>
                      <div
                        className={styles.fromGroup}
                        style={{ marginBottom: "30px" }}
                      >
                        <div className="topTipHover">
                          <label className='text-16 text-white fw-medium'>
                            {" "}
                            Overtime Rate($ per hour)
                          </label>
                          <i
                            onClick={() => handleIconClick("ot")}
                            className="tooltip-icon"
                          >
                            <img className="customeTolImg" src={tip} alt="" />
                          </i>
                          <Toltip
                            tooltipClass={
                              tooltipVisibility.ot ? "activetooltip" : ""
                            }
                            slug={CONSTANT_MANAGER.SLUG.DIRECT_JOB}
                            labelId={CONSTANT_MANAGER.DIRECT_JOB.OT_RATES}
                          />
                        </div>
                        <input
                          type="number"
                          name="otRate"
                          className="form-control rounded-3 h-48"
                          placeholder="0"
                          value={rates.otRate || ''}
                          onChange={({ target }) =>
                            onInputChange(Number(target.value), "otRate")
                          }
                        />

                        <p className={`${styles.error} text-white opacity-75`}>
                          41+ Hours Monday-Saturday
                        </p>
                        {formik.touched.otRate && formik.errors.otRate ? (
                          <div className={styles.error}>
                            {formik.errors.otRate}
                          </div>
                        ) : null}
                      </div>
                      <div
                        className={styles.fromGroup}
                        style={{ marginBottom: "30px" }}
                      >
                        <div className="topTipHover">
                          <label className='text-16 text-white fw-medium'>
                            {" "}
                            Sunday and Holiday Rate($ per hour)
                          </label>
                          <i
                            onClick={() => handleIconClick("dt")}
                            className="tooltip-icon"
                          >
                            <img className="customeTolImg" src={tip} alt="" />
                          </i>
                          <Toltip
                            tooltipClass={
                              tooltipVisibility.dt ? "activetooltip" : ""
                            }
                            slug={CONSTANT_MANAGER.SLUG.DIRECT_JOB}
                            labelId={CONSTANT_MANAGER.DIRECT_JOB.DT_RATE}
                          />
                        </div>
                        <input
                          type="number"
                          name="dtRate"
                          className="form-control rounded-3 h-48"
                          placeholder="0"
                          value={rates.dtRate || ''}
                          onChange={({ target }) =>
                            onInputChange(Number(target.value), "dtRate")
                          }
                        />

                        <p className={`${styles.error} text-white opacity-75`}>Sunday and US Holidays</p>
                        {formik.touched.dtRate && formik.errors.dtRate ? (
                          <div className={styles.error}>
                            {formik.errors.dtRate}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <div className="col-4 text-start my-3 btn-hover-effect">
                    <h6
                      onClick={() => handleBack()}
                      className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                        Back
                      </span>
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="" />
                      </i>
                    </h6>
                  </div>
                  <div className="col-8">
                    <div className="d-flex align-items-center jusify-content-end">
                      {/* <div className="col-6">
                              <SaveAsDraftButton />
                            </div> */}

                      <div className="col-12 text-end my-3 btn-hover-effect">
                        <button
                          type="submit"
                          className="btn arrowblue border-grey border-1 position-relative pe-5"
                        >
                          <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                            Next
                          </span>
                          <i className="bg-blue rounded-3 arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                            <img src={whitearrow} alt="" />
                          </i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddRatesForDirectJob;
