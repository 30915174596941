import React, { useEffect, useState } from "react";
import { JobResponse, Pagination } from "../interface";
import styles from "../../common/css/styles/style.module.css";
import loc from "../../../assets/images/icon_location.svg";
import skill from "../../../assets/images/top_skill.svg";
import bags2 from "../../../assets/images/bags.png";
import share from "../../../assets/images/share_profile.svg";
import compnayname from "../../../assets/images/compnayname.png";
import InfiniteScroll from "react-infinite-scroll-component";
import { getRecommendedWork } from "../api";
import moment from "moment";
import EligibiltyModal from "../../common/modals/eligibilty/page";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../utils/routes/AppRoutes";
import { Card, Placeholder } from "react-bootstrap";

interface UserListProps {
  users: JobResponse[];
  onSelectUser: (user: JobResponse) => void;
  fetchMoreData: () => void;
  onViewFullContract: () => void;
  loading: boolean
}

const UserList: React.FC<UserListProps> = ({
  users,
  onSelectUser,
  fetchMoreData,
  onViewFullContract,
  loading
}) => {
  const navigate = useNavigate();
  // const hasMoreItems = pagination.currentPage < pagination.totalPage;
  const hasMoreItem = true;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [workId, setWorkId] = useState("");

  console.log("user", users);

  useEffect(() => {
    if (users.length > 0) {
      const userId = users[0]._id; // Get the first user's ID
      const queryParams = new URLSearchParams(location.search);
      queryParams.set("userId", userId); // Set the userId param
      const newPath = `${location.pathname}?${queryParams.toString()}`;
      navigate(newPath); // Update the URL without reloading the page
    }
  }, [users, location.pathname, navigate]);

  const handleShowModal = (value: any) => {
    if (
      value.eligibilityQuestions.length > 0 ||
      value.interviewQuestions.length > 0
    ) {
      setShowModal(true);
      setWorkId(value._id);
    } else {
      navigate(`${APP_ROUTES.JOBDETAIL}/${value._id}`);
    }
  };

  const handleUserSelect = (user: JobResponse) => {
    onSelectUser(user); // Select the user
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("userId", user._id); // Update the userId param in the URL
    const newPath = `${location.pathname}?${queryParams.toString()}`;
    navigate(newPath); // Update the URL without reloading the page
  };

  return (
    <>
      {showModal && (
        <EligibiltyModal onClose={() => setShowModal(false)} workId={workId} />
      )}
       {loading?<div className="col-lg-4"><Placeholder  as={"div"} style={{ height: "100vh" }} animation="glow">
            
            <Placeholder style={{ height: "100vh" }}   lg={12} />
          </Placeholder></div>:<div className="col-lg-4">
        <InfiniteScroll
          style={{
            // display: 'flex',
            // flexDirection: 'column-reverse',
            overflowY: "scroll", // Enable vertical scrolling
            scrollbarWidth: "none", // Hide scrollbar for Firefox
            msOverflowStyle: "none", // Hide scrollbar for IE and Edge
          }}
          dataLength={users?.length || 0}
          next={fetchMoreData}
          height={1200}
          hasMore={hasMoreItem}
          loader
        >
          {users?.map((user, index) => (
            <div
              key={index}
              className={`mb-3 ${styles.ContarctOuter}`}
              onClick={() => handleUserSelect(user)}
              style={{ cursor: "pointer" }}
            >
              <div className={styles.opprBox}>
                {/* <div className={`${styles.PendingCOnatctList} ${styles.active}`}> */}
                <div className={`${styles.PendingCOnatctList}`}>
                  <h5 className="fw-400 text-14">#{user?.workNumber}</h5>
                  <h6 className="text-18">
                    {user?.occupation?.name}{" "}
                    <span className={styles.boosted}>boosted</span>
                    <span className={styles.worktype}>{user?.workType}</span>
                  </h6>
                  {/* <div className="mt-2 mb-2">
                  <p className="text-14 mb-0 d-flex align-items-center">
                    <i className="me-2"><img src={compnayname} alt="" width={18} height={18} className="img-fluid" /></i> Company Name
                  </p>
                </div> */}
                  <div className="mt-2 mb-2">
                    <p className="text-14 mb-0 d-flex align-items-center">
                      <i className="me-2">
                        <img
                          src={loc}
                          alt=""
                          width={12}
                          height={12}
                          className="img-fluid"
                        />
                      </i>{" "}
                      {user?.address}
                    </p>
                  </div>
                  <p className="mb-2">
                    <span
                      className={`text-capitalize text-12 ${styles.Hrrate}`}
                      style={{ marginBottom: "0" }}
                    >
                      {" "}
                      <img src={bags2} alt="" />
                      {user?.planType}
                    </span>
                  </p>
                  <div className="">
                    <ul className="mt-2 py-2 row align-items-center justify-content-start mb-0">
                      <li className="col-3 me-0 mb-3">
                        <h6 className="text-12 fw-semibold text-muted">
                          Start Date
                        </h6>
                        <p className="text-12 fw-medium mb-0">
                          {moment(user?.times?.startDate).format("MM-DD-YYYY")}
                        </p>
                      </li>
                      <li className="col-4 me-0 ps-4 mb-3">
                        <h6 className="text-12 fw-semibold text-muted">
                          Duration
                        </h6>
                        <p className="text-12 fw-medium mb-0">
                          {user?.times?.duration === undefined
                            ? "Not Mentioned"
                            : user?.times?.duration > 1
                            ? user?.times?.duration + " " + "Days"
                            : user?.times?.duration + " " + "Day"}
                        </p>
                      </li>
                      <li className="col-5 me-0 mb-3">
                        <h6 className="text-12 fw-semibold text-muted">
                          Estimated End Date
                        </h6>
                        <p className="text-12 fw-medium mb-0">
                          {moment(user?.times?.endDate).format("MM-DD-YYYY")}
                        </p>
                      </li>
                      <li className="col-3 me-0">
                        <h6 className="text-12 fw-semibold text-muted">
                          # of People
                        </h6>
                        <p className="text-12 fw-medium mb-0">
                          {user?.times?.noOfPepole}
                        </p>
                      </li>

                      <li className="col-4 ps-4 me-0">
                        <h6 className="text-12 fw-semibold text-muted">
                          Shift
                        </h6>
                        <p className="text-12 fw-400 mb-0">
                          {user?.times?.shift}
                        </p>
                      </li>
                      <li className="col-5 me-0">
                        <h6 className="text-12 fw-semibold text-muted">
                          Planned Hour Per Day
                        </h6>
                        <p className="text-12 fw-400 mb-0">
                          {user?.times?.plannedHoursPerDay}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className={`row align-items-center my-2 pt-0 ${styles.PenigSkilRate}`}>
                    <div className="col-6">
                      <div className={`row align-items-center`}>
                        <div className="col-3 text-center">
                          <i>
                            <img
                              src={skill}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </i>
                        </div>
                        <div className="col-9 ps-0">
                          <h5 className="mb-0 text-14">Top Skill</h5>
                          {user?.technicalSkills?.map((item, ind) => (
                            <p key={ind} className="text-14 mb-0">
                              {item?.manufacturerName}
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`pt-0 ${styles.PenigSkilRate}`}>
                    <h5 className="text-14 mb-2">Pay Rates / Hour</h5>
                    <ul className="justify-content-between">
                      <li>
                        <span className="text-14 fw-semibold text-muted">
                          Base
                        </span>
                        <label className="text-14 fw-semibold">
                          {user?.rates?.baseRate}
                        </label>
                      </li>
                      <li>
                        <span className="text-14 fw-semibold text-muted">
                          Overtime
                        </span>
                        <label className="text-14 fw-semibold">
                          {user?.rates?.overTimeRate}
                        </label>
                      </li>
                      <li>
                        <span className="text-14 fw-semibold text-muted">
                          Sun/Holidays
                        </span>
                        <label className="text-14 fw-semibold">
                          {user?.rates?.doubleTimeRate}
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-12">
                    <div className={styles.bordeerBottom}></div>
                    <div className="row align-items-center">
                      <div className="col-6">
                        <button
                          type="button"
                          className={`btn w-100 px-3 py-2 bg-blue text-14 ${styles.applybtn}`}
                          // onClick={() => handleShowModal(user)}
                          onClick={onViewFullContract}
                          disabled={user?.isApplied === true}
                        >
                          {user?.isApplied === true
                            ? "Applied"
                            : "View and Apply"}
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          type="button"
                          className={`btn w-100 px-3 py-2 bg-blue text-14 border-blue d-flex align-items-center justify-content-center ${styles.sharebtn}`}
                          // onClick={onViewFullContract}
                        >
                          <i className="me-2">
                            <img
                              src={share}
                              alt=""
                              width={15}
                              height={15}
                              className="img-fluid"
                            />
                          </i>
                          Share
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </InfiniteScroll>
      </div>}
      
    </>
  );
};

export default UserList;
