import React, { useEffect, useState } from "react";
import { Dropdown, FormControl, Modal, ModalFooter, Spinner } from "react-bootstrap";
import penbl from "../../../../assets/images/penbl.png";
import upload from "../../../../assets/images/upload.svg";
import styles from "../styles/style.module.css";
import blackarrow from "../../../../assets/images/blackarrow.svg";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import { MASTER_TYPE, SKILL_TYPE } from "../../../../utils/enums/masterTypes.enum";
import { updateUserProfile } from "../../../home/api";
import { getMasterTypes } from "../../../../feature/auth/signup/api";
import { IOption } from "../../../../Interfaces";
import { uploadFileImg } from "../../../Posts/api";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import { date } from "yup";
import { ProfExperience } from "../../interface";
import { toast } from "react-toastify";
import TextEditorCustomComp from "../../../contractBeforeLogin/HourlyContractType/HourlyContract/StepsComonents/TextEditorCustomComp/TextEditorCustomComp";
import { postMasterType } from "../../../work/api";
import { MESSAGES } from "../../../../utils/messages";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  refresh: () => void;
  indexNumber: number | null;
  profExpData: ProfExperience[];
  handleEdit: (updatedData: ProfExperience) => void;
  initialData: ProfExperience | null;
}

const ProfExpModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  profExpData = [],
  refresh,
  indexNumber,
  initialData,
}) => {
  if (!isOpen) return null;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchTermCompany, setSearchTermCompany] = useState<string>("");

  const [filterValue, setFilterValue] = useState<string>("");
  const [filterValueCompany, setFilterValueCompany] = useState<string>("");
  const [occupation, setOccupation] = useState("");
  const [occupationId, setOccupationId] = useState("");
  const [occuData, setOccuData] = useState<IOption[]>([]);
  const [company, setCompany] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [companyData, setCompanyData] = useState<IOption[]>([]);
  const [img, setImg] = useState<any>("");
  const [newRes, setNewPRes] = useState<any>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [isCurrent, setIsCurrent] = useState<boolean>(false);
  const currentDate = moment(Date.now()).format("YYYY-MM-DD");
  const [index, setIndex] = useState<number | null>(null);
  const [errors, setErrors] = useState<{
    occupation?: string;
    company?: string;
    startDate?: string;
    endDate?: string;
    description?: string;
  }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (initialData) {
      // If there is initial data, pre-fill the form fields
      setOccupation(initialData?.occupationDetail?.name || "");
      setOccupationId(initialData?.occupationDetail?._id || "");
      setCompany(initialData?.companyDetail?.name || "");
      setCompanyId(initialData?.companyDetail?._id || "");
      // setImg(initialData?.image || null);
      setNewPRes(initialData?.description || "");
      setStartDate(moment(initialData?.from).format("YYYY-MM-DD") || "");
      setEndDate(moment(initialData?.to).format("YYYY-MM-DD") || "");
      setIndex(indexNumber);
    }
  }, []);
  console.log("initialData", initialData)
  const editorConfiguration = {
    toolbar: ["undo", "redo", "|", "bold", "italic", "ckwebspeech"],
    height: "150px",
  };

  const loadDropDowns = async () => {
    try {
      const occupationDataRes = await getMasterTypes(MASTER_TYPE.OCCUPATIONS);
      const companyRes = await getMasterTypes(MASTER_TYPE.COMPANIES);

      if (occupationDataRes?.data || companyRes?.data) {
        setOccuData(
          occupationDataRes?.data.map((x: any) => ({
            label: x.name,
            value: x._id,
          }))
        );
        setCompanyData(
          companyRes?.data.map((x: any) => ({
            label: x.name,
            value: x._id,
          }))
        );
      }
    } catch (error) {
      console.error("Failed to load data", error);
    }
  };

  useEffect(() => {
    loadDropDowns();
  }, []);

  const handleSubmitCustomCompany= async () => {
    if (!searchTermCompany.trim()) return; // Ensure input is not empty
    
    const payload = {
      type: MASTER_TYPE.COMPANIES,
      name: searchTermCompany,
      alias: searchTermCompany,
    };
  
    try {
      const response = await postMasterType(payload);
      
      if (response?.data) {
        const newOccupation = { label: response.data.name, value: response.data._id };
  
        // Update occupation dropdown data
        // setOccuData((prevData) => [...prevData, newOccupation]);
        setCompanyData((prevData) => [...prevData, newOccupation]);
  
        // Select the newly added occupation
        // setOccupation(newOccupation.label);
        // setOccupationId(newOccupation.value);
        setCompany(newOccupation.label);
        setCompanyId(newOccupation.value);
  
        toast.success("Company added successfully!");
        setSearchTermCompany(""); 
        setFilterValueCompany("")// Clear input field after successful submission
      }
    } catch (error) {
      // console.error("Error adding custom occupation:", error);
      toast.error("Error adding custom company");
    }
  };


  const handleOccupationChange = (label: string, value: string) => {
    setOccupation(label);
    setOccupationId(value);
  };

  const handleCompanyChange = (label: string, value: string) => {
    setCompany(label);
    setCompanyId(value);
  };

  const handleSubmitCustomOccupation = async () => {
    if (!searchTerm.trim()) return; // Ensure input is not empty
    
    const payload = {
      type: MASTER_TYPE.OCCUPATIONS,
      name: searchTerm,
      alias: searchTerm,
    };
  
    try {
      const response = await postMasterType(payload);
      
      if (response?.data) {
        const newOccupation = { label: response.data.name, value: response.data._id };
  
        // Update occupation dropdown data
        setOccuData((prevData) => [...prevData, newOccupation]);
  
        // Select the newly added occupation
        setOccupation(newOccupation.label);
        setOccupationId(newOccupation.value);
  
        toast.success(MESSAGES.SKILL.SUCCESS);
        setSearchTerm(""); 
        setFilterValue("")// Clear input field after successful submission
      }
    } catch (error) {
      console.error("Error adding custom occupation:", error);
      toast.error(MESSAGES.SKILL.ERROR);
    }
  };

  const handleFileUpload = async (files: FileList | null) => {
    if (files) {
      const formData = new FormData();
      Array.from(files).forEach((img) => {
        formData.append("files", img);
      });
      try {
        const response = await uploadFileImg(formData);
        const newImageUrl = response.data.files[0];
        setImg(newImageUrl);
      } catch (err) { }
    }
  };

  const removeUploadedFiles = (): void => {
    // if (fileIndex >= 0 && fileIndex < img.length) {
    //   const updatedFiles = img.filter((_, index) => index !== fileIndex); // Remove the image at the specified index
    //   setImg(""); // Update the state with the new array
    // }
    setImg("");
  };

  const handleIsCurrent = () => { };

  console.log(isCurrent, "Is current");

  const updateExperience = async () => {
    setErrors({});
    const newErrors: { occupation?: string; company?: string; startDate?: string; endDate?: string; description?: string } = {};
    if (!occupation) {
      newErrors.occupation = "Job Title is required.";
    }
    if (!company) {
      newErrors.company = "Company Name is required.";
    }
    if (!startDate) {
      newErrors.startDate = "Start Date is required.";
    }
    if (!endDate) {
      newErrors.endDate = "End Date is required.";
    }
    if (!newRes) {
      newErrors.description = "Responsibilities are required.";
    }

    // If there are errors, set them and return
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    if (occupation && company && startDate && endDate && newRes || img) {
      if (currentDate >= startDate && currentDate <= endDate) {
        setIsCurrent(true);
      } else {
        setIsCurrent(false);
      }
      console.log(isCurrent, "Is current");
      setIsSubmitting(true);
      const payload = {
        isCurrent: isCurrent,
        occupationId: occupationId,
        description: newRes,
        companyId: companyId,
        from: startDate,
        to: endDate,
      };
      try {
        let existingData: any = [];
        let successMessage = "";
        if (profExpData?.length && initialData) {
          existingData = profExpData?.map((data, ind) => {
            if (ind === index) {
              return {
                isCurrent: payload.isCurrent,
                occupationId: payload.occupationId,
                description: payload.description,
                companyId: payload.companyId,
                from: payload.from,
                to: payload.to,
                _id: data._id
              };
            } else {
              return {
                isCurrent: data.isCurrent,
                occupationId: data.occupationDetail._id,
                description: data.description,
                companyId: data.companyDetail._id,
                from: data.from,
                to: data.to,
                _id: data._id
              };
            }
          });
        } else {
          if (profExpData?.length) {
            existingData = profExpData?.map((data, ind) => {
              return {
                isCurrent: data?.isCurrent,
                occupationId: data.occupationDetail._id,
                description: data.description,
                companyId: data.companyDetail._id,
                from: data.from,
                to: data.to,
                _id: data._id
              };
            });
          }
          existingData = [...existingData, payload];
          successMessage = "Updated successfully!";
        }

        let updatedData = {
          workExperiences: existingData,
        };
        const response = await updateUserProfile(updatedData);
        refresh();
        setIsSubmitting(false);
        toast.success(response?.message);
        onClose();
      } catch (error) {
        console.log(
          "Error occuring while updating the professional experience",
          error
        );
      }
    }
  };

  const occuOptions =
    filterValue && filterValue !== ""
      ? occuData.filter((x) =>
        x.label.toLowerCase().includes(filterValue.toLowerCase())
      )
      : occuData;

  const companyOptions =
    filterValueCompany && filterValueCompany !== ""
      ? companyData.filter((x) =>
        x.label.toLowerCase().includes(filterValueCompany.toLowerCase())
      )
      : companyData;

  return (

    <>
      <Modal show={isOpen} onHide={onClose} centered size="lg" backdrop='static'>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className="addModalPro">
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="formGroup">
                      <label>Job Title</label>
                      <div>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="primary"
                            id="dropdown-basic"
                          >
                            {occupation || "Please select an option"}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <div className="">
                              <FormControl
                                autoFocus
                                className="mx-3 my-2 w-auto"
                                placeholder="Search..."
                                onChange={(e) => setFilterValue(e.target.value)}
                                value={filterValue}
                              />
                            </div>

                            <div
                              style={{
                                maxHeight: "300px",
                                // overflowY: "scroll",
                              }}
                            >
                              {occuOptions.length === 0 ? (
                                <Dropdown.Item disabled>
                                  No options found
                                </Dropdown.Item>
                              ) : (
                                [
                                  {
                                    label: "Select primary occupation",
                                    value: "",
                                  },
                                  ...occuOptions,
                                ].map((option, index: number) => (
                                  <>
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() =>
                                        handleOccupationChange(
                                          option.label,
                                          option.value
                                        )
                                      }
                                    >
                                      {option.label}
                                    </Dropdown.Item>
                                  </>
                                ))
                              )}

                              <div className={styles.addOccuatin}>
                                <div className="col-8">
                                  <input
                                    type="text"
                                    placeholder="Type an occupation here you want to add..."
                                    name="customOccupation"
                                    value={searchTerm}
                                    onChange={(e) =>
                                      setSearchTerm(e.target.value)
                                    }
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        handleSubmitCustomOccupation();
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                        {errors.occupation && <div className="text-danger">{errors.occupation}</div>}
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "100%" }}>
                    <div style={{ marginBottom: "12px" }}>
                      <label style={{ fontWeight: "bold", display: "block", marginBottom: "6px" }}>
                        Company Name
                      </label>
                      <Dropdown style={{ width: "100%" }}>
                        <Dropdown.Toggle
                          variant="primary"
                          id="dropdown-basic"
                          style={{ width: "100%", textAlign: "left" }}
                        >
                          {company || "Please select an option"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          style={{
                            position: "absolute",
                            zIndex: 1050,
                            width: "100%",
                            maxHeight: "250px",
                            overflowY: "auto",
                            background: "white",
                            border: "1px solid #ccc",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          {/* Search Input */}
                          <div style={{ padding: "8px 12px" }}>
                            <FormControl
                              autoFocus
                              style={{
                                width: "100%",
                                padding: "6px",
                                border: "1px solid #ddd",
                                borderRadius: "4px",
                              }}
                              placeholder="Search..."
                              onChange={(e) => setFilterValueCompany(e.target.value)}
                              value={filterValueCompany}
                            />
                          </div>

                          {/* Dropdown Items */}
                          <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                            {companyOptions.length === 0 ? (
                              <Dropdown.Item disabled>No options found</Dropdown.Item>
                            ) : (
                              [
                                { label: "Select primary company", value: "" },
                                ...companyOptions,
                              ].map((option, index) => (
                                <Dropdown.Item
                                  key={index}
                                  onClick={() => handleCompanyChange(option.label, option.value)}
                                  style={{ padding: "8px 12px", cursor: "pointer" }}
                                >
                                  {option.label}
                                </Dropdown.Item>
                              ))
                            )}
                          </div>

                          {/* Custom Company Input */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "8px 12px",
                              borderTop: "1px solid #ddd",
                            }}
                          >
                            <input
                              type="text"
                              placeholder="Type a company name to add..."
                              name="customCompany"
                              value={searchTermCompany}
                              onChange={(e) => setSearchTermCompany(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  console.log("enter")
                                  handleSubmitCustomCompany();
                                  e.preventDefault();
                                }
                              }}
                              style={{
                                flex: 1,
                                padding: "6px",
                                border: "1px solid #ddd",
                                borderRadius: "4px",
                              }}
                            />
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>

                      {/* Error Message */}
                      {errors.company && (
                        <div style={{ color: "red", marginTop: "6px", fontSize: "14px" }}>
                          {errors.company}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-12">
  <div className="formGroup">
    <label>Start Date</label>
    <div>
      <input
        type="date"
        value={startDate}
        onChange={(e) => {
          const newStartDate = e.target.value;
          setStartDate(newStartDate);

          // Reset the endDate if it's earlier than the startDate
          if (endDate && newStartDate > endDate) {
            setEndDate("");
            setErrors((prev) => ({
              ...prev,
              endDate: "End date cannot be earlier than the start date.",
            }));
          } else {
            setErrors((prev) => ({ ...prev, endDate: undefined }));
          }
        }}
        className="form-control"
        placeholder="02/25/2024"
      />
      {errors.startDate && <div className="text-danger">{errors.startDate}</div>}
    </div>
  </div>
</div>

<div className="col-lg-6 col-12">
  <div className="formGroup">
    <label>Last Date</label>
    <div style={{ marginBottom: "30px" }}>
      <input
        type="date"
        value={endDate}
        onChange={(e) => {
          const newEndDate = e.target.value;
          if (newEndDate < startDate) {
            setErrors((prev) => ({
              ...prev,
              endDate: "End date cannot be earlier than the start date.",
            }));
          } else {
            setErrors((prev) => ({ ...prev, endDate: undefined }));
            setEndDate(newEndDate);
          }
        }}
        className="form-control"
        placeholder="02/25/2024"
      />
      {errors.endDate && <div className="text-danger">{errors.endDate}</div>}
    </div>
  </div>
</div>

                  <div className="col-12">
                    <div className="formGroup">
                      <label>Responsibilities</label>
                      <div style={{ marginBottom: "30px" }}>
                        {<TextEditorCustomComp
                          data={newRes}
                          setDesc={setNewPRes}
                          setIndex={() => { }}
                          index={0}
                        />}
                        {errors.description && <div className="text-danger">{errors.description}</div>}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="formGroup">
                      <label>Upload Image</label>
                      <div
                        className={styles.uploadimagefile}
                        style={{ marginBottom: "30px" }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          className="form-control"
                          placeholder="eg : Version 1.4, IRC5, Mustang Etc."
                          onChange={({ target }) =>
                            handleFileUpload(target.files)
                          }
                        />
                        <div className={styles.uploadimage}>
                          <i>
                            <img src={upload} alt="" />
                          </i>
                          <p className="mb-0 text-14 text-blue">
                            Upload jpeg/png file
                          </p>
                        </div>
                      </div>
                      {img && (
                        <li>
                          <div className={styles.uploadImages}>
                            <img src={img} alt="" className="img-fluid" />
                            <i
                              className="fas fa-times"
                              onClick={removeUploadedFiles} // Call the function to remove the image
                            ></i>
                          </div>
                        </li>
                      )}
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </Modal.Body>
          <ModalFooter className="btn-hover-effect mb-2 justify-content-end">
            <div className="btn-hover-effect">
              <h6 onClick={onClose} className="btn bg-white arrowyellow border border-1 rounded-3 position-relative ps-5 backbutton w-auto"
              >
                <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                  Back
                </span>
                <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                  <img src={blackarrow} alt="" style={{ transform: "rotate(-180deg)" }} />
                </i>
              </h6>
            </div>
            <div className="btn-hover-effect">
              <h6 onClick={updateExperience} className="btn bg-white arrowblue border border-1 rounded-3 position-relative pe-5 w-auto">
                <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                  {isSubmitting ? (
                    <Spinner animation="border" size="sm" className="me-2" />
                  ) : (
                    "Save"
                  )}
                </span>
                <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                  <img src={whitearrow} alt="" />
                </i>
              </h6>
            </div>

          </ModalFooter>
        </form>

      </Modal>
    </>
  );
};

export default ProfExpModal;
