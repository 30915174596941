import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../assets/images/blackarrow.svg";
import { updateUserProfile } from "../../../home/api";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import TextEditorCustomComp from "../../../contractBeforeLogin/HourlyContractType/HourlyContract/StepsComonents/TextEditorCustomComp/TextEditorCustomComp";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  refresh: () => void;
  bio: string | undefined;
}

const ShortBioModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  bio,
  refresh,
}) => {
  const [newBio, setNewBio] = useState(bio || "");
  const [validationError, setValidationError] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleBioUpdate = async () => {
    console.log("newBio", newBio);
    const nbl=newBio.trim().split(/\s+/).length
    if (!newBio || newBio.trim() === "" || newBio === "<p>&nbsp;</p>"||newBio==="<br>") {
      setValidationError("Bio cannot be empty.");
      return;
    }
    if(nbl>50)
    {
      setValidationError("Bio cannot be more than 50 words.");
      return;
    }

    setValidationError(""); // Clear validation error if present
    setIsSubmitting(true);

    const payload = { bio: newBio };

    try {
      await updateUserProfile(payload);
      refresh();
      setIsSubmitting(false);
      toast.success("Updated Successfully");
      onClose();
    } catch (error) {
      console.log("Error occurred while updating Bio", error);
    }
  };

  return (
    <Modal show={isOpen} onHide={onClose} centered size="lg" backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <form>
        <Modal.Body>
          <div className="row p-3">
            <div className="col-12">
              <div className="formGroup">
                <label className="fw-semibold mb-2">Short Bio*</label>
                <TextEditorCustomComp
                  data={newBio}
                  setDesc={setNewBio}
                  setIndex={() => { }}
                  index={0}
                />
                {validationError && (
                  <div style={{ color: "red", marginTop: "10px" }}>
                    {validationError}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="btn-hover-effect mb-2 justify-content-end">
          <Button
            type="button"
            variant="secondary"
            onClick={onClose}
            className="btn bg-white arrowyellow border border-1 rounded-3 position-relative ps-5 backbutton w-auto"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              Back
            </span>
            <i
              className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3"

            >
              <img src={blackarrow} alt="" />
            </i>
          </Button>

          <Button
            type="button"
            // variant="secondary"
            onClick={handleBioUpdate} // Updated to ensure the onClick triggers correctly
            className="btn arrowblue bg-white border-grey border-1 position-relative pe-5 w-auto"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              {isSubmitting ? (
                <Spinner animation="border" size="sm" className="me-2" />
              ) : (
                "Save"
              )}
            </span>
            <i
              className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3"
            >
              <img src={whitearrow} alt="" />
            </i>
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ShortBioModal;
